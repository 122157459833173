import React, { Component } from "react";
import ProfileSidebar from "../../components/ProfileSidebar";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";

import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";

const cookies = new Cookies();

class CoursePaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      enrollment: [],
      total_payable: 0,
      total_paid: 0,
      total_due: 0,
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    document.title = "Payment Info";
    const url = BASE_URL + "/api/student/myCourses";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        res.data[0].map((course) => {
          if (course.id == parseInt(this.props.match.params.id)) {
            this.setState({
              course: course,
            });
          }
          let enrolled_course = res.data[1].find(
            (content) =>
              content.course_id == parseInt(this.props.match.params.id)
          );
          let n2 = 0;
          let n3 = 0;
          let n4 = 0;
          let n5 = 0;
          if (enrolled_course.second_payment !== null) {
            n2 = enrolled_course.second_payment;
          }
          if (enrolled_course.third_payment !== null) {
            n3 = enrolled_course.third_payment;
          }
          if (enrolled_course.fourth_payment !== null) {
            n4 = enrolled_course.fourth_payment;
          }
          if (enrolled_course.fifth_payment !== null) {
            n5 = enrolled_course.fifth_payment;
          }
          let paid =
            parseInt(enrolled_course.first_payment) +
            parseInt(n2) +
            parseInt(n3) +
            parseInt(n4) +
            parseInt(n5);
          let due =
            parseInt(enrolled_course.payable) -
            (parseInt(enrolled_course.first_payment) +
              parseInt(n2) +
              parseInt(n3) +
              parseInt(n4) +
              parseInt(n5));
          this.setState({
            total_payable: parseInt(enrolled_course.payable),
            total_paid: parseInt(paid),
            total_due: parseInt(due),
            enrollment: enrolled_course,
            loading: false,
          });
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        }
      });
  }
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div
              className={`${
                this.state.isMobile ? "col-md-12  bg-primary" : "leftbar"
              }`}
            >
              {this.state.sidebar ? <ProfileSidebar /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              {this.state.loading ? (
                <div className="col-md-8 offset-md-2 mt-3">
                  <h1>
                    <Skeleton count={3} />
                  </h1>
                  <h3>
                    <Skeleton count={5} />
                  </h3>
                  <Skeleton count={8} />
                </div>
              ) : (
                <div className="container">
                  <div className="row">
                    <span className=" mt-3 mb-2">
                      <Link
                        to="/profile/payment-status"
                        className="nav-link text-dark pr-0"
                      >
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          {_t(t("Payment Status"))}
                        </h4>
                      </Link>
                    </span>
                    <span className="mt-3 mb-2">
                      <Link
                        to={`/my-courses/${this.props.match.params.id}`}
                        className="nav-link text-dark"
                      >
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          {this.state.course.title}
                        </h4>
                      </Link>
                    </span>
                  </div>
                  <div className="row">
                    <div className="container">
                      <div className="row">
                        <div className="container">
                          <div className="card-body bg-light">
                            <div className="row bg-primary text-white text-center text-capitalize p-3 mb-3">
                              <div className="col bg-warning text-dark rounded-sm mx-2 py-1 mb-2">
                                {_t(t("Course Fee"))} : {this.state.course.fee}{" "}
                                BDT
                              </div>
                              <div className="col bg-success rounded-sm mx-2 py-1 mb-2">
                                {_t(t("Paid"))} : {this.state.total_paid} BDT
                              </div>
                              {this.state.enrollment.discount ? (
                                <div className="col bg-secondary rounded-sm mx-2 py-1 mb-2">
                                  {_t(t("Discount"))} :{" "}
                                  {this.state.enrollment.discount}{" "}
                                  {_t(t("BDT"))}
                                </div>
                              ) : null}
                              <div className="col bg-danger rounded-sm mx-2 py-1 mb-2">
                                {_t(t("Due"))} : {this.state.total_due}{" "}
                                {_t(t("BDT"))}
                              </div>
                            </div>
                            <div className="row table-responsive">
                              <table className="table table-striped table-bordered text-center">
                                <thead>
                                  <th>{_t(t("S/L"))}</th>
                                  <th>{_t(t("Payment Title"))}</th>
                                  <th>{_t(t("Payable"))}</th>
                                  <th>{_t(t("Paid"))}</th>
                                  <th>{_t(t("Last Date"))}</th>
                                  <th>{_t(t("Paid Date"))}</th>
                                  <th>{_t(t("Status"))}</th>
                                </thead>
                                <tbody>
                                  {this.state.enrollment.first_payment ? (
                                    <tr>
                                      <td>1</td>
                                      <td>
                                        1<sup>st </sup> {_t(t("Payment"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.first_payment}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.first_payment}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>-</td>
                                      <td>
                                        <Moment format="LL">
                                          {
                                            this.state.enrollment
                                              .first_paid_date
                                          }
                                        </Moment>
                                      </td>
                                      {this.state.enrollment.first_payment ? (
                                        <td className="text-success">
                                          <i class="fa fa-check-circle"></i>
                                        </td>
                                      ) : (
                                        <td className="text-danger">
                                          <i class="fa fa-times-circle"></i>
                                        </td>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.enrollment.second_payment_date ? (
                                    <tr>
                                      <td>2</td>
                                      <td>
                                        2<sup>nd </sup>
                                        {_t(t("Payment"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.second_payable}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.second_payment
                                          ? this.state.enrollment
                                              .second_payment +
                                            `${_t(t("BDT"))}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .second_payment_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .second_payment_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .second_paid_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .second_paid_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      {this.state.enrollment.second_payment ? (
                                        <td className="text-success">
                                          <i class="fa fa-check-circle"></i>
                                        </td>
                                      ) : (
                                        <td className="text-danger">
                                          <i class="fa fa-times-circle"></i>
                                        </td>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.enrollment.third_payment_date ? (
                                    <tr>
                                      <td>3</td>
                                      <td>
                                        3<sup>rd </sup> {_t(t("Payment"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.third_payable}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.third_payment
                                          ? this.state.enrollment
                                              .third_payment + `${_t(t("BDT"))}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .third_payment_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .third_payment_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .third_paid_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .third_paid_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      {this.state.enrollment.third_payment ? (
                                        <td className="text-success">
                                          <i class="fa fa-check-circle"></i>
                                        </td>
                                      ) : (
                                        <td className="text-danger">
                                          <i class="fa fa-times-circle"></i>
                                        </td>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.enrollment.fourth_payment_date ? (
                                    <tr>
                                      <td>4</td>
                                      <td>
                                        4<sup>th </sup> {_t(t("Payment"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.fourth_payable}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.fourth_payment
                                          ? this.state.enrollment
                                              .fourth_payment +
                                            `${_t(t("BDT"))}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .fourth_payment_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .fourth_payment_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .fourth_paid_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .fourth_paid_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      {this.state.enrollment.fourth_payment ? (
                                        <td className="text-success">
                                          <i class="fa fa-check-circle"></i>
                                        </td>
                                      ) : (
                                        <td className="text-danger">
                                          <i class="fa fa-times-circle"></i>
                                        </td>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.enrollment.fifth_payment_date ? (
                                    <tr>
                                      <td>5</td>
                                      <td>
                                        5<sup>th </sup> {_t(t("Payment"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.fifth_payable}{" "}
                                        {_t(t("BDT"))}
                                      </td>
                                      <td>
                                        {this.state.enrollment.fifth_payment
                                          ? this.state.enrollment
                                              .fifth_payment + `${_t(t("BDT"))}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .fifth_payment_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .fifth_payment_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {this.state.enrollment
                                          .fifth_paid_date ? (
                                          <Moment format="LL">
                                            {
                                              this.state.enrollment
                                                .fifth_paid_date
                                            }
                                          </Moment>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      {this.state.enrollment.fifth_payment ? (
                                        <td className="text-success">
                                          <i class="fa fa-check-circle"></i>
                                        </td>
                                      ) : (
                                        <td className="text-danger">
                                          <i class="fa fa-times-circle"></i>
                                        </td>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(CoursePaymentStatus));
