import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_confirmation: "",
      token: props.match.params.token,
      error: "",
      pwError: [],
      alert: "",
      loading: false,
    };
  }

  componentDidMount() {
    document.title = "Reset Password";
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/auth/student/setNewPassword";
    const user = {
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      token: this.state.token,
    };
    return axios
      .post(url, user)
      .then((res) => {
        if (res.data === 200) {
          this.setState({
            alert: "Your password has been reseted successfully.",
            error: "",
            email: "",
            pwError: [],
            password: "",
            password_confirmation: "",
            loading: false,
          });
        } else {
          this.setState({
            alert: "",
            pwError: [],
            error:
              "Please follow the link from your email and enter your email here correctly.",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          pwError: error.response.data.errors,
          loading: false,
        });
      });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card m-4 login-card">
                <div className="card-body bg-info text-center text-white">
                  <h3 className="text-center text-uppercase mb-5 mt-3">
                    {_t(t("Set New Password"))}
                  </h3>
                  {this.state.loading ? (
                    <div className="col-md-12 text-center bg-warning text-dark p-3 mt-2 rounded-sm">
                      {_t(t("Please wait!"))}
                    </div>
                  ) : (
                    <form className="col-md-12" onSubmit={this.handleSubmit}>
                      {this.state.error ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.error}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.alert ? (
                        <div className="text-center text-white rounded-sm p-2 bg-success">
                          {this.state.alert}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.pwError ? (
                        <div className="">
                          {this.state.pwError.password ? (
                            <div className="">
                              {this.state.pwError.password.map((item) => {
                                return (
                                  <div className="text-center text-white rounded-sm p-2 bg-danger my-1">
                                    {item}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-light rounded-circle">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded ml-3"
                          placeholder="Enter your email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-light rounded-circle">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          className="form-control rounded ml-3"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-light rounded-circle">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="password_confirmation"
                          className="form-control rounded ml-3"
                          placeholder="Confirm Password"
                          value={this.state.password_confirmation}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mt-5">
                        <div className="text-center">
                          <button type="submit" className="btn btn-light">
                            {_t(t("Save"))}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(SetNewPassword));
