import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";

// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ViewModerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moderator_pic: "",
      moderator_id: "",
      fname: "",
      lname: "",
      phn_no: "",
      email: "",
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Moderator's Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const moderator_url =
      BASE_URL + `/api/admin/moderator/moderator_p170815s/${id}`;
    return axios
      .get(moderator_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          moderator_pic: res.data.image,
          moderator_id: res.data.id,
          fname: res.data.fname,
          lname: res.data.lname,
          phn_no: res.data.phn_no,
          email: res.data.email,
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.moderator_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          <span className="text-primary">
                            {this.state.fname} {this.state.lname}
                          </span>
                          's information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <Link
                        to="/admin/manage-moderator"
                        className="nav-link text-light bg-secondary mb-3 rounded-sm ml-2"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                    </div>
                    {this.state.loading ? (
                      <div className="container">
                        <div className="mt-3">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-3 text-center">
                          <img
                            src={`${BASE_URL}${`/images/moderator/${this.state.moderator_pic}`}`}
                            alt="avatar"
                            className="img-fluid mx-4 rounded-sm"
                            style={{ height: "200px" }}
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="container">
                            <div className="bg-light">
                              <table className="table table-striped table-bordered">
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <td>Name</td>
                                    <td>
                                      {this.state.fname +
                                        " " +
                                        this.state.lname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>{this.state.email} </td>
                                  </tr>
                                  <tr>
                                    <td>Contact No.</td>
                                    <td>{this.state.phn_no}</td>
                                  </tr>
                                  {/* <tr>
                                    <td>Course Assigned</td>
                                    <td></td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary ml-1 mb-2"
                                onClick={() => {
                                  this.props.history.push(
                                    `/admin/edit-moderator/${this.state.moderator_id}`
                                  );
                                }}
                              >
                                Update Info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ViewModerator);
