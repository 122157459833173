import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";

// Components
import Sidebar from "../InstructorSidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class InstructorDiscussionClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      classes: [],
      searchedClasses: [],
      search: false,
      search_query: "",
      timeout: 0,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Comments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const classes_url =
      BASE_URL +
      "/api/instructor/get_discussion_classes/" +
      this.props.match.params.id;
    return axios
      .get(classes_url, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          course: res.data[0],
          classes: res.data[1],
          loading: false,
        });
        if (res.data[0] === null || res.data[1] === null) {
          this.props.history.replace({
            pathname: `/instructor/check-comments/courses`,
          });
        }
      })
      .catch(() => {
        this.props.history.replace({
          pathname: `/instructor/check-comments/courses`,
        });
      });
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/instructor/findClass/" + searchText;
      let formData = {
        course_id: this.props.match.params.id,
      };
      if (searchText !== null && searchText !== "") {
        return axios
          .post(url, formData, {
            headers: {
              Authorization: `Bearer ${cookies.get("instructorToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedClasses: res.data,
            });
          });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                {this.state.loading ? (
                  <Skeleton height="40px" className="my-3" />
                ) : (
                  <>
                    <div className="row">
                      <span className="mt-1">
                        <div className="nav-link text-dark">
                          <h4>
                            <i
                              className="fa fa-chevron-right"
                              style={{ fontSize: 20 }}
                            ></i>{" "}
                            All Classes of "
                            <span className="text-primary">
                              {this.state.course.title}
                            </span>
                            "
                          </h4>
                        </div>
                      </span>
                    </div>
                  </>
                )}
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <Link
                        to="/instructor/check-comments/courses"
                        className="nav-link text-light bg-secondary rounded-sm ml-2"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                      <form
                        className="ml-auto"
                        onSubmit={this.handleSearchSubmit}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            name="search_query"
                            className="form-control text-center"
                            placeholder="Search.."
                            onChange={this.handleSearch}
                          />
                          <div className="input-group-append d-none">
                            <button
                              type="submit"
                              className="input-group-text bg-primary text-white"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {this.state.loading ? (
                      <div className="row">
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row mt-3">
                          {!this.state.search
                            ? [
                                this.state.classes.map((item, index) => {
                                  return (
                                    <div className="col-md-4">
                                      <Link
                                        to={{
                                          pathname: `/instructor/check-comments/class/${item.id}`,
                                        }}
                                        className="card-link"
                                      >
                                        <div className="card class-card bg-light text-center">
                                          <div className="card-body">
                                            <h4>Class name</h4>
                                            <div className="text-primary text-truncate">
                                              {item.title}
                                            </div>
                                          </div>
                                          <div className="card-footer">
                                            <p>
                                              Main Concept
                                              <br />
                                              <span className="text-truncate">
                                                {item.content_about}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                }),
                              ]
                            : [
                                this.state.searchedClasses.length > 0 ? (
                                  this.state.searchedClasses.map(
                                    (item, index) => {
                                      return (
                                        <div className="col-md-4">
                                          <Link
                                            to={{
                                              pathname: `/instructor/check-comments/class/${item.id}`,
                                            }}
                                            className="card-link"
                                          >
                                            <div className="card class-card bg-light text-center">
                                              <div className="card-body">
                                                <h4>Class name</h4>
                                                <div className="text-primary text-truncate">
                                                  {item.title}
                                                </div>
                                              </div>
                                              <div className="card-footer">
                                                <p>
                                                  Main Concept
                                                  <br />
                                                  <span className="text-truncate">
                                                    {item.content_about}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="col-md-12 text-center  my-auto">
                                    <div className="card card-body bg-light text-danger no-searched-course mb-2">
                                      No class found by your query.
                                    </div>
                                  </div>
                                ),
                              ]}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(InstructorDiscussionClass);
