import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class EditStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_pic: "",
      student_id: "",
      fname: "",
      lname: "",
      image: "",
      gender: "",
      givenDateOfBirth: "",
      dateOfBirth: null,
      phn_no: "",
      occupation: "",
      address: "",
      email: "",
      fb: "",
      student_type: "",
      note: "",
      ip_limit: "",
      password: "",
      password_confirmation: "",
      error: [],
      fileError: "",
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    document.title = "Edit Student Info";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const student_url = BASE_URL + `/api/admin/student/student_p170815s/${id}`;
    return axios
      .get(student_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          student_pic: res.data.image,
          student_id: res.data.id,
          fname: res.data.fname,
          lname: res.data.lname,
          gender: res.data.gender,
          phn_no: res.data.phn_no,
          givenDateOfBirth: res.data.dateOfBirth,
          occupation: res.data.occupation,
          address: res.data.address,
          email: res.data.email,
          fb: res.data.fb,
          student_type: res.data.student_type,
          note: res.data.note,
          ip_limit: res.data.ip_limit,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
  };

  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url =
      BASE_URL + `/api/admin/student/student_p170815s/${this.state.student_id}`;
    const formData = {
      fname: this.state.fname,
      lname: this.state.lname,
      gender: this.state.gender,
      dateOfBirth: this.state.dateOfBirth,
      phn_no: this.state.phn_no,
      occupation: this.state.occupation,
      address: this.state.address,
      email: this.state.email,
      fb: this.state.fb,
      student_type: this.state.student_type,
      note: this.state.note,
      ip_limit: this.state.ip_limit,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      file: this.state.image,
    };
    return axios
      .patch(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          student_pic: res.data.student.image,
          student_id: res.data.student.id,
          fname: res.data.student.fname,
          lname: res.data.student.lname,
          gender: res.data.student.gender,
          phn_no: res.data.student.phn_no,
          givenDateOfBirth: res.data.student.dateOfBirth,
          occupation: res.data.student.occupation,
          address: res.data.student.address,
          email: res.data.student.email,
          fb: res.data.student.fb,
          student_type: res.data.student_type,
          note: res.data.note,
          ip_limit: res.data.ip_limit,
          image: "",
          password: "",
          password_confirmation: "",
          error: [],
          fileError: "",
          loading: false,
        });
        this.props.history.push({
          pathname: "/admin/manage-student",
          state: {
            alert_success: res.data.msg,
          },
        });
      })
      .catch((error) => {
        if (error.response.data.message == "Image source not readable") {
          this.setState({
            fileError:
              error.response.data.message +
              ". " +
              "Choose a valid image with jpeg, jpg, png extention.",
          });
        } else {
          this.setState({
            fileError: "",
          });
        }
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.student_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          Update{" "}
                          <span className="text-primary">
                            {this.state.fname} {this.state.lname}
                          </span>
                          's information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <Link
                        to="/admin/manage-student"
                        className="nav-link text-light bg-secondary mb-1 rounded-sm"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Please fill the form accordingly
                      </div>
                      {this.state.loading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      ) : (
                        <>
                          <div className="text-center mb-3">
                            <img
                              src={`${BASE_URL}${`/images/student/${this.state.student_pic}`}`}
                              alt="avatar"
                              className="img-fluid"
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                          <form
                            className="form-group"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="student_type"
                                  className="col-md-3 my-auto"
                                >
                                  Student Type
                                </label>
                                <div className="col-md-9 pl-0">
                                  <select
                                    name="student_type"
                                    className="form-control rounded-sm pl-2"
                                    onChange={this.handleChange}
                                    value={this.state.student_type}
                                    required
                                  >
                                    <option value="">
                                      Please select a student type
                                    </option>
                                    <option
                                      key="1"
                                      value="Office"
                                      className="text-capitalize"
                                    >
                                      Office
                                    </option>
                                    <option
                                      key="2"
                                      value="Virtual"
                                      className="text-capitalize"
                                    >
                                      Virtual
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="fname"
                                  className="col-md-3 my-auto"
                                >
                                  First Name
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    placeholder="Type first name"
                                    className="form-control"
                                    value={this.state.fname}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.fname ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fname.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="lname"
                                  className="col-md-3 my-auto"
                                >
                                  Last Name
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    placeholder="Type last name"
                                    className="form-control"
                                    value={this.state.lname}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.lname ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.lname.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="image"
                                  className="col-md-3 my-auto"
                                >
                                  Image
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    className="form-control-file pl-0"
                                    onChange={this.handleChangeFile}
                                  />
                                </div>
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.fileError
                                    ? "*" + this.state.fileError
                                    : ""}
                                </div>
                              </div>
                              <div className="col-md-7 input-group mb-2">
                                <label
                                  htmlFor={"male" || "female"}
                                  className="col-md-5 my-auto mr-2"
                                >
                                  Gender
                                </label>
                                <div className="col-md-7 pl-0 my-auto form-control pl-2 ml-1">
                                  <input
                                    type="radio"
                                    value="MALE"
                                    id="male"
                                    name="gender"
                                    className="form-check-inline"
                                    onChange={this.handleChange}
                                    checked={this.state.gender == "MALE"}
                                    required
                                  />
                                  <label
                                    htmlFor="male"
                                    className="my-auto form-check-label mr-4"
                                  >
                                    {" "}
                                    Male
                                  </label>
                                  <input
                                    type="radio"
                                    value="FEMALE"
                                    id="female"
                                    name="gender"
                                    className="form-check-inline"
                                    onChange={this.handleChange}
                                    checked={this.state.gender == "FEMALE"}
                                    required
                                  />
                                  <label
                                    htmlFor="female"
                                    className="my-auto form-check-label"
                                  >
                                    {" "}
                                    Female
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-5 input-group mb-2">
                                <label
                                  htmlFor="dateOfBirth"
                                  className="col-md-5 my-auto"
                                >
                                  Date of Birth
                                </label>
                                <div className="col-md-7 pl-0">
                                  <DatePicker
                                    selected={this.state.dateOfBirth}
                                    onChange={this.handleDateOfBirth}
                                    dateFormat="yyyy/MM/dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText={
                                      this.state.givenDateOfBirth
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="phn_no"
                                  className="col-md-3 my-auto"
                                >
                                  Phone No.
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="phn_no"
                                    name="phn_no"
                                    placeholder="01x xxxx"
                                    className="form-control"
                                    value={this.state.phn_no}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.phn_no ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.phn_no.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="occupation"
                                  className="col-md-3 my-auto"
                                >
                                  Occupation
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="occupation"
                                    name="occupation"
                                    placeholder="Type occupation"
                                    className="form-control"
                                    value={this.state.occupation}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.occupation ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.occupation.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="address"
                                  className="col-md-3 my-auto"
                                >
                                  Address
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Type address"
                                    className="form-control"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.address ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.address.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="email"
                                  className="col-md-3 my-auto"
                                >
                                  Email
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="example@softechit.com"
                                    className="form-control"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.email ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.email.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="ip_limit"
                                  className="col-md-3 my-auto"
                                >
                                  Ip Limit
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="number"
                                    id="ip_limit"
                                    name="ip_limit"
                                    placeholder="Ip limitation- e.g: 3"
                                    className="form-control"
                                    value={this.state.ip_limit}
                                    onChange={this.handleChange}
                                    min="1"
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.ip_limit ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.ip_limit.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="email"
                                  className="col-md-3 my-auto"
                                >
                                  Facebook
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="url"
                                    id="fb"
                                    name="fb"
                                    placeholder="https://www.facebook.com/example"
                                    className="form-control"
                                    value={this.state.fb}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.fb ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fb.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="note"
                                  className="col-md-3 my-auto"
                                >
                                  Note
                                </label>
                                <div className="col-md-9 pl-0">
                                  <textarea
                                    id="note"
                                    name="note"
                                    placeholder="Type a note on student"
                                    className="form-control"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.note}
                                  </textarea>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.note ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.note.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="password"
                                  className="col-md-3 my-auto"
                                >
                                  Password
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    className="form-control"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 input-group">
                                <label
                                  htmlFor="password_confirmation"
                                  className="col-md-3 my-auto"
                                >
                                  Password Confirmation
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="password"
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    placeholder="Re-type password"
                                    className="form-control"
                                    value={this.state.password_confirmation}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.password ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.password.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success btn-sm mt-2 px-4"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(EditStudent);
