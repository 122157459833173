import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { _t } from "../functions/Functions";
const cookies = new Cookies();

class ProfileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      feedback: [],
      CommentNotifications: [],
      course_id: null,
      enrollments: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/student/myCourses";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          courses: res.data[0],
          enrollments: res.data[1],
          course_id: res.data[0][0] ? res.data[0][0].id : null,
        });
        const user_url = BASE_URL + "/api/auth/student/me";
        return axios
          .get(user_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              student: res.data,
            });
            const feedback_url =
              BASE_URL + "/api/student/unseen_feedback/" + res.data.id;
            return axios
              .get(feedback_url, {
                headers: {
                  Authorization: `Bearer ${cookies.get("studentToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  feedback: res.data[0],
                });

                const CommentNotifications_url =
                  BASE_URL + "/api/student/notifications";
                return axios
                  .get(CommentNotifications_url, {
                    headers: {
                      Authorization: `Bearer ${cookies.get("studentToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      CommentNotifications: res.data[0],
                      loading: false,
                    });
                  });
              });
          });
      });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="text-uppercase">
          <NavLink
            to={{pathname:"/refresh", state:"/profile/personal-info"}}
            className={`nav-link profile-sidebar-link ${window.location.href.includes("/profile/personal-info") ? 'profile-sidebar-active': "" }`}
          >
            {_t(t("Personal Info"))}
          </NavLink>

          {this.state.courses.length > 1 ? (
            <NavLink
              to={{pathname:"/refresh", state:"/my-courses"}}
              className={`nav-link profile-sidebar-link ${window.location.href.includes("/my-courses") ? 'profile-sidebar-active': "" }`}

            >
              {_t(t("My Courses"))}{" "}
              {!this.state.loading ? (
                <sup className="badge badge-sm badge-warning">
                  {this.state.courses.length}
                </sup>
              ) : (
                ""
              )}
            </NavLink>
          ) : (
            [
              this.state.loading ? (
                <NavLink
                  to={{pathname:"/refresh", state:"/my-courses"}}
                  className={`nav-link profile-sidebar-link ${window.location.href.includes("/my-courses") ? 'profile-sidebar-active': "" }`}
                >
                  {_t(t("My Courses"))}
                </NavLink>
              ) : (
                <NavLink
                  to={{pathname:"/refresh", state:`/my-courses/${this.state.course_id}`}}
                  className={`nav-link profile-sidebar-link ${window.location.href.includes("/my-courses") ? 'profile-sidebar-active': "" }`}
                >
                  {_t(t("My Courses"))}{" "}
                  <sup className="badge badge-sm badge-warning">
                    {this.state.courses.length}
                  </sup>
                </NavLink>
              ),
            ]
          )}

          <NavLink
            to={{pathname:"/refresh", state:"/profile/payment-status"}}
            className={`nav-link profile-sidebar-link ${window.location.href.includes("/profile/payment-status") ? 'profile-sidebar-active': "" }`}
          >
            {_t(t("Payment Info"))}
          </NavLink>

          {this.state.CommentNotifications.length > 0 ? (
            <NavLink
              to={{pathname:"/refresh", state:"/notifications"}}
              className={`nav-link profile-sidebar-link ${window.location.href.includes("/notifications") ? 'profile-sidebar-active': "" }`}
            >
              {_t(t("Problem's Reply"))}{" "}
              <sup className="badge badge-sm badge-warning">
                {this.state.CommentNotifications.length}
              </sup>
            </NavLink>
          ) : (
            <NavLink
              to={{pathname:"/refresh", state:"/notifications"}}
              className={`nav-link profile-sidebar-link ${window.location.href.includes("/notifications") ? 'profile-sidebar-active': "" }`}
            >
              {_t(t("Problem's Reply"))}{" "}
            </NavLink>
          )}

          {this.state.feedback.length > 0 ? (
            <NavLink
              to={{pathname:"/refresh", state:"/feedback"}}
              className={`nav-link profile-sidebar-link ${window.location.href.includes("/feedback") ? 'profile-sidebar-active': "" }`}
            >
              {_t(t("Assignment's Feedback"))}{" "}
              <sup className="badge badge-sm badge-warning">
                {this.state.feedback.length}
              </sup>
            </NavLink>
          ) : (
            <NavLink
              to={{pathname:"/refresh", state:"/feedback"}}
              className={`nav-link profile-sidebar-link ${window.location.href.includes("/feedback") ? 'profile-sidebar-active': "" }`}
            >
              {_t(t("Assignment's Feedback"))}
            </NavLink>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(ProfileSidebar);
