import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ModeratorLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      studentLogin: "",
      adminLogin: "",
      instructorLogin: "",
      alert: "",
      loading: false,
    };
  }

  componentDidMount() {
    //redirect if logged in
    cookies.get("moderatorToken") && this.props.history.push("/moderator");

    document.title = "Moderator Login";
    if (this.props.location.state) {
      let alert = this.props.location.state.alert;
      this.setState({
        alert: alert,
      });
    }
    this.setState({
      studentLogin: cookies.get("studentToken"),
      adminLogin: cookies.get("adminToken"),
      instructorToken: cookies.get("instructorToken"),
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/auth/moderator/login";
    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    return axios
      .post(url, user)
      .then((res) => {
        if (this.state.studentLogin) {
          // localStorage.removeItem("studentToken");
          cookies.remove("studentToken");
        }
        if (this.state.adminLogin) {
          cookies.remove("adminToken");
        }
        if (this.state.instructorLogin) {
          // localStorage.removeItem("instructorToken");
          cookies.remove("instructorToken");
        }
        // localStorage.setItem("moderatorToken", res.data.access_token);
        let date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        cookies.set("moderatorToken", res.data.access_token, {
          path: "/",
          expires: date,
        });
        this.setState({
          email: "",
          password: "",
          loading: false,
        });
        this.props.history.push("/moderator");
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.error,
          alert: "",
          loading: false,
        });
      });
  };
  render() {
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card m-4 login-card">
                <div className="card-body bg-dark text-center text-white">
                  <h3 className="text-center ">Moderator Login</h3>
                  <img
                    src="https://image.freepik.com/free-vector/follow-me-social-business-theme-design_24877-50426.jpg"
                    alt="user"
                    height="100"
                    width="100"
                    className="rounded-circle"
                  />
                  {this.state.loading ? (
                    <div className="col-md-12 text-center bg-success text-white p-3 mt-2 rounded-sm">
                      Please wait !
                    </div>
                  ) : (
                    <form className="col-md-12" onSubmit={this.handleSubmit}>
                      {this.state.error ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.error}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.alert ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.alert}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group mt-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-primary text-white rounded-circle">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded ml-3"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-primary text-white rounded-circle">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          className="form-control rounded ml-3"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mt-5">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                          >
                            Login
                          </button>
                        </div>
                        {/* <div className="text-center">
                        <Link to="#" className="nav-link">
                          Forgot password ?
                        </Link>
                      </div> */}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
