import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
// Components
import Sidebar from "../Sidebar";
const cookies = new Cookies();

class ApproveRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      student_pic: "",
      request_id: "",
      fname: "",
      lname: "",
      image: "",
      gender: "",
      givenDateOfBirth: "",
      dateOfBirth: null,
      phn_no: "",
      occupation: "",
      address: "",
      email: "",
      fb: "",
      student_type: "",
      note: "",
      ip_limit: 2,
      password: "",
      password_confirmation: "",
      error: [],
      fileError: "",
      paid_on: "",
      paid_number: "",
      transaction_id: "",
      admin_bank_account: "",
      //enrollment states
      batch: "",
      roll: "",
      course_id: "",
      course_fee: "",
      discount: "",
      payable: "",
      first_payment: "",
      first_payment_date: null,
      given_first_payment_date: null,

      second_payable: "",
      second_payment_date: null,
      second_payment: "",
      second_paid_date: null,

      third_payable: "",
      third_payment_date: null,
      third_payment: "",
      third_paid_date: null,

      fourth_payable: "",
      fourth_payment_date: null,
      fourth_payment: "",
      fourth_paid_date: null,

      fifth_payable: "",
      fifth_payment_date: null,
      fifth_payment: "",
      fifth_paid_date: null,

      allow_class_from: "",
      allow_class_to: "",
      //common states
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    document.title = "Approve Request";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const student_url =
      BASE_URL + `/api/admin/approve_registration_request/${id}`;
    return axios
      .get(student_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          student_pic: res.data.image,
          request_id: res.data.id,
          course_id: res.data.course_id,
          fname: res.data.fname,
          lname: res.data.lname,
          gender: res.data.gender,
          phn_no: res.data.phn_no,
          givenDateOfBirth: res.data.dateOfBirth,
          occupation: res.data.occupation,
          address: res.data.address,
          email: res.data.email,
          fb: res.data.fb,
          student_type: res.data.student_type,
          note: res.data.note,
          paid_on: res.data.paid_on,
          paid_number:
            res.data.bkash_number ||
            res.data.nagad_number ||
            res.data.rocket_number ||
            res.data.admin_bank_account,
          transaction_id:
            res.data.bkash_transaction ||
            res.data.nagad_transaction ||
            res.data.rocket_transaction,
          admin_bank_account: res.data.admin_bank_account,
          //enrollments
          first_payment: res.data.first_payment,
          given_first_payment_date: res.data.first_paid_date,
        });
        const url = BASE_URL + "/api/registration_info";
        return axios.get(url).then((res) => {
          this.setState({
            courses: res.data,
          });
          this.state.courses.map((item) => {
            if (item.id == this.state.course_id) {
              this.setState({
                course_fee: item.fee,
                payable: item.fee - this.state.discount,
                loading: false,
              });
            }
          });
        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
  };

  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url =
      BASE_URL +
      `/api/admin/approve_registration_request/${this.state.request_id}`;
    const formData = {
      fname: this.state.fname,
      lname: this.state.lname,
      gender: this.state.gender,
      dateOfBirth: this.state.dateOfBirth,
      phn_no: this.state.phn_no,
      occupation: this.state.occupation,
      address: this.state.address,
      email: this.state.email,
      fb: this.state.fb,
      student_type: this.state.student_type,
      ip_limit: this.state.ip_limit,
      note: this.state.note,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      file: this.state.image,
      //enrollment
      course_id: this.state.course_id,
      batch: this.state.batch,
      roll: this.state.roll,
      course_fee: this.state.course_fee,
      discount: this.state.discount,
      payable: this.state.payable,

      first_payment: this.state.first_payment,
      first_payment_date: this.state.first_payment_date,

      second_payable: this.state.second_payable,
      second_payment_date: this.state.second_payment_date,
      second_payment: this.state.second_payment,
      second_paid_date: this.state.second_paid_date,

      third_payable: this.state.third_payable,
      third_payment_date: this.state.third_payment_date,
      third_payment: this.state.third_payment,
      third_paid_date: this.state.third_paid_date,

      fourth_payable: this.state.fourth_payable,
      fourth_payment_date: this.state.fourth_payment_date,
      fourth_payment: this.state.fourth_payment,
      fourth_paid_date: this.state.fourth_paid_date,

      fifth_payable: this.state.fifth_payable,
      fifth_payment_date: this.state.fifth_payment_date,
      fifth_payment: this.state.fifth_payment,
      fifth_paid_date: this.state.fifth_paid_date,

      allow_class_from: this.state.allow_class_from
        ? this.state.allow_class_from - 1
        : "",
      allow_class_to: this.state.allow_class_to
        ? this.state.allow_class_to - 1
        : "",
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.props.history.push({
          pathname: "/admin/manage-enrollment",
          state: { alert_success: res.data },
        });
      })
      .catch((error) => {
        if (error.response.data.message == "Image source not readable") {
          this.setState({
            fileError:
              error.response.data.message +
              ". " +
              "Choose a valid image with jpeg, jpg, png extention.",
          });
        } else {
          this.setState({
            fileError: "",
          });
        }
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  handleChangeCourse = (e) => {
    this.setState({
      course_id: e.target.value,
    });
    const course_url =
      BASE_URL + `/api/admin/course/p170815s/${e.target.value}`;
    return axios
      .get(course_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          course_fee: res.data.fee,
          payable: res.data.fee - this.state.discount,
        });
      });
  };

  handleDiscount = (e) => {
    let payableAmount = "";
    payableAmount = this.state.course_fee - e.target.value;
    this.setState({
      payable: payableAmount,
      discount: e.target.value,
    });
  };

  handleFirstPaymentDate = (date) => {
    this.setState({
      first_payment_date: date,
    });
  };

  handleSecondPaymentDate = (date) => {
    this.setState({
      second_payment_date: date,
    });
  };
  handleSecondPaidDate = (date) => {
    this.setState({
      second_paid_date: date,
    });
  };

  handleThirdPaymentDate = (date) => {
    this.setState({
      third_payment_date: date,
    });
  };
  handleThirdPaidDate = (date) => {
    this.setState({
      third_paid_date: date,
    });
  };

  handleFourthPaymentDate = (date) => {
    this.setState({
      fourth_payment_date: date,
    });
  };
  handleFourthPaidDate = (date) => {
    this.setState({
      fourth_paid_date: date,
    });
  };

  handleFifthPaymentDate = (date) => {
    this.setState({
      fifth_payment_date: date,
    });
  };

  handleFifthPaidDate = (date) => {
    this.setState({
      fifth_paid_date: date,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.request_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          Approve{" "}
                          <span className="text-primary">
                            {this.state.fname} {this.state.lname}
                          </span>
                          's Request
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <Link
                        to="/admin/student-requests"
                        className="nav-link text-light bg-secondary mb-1 rounded-sm"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Please fill the form accordingly
                      </div>
                      {this.state.loading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-3">
                              <img
                                src={`${BASE_URL}${`/images/student/${this.state.student_pic}`}`}
                                alt="avatar"
                                className="img-fluid"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </div>
                            <div className="col-md-9">
                              Paid on: <b>{this.state.paid_on}</b> <br />
                              {this.state.admin_bank_account ? (
                                <>
                                  Account (your account):{" "}
                                  <b>{this.state.paid_number}</b>
                                  <br />
                                </>
                              ) : (
                                [
                                  this.state.paid_on !== "Office" ? (
                                    <>
                                      Number: <b>{this.state.paid_number}</b>{" "}
                                      <br />
                                      Transaction id:{" "}
                                      <b>{this.state.transaction_id}</b>
                                    </>
                                  ) : (
                                    ""
                                  ),
                                ]
                              )}
                              <a
                                href={this.state.fb}
                                className="nav-link btn btn-warning btn-sm  rounded-0 mt-2"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ maxWidth: "120px" }}
                              >
                                <i className="fa fa-facebook"></i> Facebook
                              </a>
                            </div>
                          </div>

                          <form
                            className="form-group"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="student_type"
                                  className="col-md-3 my-auto"
                                >
                                  Student Type
                                </label>
                                <div className="col-md-9 pl-0">
                                  <select
                                    name="student_type"
                                    className="form-control rounded-sm pl-2"
                                    onChange={this.handleChange}
                                    value={this.state.student_type}
                                    required
                                  >
                                    <option value="">
                                      Please select a student type
                                    </option>
                                    <option
                                      key="1"
                                      value="Office"
                                      className="text-capitalize"
                                    >
                                      Office
                                    </option>
                                    <option
                                      key="2"
                                      value="Virtual"
                                      className="text-capitalize"
                                    >
                                      Virtual
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="fname"
                                  className="col-md-3 my-auto"
                                >
                                  First Name
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    placeholder="Type first name"
                                    className="form-control"
                                    value={this.state.fname}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.fname ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fname.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="lname"
                                  className="col-md-3 my-auto"
                                >
                                  Last Name
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    placeholder="Type last name"
                                    className="form-control"
                                    value={this.state.lname}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.lname ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.lname.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="image"
                                  className="col-md-3 my-auto"
                                >
                                  Image
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    className="form-control-file pl-0"
                                    onChange={this.handleChangeFile}
                                  />
                                </div>
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.fileError
                                    ? "*" + this.state.fileError
                                    : ""}
                                </div>
                              </div>
                              <div className="col-md-7 input-group mb-2">
                                <label
                                  htmlFor={"male" || "female"}
                                  className="col-md-5 my-auto mr-2"
                                >
                                  Gender
                                </label>
                                <div className="col-md-7 pl-0 my-auto form-control pl-2 ml-1">
                                  <input
                                    type="radio"
                                    value="MALE"
                                    id="male"
                                    name="gender"
                                    className="form-check-inline"
                                    onChange={this.handleChange}
                                    checked={this.state.gender == "MALE"}
                                    required
                                  />
                                  <label
                                    htmlFor="male"
                                    className="my-auto form-check-label mr-4"
                                  >
                                    {" "}
                                    Male
                                  </label>
                                  <input
                                    type="radio"
                                    value="FEMALE"
                                    id="female"
                                    name="gender"
                                    className="form-check-inline"
                                    onChange={this.handleChange}
                                    checked={this.state.gender == "FEMALE"}
                                    required
                                  />
                                  <label
                                    htmlFor="female"
                                    className="my-auto form-check-label"
                                  >
                                    {" "}
                                    Female
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-5 input-group mb-2">
                                <label
                                  htmlFor="dateOfBirth"
                                  className="col-md-5 my-auto"
                                >
                                  Date of Birth
                                </label>
                                <div className="col-md-7 pl-0">
                                  <DatePicker
                                    selected={this.state.dateOfBirth}
                                    onChange={this.handleDateOfBirth}
                                    dateFormat="yyyy/MM/dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText={
                                      this.state.givenDateOfBirth
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 input-group mb-2 mt-4">
                                <label
                                  htmlFor="phn_no"
                                  className="col-md-3 my-auto"
                                >
                                  Phone No.
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="phn_no"
                                    name="phn_no"
                                    placeholder="01x xxxx"
                                    className="form-control"
                                    value={this.state.phn_no}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.phn_no ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.phn_no.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="occupation"
                                  className="col-md-3 my-auto"
                                >
                                  Occupation
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="occupation"
                                    name="occupation"
                                    placeholder="Type occupation"
                                    className="form-control"
                                    value={this.state.occupation}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.occupation ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.occupation.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="address"
                                  className="col-md-3 my-auto"
                                >
                                  Address
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Type address"
                                    className="form-control"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.address ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.address.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="email"
                                  className="col-md-3 my-auto"
                                >
                                  Email
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="example@softechit.com"
                                    className="form-control"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.email ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.email.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2 mt-4">
                                <label
                                  htmlFor="ip_limit"
                                  className="col-md-3 my-auto"
                                >
                                  Ip Limit
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="number"
                                    id="ip_limit"
                                    name="ip_limit"
                                    placeholder="Ip limitation- e.g: 3"
                                    className="form-control"
                                    value={this.state.ip_limit}
                                    onChange={this.handleChange}
                                    min="1"
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.ip_limit ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.ip_limit.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="email"
                                  className="col-md-3 my-auto"
                                >
                                  Facebook
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="url"
                                    id="fb"
                                    name="fb"
                                    placeholder="https://www.facebook.com/example"
                                    className="form-control"
                                    value={this.state.fb}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.fb ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fb.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="note"
                                  className="col-md-3 my-auto"
                                >
                                  Note
                                </label>
                                <div className="col-md-9 pl-0">
                                  <textarea
                                    id="note"
                                    name="note"
                                    placeholder="Type a note on student"
                                    className="form-control"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.note}
                                  </textarea>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.note ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.note.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="password"
                                  className="col-md-3 my-auto"
                                >
                                  Password
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    className="form-control"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 input-group mb-5">
                                <label
                                  htmlFor="password_confirmation"
                                  className="col-md-3 my-auto"
                                >
                                  Password Confirmation
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="password"
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    placeholder="Re-type password"
                                    className="form-control"
                                    value={this.state.password_confirmation}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.password ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.password.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="input-group col-md-12">
                                <label className="my-auto col-md-3">
                                  <b>Course</b>
                                </label>
                                <select
                                  name="course_id"
                                  className="form-control rounded-sm pl-2"
                                  onChange={this.handleChangeCourse}
                                  value={this.state.course_id}
                                  required
                                >
                                  <option value="">
                                    Please select a course
                                  </option>
                                  {/* Loop Here */}
                                  {this.state.courses.map((item) => {
                                    return (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                        className="text-capitalize"
                                      >{`${item.title}`}</option>
                                    );
                                  })}
                                  {/* Loop Here */}
                                </select>
                              </div>

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="batch"
                                  className="my-auto col-md-3"
                                >
                                  <b>Batch</b>
                                </label>
                                <input
                                  type="text"
                                  id="batch"
                                  className="form-control col-md-9 rounded-sm"
                                  name="batch"
                                  value={this.state.batch}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.batch ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.batch.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="roll"
                                  className="my-auto col-md-3"
                                >
                                  <b>Roll No.</b>
                                </label>
                                <input
                                  type="text"
                                  id="roll"
                                  className="form-control col-md-9 rounded-sm"
                                  name="roll"
                                  value={this.state.roll}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.roll ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.roll.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label className="my-auto col-md-3">
                                  <b>Class Access</b>
                                </label>
                                <span className="col-md-3 form-control bg-warning mr-auto text-dark">
                                  Optional
                                </span>
                                <input
                                  type="number"
                                  id="allow_class_from"
                                  className="form-control col-md-3 rounded-sm mb-2 mr-auto"
                                  name="allow_class_from"
                                  placeholder="From.."
                                  value={this.state.allow_class_from}
                                  onChange={this.handleChange}
                                  min="1"
                                />
                                <input
                                  type="number"
                                  id="allow_class_to"
                                  className="form-control col-md-3 rounded-sm mb-2 ml-auto"
                                  name="allow_class_to"
                                  placeholder="To.."
                                  value={this.state.allow_class_to}
                                  onChange={this.handleChange}
                                  required={this.state.allow_class_from}
                                  min="1"
                                />
                              </div>

                              <div className="input-group col-md-12">
                                <label className="my-auto col-md-3">
                                  <b>Course Fee</b>
                                </label>
                                <span className="form-control col-md-9 rounded-sm border-right-0">
                                  <span className="text-left text-info">
                                    {this.state.course_fee ||
                                      "Select a course to see.."}
                                  </span>
                                </span>
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="discount"
                                  className="my-auto col-md-3"
                                >
                                  <b>Discount</b>
                                </label>
                                <input
                                  type="number"
                                  id="discount"
                                  className="form-control col-md-9 rounded-sm border-right-0"
                                  name="discount"
                                  placeholder="Amount of discount"
                                  value={this.state.discount}
                                  onChange={this.handleDiscount}
                                  disabled={!this.state.course_id}
                                  min="0"
                                  max={this.state.course_fee}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.discount}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label className="my-auto col-md-3">
                                  <b>Total Payable</b>
                                </label>
                                <span className="form-control col-md-9 rounded-sm border-right-0">
                                  <span className="text-left text-info">
                                    {this.state.payable || "0"}
                                  </span>
                                </span>
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="first_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>1st Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="first_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="first_payment"
                                  placeholder="First payment amount"
                                  value={this.state.first_payment}
                                  onChange={this.handleChange}
                                  required
                                  min="0"
                                  max={this.state.payable}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.first_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="first_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>1st Paid Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="first_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="first_payment_date"
                                  className="form-control"
                                  value={this.state.first_payment_date}
                                  onChange={this.handleChange}
                                  required
                                /> */}
                                <DatePicker
                                  selected={this.state.first_payment_date}
                                  onChange={this.handleFirstPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_first_payment_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.first_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.first_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="second_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="second_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payable"
                                  placeholder="Second payable amount"
                                  value={this.state.second_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payable}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="second_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payment_date"
                                  className="form-control"
                                  value={this.state.second_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.second_payment_date}
                                  onChange={this.handleSecondPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.second_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="second_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payment"
                                  placeholder="Second payment amount"
                                  value={this.state.second_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="second_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_paid_date"
                                    className="form-control"
                                    value={this.state.second_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.second_paid_date}
                                  onChange={this.handleSecondPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.second_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="third_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="third_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payable"
                                  placeholder="Third payable amount"
                                  value={this.state.third_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payable}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="third_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payment_date"
                                  className="form-control"
                                  value={this.state.third_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.third_payment_date}
                                  onChange={this.handleThirdPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.third_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="third_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payment"
                                  placeholder="Third payment amount"
                                  value={this.state.third_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.third_paid_date}
                                  onChange={this.handleThirdPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.third_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="fourth_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="fourth_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payable"
                                  placeholder="Fourth payable amount"
                                  value={this.state.fourth_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payable}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="fourth_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payment_date"
                                  className="form-control"
                                  value={this.state.fourth_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.fourth_payment_date}
                                  onChange={this.handleFourthPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fourth_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="fourth_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payment"
                                  placeholder="Fourth payment amount"
                                  value={this.state.fourth_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.fourth_paid_date}
                                  onChange={this.handleFourthPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_fourth_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fourth_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="fifth_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="fifth_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payable"
                                  placeholder="Fifth payable amount"
                                  value={this.state.fifth_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payable}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="fifth_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payment_date"
                                  className="form-control"
                                  value={this.state.fifth_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.fifth_payment_date}
                                  onChange={this.handleFifthPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fifth_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="fifth_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payment"
                                  placeholder="Fifth payment amount"
                                  value={this.state.fifth_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.fifth_paid_date}
                                  onChange={this.handleFifthPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_fifth_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fifth_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success btn-sm mt-2 px-4"
                              >
                                <i className="fa fa-check"></i> Approve
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ApproveRequest);
