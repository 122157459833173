import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class InstructorRoute extends Component {
  render() {
    let token = cookies.get("instructorToken");
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => {
          return token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/instructor-login",
                state: { alert: "You need to login first!" },
              }}
            />
          );
        }}
      ></Route>
    );
  }
}
