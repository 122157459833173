import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";

class ModeratorSidebar extends Component {
  render() {
    const { path } = this.props;
    return (
      <>
        <div id="accordion" className="pl-1">
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-2"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-1"
                  aria-expanded="false"
                  aria-controls="collapse-1"
                >
                  Dashboard
                </a>
              </h5>
            </div>
            <div
              id="collapse-1"
              className={`collapse + ${
                path === "/moderator" || path === "/moderator/" ? "show" : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-1"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Dashboard
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-assignments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-assignments"
                  aria-expanded="false"
                  aria-controls="collapse-assignments"
                >
                  Assignments
                </a>
              </h5>
            </div>
            <div
              id="collapse-assignments"
              className={`collapse + ${
                path === "/moderator/check-assignments" ||
                path === "/moderator/check-assignments/" ||
                path === `/moderator/check-assignments/${this.props.id}` ||
                path === `/moderator/check-assignments/${this.props.id}/` ||
                path === "/moderator/manage-assignments" ||
                path === "/moderator/manage-assignments/" ||
                path === `/moderator/view-assignments/${this.props.id}` ||
                path === `/moderator/view-assignments/${this.props.id}/` ||
                path === "/moderator/manage-feedback" ||
                path === "/moderator/manage-feedback/" ||
                path === `/moderator/check-feedback/${this.props.id}` ||
                path === `/moderator/check-feedback/${this.props.id}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-assignments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator/manage-feedback"
                  className={`nav-link py-0 + ${
                    path === "/moderator/manage-feedback" ||
                    path === "/moderator/manage-feedback/" ||
                    path === `/moderator/check-feedback/${this.props.id}` ||
                    path === `/moderator/check-feedback/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  Feedback
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/check-assignments"
                  className={`nav-link py-0 + ${
                    path === "/moderator/check-assignments" ||
                    path === "/moderator/check-assignments/" ||
                    path === `/moderator/check-assignments/${this.props.id}` ||
                    path === `/moderator/check-assignments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  New Assignments
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/manage-assignments"
                  className={`nav-link py-0 + ${
                    path === "/moderator/manage-assignments" ||
                    path === "/moderator/manage-assignments/" ||
                    path === `/moderator/view-assignments/${this.props.id}` ||
                    path === `/moderator/view-assignments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  Checked Assignments
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-comments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-comments"
                  aria-expanded="false"
                  aria-controls="collapse-comments"
                >
                  Discussion
                </a>
              </h5>
            </div>
            <div
              id="collapse-comments"
              className={`collapse + ${
                path === "/moderator/check-comments" ||
                path === "/moderator/check-comments/" ||
                path === `/moderator/check-comments/${this.props.id}` ||
                path === `/moderator/check-comments/${this.props.id}/` ||
                path === "/moderator/check-comments/courses" ||
                path === "/moderator/check-comments/courses/" ||
                path === `/moderator/check-comments/courses/${this.props.id}` ||
                path ===
                  `/moderator/check-comments/courses/${this.props.id}/` ||
                path === `/moderator/check-comments/class/${this.props.id}` ||
                path === `/moderator/check-comments/class/${this.props.id}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-comments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator/check-comments"
                  className={`nav-link py-0 + ${
                    path === "/moderator/check-comments" ||
                    path === "/moderator/check-comments/" ||
                    path === `/moderator/check-comments/${this.props.id}` ||
                    path === `/moderator/check-comments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  Check Comments
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/check-comments/courses"
                  className={`nav-link py-0
                    ${
                      path === "/moderator/check-comments/courses" ||
                      path === "/moderator/check-comments/courses/" ||
                      path ===
                        `/moderator/check-comments/courses/${this.props.id}` ||
                      path ===
                        `/moderator/check-comments/courses/${this.props.id}/` ||
                      path ===
                        `/moderator/check-comments/class/${this.props.id}` ||
                      path ===
                        `/moderator/check-comments/class/${this.props.id}/`
                        ? "sidebar-active"
                        : ""
                    }
                  `}
                >
                  All Discussion
                </NavLink>
              </div>
            </div>
          </div>

          {/* notice start */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-notices"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-notice"
                  aria-expanded="false"
                  aria-controls="collapse-notice"
                >
                  Notice
                </a>
              </h5>
            </div>
            <div
              id="collapse-notice"
              className={`nav-link py-0 collapse + ${
                path === "/moderator/notice/all-notice" ||
                path === "/moderator/notice/add-notice" ||
                path === `/moderator/notice/edit-notice/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-notices"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator/notice/all-notice"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  All Notice
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/notice/add-notice"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Add Notice
                </NavLink>
              </div>
            </div>
          </div>

          {/* notice end */}

          {/* upload files start */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-upload-files"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-files"
                  aria-expanded="false"
                  aria-controls="collapse-files"
                >
                  Upload
                </a>
              </h5>
            </div>
            <div
              id="collapse-files"
              className={`nav-link py-0 collapse + ${
                path === "/moderator/files/categories" ||
                path === "/moderator/files/all-upload-file" ||
                path === "/moderator/files/add-upload-file" ||
                path === `/moderator/files/edit-cate/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-upload-files"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator/files/add-upload-file"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Add new file
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/files/all-upload-file"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  All Files
                </NavLink>

                <NavLink
                  exact
                  to="/moderator/files/categories"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Categories
                </NavLink>
              </div>
            </div>
          </div>

          {/* live chat */}
          {/* <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-live-chat"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-live-chat"
                  aria-expanded="false"
                  aria-controls="collapse-live-chat"
                >
                  live chat
                </a>
              </h5>
            </div>
            <div
              id="collapse-live-chat"
              className={`nav-link py-0 collapse + ${
                path === "/moderator/files/categories" ||
                path === "/moderator/files/all-upload-file" ||
                path === "/moderator/files/add-upload-file" ||
                path === `/moderator/files/edit-cate/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-live-chat"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/moderator/files/add-upload-file"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  generate link
                </NavLink>
              </div>
            </div>
          </div> */}

          {/* uploads files end */}
        </div>
      </>
    );
  }
}
export default withRouter(ModeratorSidebar);
