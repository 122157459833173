import React, { Component } from "react";

export default class Banner extends Component {
  render() {
    const { title, bannerCSS, children } = this.props;
    return (
      <div className={bannerCSS}>
        <h2 className="pt-3 pb-3">
          <span className="d-inline-block">
            {title}
          </span>
          {children}
        </h2>
      </div>
    );
  }
}
