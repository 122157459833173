import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
// Components
import Sidebar from "../Sidebar";
import { BASE_URL } from "../../../BaseUrl";

import Cookies from "universal-cookie";
const cookies = new Cookies();
class AddInstructor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      phn_no: "",
      email: "",
      password: "",
      password_confirmation: "",
      image: "",
      facebook: '',
      desig: '',
      loading: false,
      error: [],
      fileError: "",
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    this.setState({
      path: this.props.history.location.pathname,
    });
    document.title = "Add Instructor";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
  };

  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/instructor/instructor_p170815s";
    const formData = {
      fname: this.state.fname,
      lname: this.state.lname,
      phn_no: this.state.phn_no,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      file: this.state.image,
      designation: this.state.desig,
      intro: this.state.intro,
      facebook: this.state.facebook
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.setState({
          fname: "",
          lname: "",
          phn_no: "",
          email: "",
          password: "",
          password_confirmation: "",
          image: "",
          error: [],
          fileError: "",
          loading: false,
        });
        this.props.history.push("/admin/manage-instructor");
      })
      .catch((error) => {
        if (error.response.data.message === "Image source not readable") {
          this.setState({
            fileError:
              error.response.data.message +
              ". " +
              "Choose a valid image with jpeg, jpg, png extention.",
          });
        } else {
          this.setState({
            fileError: "",
          });
        }
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Add an Instructor
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Please fill the form accordingly
                      </div>
                      {this.state.loading ? (
                        <span className="text-center text-primary">
                          Loading..
                        </span>
                      ) : (
                        <form
                          className="form-group"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="fname"
                                className="col-md-3 my-auto"
                              >
                                First Name
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  placeholder="Type first name"
                                  className="form-control"
                                  value={this.state.fname}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.fname ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fname.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="lname"
                                className="col-md-3 my-auto"
                              >
                                Last Name
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="lname"
                                  name="lname"
                                  placeholder="Type last name"
                                  className="form-control"
                                  value={this.state.lname}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.lname ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.lname.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="phn_no"
                                className="col-md-3 my-auto"
                              >
                                Phone No.
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="phn_no"
                                  name="phn_no"
                                  placeholder="019 xxxx"
                                  className="form-control"
                                  value={this.state.phn_no}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.phn_no ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.phn_no.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="email"
                                className="col-md-3 my-auto"
                              >
                                Email
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="example@softechit.com"
                                  className="form-control"
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.email ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.email.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="desig"
                                className="col-md-3 my-auto"
                              >
                                Designation
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="desig"
                                  name="desig"
                                  placeholder="Digital Marketer"
                                  className="form-control"
                                  // value={this.state.email}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.email ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.email.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="facebook"
                                className="col-md-3 my-auto"
                              >
                                Facebook
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="facebook"
                                  name="facebook"
                                  placeholder="https://www.facebook.com/abc"
                                  className="form-control"
                                  // value={this.state.email}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.email ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.email.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="password"
                                className="col-md-3 my-auto"
                              >
                                Password
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  className="form-control"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="password_confirmation"
                                className="col-md-3 my-auto"
                              >
                                Password Confirmation
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="password"
                                  id="password_confirmation"
                                  name="password_confirmation"
                                  placeholder="Re-type password"
                                  className="form-control"
                                  value={this.state.password_confirmation}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.password ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.password.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="intro"
                                className="col-md-3 my-auto"
                              >
                                Introduction
                              </label>
                              <div className="col-md-9 pl-0">
                                <textarea
                                  type="text"
                                  rows="3"
                                  id="des"
                                  name="intro"
                                  placeholder="Introduction"
                                  className="form-control"
                                  // value={ }
                                  onChange={this.handleChange}
                                  required
                                ></textarea>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.email ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.email.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="image"
                                className="col-md-3 my-auto"
                              >
                                Image
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="file"
                                  id="image"
                                  name="image"
                                  className="form-control-file pl-0"
                                  onChange={this.handleChangeFile}
                                  required
                                />
                              </div>
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.fileError
                                  ? "*" + this.state.fileError
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-2 px-4"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(AddInstructor);
