import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UpdateEnrollmentContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      name: "",
      phn_no: "",
      googleLink: "",
      google: "",
      error: [],
      path: "",
      google_success: null,
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Enrollment Contacts";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });
    const url = BASE_URL + "/api/admin/contact_for_enrollment";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          contacts: res.data[0],
          googleLink: res.data[1] ? res.data[1].google_link : "",
          loading: false,
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/contact_for_enrollment";
    const formData = {
      name: this.state.name,
      phn_no: this.state.phn_no,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          contacts: res.data,
          name: "",
          phn_no: "",
          error: [],
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };
  handleDelete = (e) => {
    const url = BASE_URL + "/api/admin/contact_for_enrollment/" + e;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          contacts: res.data,
        });
      });
  };
  handleSubmitGoogleForm = (e) => {
    e.preventDefault();
    const url = BASE_URL + "/api/admin/google_for_enrollment";
    const formData = {
      google: this.state.google,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          googleLink: res.data.google_link,
          google: "",
          error: [],
          google_success: "Link updated successfully",
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ google_success: null });
          }.bind(this),
          5000
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update Enrollment's contact numbers
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header border-0 text-center text-uppercase mb-3">
                        Enrollment Contacts
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <form
                          className="form-group"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="col-md-12 input-group mb-2">
                            <div className="col-md-8 offset-md-2 pl-0 table-responsive">
                              <table className="table table-striped table-hover table-bordered">
                                <thead className="text-center">
                                  <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody className="text-center">
                                  {this.state.contacts.map((item) => {
                                    return (
                                      <tr>
                                        <td>{item.name}</td>
                                        <td>{item.phn_no}</td>
                                        <td>
                                          <span
                                            onClick={() => {
                                              this.handleDelete(item.id);
                                            }}
                                          >
                                            <i className="fa fa-times delete-comment"></i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-md-12 input-group mb-2">
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 input-group">
                              <label
                                htmlFor="name"
                                className="col-md-3 my-auto"
                              >
                                Name
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Type name"
                                  className="form-control"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.error.name ? (
                                  <ul className="list-group list-group-horizontal">
                                    {this.state.error.name.map((item) => {
                                      return (
                                        <li className="list-group-item border-0 bg-light py-0 pl-0">
                                          {"*" + item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="col-md-8 offset-md-2 pl-0 mt-3 input-group">
                              <label
                                htmlFor="phn_no"
                                className="col-md-3 my-auto"
                              >
                                Phone No.
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="phn_no"
                                  name="phn_no"
                                  placeholder="01x xx xx xx xx"
                                  className="form-control"
                                  value={this.state.phn_no}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.error.phn_no ? (
                                  <ul className="list-group list-group-horizontal">
                                    {this.state.error.phn_no.map((item) => {
                                      return (
                                        <li className="list-group-item border-0 bg-light py-0 pl-0">
                                          {"*" + item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-2 px-4"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 mt-2 pb-0">
                      <div className="card-header border-0 text-center text-uppercase mb-3">
                        Google Form Link
                      </div>
                      {this.state.google_success ? (
                        <div className="alert alert-success text-center">
                          {this.state.google_success}
                        </div>
                      ) : (
                        ""
                      )}
                      <form
                        className="form-group"
                        onSubmit={this.handleSubmitGoogleForm}
                      >
                        <div className="col-md-12 input-group mb-2">
                          <div className="col-md-8 offset-md-2 pl-0 table-responsive">
                            <table className="table table-striped table-hover table-bordered">
                              <thead className="text-center">
                                <tr>
                                  <th>Google Form Link</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                <tr>
                                  <td>
                                    <a
                                      href={
                                        this.state.googleLink
                                          ? this.state.googleLink
                                          : "#"
                                      }
                                      target="_blank"
                                      title={
                                        this.state.googleLink
                                          ? this.state.googleLink
                                          : ""
                                      }
                                    >
                                      Click Here
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-12 input-group mb-2">
                          <div className="col-md-8 offset-md-2 pl-0 mt-3 input-group">
                            <label
                              htmlFor="google"
                              className="col-md-4 my-auto"
                            >
                              Google Form Link
                            </label>
                            <div className="col-md-8 pl-0">
                              <input
                                type="url"
                                id="google"
                                name="google"
                                placeholder="Type google form url"
                                className="form-control"
                                value={this.state.google}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                          {this.state.error ? (
                            <div className="offset-3 text-danger fs-13 pl-0">
                              {this.state.error.name ? (
                                <ul className="list-group list-group-horizontal">
                                  {this.state.error.name.map((item) => {
                                    return (
                                      <li className="list-group-item border-0 bg-light py-0 pl-0">
                                        {"*" + item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-success btn-sm mt-2 px-4"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(UpdateEnrollmentContact);
