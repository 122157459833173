import React, { Component } from 'react';
import course1 from '../images/allCourses/course1.png';
import { BASE_URL } from "../BaseUrl";
import '../css/slick.min.css'
import Slider from 'react-slick';
import axios from "axios";

import { Link } from "react-router-dom";
class NewAllCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.setState({
            loading: true,
        });
        const url = BASE_URL + "/api/all_courses";
        return axios.get(url).then((res) => {
            this.setState({
                courses: res.data,
                loading: false,
            });
        });
    }


    render() {

        // var settings = {
        //     mobileFirst: true,
        //     dots: true,
        //     infinite: true,
        //     arrows: false,
        //     autoplaySpeed: 5000,
        //     autoplay: true,
        //     slidesToShow: 4,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1920,
        //             settings: {
        //                 slidesToShow: 3,
        //                 slidesToScroll: 1,
        //                 infinite: true,
        //             }
        //         },
        //         {
        //             breakpoint: 1100,
        //             settings: {
        //                 slidesToShow: 2,
        //                 slidesToScroll: 2,
        //                 initialSlide: 1
        //             }
        //         },
        //         {
        //             breakpoint: 768,
        //             settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1
        //             }
        //         }
        //     ]
        // };
        return (<>
            {this.state.loading ? (
                <div className="row col-md-8 offset-md-2  mt-5 mb-5"></div>
            ) : (
                <div className='v2_course-section hero-bg'>
                    <div className="container">
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center">
                                <h2 className="text-capitalize font-weight-bold mb-0">all course</h2>
                                <Link to="/all-courses" className="btn1 btn orange-bg px-md-5 text-capitalize"><i class="fa fa-user" aria-hidden="true"></i> | see all</Link>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row"> {/* row*/}
                            {/* <div className="col-xl-10 ml-auto"> */}
                            {/* <Slider {...settings}> */}
                            {this.state.courses.slice(0, 3).map((item) => {
                                return (
                                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div class="course_card h-100">
                                            <div class="course_card_img">

                                                <img src={`${BASE_URL}${`/images/course/${item.thumbnail}`}`} alt="course" />
                                            </div>
                                            <div class="course_card_content">

                                                <span class="title title-gray d-inline-block mt-4"><i class="fa fa-book" aria-hidden="true"></i> {item.lectures_count} lessons</span>
                                                <Link to={`/all-courses/${item.id}`}>
                                                    <h5 class="title mt-3">{item.title}</h5>
                                                </Link>
                                                {item.title ? (
                                                    <p class="description mb-0">{item.description}</p>
                                                ) : ''}

                                            </div>
                                            <div class="course_card_footer">
                                                <Link to='/student-registration' className="nav-item btn  button--ujarak position-relative">enroll now</Link></div>
                                        </div>
                                    </div>

                                )
                            })}
                            {/* </Slider> */}
                        </div>
                        {/* </div> */}
                    </div>
                </div>)}





        </>);
    }
}

export default NewAllCourse;
