import React, { Component } from "react";
import ProfileSidebar from "../../components/ProfileSidebar";
import { Link, withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

const MAX_LENGTH = 18;

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null,
      feedback: [],
      classes: [],
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    document.title = "Feedback";
    const user_url = BASE_URL + "/api/auth/student/me";
    return axios
      .get(user_url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          student: res.data,
        });
        const feedback_url =
          BASE_URL + "/api/student/unseen_feedback/" + res.data.id;
        return axios
          .get(feedback_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              feedback: res.data[0],
              classes: res.data[1],
              loading: false,
            });
          });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload(this.props.history.replace("/"));
        }
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div
              className={`${
                this.state.isMobile ? "col-md-12  bg-primary" : "leftbar"
              }`}
            >
              {this.state.sidebar ? <ProfileSidebar /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h3 className="mt-3 mb-2">{_t(t("Assignment's Feedback"))}</h3>
                <div className="row">
                  <div className="container mb-3">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="col-md-12">
                          <h1>
                            <Skeleton count={3} />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={8} />
                        </div>
                      ) : (
                        <div className="container">
                          <div className="card-body bg-light">
                            {this.state.feedback.length > 0 ? (
                              [
                                this.state.feedback.map((item) => {
                                  let itemClass = this.state.classes.find(
                                    (cls) => cls.id == item.add_class_id
                                  );
                                  return (
                                    <Link
                                      to={`/assignment/${itemClass.course_id}/${itemClass.id}`}
                                      className="alert alert-success nav-link"
                                    >
                                      <span className="row">
                                        <span className="col-md-8 text-truncate">
                                          <span className="text-dark">
                                            {_t(t("You got a new feedback"))}
                                          </span>{" "}
                                          -{" "}
                                          <b>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: item.remark,
                                              }}
                                            />
                                          </b>
                                        </span>
                                        <span className="col-md-4 text-right">
                                          <span className=" btn btn-sm btn-secondary">
                                            {_t(t("View"))}
                                          </span>
                                        </span>
                                      </span>
                                    </Link>
                                  );
                                }),
                              ]
                            ) : (
                              <div className="alert alert-danger py-5 text-center">
                                {_t(t("No Feedback"))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(Feedback));
