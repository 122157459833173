import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Sidebar from "../Sidebar";

import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const $ = require("jquery");
$.DataTable = require("datatables.net");

class ManageStudent4g extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      path: "",
      alert_success: "",
      remove_ip: "",
      remove_student: "",
      isMobile: false,
      sidebar: true,
      loading: false,
    };
  }
  componentDidMount() {
    document.title = "Manage Students";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const students_url = BASE_URL + "/api/admin/student/student_p170815s_4g";
    return axios
      .get(students_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          students: res.data,
          loading: false,
        });
        $(document).ready(function () {
          $("#studentTable").DataTable({
            ordering: true,
          });
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = (e) => {
    this.props.history.push(`/admin/edit-student/${e}`);
  };

  handleDelete = (e) => {
    const student_url = BASE_URL + `/api/admin/student/student_p170815s/${e}`;
    return axios
      .delete(student_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        // reload added for deleting student
        window.location.reload();
        // this.setState({
        //   remove_student: res.data.msg,
        // });
        // setTimeout(
        //   function () {
        //     this.setState({ remove_student: "" });
        //   }.bind(this),
        //   3000
        // );
        // const students_url = BASE_URL + "/api/admin/student/student_p170815s";
        // return axios
        //   .get(students_url, {
        //     headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
        //   })
        //   .then((res) => {
        //     this.setState({
        //       students: res.data,
        //     });
        //   });
      });
  };

  handleIP = (e) => {
    const ip_url = BASE_URL + `/api/admin/ip/remove/${e}`;
    return axios
      .delete(ip_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          remove_ip: res.data.alert_success || res.data.alert_error,
        });
        setTimeout(
          function () {
            this.setState({ remove_ip: "" });
          }.bind(this),
          3000
        );
        const students_url = BASE_URL + "/api/admin/student/student_p170815s";
        return axios
          .get(students_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              students: res.data,
            });
          });
      });
  };

  handleView = (e) => {
    this.props.history.push(`/admin/student-info/${e}`);
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h4 className="mt-2 mb-4">
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Manage Students
                </h4>
                <div className="container">
                  {/* Error or Success Message */}
                  {this.state.remove_ip ? (
                    <div className="text-center bg-danger text-white p-3 mb-2">
                      {this.state.remove_ip}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.remove_student ? (
                    <div className="text-center bg-danger text-white p-3 mb-2">
                      {this.state.remove_student}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.alert_success ? (
                    <div className="text-center bg-success text-white p-3 mb-2">
                      {this.state.alert_success}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Error or Success Message */}
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        All Students
                      </div>
                      {this.state.loading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      ) : (
                        <div className="table-responsive">
                          <table
                            class="table table-striped text-dark table-hover table-sm"
                            id="studentTable"
                          >
                            <thead>
                              <tr className="text-center">
                                <th scope="col">S/L</th>
                                <th scope="col">Avatar</th>
                                <th scope="col" className="text-left">
                                  Name
                                </th>
                                <th scope="col">Type</th>
                                <th scope="col">Contact No.</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.students.map((student, index) => {
                                return (
                                  <tr className="text-center">
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <img
                                        src={`${BASE_URL}${`/images/student/${student.image}`}`}
                                        height="60px"
                                        width="100px"
                                        alt="avatar"
                                        className="avatar-sm"
                                      />
                                    </td>
                                    <td className="text-left">
                                      {student.fname} {student.lname}
                                    </td>
                                    <td>{student.student_type} </td>
                                    <td>{student.phn_no} </td>
                                    <td>
                                      <div className="row mx-2">
                                        {/* MODAL TRIGGER */}
                                        <button
                                          className="btn btn-sm btn-success col-md-3"
                                          style={{ borderRadius: "0" }}
                                          data-toggle="modal"
                                          data-target={`#ipModal${student.id}`}
                                          title="Remove IP Address"
                                        >
                                          IP
                                        </button>
                                        <button
                                          className="btn btn-sm btn-primary col-md-3"
                                          style={{ borderRadius: "0" }}
                                          onClick={() => {
                                            this.handleView(student.id);
                                          }}
                                          title="View Details"
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>

                                        <button
                                          className="btn btn-sm btn-secondary col-md-3"
                                          style={{ borderRadius: "0" }}
                                          onClick={() => {
                                            this.handleEdit(student.id);
                                          }}
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>

                                        {/* MODAL TRIGGER */}
                                        <button
                                          className="btn btn-sm btn-danger col-md-3"
                                          style={{ borderRadius: "0" }}
                                          data-toggle="modal"
                                          data-target={`#myModal${student.id}`}
                                          title="Delete Student"
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                        {/* MODAL */}
                                        <div
                                          className="modal"
                                          id={`myModal${student.id}`}
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5 className="modal-title">
                                                  Are you sure want to delete
                                                  this student?
                                                </h5>
                                                <button
                                                  className="close"
                                                  data-dismiss="modal"
                                                >
                                                  &times;
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="row">
                                                  <div className="col-md-8">
                                                    <h6>
                                                      <b>Student Name: </b>{" "}
                                                      {student.fname +
                                                        " " +
                                                        student.lname}
                                                    </h6>
                                                    <h6>
                                                      <b>Email: </b>{" "}
                                                      {student.email}
                                                    </h6>
                                                    <h6>
                                                      <b>Contact Number: </b>{" "}
                                                      {student.phn_no}
                                                    </h6>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <img
                                                      src={`${BASE_URL}${`/images/student/${student.image}`}`}
                                                      alt="avatar"
                                                      className="avatar-sm"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer justify-content-around">
                                                  <div className="row ">
                                                    <button
                                                      className="btn btn-danger mr-3"
                                                      onClick={() => {
                                                        this.handleDelete(
                                                          student.id
                                                        );
                                                      }}
                                                      data-dismiss="modal"
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      data-dismiss="modal"
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* IP MODAL */}
                                        <div
                                          className="modal"
                                          id={`ipModal${student.id}`}
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5 className="modal-title">
                                                  Are you sure want to remove
                                                  IP?
                                                </h5>
                                                <button
                                                  className="close"
                                                  data-dismiss="modal"
                                                >
                                                  &times;
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="row">
                                                  <div className="col-md-3">
                                                    <img
                                                      src={`${BASE_URL}${`/images/student/${student.image}`}`}
                                                      alt="avatar"
                                                      height="80"
                                                      width="80"
                                                      className="rounded-circle avatar-sm"
                                                    />
                                                  </div>
                                                  <div className="col-md-9">
                                                    <h6>
                                                      <b>Student Name: </b>{" "}
                                                      {student.fname +
                                                        " " +
                                                        student.lname}
                                                    </h6>
                                                    <h6>
                                                      <b>Contact Number: </b>{" "}
                                                      {student.phn_no}
                                                    </h6>
                                                    <h6>
                                                      <b className="btn btn-warning btn-sm btn-block mb-0">
                                                        IP List
                                                      </b>
                                                      <div className="table-responsive">
                                                        <table class="table table-bordered">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">
                                                                IP
                                                              </th>
                                                              <th scope="col">
                                                                Location
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {student.custom_ip_address !==
                                                            null ? (
                                                              student.custom_ip_address.map(
                                                                (item) => {
                                                                  return (
                                                                    <tr>
                                                                      <th scope="row">
                                                                        {
                                                                          item[0]
                                                                        }
                                                                      </th>
                                                                      <td>
                                                                        {
                                                                          item[1]
                                                                        }
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )
                                                            ) : (
                                                              <tr>
                                                                <th scope="row">
                                                                  -
                                                                </th>
                                                                <td>-</td>
                                                              </tr>
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div className="modal-footer justify-content-around">
                                                  <div className="row ">
                                                    <button
                                                      className="btn btn-danger mr-3"
                                                      onClick={() => {
                                                        this.handleIP(
                                                          student.id
                                                        );
                                                      }}
                                                      data-dismiss="modal"
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      data-dismiss="modal"
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageStudent4g);
