import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Components
import Sidebar from "../ModeratorSidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();
class AddNotice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedBatch: null,
      seletedCourse: '',
      details: [],
      courses: [],
      students: [],
      loading: false,
      error: [],
      batch_id: null,
      path: "",
      isMobile: false,
      sidebar: true,
      batch: true,
      student: false,
      notice: '',
      isChecked: true,
    };
    this.addNotice = this.addNotice.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
  }

  componentDidMount() {
    document.title = "Send Notice";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });
    // const url = BASE_URL + "/api/moderator/course/p170815s";
    const url = BASE_URL + "/api/moderator/moderatorCourse";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("moderatorToken")}` },
      })
      .then((res2) => {
        this.setState({
          courses: res2.data,
        });
        // console.log(this.state.batch_id);
        const students_url = BASE_URL + "/api/moderator/student/student_p170815s";
        return axios
          .get(students_url, {
            headers: { Authorization: `Bearer  ${cookies.get("moderatorToken")}` },
          })
          .then((res3) => {
            this.setState({
              students: res3.data,
              loading: false,
            });
          });


      });
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  handleBatch = (batch) => {
    this.setState({
      selectedBatch: batch.id,//batch
      seletedCourse: batch.title
    });
    // console.log(this.state.selectedBatch);
    // console.log(this.state.seletedCourse);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // addNotice
  addNotice(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/moderator/send-notice`;

    const formData = {
      course_id: this.state.selectedBatch,
      course_title: this.state.seletedCourse,
      notice: this.state.notice,
      isEnable: this.state.isChecked
    }
    return axios.post(url, formData, {
      headers: { Authorization: `Bearer  ${cookies.get("moderatorToken")}` },
    }).then((res) => {
      console.log(res);
      this.setState({
        notice: '',
        selectedBatch: '',
        checked: true,
        error: [],
        loading: false,
      })
      toast.success('Notice send successfully')
    }).catch((error, res) => {
      this.setState({
        loading: false,
        error: error.response.data.errors
      })
    });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        {/* Notiification */}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* sidebar navigatin for mobile */}
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        {/* sidebar navigatin for mobile */}

        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row align-items-center justify-content-between mb-5">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Add New Notice
                      </h4>
                    </div>
                  </span>
                  <Link className="btn btn-primary" to='/moderator/notice/all-notice'>Back to All Notice</Link>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Add Notice
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <form
                            className="form-group"
                            onSubmit={this.addNotice}
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-12 input-group mb-2">
                                <Select
                                  options={this.state.courses}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.id}
                                  className="basic-multi-select w-100"
                                  classNamePrefix="select"
                                  onChange={this.handleBatch}
                                  placeholder="Please select a course"
                                />
                              </div>
                              <div className="col-lg-12 d-flex justify-content-between form-group">
                                <textarea
                                  id="notice"
                                  name="notice"
                                  placeholder="Write notice..."
                                  className="form-control"
                                  value={this.state.notice}
                                  onChange={this.handleChange}
                                  rows="3"
                                  required
                                />
                              </div>
                              <div className="col-lg-12 d-flex justify-content-between form-group">
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    defaultChecked={this.state.isChecked}
                                    onChange={this.toggleChange} />
                                  <label class="form-check-label" onChange={this.onCheck} for="exampleCheck1">Enable</label>
                                </div>
                              </div>
                              <div className="col-lg-12 d-flex justify-content-center">
                                <button className="btn btn-success w-50 px-4" type="submit">Add Notice</button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AddNotice);

