import React, { Component } from "react";
import { withRouter, NavLink, Redirect } from "react-router-dom";
import axios from "axios";

import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class PlayCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      classes: [],
      contents: [],
      sourceCodes: [],
      videoUrl: "",
      videoID: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.setState({ loading: true });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const url =
      BASE_URL + "/api/admin/course/play_course/" + this.props.match.params.id;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          course: res.data[0],
          classes: res.data[1],
          contents: res.data[2],
          sourceCodes: res.data[3],
        });
        if (res.data[0] !== null) {
          document.title = res.data[0].title;
          // give player a videoId
          let videoID = this.props.match.params.activeVideo
            ? this.props.match.params.activeVideo
            : this.state.contents[0].id;

          this.state.contents.map((item) => {
            if (item.id === parseInt(videoID)) {
              this.setState({
                videoUrl: item.video_id,
              });
            }
          });
          if (this.state.videoUrl) {
            // give player a videoId
            this.props.history.replace({
              pathname: `/admin/play-course/${this.props.match.params.id}/${videoID}`,
            });
          } else {
            this.props.history.replace({
              pathname: `/admin/manage-course`,
            });
          }
        } else {
          this.props.history.replace({
            pathname: `/admin/manage-course`,
          });
        }
      });
  }

  handleVideo = (idOfContent) => {
    {
      this.state.contents.map((content) => {
        if (content.id === parseInt(idOfContent)) {
          this.setState({
            videoUrl: content.video_id,
          });
        }
      });
    }
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className="col-md-12 text-center text-primary mt-4 p-4 fullPageHeight">
            Loading..
          </div>
        </>
      );
    } else {
      if (this.state.course) {
        return (
          <>
            <div className="container-fluid">
              <div className="row course-title single-course-title">
                <span>
                  <NavLink
                    to={"/admin/course-info/" + this.props.match.params.id}
                  >
                    <i className="fa fa-arrow-circle-left text-white"></i>
                  </NavLink>
                </span>
                <span className="ml-2">
                  Course Title : {this.state.course.title}
                </span>
              </div>
            </div>
            <div
              className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
              style={{
                position: "fixed",
                top: "30%",
                zIndex: "10",
              }}
            >
              <button
                className="btn btn-sm btn-warning rounded-circle"
                title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                onClick={this.handleSidebar}
              >
                <i className="fa fa-align-right"></i>
              </button>
            </div>
            <div className={`${this.state.isMobile ? "marginx " : "course"}`}>
              <div className="row">
                <div
                  className={`${
                    this.state.isMobile
                      ? "col-md-12 mobileCoursebar"
                      : "coursebar"
                  }`}
                >
                  {this.state.sidebar ? (
                    <div className="accordion pb-3" id="accordion">
                      {this.state.classes.map((courseClass, index) => {
                        let tempContentID = this.state.contents.find(
                          (content) =>
                            content.id ===
                            parseInt(this.props.match.params.activeVideo)
                        );
                        return (
                          <div key={index}>
                            <div
                              className="card-header video-card-header text-left p-4"
                              style={{ borderRadius: 0 }}
                              id={`heading-${index}`}
                            >
                              <h5 className="mb-0">
                                <a
                                  className="collapsed sidebar-active text-white"
                                  role="button"
                                  data-toggle="collapse"
                                  href={`#collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse-${index}`}
                                >
                                  Class {index + 1} : {courseClass.title}
                                </a>
                              </h5>
                            </div>

                            <div
                              id={`collapse-${index}`}
                              className={`collapse ${[
                                tempContentID
                                  ? [
                                      courseClass.id ===
                                      tempContentID.add_class_id
                                        ? "show"
                                        : "",
                                    ]
                                  : [
                                      courseClass.id ===
                                      this.state.contents[0].id
                                        ? "show"
                                        : "",
                                    ],
                              ]}`}
                              data-parent="#accordion"
                              aria-labelledby={`heading-${index}`}
                            >
                              <div className="card-body bg-white pt-1">
                                <div className="card-header bg-white text-secondary text-center fs-13 rounded-0 border-0 ">
                                  Main Concept : {courseClass.content_about}
                                </div>
                                {this.state.contents.map(
                                  (classContent, index) => {
                                    if (
                                      classContent.add_class_id ===
                                      courseClass.id
                                    ) {
                                      return (
                                        <div key={index} className={`px-3`}>
                                          <NavLink
                                            to={`/admin/play-course/${courseClass.course_id}/${classContent.id}`}
                                            activeClassName="sidebar-active"
                                            className={`nav-link py-0 border-bottom`}
                                            onClick={() => {
                                              this.handleVideo(classContent.id);
                                            }}
                                          >
                                            <span className="text-capitalize">
                                              <i className="fa fa-play-circle"></i>{" "}
                                              {classContent.title}
                                            </span>
                                          </NavLink>
                                        </div>
                                      );
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                                <div className={`mt-2`}>
                                  <div className="text-center mb-2">
                                    {this.state.sourceCodes.map((srcCode) => {
                                      if (
                                        srcCode.add_class_id === courseClass.id
                                      ) {
                                        return (
                                          <a
                                            href={
                                              BASE_URL +
                                              "/tutorials/source/" +
                                              srcCode.source
                                            }
                                            className={`mx-1 text-secondary`}
                                          >
                                            <i className="fa fa-file-text"></i>{" "}
                                            Source Code
                                          </a>
                                        );
                                      } else {
                                        return "";
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`${
                    this.state.isMobile ? "col-md-12 mt-0 pt-0" : "videobar"
                  }`}
                >
                  <div className="container">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          {this.state.videoUrl ? (
                            <iframe
                              src={`https://player.vimeo.com/video/${this.state.videoUrl}`}
                              style={{
                                height: `calc(100vh - 176px)`,
                                width: `100%`,
                              }}
                              frameborder="0"
                              allow="autoplay; fullscreen"
                              allowfullscreen
                            ></iframe>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return false;
      }
    }
  }
}
export default withRouter(PlayCourse);
