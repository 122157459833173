import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";

class Error extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page bg-danger text-center">
          <div className="mt-5">
            <img
              src="https://4.bp.blogspot.com/-gdd8Nj5zNqg/T29ZjfvDjYI/AAAAAAAAAMw/vwwjnuqR1Ro/w1200-h630-p-k-no-nu/404+error+page+6.jpg"
              height="150px"
              width="200px"
              className="rounded-sm"
            />
          </div>
          <div className="text-white mt-3">
            <h3>{_t(t("Opps! It's a dead end!"))}</h3>
            <NavLink to="/" className="btn btn-secondary mt-3 px-3 ">
              {_t(t("Back to Homepage"))}
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Error);
