import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Sidebar from "./ModeratorSidebar";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ModeratorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moderator: "",
      new_assignments: 0,
      new_feedback: 0,
      new_comments: 0,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    document.title = "Moderator Dashboard";
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const user_url = BASE_URL + "/api/auth/moderator/me";
    return axios
      .get(user_url, {
        headers: { Authorization: `Bearer ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        this.setState({
          moderator: res.data,
        });
        const user_url = BASE_URL + "/api/moderator/moderator_dashboard";
        return axios
          .get(user_url, {
            headers: {
              Authorization: `Bearer ${cookies.get("moderatorToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              new_assignments: res.data[0],
              new_feedback: res.data[1],
              new_comments: res.data[2],
              loading: false,
            });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                {this.state.loading ? (
                  <Skeleton height="40px" className="my-3" />
                ) : (
                  <h4 className="mt-2 mb-4">
                    <i
                      className="fa fa-chevron-right"
                      style={{ fontSize: 20 }}
                    ></i>{" "}
                    Moderator Dashboard
                    <br />
                    <span style={{ fontSize: "14px" }}>
                      Welcome Moderator{" "}
                      <b>
                        {" " +
                          this.state.moderator.fname +
                          " " +
                          this.state.moderator.lname}
                      </b>
                    </span>{" "}
                  </h4>
                )}
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="container">
                        {this.state.loading ? (
                          <div className="row col-md-12 justify-content-around text-white">
                            <div className="col-md-3 mb-3">
                              <Skeleton className="dashboard-card" />
                            </div>
                            <div className="col-md-3 mb-3">
                              <Skeleton className="dashboard-card" />
                            </div>
                            <div className="col-md-3 mb-3">
                              <Skeleton className="dashboard-card" />
                            </div>
                          </div>
                        ) : (
                          <div className="row col-md-12 justify-content-around text-white">
                            <div className="col-md-3 card card-body bg-danger dashboard-card mb-3">
                              <div className="text-center bg-none">
                                New Assignment(s)
                              </div>
                              <h1 className="text-center">
                                {this.state.new_assignments}
                              </h1>
                              <div className="text-center">
                                <Link
                                  to="/moderator/check-assignments"
                                  className="btn btn-sm btn-success btn-block px-3"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-3 card card-body bg-primary dashboard-card mb-3">
                              <div className="text-center bg-none">
                                New Feedback
                              </div>
                              <h1 className="text-center">
                                {this.state.new_feedback}
                              </h1>
                              <div className="text-center">
                                <Link
                                  to="/moderator/manage-feedback"
                                  className="btn btn-sm btn-success btn-block px-3"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-3 card card-body bg-secondary dashboard-card mb-3">
                              <div className="text-center bg-none">
                                New Comment(s)
                              </div>
                              <h1 className="text-center">
                                {this.state.new_comments}
                              </h1>
                              <div className="text-center">
                                <Link
                                  to="/moderator/check-comments"
                                  className="btn btn-sm btn-success btn-block px-3"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12 mt-4 text-center">
                          <img
                            className="img-fluid"
                            src="https://image.freepik.com/free-vector/focused-tiny-people-reading-books_74855-5836.jpg"
                            alt="dashboard-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ModeratorDashboard);
