import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../ModeratorSidebar";

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ModeratorViewAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignment: [],
      student: [],
      course: [],
      feedbacks: [],
      admins: [],
      instructors: [],
      moderators: [],
      userID: null,
      tempClass: [],
      score: null,
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
      comment: "",
      commentBtn: false,
      commentLoading: false,
    };
  }
  componentDidMount() {
    document.title = "Check Assignment";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );

    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const assignments_url =
      BASE_URL + `/api/moderator/moderator_assignment/${id}`;
    return axios
      .get(assignments_url, {
        headers: { Authorization: `Bearer ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        if (res.data[0] !== null) {
          this.setState({
            assignment: res.data[0],
            userID: res.data[1],
          });
          const class_url =
            BASE_URL +
            `/api/moderator/class/p20081994s/${res.data[0].add_class_id}`;
          return axios
            .get(class_url, {
              headers: {
                Authorization: `Bearer ${cookies.get("moderatorToken")}`,
              },
            })
            .then((res) => {
              this.setState({
                tempClass: res.data,
              });
              const course_url =
                BASE_URL +
                `/api/moderator/course/p170815s/${res.data.course_id}`;
              return axios
                .get(course_url, {
                  headers: {
                    Authorization: `Bearer ${cookies.get("moderatorToken")}`,
                  },
                })
                .then((res) => {
                  this.setState({
                    course: res.data,
                  });
                  const student_url =
                    BASE_URL +
                    `/api/moderator/student/student_p170815s/${this.state.assignment.student_id}`;
                  return axios
                    .get(student_url, {
                      headers: {
                        Authorization: `Bearer ${cookies.get(
                          "moderatorToken"
                        )}`,
                      },
                    })
                    .then((res) => {
                      this.setState({
                        student: res.data,
                      });
                      const feedback_url =
                        BASE_URL +
                        "/api/moderator/moderator_assignment_feedback";
                      let formData = {
                        assignment_id: this.props.match.params.id,
                      };
                      return axios
                        .post(feedback_url, formData, {
                          headers: {
                            Authorization: `Bearer ${cookies.get(
                              "moderatorToken"
                            )}`,
                          },
                        })
                        .then((res) => {
                          this.setState({
                            feedbacks: res.data[0],
                            admins: res.data[1],
                            instructors: res.data[2],
                            moderators: res.data[3],
                            loading: false,
                          });
                        });
                    });
                });
            });
        } else {
          this.props.history.push("/moderator/manage-assignments");
        }
      })
      .catch(() => {
        this.props.history.push("/moderator/manage-assignments");
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, assignment_id) => {
    e.preventDefault();
    this.setState({
      commentLoading: true,
    });
    const assignments_url =
      BASE_URL + `/api/moderator/moderator_assignment/${assignment_id}`;
    let formData = {
      comment: this.state.comment,
      enrollment_id: this.state.assignment.enrollment_id,
      add_class_id: this.state.assignment.add_class_id,
    };
    return axios
      .patch(assignments_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        this.setState({
          feedbacks: res.data,
          comment: "",
          commentLoading: false,
        });
        const seen_url =
          BASE_URL +
          "/api/moderator/seen_feedback/" +
          this.state.assignment.add_class_id +
          "/" +
          this.props.match.params.id;
        return axios.get(seen_url, {
          headers: {
            Authorization: `Bearer ${cookies.get("moderatorToken")}`,
          },
        });
      });
  };

  feedbackDelete = (id) => {
    const delete_feedback =
      BASE_URL +
      `/api/moderator/delete_feedback/${id}/${this.props.match.params.id}`;
    return axios
      .get(delete_feedback, {
        headers: { Authorization: `Bearer ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        this.setState({
          feedbacks: res.data,
        });
      });
  };

  // Jodit Editor plugin
  updateContent = (value) => {
    if (value !== null && value !== "") {
      this.setState({
        commentBtn: true,
        comment: value,
      });
    } else {
      this.setState({
        commentBtn: false,
      });
    }
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };
  // Jodit Editor plugin

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Check Assignment
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <Link
                        to="/instructor/manage-assignments"
                        className="nav-link text-light bg-secondary mb-3 rounded-sm ml-2"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                    </div>
                    {this.state.loading ? (
                      <div className="row text-center">
                        <div className="col-md-12 text-primary">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="container">
                          <div className="bg-light pb-4">
                            <span className="row col-md-12 mx-auto mb-2">
                              <a
                                tearget="_blank"
                                href={
                                  BASE_URL +
                                  "/assignments/" +
                                  this.state.assignment.assignment
                                }
                                className="btn btn-sm btn-block btn-success mx-5 py-3"
                              >
                                Download Submitted Assignment
                              </a>
                            </span>
                            <div className="row col-md-10 offset-md-1 mx-auto mb-2">
                              <table className="table table-striped table-bordered table-sm">
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <td>Student's Name</td>
                                    <td>
                                      {this.state.student.fname +
                                        " " +
                                        this.state.student.lname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Contact No.</td>
                                    <td>{this.state.student.phn_no}</td>
                                  </tr>
                                  <tr>
                                    <td>Course Title</td>
                                    <td>{this.state.course.title} </td>
                                  </tr>
                                  <tr>
                                    <td>Submitted</td>
                                    <td>
                                      <Moment format="LLL">
                                        {this.state.assignment.created_at}
                                      </Moment>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Updated</td>
                                    <td>
                                      <Moment format="LLL">
                                        {this.state.assignment.updated_at}
                                      </Moment>{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {this.state.assignment.score ? (
                              <div className="row mb-2 justify-content-center">
                                <h4 className="course-heading text-uppercase bg-warning px-3 rounded-sm py-2">
                                  Score: {this.state.assignment.score} / 10
                                </h4>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row mb-2 justify-content-center">
                              <h3 className="course-heading text-uppercase border-bottom mb-2">
                                Class Title: {this.state.tempClass.title}
                              </h3>
                            </div>
                            {this.state.feedbacks.length > 0 ? (
                              <div className="col-md-10 offset-md-1 mb-2">
                                {this.state.feedbacks.map((item) => {
                                  return (
                                    <div className="row justify-content-around mb-2">
                                      <div className="col-md-1">
                                        {item.student_id ? (
                                          <img
                                            src={`${BASE_URL}${`/images/student/${this.state.student.image}`}`}
                                            alt="dp"
                                            className="rounded-circle"
                                            height="60"
                                            width="60"
                                          />
                                        ) : (
                                          [
                                            item.admin_id
                                              ? [
                                                  this.state.admins.map(
                                                    (admin) => {
                                                      if (
                                                        admin.id ===
                                                        item.admin_id
                                                      ) {
                                                        return (
                                                          <img
                                                            src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                            alt="dp"
                                                            className="rounded-circle"
                                                            height="60"
                                                            width="60"
                                                          />
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ]
                                              : [
                                                  item.instructor_id
                                                    ? [
                                                        this.state.instructors.map(
                                                          (instructor) => {
                                                            if (
                                                              instructor.id ===
                                                              item.instructor_id
                                                            ) {
                                                              return (
                                                                <img
                                                                  src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                  alt="dp"
                                                                  className="rounded-circle"
                                                                  height="60"
                                                                  width="60"
                                                                />
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        this.state.moderators.map(
                                                          (moderator) => {
                                                            if (
                                                              moderator.id ===
                                                              item.moderator_id
                                                            ) {
                                                              return (
                                                                <img
                                                                  src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                  alt="dp"
                                                                  className="rounded-circle"
                                                                  height="60"
                                                                  width="60"
                                                                />
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ],
                                                ],
                                          ]
                                        )}
                                      </div>

                                      <div className="col-md-10 mt-1">
                                        {item.student_id ? (
                                          <>
                                            <b>
                                              {this.state.student.fname +
                                                " " +
                                                this.state.student.lname}
                                            </b>{" "}
                                            <span>
                                              (
                                              <Moment format="LLL">
                                                {item.created_at}
                                              </Moment>
                                              )
                                            </span>
                                            <i
                                              className="fa fa-times ml-2 delete-comment"
                                              onClick={() =>
                                                this.feedbackDelete(item.id)
                                              }
                                              title="Delete Comment"
                                            ></i>
                                          </>
                                        ) : (
                                          [
                                            item.admin_id
                                              ? [
                                                  this.state.admins.map(
                                                    (admin) => {
                                                      if (
                                                        admin.id ===
                                                        item.admin_id
                                                      ) {
                                                        return (
                                                          <>
                                                            <b>{admin.name}</b>{" "}
                                                            (
                                                            <span className="text-primary">
                                                              Admin
                                                            </span>
                                                            ){" "}
                                                            <span>
                                                              (
                                                              <Moment format="LLL">
                                                                {
                                                                  item.created_at
                                                                }
                                                              </Moment>
                                                              )
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ]
                                              : [
                                                  item.instructor_id
                                                    ? [
                                                        this.state.instructors.map(
                                                          (instructor) => {
                                                            if (
                                                              instructor.id ===
                                                              item.instructor_id
                                                            ) {
                                                              return (
                                                                <>
                                                                  <b>
                                                                    {instructor.fname +
                                                                      " " +
                                                                      instructor.lname}
                                                                  </b>{" "}
                                                                  (
                                                                  <span className="text-primary">
                                                                    Instructor
                                                                  </span>
                                                                  ){" "}
                                                                  <span>
                                                                    (
                                                                    <Moment format="LLL">
                                                                      {
                                                                        item.created_at
                                                                      }
                                                                    </Moment>
                                                                    )
                                                                  </span>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        this.state.moderators.map(
                                                          (moderator) => {
                                                            if (
                                                              moderator.id ===
                                                              item.moderator_id
                                                            ) {
                                                              return (
                                                                <>
                                                                  <b>
                                                                    {moderator.fname +
                                                                      " " +
                                                                      moderator.lname}
                                                                  </b>{" "}
                                                                  (
                                                                  <span className="text-primary">
                                                                    Moderator
                                                                  </span>
                                                                  ){" "}
                                                                  <span>
                                                                    (
                                                                    <Moment format="LLL">
                                                                      {
                                                                        item.created_at
                                                                      }
                                                                    </Moment>
                                                                    )
                                                                  </span>
                                                                  {this.state
                                                                    .userID ===
                                                                  moderator.id ? (
                                                                    <i
                                                                      className="fa fa-times ml-2 delete-comment"
                                                                      onClick={() =>
                                                                        this.feedbackDelete(
                                                                          item.id
                                                                        )
                                                                      }
                                                                      title="Delete Comment"
                                                                    ></i>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ],
                                                ],
                                          ]
                                        )}
                                        <br />
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.remark,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                            <form
                              className="form-group mx-5 py-3 border px-2 bg-light"
                              onSubmit={(e) => {
                                this.handleSubmit(
                                  e,
                                  this.props.match.params.id
                                );
                              }}
                            >
                              <div>
                                <label
                                  className="text-success"
                                  htmlFor="remark"
                                >
                                  Suggestion
                                </label>
                                <JoditEditor
                                  editorRef={this.setRef}
                                  value={this.state.comment}
                                  config={this.config}
                                  onChange={this.updateContent}
                                />
                              </div>

                              <div className="text-center">
                                {this.state.commentLoading ? (
                                  <button
                                    className="btn btn-success btn-block mt-2 text-uppercase col-md-12 mb-2"
                                    disabled
                                  >
                                    Please wait
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block mt-2 text-uppercase col-md-12 mb-2"
                                    disabled={!this.state.commentBtn}
                                  >
                                    Add Suggestion
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ModeratorViewAssignment);
