import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// Components
import Sidebar from "../Sidebar";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ManageCourseClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      sourceCodes: [],
      class_contents: [],
      loading: false,
      path: "",
      alert_success: "",
      isMobile: false,
      sidebar: true,
      alert_success: "",
      error: [],
      submitSource: null,
      loadSource: false,
    };
  }
  componentDidMount() {
    document.title = "Course Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const class_url =
      BASE_URL +
      `/api/admin/class/course_class_p20081994s/${this.props.match.params.id}`;
    return axios
      .get(class_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          classes: res.data[0],
          sourceCodes: res.data[1],
        });
        const contents_url =
          BASE_URL +
          `/api/admin/content/course_content_p20081994s/${this.props.match.params.id}`;
        return axios
          .get(contents_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              class_contents: res.data,
              loading: false,
            });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleClassDelete = (e) => {
    const url = BASE_URL + `/api/admin/class/p20081994s/${e}`;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.setState({
          alert_success: "Class deleted successfully",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: "" });
          }.bind(this),
          3000
        );
        const class_url =
          BASE_URL +
          `/api/admin/class/course_class_p20081994s/${this.props.match.params.id}`;
        return axios
          .get(class_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              classes: res.data[0],
              sourceCodes: res.data[1],
            });
            const contents_url =
              BASE_URL +
              `/api/admin/content/course_content_p20081994s/${this.props.match.params.id}`;
            return axios
              .get(contents_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  class_contents: res.data,
                  loading: false,
                });
              });
          });
      });
  };

  handleContentDelete = (e) => {
    const url = BASE_URL + `/api/admin/content/content_p20081994s/${e}`;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.setState({
          alert_success: "File deleted successfully",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: "" });
          }.bind(this),
          3000
        );
        const class_url =
          BASE_URL +
          `/api/admin/class/course_class_p20081994s/${this.props.match.params.id}`;
        return axios
          .get(class_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              classes: res.data[0],
              sourceCodes: res.data[1],
            });
            const contents_url =
              BASE_URL +
              `/api/admin/content/course_content_p20081994s/${this.props.match.params.id}`;
            return axios
              .get(contents_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  class_contents: res.data,
                  loading: false,
                });
              });
          });
      });
  };

  handleSource = (e) => {
    this.setState({
      submitSource: e.target.files[0],
    });
  };

  handleSourceSubmit = (e, id) => {
    e.preventDefault();
    this.setState({
      loadSource: true,
    });
    const assignment_url = BASE_URL + `/api/admin/content/source_code`;
    let formData = new FormData();
    formData.append("submitSource", this.state.submitSource);
    formData.append("course_id", this.props.match.params.id);
    formData.append("add_class_id", id);
    return axios
      .post(assignment_url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          sourceCodes: res.data,
          alert_success: "Source Code submitted successfully",
          loadSource: false,
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.errors,
          loadAssignment: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          5000
        );
        this.props.history.replace();
      });
  };

  sourceDelete = (id, course_id) => {
    this.setState({
      loadSource: true,
    });
    const url = BASE_URL + "/api/admin/content/delete_source_code";
    let formData = {
      id: id,
      course_id: course_id,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          sourceCodes: res.data,
          alert_success: "Deleted Successfully",
          loadSource: false,
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          Course's Class Information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  {/* Error or Success Message */}
                  {this.state.alert_success ? (
                    <div className="text-center bg-success text-white p-3 mb-2">
                      {this.state.alert_success}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Error or Success Message */}
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row pl-1 mr-1 ml-3">
                          <Link
                            to={`/admin/course-info/${this.props.match.params.id}`}
                            className="nav-link text-light bg-secondary mb-3 rounded-sm"
                            style={{ width: "120px" }}
                          >
                            <i className="fa fa-reply-all"></i> Go Back
                          </Link>
                        </div>
                      </div>
                      <div className="row col-md-8">
                        <div className="row ml-auto mr-1 pr-0">
                          <span className="nav-link text-dark  mb-3 rounded-sm ml-1">
                            Total Class: {this.state.classes.length}
                          </span>
                          <Link
                            to={`/admin/add-course/${this.props.match.params.id}/add-class`}
                            className="nav-link text-white bg-primary mb-3 rounded-sm ml-1"
                          >
                            <i className="fa fa-plus"></i> Class
                          </Link>
                        </div>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <div className="container">
                        <div className="mt-3">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="container">
                              <div id="class_accordion" className="pl-1 pb-4">
                                {/* Loop Here */}
                                {this.state.classes.map((item, index) => {
                                  return (
                                    <div
                                      className="card mb-2 border-0"
                                      key={item.id}
                                    >
                                      <div
                                        className="card-header bg-warning "
                                        id={`class-heading-${item.id}`}
                                      >
                                        <div className="mb-0">
                                          <a
                                            className="collapsed text-dark"
                                            role="button"
                                            data-toggle="collapse"
                                            href={`#class-collapse-${item.id}`}
                                            aria-expanded="false"
                                            aria-controls={`class-collapse-${item.id}`}
                                          >
                                            <span>
                                              {index + 1}. {item.title}
                                              {` (${item.content_about})`}
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                      <div
                                        id={`class-collapse-${item.id}`}
                                        className={`collapse ${this.state.classes[0].id === item.id
                                          ? "show"
                                          : ""
                                          }`}
                                        data-parent="#class_accordion"
                                        aria-labelledby={`class-heading-${item.id}`}
                                      >
                                        <div className="card-body">
                                          <div className="row col-md-12">
                                            <div className="row ml-auto">
                                              <Link
                                                to={`/admin/course/${this.props.match.params.id}/edit-class/${item.id}`}
                                                className="nav-link text-light bg-info mb-3 rounded-sm"
                                              >
                                                <i className="fa fa-pencil"></i>{" "}
                                                Class Info
                                              </Link>

                                              {/* MODAL TRIGGER */}
                                              <button
                                                className="btn btn-sm btn-secondary mb-3 rounded-sm ml-2"
                                                data-toggle="modal"
                                                data-target={`#sourceModal${item.id}`}
                                                title="Manage source code"
                                              >
                                                <i className="fa fa-pencil"></i>{" "}
                                                Source Code
                                              </button>
                                              {/* MODAL */}
                                              <div
                                                className="modal"
                                                id={`sourceModal${item.id}`}
                                              >
                                                <div className="modal-dialog">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5 className="modal-title">
                                                        Manage Source Code
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        data-dismiss="modal"
                                                      >
                                                        &times;
                                                      </button>
                                                    </div>
                                                    <div className="modal-body">
                                                      {this.state.loadSource ? (
                                                        <div className="col-md-12 text-primary text-center">
                                                          Updating..
                                                        </div>
                                                      ) : (
                                                        <>
                                                          <div className="row">
                                                            {/* Error or Success Message */}
                                                            {this.state
                                                              .alert_success ? (
                                                              <div className="text-center col-md-12 text-white bg-info py-2 mb-2">
                                                                {
                                                                  this.state
                                                                    .alert_success
                                                                }
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {this.state.error
                                                              ? [
                                                                this.state
                                                                  .error
                                                                  .submitSource ? (
                                                                  <div className="text-center text-white col-md-12 bg-danger py-2 mb-2">
                                                                    <div>
                                                                      {
                                                                        this
                                                                          .state
                                                                          .error
                                                                          .submitSource
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                ),
                                                              ]
                                                              : ""}
                                                            {/* Error or Success Message */}

                                                            {this.state.sourceCodes.map(
                                                              (source) => {
                                                                if (
                                                                  source.add_class_id ===
                                                                  item.id
                                                                ) {
                                                                  return (
                                                                    <span className="row col-md-12 mx-auto mb-2">
                                                                      <a
                                                                        tearget="_blank"
                                                                        href={
                                                                          BASE_URL +
                                                                          "/tutorials/source/" +
                                                                          source.source
                                                                        }
                                                                        className="btn btn-sm btn-block btn-success mx-5 py-3"
                                                                      >
                                                                        Download
                                                                        Source
                                                                        Code
                                                                      </a>
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </div>
                                                          <div className="row">
                                                            <span className="row col-md-12 mx-auto mb-2">
                                                              <form
                                                                className="mx-5 py-3 border px-2 bg-light"
                                                                onSubmit={(
                                                                  e
                                                                ) => {
                                                                  this.handleSourceSubmit(
                                                                    e,
                                                                    item.id
                                                                  );
                                                                }}
                                                              >
                                                                <div className="input-group">
                                                                  <label>
                                                                    Upload
                                                                    Source Code
                                                                    <span className="fs-13 bg-warning rounded-sm px-1 ml-1">
                                                                      {
                                                                        " (Upload Zip / Rar folder)"
                                                                      }
                                                                    </span>
                                                                  </label>
                                                                  <input
                                                                    type="file"
                                                                    id="submitAssignment"
                                                                    className="form-control-file mt-2"
                                                                    onChange={
                                                                      this
                                                                        .handleSource
                                                                    }
                                                                    required
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <button
                                                                    type="submit"
                                                                    className="btn btn-success btn-sm mt-2 px-4"
                                                                  >
                                                                    Submit
                                                                  </button>
                                                                </div>
                                                              </form>
                                                            </span>
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                    <div className="modal-footer">
                                                      {this.state.sourceCodes.map(
                                                        (source) => {
                                                          if (
                                                            source.add_class_id ===
                                                            item.id
                                                          ) {
                                                            return (
                                                              <span className="mx-auto mb-2">
                                                                <button
                                                                  className="btn btn-sm btn-danger mx-3"
                                                                  onClick={() =>
                                                                    this.sourceDelete(
                                                                      source.id,
                                                                      source.course_id
                                                                    )
                                                                  }
                                                                >
                                                                  Delete Source
                                                                  Code
                                                                </button>
                                                              </span>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <Link
                                                to={`/admin/edit-course/${this.props.match.params.id}/edit-class/class-contents/${item.id}`}
                                                className="nav-link text-light bg-primary mb-3 rounded-sm ml-2"
                                              >
                                                <i className="fa fa-pencil"></i>{" "}
                                                Contents
                                              </Link>

                                              {/* MODAL TRIGGER */}
                                              <button
                                                className="btn btn-sm btn-danger mb-3 rounded-sm ml-2"
                                                data-toggle="modal"
                                                data-target={`#myModal${item.id}`}
                                                title="Delete Class"
                                              >
                                                <i className="fa fa-times"></i>{" "}
                                                Delete Class
                                              </button>
                                              {/* MODAL */}
                                              <div
                                                className="modal"
                                                id={`myModal${item.id}`}
                                              >
                                                <div className="modal-dialog">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5 className="modal-title">
                                                        Are you sure want to
                                                        delete this class?
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        data-dismiss="modal"
                                                      >
                                                        &times;
                                                      </button>
                                                    </div>
                                                    <div className="modal-footer justify-content-around">
                                                      <div className="row ">
                                                        <button
                                                          className="btn btn-danger mr-3"
                                                          onClick={() => {
                                                            this.handleClassDelete(
                                                              item.id
                                                            );
                                                          }}
                                                          data-dismiss="modal"
                                                        >
                                                          Yes
                                                        </button>
                                                        <button
                                                          className="btn btn-success"
                                                          data-dismiss="modal"
                                                        >
                                                          No
                                                        </button>
                                                      </div>
                                                    </div>{" "}
                                                    <div className="text-center pb-2 text-danger fs-13">
                                                      <b>
                                                        N.B : All the Videos of
                                                        this class will be
                                                        deleted.
                                                      </b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="table-responsive bg-light">
                                              <table className="table table-striped table-hover text-center">
                                                <thead>
                                                  <tr>
                                                    <th>#</th>
                                                    <th>Contents</th>
                                                    <th className="text-center">
                                                      Action
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.class_contents.map(
                                                    (content, index) => {
                                                      if (
                                                        content.add_class_id ===
                                                        item.id
                                                      ) {
                                                        return (
                                                          <tr key={content.id}>
                                                            <td>#</td>
                                                            <td>
                                                              {content.title}
                                                            </td>
                                                            <td>
                                                              <div className="row col-md-12 mx-0">
                                                                <NavLink
                                                                  to={
                                                                    "/admin/play-course/" +
                                                                    content.course_id +
                                                                    "/" +
                                                                    content.id
                                                                  }
                                                                  className="btn btn-sm btn-outline-success rounded-sm col mr-1"
                                                                  title="Play"
                                                                >
                                                                  <i className="fa fa-play"></i>
                                                                </NavLink>
                                                                {/* MODAL TRIGGER */}
                                                                <button
                                                                  className="btn btn-sm btn-outline-danger rounded-sm col"
                                                                  data-toggle="modal"
                                                                  data-target={`#myModal${content.id}`}
                                                                  title="Delete File"
                                                                >
                                                                  <i className="fa fa-times"></i>
                                                                </button>
                                                              </div>
                                                              {/* MODAL */}
                                                              <div
                                                                className="modal"
                                                                id={`myModal${content.id}`}
                                                              >
                                                                <div className="modal-dialog">
                                                                  <div className="modal-content">
                                                                    <div className="modal-header">
                                                                      <h5 className="modal-title">
                                                                        Are you
                                                                        sure
                                                                        want to
                                                                        delete
                                                                        this
                                                                        content?
                                                                      </h5>
                                                                      <button
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                      >
                                                                        &times;
                                                                      </button>
                                                                    </div>
                                                                    <div className="modal-footer justify-content-around">
                                                                      <div className="row ">
                                                                        <button
                                                                          className="btn btn-danger mr-3"
                                                                          onClick={() => {
                                                                            this.handleContentDelete(
                                                                              content.id
                                                                            );
                                                                          }}
                                                                          data-dismiss="modal"
                                                                        >
                                                                          Yes
                                                                        </button>
                                                                        <button
                                                                          className="btn btn-success"
                                                                          data-dismiss="modal"
                                                                        >
                                                                          No
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                      return false;
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {/* Loop Here */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageCourseClasses);
