import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import handVec from '../images/allCourses/hand-vec.png';
import promo1 from '../images/allCourses/promo1.png';
import promo2 from '../images/allCourses/promo2.png';
import promo3 from '../images/allCourses/promo3.png';
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
// Components
// import Sidebar from "../../Sidebar";
const cookies = new Cookies();


class Promote extends Component {
    constructor(props) {
        super(props);
        this.state = {
          main_title: "",
          image1: '',
          image2:'',
          image3:'',
          promo_des:'',
          promo_title1: '',
          promo_title2: '',
          promo_title3: '',
        };
      }

    componentDidMount() { 
        const PromoteUrl = BASE_URL + `/api/get_promote_data`;
        // , {
        //   headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
        // }
        return axios
        .get(PromoteUrl).then((res) => {
          this.setState({
            main_title: res.data[0].main_title,
            image1: res.data[0].image1,
            image2: res.data[0].image2,
            image3: res.data[0].image3,
            promo_des: res.data[0].promo_des,
            promo_title1: res.data[0].promo_title1,
            promo_title2: res.data[0].promo_title2,
            promo_title3: res.data[0].promo_title3,
          });
    
          // console.log(res.data);
          
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error.response.data.errors,
          });
        });
    }
    render() {
        return <> 
            <div className="container v2_promote-section mb-150">
                <div className="row justify-content-cente align-items-center">
                    <div className="col-lg-3 position-relative text-center text-lg-left">
                        <h3 className="promo-title font-weight-bold">{this.state.main_title}</h3>
                        {/* <button className="btn1 btn sky-bg mt-4"><i class="fa fa-user" aria-hidden="true"></i> | Enrolle Now</button> */}
                        <Link to="/student-registration" className="btn1 btn sky-bg mt-4"><i class="fa fa-user" aria-hidden="true"></i> | Enroll Now</Link>
                        <img  
                        src={handVec} 
                        alt="" 
                        className="pos-hanvec heartbeat"/>
                    </div>
                    <div className="col-lg-3 text-center mt-5 mt-lg-0">
                        <img 
                         src={`${BASE_URL}${`/images/promotion/${this.state.image1}`}`}
                         className="img-promo" 
                         alt="" />
                        <h5 className="mt-3 font-weight-bold">{this.state.promo_title1} </h5>
                    </div>
                    <div className="col-lg-3 orange-bg rounded py-5 text-center mt-5 mt-lg-0">
                        <img 
                         src={`${BASE_URL}${`/images/promotion/${this.state.image2}`}`}
                        className="img-promo" 
                        alt="" />
                        <h6 className="mt-3 font-weight-bold">{this.state.promo_title2}</h6>
                        <span className="text-white">{this.state.promo_des}</span>
                    </div>
                    <div className="col-lg-3 text-center mt-5 mt-lg-0">
                        <img  
                        src={`${BASE_URL}${`/images/promotion/${this.state.image3}`}`} className="img-promo " 
                        alt="" />
                        <h5 className="mt-3 font-weight-bold">{this.state.promo_title3} </h5>
                    </div>
                </div>
            </div>
        </>
    }
}

export default Promote;
