import React, { Component } from 'react';
import enroll1 from '../images/allCourses/enroll1.png';
import { Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../BaseUrl";
export default class NewCourses extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/all_courses";
    return axios.get(url).then((res) => {
      this.setState({
        courses: res.data,
        loading: false,
      });
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="row col-md-8 offset-md-2  mt-5 mb-5"></div>
        ) : (
          <div className='v2_course-section'>
          <div className="container">
              <div className="row align-items-center mb-4">
                  <div className="col-lg-12 d-flex justify-content-center">
                      <h2 className="text-capitalize font-weight-bold">New Courses</h2>
                  </div>
              </div>
              <div className="row justify-content-cente align-items-center">
              {this.state.courses.slice(0, 3).map((item) => {
                return (

                  <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                      <div class="single-enroll">
                          {/* <img src={`item.thumbnail`} alt="img" className="img-item mb-3 w-100"/> */}
                          <img
                          className="card-img-top"
                          src={`${BASE_URL}${`/images/course/${item.thumbnail}`}`}
                          height="150px"
                          width="100%"
                          alt=""
                        />

                          <div className="content-box px-3 py-3">
                            <span className="text-sky d-block"><i class="fa fa-book" aria-hidden="true"></i> {`${item.lectures_count} lessons`}</span>
                            <Link>
                                <h5 className="mt-3">  {item.title}</h5>
                            </Link>
                            <p className="mt-3">{item.description}</p>
                            <div className="d-flex justify-content-between">
                                <h5 className="text-sky">{`${item.fee} ৳`}</h5>
                                <Link className="text-sky"><i class="fa fa-check-circle" aria-hidden="true"></i> Enroll</Link>
                            </div>
                          </div>
                      </div>
                  </div>
     );
    })}

              </div>
          </div>
      </div> 
        )}
      </>
    );
  }

}




