import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ProfileSidebar from "../../components/ProfileSidebar";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";

import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";

const cookies = new Cookies();

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: [],
      courses: [],
      enrolledCourses: [],
      path: "",
      edit: false,
      loading: false,
      avatarSubmit: false,
      isMobile: false,
      sidebar: true,
      image: null,
      phn_no: "",
      occupation: "",
      address: "",
      password: "",
      password_confirmation: "",
      error: [],
      alert_success: "",
    };
  }
  componentDidMount() {
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    document.title = "Student Dashboard";
    this.setState({
      loading: true,
    });
    const user_url = BASE_URL + "/api/auth/student/me";
    return axios
      .get(user_url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          student: res.data,
          phn_no: res.data.phn_no,
        });
        const url = BASE_URL + "/api/student/myCourses";
        return axios
          .get(url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              courses: res.data[0],
              enrolledCourses: res.data[1],
              loading: false,
            });
          });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload(this.props.history.replace("/"));
        }
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleEdit = () => {
    this.setState({
      edit: true,
      phn_no: this.state.student.phn_no,
      occupation: this.state.student.occupation,
      address: this.state.student.address,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  handleAvatarSubmit = (e) => {
    this.setState({
      avatarSubmit: true,
    });
    e.preventDefault();
    const url = BASE_URL + `/api/student/avatar_info`;
    let fd = new FormData();
    fd.append("photo", this.state.image);
    fd.append("id", this.state.student.id);
    return axios
      .post(url, fd, {
        headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          student: res.data,
          avatarSubmit: false,
          error: [],
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          avatarSubmit: false,
        });
      });
  };

  handleUpdate = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const url =
      BASE_URL + `/api/student/personal_info/${this.state.student.id}`;
    let formData = {
      phn_no: this.state.phn_no,
      occupation: this.state.occupation,
      address: this.state.address,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };
    return axios
      .patch(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          loading: false,
          student: res.data,
          edit: false,
          error: [],
          password: "",
          password_confirmation: "",
          alert_success: "Updated Successfully",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: "" });
          }.bind(this),
          3000
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div
              className={`${
                this.state.isMobile ? "col-md-12  bg-primary" : "leftbar"
              }`}
            >
              {this.state.sidebar ? <ProfileSidebar /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h3 className="mt-3 mb-4">{_t(t("Personal Information"))}</h3>
                <div className="row">
                  <div className="container">
                    {this.state.loading ? (
                      <div className="col-md-12">
                        <h1>
                          <Skeleton count={3} />
                        </h1>
                        <h3>
                          <Skeleton count={5} />
                        </h3>
                        <Skeleton count={8} />
                      </div>
                    ) : (
                      [
                        this.state.edit ? (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="container">
                                <div className="bg-light mx-5">
                                  <form
                                    className="form-group p-3"
                                    onSubmit={this.handleUpdate}
                                  >
                                    <div className="row">
                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="fname"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("First Name"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="text"
                                            id="fname"
                                            name="fname"
                                            placeholder="Type first name"
                                            className="form-control"
                                            value={this.state.student.fname}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="lname"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Last Name"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="text"
                                            id="lname"
                                            name="lname"
                                            placeholder="Type last name"
                                            className="form-control"
                                            value={this.state.lname}
                                            value={this.state.student.lname}
                                            disabled
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="email"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Email"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            value={this.state.student.email}
                                            disabled
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="phn_no"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Phone No."))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="text"
                                            id="phn_no"
                                            name="phn_no"
                                            placeholder="01x xxxx"
                                            className="form-control"
                                            value={this.state.phn_no}
                                            onChange={this.handleChange}
                                            required
                                          />
                                        </div>
                                        {this.state.error ? (
                                          <div className="offset-3 text-danger fs-13 pl-0">
                                            {this.state.error.phn_no ? (
                                              <ul className="list-group list-group-horizontal">
                                                {this.state.error.phn_no.map(
                                                  (item) => {
                                                    return (
                                                      <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                        {"*" + item}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="occupation"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Occupation"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="text"
                                            id="occupation"
                                            name="occupation"
                                            placeholder="Type occupation"
                                            className="form-control"
                                            value={this.state.occupation}
                                            onChange={this.handleChange}
                                            required
                                          />
                                        </div>
                                        {this.state.error ? (
                                          <div className="offset-3 text-danger fs-13 pl-0">
                                            {this.state.error.occupation ? (
                                              <ul className="list-group list-group-horizontal">
                                                {this.state.error.occupation.map(
                                                  (item) => {
                                                    return (
                                                      <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                        {"*" + item}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="address"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Address"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            placeholder="Type address"
                                            className="form-control"
                                            value={this.state.address}
                                            onChange={this.handleChange}
                                            required
                                          />
                                        </div>
                                        {this.state.error ? (
                                          <div className="offset-3 text-danger fs-13 pl-0">
                                            {this.state.error.address ? (
                                              <ul className="list-group list-group-horizontal">
                                                {this.state.error.address.map(
                                                  (item) => {
                                                    return (
                                                      <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                        {"*" + item}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div className="col-md-12 input-group mb-2">
                                        <label
                                          htmlFor="password"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Password"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            className="form-control"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 input-group">
                                        <label
                                          htmlFor="password_confirmation"
                                          className="col-md-3 my-auto"
                                        >
                                          {_t(t("Password Confirmation"))}
                                        </label>
                                        <div className="col-md-9 pl-0">
                                          <input
                                            type="password"
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            placeholder="Re-type password"
                                            className="form-control"
                                            value={
                                              this.state.password_confirmation
                                            }
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                        {this.state.error ? (
                                          <div className="offset-3 text-danger fs-13 pl-0">
                                            {this.state.error.password ? (
                                              <ul className="list-group list-group-horizontal">
                                                {this.state.error.password.map(
                                                  (item) => {
                                                    return (
                                                      <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                        {"*" + item}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div className="text-center">
                                      <button
                                        type="submit"
                                        className="btn btn-sm btn-success ml-1 mb-2 px-5 mt-2"
                                      >
                                        {_t(t("Update"))}
                                      </button>
                                      <button
                                        onClick={() => {
                                          this.setState({
                                            edit: false,
                                            error: [],
                                            password: "",
                                            password_confirmation: "",
                                          });
                                        }}
                                        type="button"
                                        className="btn btn-sm btn-danger ml-2 my-2"
                                      >
                                        {_t(t("Cancel"))}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-3 text-center">
                              <img
                                src={`${BASE_URL}${`/images/student/${this.state.student.image}`}`}
                                alt=""
                                className="img-fluid"
                              />
                              {/* MODAL TRIGGER */}
                              <button
                                className="btn btn-sm btn-primary mt-2 mb-2 px-4"
                                data-toggle="modal"
                                data-target={`#myModalStudent`}
                              >
                                {_t(t("Profile Picture"))}
                              </button>
                              {/* MODAL */}
                              <div className="modal" id={`myModalStudent`}>
                                <div className="modal-dialog">
                                  <div className="modal-content assignment-modal">
                                    <div className="modal-header bg-dark text-white rounded-0">
                                      <h5 className="modal-title">
                                        {_t(t("Change Profile Picture"))}
                                      </h5>
                                      <button
                                        className="close text-white"
                                        data-dismiss="modal"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        {this.state.avatarSubmit ? (
                                          <span className="col-md-12 mb-2">
                                            <span className="text-center text-primary">
                                              {_t(t("Updating.."))}
                                            </span>
                                          </span>
                                        ) : (
                                          <span className="row col-md-12 mx-auto mb-2">
                                            {this.state.error ? (
                                              <div className="text-danger fs-13 pl-0 mb-2">
                                                {this.state.error.photo ? (
                                                  <ul className="list-group list-group-horizontal">
                                                    {this.state.error.photo.map(
                                                      (item) => {
                                                        return (
                                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                            {"*" + item}
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <form
                                              className="mx-5 py-3 border px-2 bg-light"
                                              onSubmit={this.handleAvatarSubmit}
                                            >
                                              {/* -ছবির সাইজ ২MB এর মাঝে এমন কোনো
                                              ছবি আপলোড করুন। */}
                                              -
                                              {_t(
                                                t(
                                                  "Please select a photo less than 2MB in size"
                                                )
                                              )}
                                              <div className="input-group">
                                                <label>Choose Photo</label>
                                                <input
                                                  type="file"
                                                  id="image"
                                                  name="image"
                                                  className="form-control-file mt-2"
                                                  onChange={
                                                    this.handleChangeFile
                                                  }
                                                  required
                                                />
                                              </div>
                                              <div>
                                                <button
                                                  type="submit"
                                                  className="btn btn-success btn-sm mt-2 px-4"
                                                >
                                                  {_t(t("Submit"))}
                                                </button>
                                              </div>
                                            </form>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="modal-footer bg-light">
                                      <div className="row text-right mr-2">
                                        <button
                                          className="btn btn-danger btn-sm fs-13"
                                          data-dismiss="modal"
                                        >
                                          {_t(t("Exit"))}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="container">
                                <div className="bg-light">
                                  {/* Error or Success Message */}
                                  {this.state.alert_success ? (
                                    <div className="mx -4 col-md-12 text-center bg-success text-white p-3 mb-2">
                                      {this.state.alert_success}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/* Error or Success Message */}
                                  <table className="table table-striped table-bordered">
                                    <thead></thead>
                                    <tbody>
                                      <tr>
                                        <td>{_t(t("Name"))}</td>
                                        <td>
                                          {this.state.student.fname +
                                            " " +
                                            this.state.student.lname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{_t(t("Email"))}</td>
                                        <td>{this.state.student.email}</td>
                                      </tr>

                                      {this.state.enrolledCourses.map(
                                        (myCourse, index) => {
                                          if (myCourse.batch !== "") {
                                            let tempCourse =
                                              this.state.courses.find(
                                                (content) =>
                                                  content.id ==
                                                  myCourse.course_id
                                              );
                                            return (
                                              <tr>
                                                <td>
                                                  {_t(t("Batch"))} /{" "}
                                                  {_t(t("Roll"))}
                                                </td>
                                                <td>
                                                  <span className="text-primary">
                                                    {tempCourse.title}
                                                  </span>
                                                  - {_t(t("Batch"))}:{" "}
                                                  {myCourse.batch},
                                                  {_t(t("Roll No."))}:{" "}
                                                  {myCourse.roll}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )}

                                      <tr>
                                        <td>{_t(t("Contact Number"))}</td>
                                        <td>{this.state.phn_no}</td>
                                      </tr>

                                      <tr>
                                        <td>{_t(t("Address"))}</td>
                                        <td>{this.state.student.address}</td>
                                      </tr>
                                      <tr>
                                        <td>{_t(t("Gender"))}</td>
                                        <td>{this.state.student.gender}</td>
                                      </tr>
                                      <tr>
                                        <td>{_t(t("Date of Birth"))}</td>
                                        <td>
                                          <Moment format="LL">
                                            {this.state.student.dateOfBirth}
                                          </Moment>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{_t(t("Occupation"))}</td>
                                        <td>{this.state.student.occupation}</td>
                                      </tr>
                                      <tr>
                                        <td>{_t(t("Admission Date"))}</td>
                                        <td>
                                          <Moment format="LL">
                                            {this.state.student.admissionDate}
                                          </Moment>
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Ip Limitation</td>
                                        <td>{this.state.student.ip_limit}</td>
                                      </tr> */}
                                      <tr>
                                        <td>{_t(t("Course Enrolled"))}</td>
                                        <td>{this.state.courses.length}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="text-center">
                                  <button
                                    onClick={this.handleEdit}
                                    type="button"
                                    className="btn btn-sm btn-primary ml-1 mb-2"
                                  >
                                    {_t(t("Edit Informations"))}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      ]
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(PersonalInfo));
