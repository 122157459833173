//for localhost
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/allthecourse_updated_production/laravel/public";

// for allthecourse.com from localhost
export let BASE_URL = "https://allthecourse.com" + "/api/public";
// export let BASE_URL = "http://apiupdatedcourse.test";

//for live server installation (Laravel React in same place)
// export let BASE_URL =
//   window.location.protocol + "//" + window.location.hostname;

//for allthecourse.com
// export let BASE_URL =
//   window.location.protocol + "//" + window.location.hostname + "/api/public";

// allthecourse.com jwt secret
// JWT_SECRET=sxIPR6QBc00nSkgigX3ETKcGrn1mbbC9zyLKPmchiLtD3DGJRF83PMXFAZgxLAcD

//localhost jwt secret
// JWT_SECRET=rkKMSajYgxxE7CXIBjObRQdHGs8JFZ3nfPew2XnpuiZhDFIbacmmDU9JH4Gs05vJ
