import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
class InstructorSidebar extends Component {
  render() {
    const { path } = this.props;
    return (
      <>
        <div id="accordion" className="pl-1">
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-2"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-1"
                  aria-expanded="false"
                  aria-controls="collapse-1"
                >
                  Dashboard
                </a>
              </h5>
            </div>
            <div
              id="collapse-1"
              className={`collapse + ${path === "/instructor" || path === "/instructor/" ? "show" : ""
                }`}
              data-parent="#accordion"
              aria-labelledby="heading-1"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/instructor"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Dashboard
                </NavLink>
              </div>
            </div>
          </div>

          {/* profile */}

          <div id="accordion" className="pl-1">
            <div className="card admin-sidebar border-right-0">
              <div
                className="card-header admin-card-header"
                style={{ borderRadius: 0 }}
                id="heading-2"
              >
                <h5 className="mb-0">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-profile"
                    aria-expanded="false"
                    aria-controls="collapse-profile"
                  >
                    Profile
                  </a>
                </h5>
              </div>
              <div
                id="collapse-profile"
                className={`collapse + ${path === "/instructor/instructor-profile" || path === "/instructor/instructor-profile/" ? "show" : ""
                  }`}
                data-parent="#accordion"
                aria-labelledby="heading-1"
              >
                <div className="card-body">
                  <NavLink
                    exact
                    to="/instructor/instructor-profile"
                    activeClassName=""
                    className="nav-link py-0"
                  >
                    Profile
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          {/* profile */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-assignments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-assignments"
                  aria-expanded="false"
                  aria-controls="collapse-assignments"
                >
                  Assignments
                </a>
              </h5>
            </div>
            <div
              id="collapse-assignments"
              className={`collapse + ${path === "/instructor/check-assignments" ||
                path === "/instructor/check-assignments/" ||
                path === `/instructor/check-assignments/${this.props.id}` ||
                path === `/instructor/check-assignments/${this.props.id}/` ||
                path === "/instructor/manage-assignments" ||
                path === "/instructor/manage-assignments/" ||
                path === `/instructor/view-assignments/${this.props.id}` ||
                path === `/instructor/view-assignments/${this.props.id}/` ||
                path === "/instructor/manage-feedback" ||
                path === "/instructor/manage-feedback/" ||
                path === `/instructor/check-feedback/${this.props.id}` ||
                path === `/instructor/check-feedback/${this.props.id}/`
                ? "show"
                : ""
                }`}
              data-parent="#accordion"
              aria-labelledby="heading-assignments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/instructor/manage-feedback"
                  className={`nav-link py-0 + ${path === "/instructor/manage-feedback" ||
                    path === "/instructor/manage-feedback/" ||
                    path === `/instructor/check-feedback/${this.props.id}` ||
                    path === `/instructor/check-feedback/${this.props.id}/`
                    ? "sidebar-active"
                    : ""
                    }`}
                >
                  Feedback
                </NavLink>

                <NavLink
                  exact
                  to="/instructor/check-assignments"
                  className={`nav-link py-0 + ${path === "/instructor/check-assignments" ||
                    path === "/instructor/check-assignments/" ||
                    path === `/instructor/check-assignments/${this.props.id}` ||
                    path === `/instructor/check-assignments/${this.props.id}/`
                    ? "sidebar-active"
                    : ""
                    }`}
                >
                  New Assignments
                </NavLink>

                <NavLink
                  exact
                  to="/instructor/manage-assignments"
                  className={`nav-link py-0 + ${path === "/instructor/manage-assignments" ||
                    path === "/instructor/manage-assignments/" ||
                    path === `/instructor/view-assignments/${this.props.id}` ||
                    path === `/instructor/view-assignments/${this.props.id}/`
                    ? "sidebar-active"
                    : ""
                    }`}
                >
                  Checked Assignments
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-comments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-comments"
                  aria-expanded="false"
                  aria-controls="collapse-comments"
                >
                  Discussion
                </a>
              </h5>
            </div>
            <div
              id="collapse-comments"
              className={`collapse + ${path === "/instructor/check-comments" ||
                path === "/instructor/check-comments/" ||
                path === `/instructor/check-comments/${this.props.id}` ||
                path === `/instructor/check-comments/${this.props.id}/` ||
                path === "/instructor/check-comments/courses" ||
                path === "/instructor/check-comments/courses/" ||
                path ===
                `/instructor/check-comments/courses/${this.props.id}` ||
                path ===
                `/instructor/check-comments/courses/${this.props.id}/` ||
                path === `/instructor/check-comments/class/${this.props.id}` ||
                path === `/instructor/check-comments/class/${this.props.id}/`
                ? "show"
                : ""
                }`}
              data-parent="#accordion"
              aria-labelledby="heading-comments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/instructor/check-comments"
                  className={`nav-link py-0 + ${path === "/instructor/check-comments" ||
                    path === "/instructor/check-comments/" ||
                    path === `/instructor/check-comments/${this.props.id}` ||
                    path === `/instructor/check-comments/${this.props.id}/`
                    ? "sidebar-active"
                    : ""
                    }`}
                >
                  Check Comments
                </NavLink>

                <NavLink
                  exact
                  to="/instructor/check-comments/courses"
                  className={`nav-link py-0
                    ${path === "/instructor/check-comments/courses" ||
                      path === "/instructor/check-comments/courses/" ||
                      path ===
                      `/instructor/check-comments/courses/${this.props.id}` ||
                      path ===
                      `/instructor/check-comments/courses/${this.props.id}/` ||
                      path ===
                      `/instructor/check-comments/class/${this.props.id}` ||
                      path ===
                      `/instructor/check-comments/class/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                    }
                  `}
                >
                  All Discussion
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(InstructorSidebar);
