
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import axios from "axios";
import posImg from '../../images/allCourses/traingle.jpg'

const cookies = new Cookies();

class StudentNotice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      enrollments: [],
      loading: false,
      alert_danger: "",
      search: false,
      search_query: "",
      timeout: 0,
      searchedCourses: [],
      notices: [],
      noticeAll: [],
      isEnable: null,
      noticeTime: null,
      checkNotice: false,
      noticeCounter: null
    };
  }
  componentDidMount() {
    // var noticeTime = "2021-09-30 12:23:58";
    window.scrollTo(0, 0);
    document.title = "Student Notice";
    this.setState({
      loading: true,
      noticeTime: new Date("2021-09-30 12:23:58").toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
    });
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_danger;
      this.setState({
        alert_danger: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_danger: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }

    const allNoticeUrl = BASE_URL + `/api/student/check-notice`;
    return axios
      .get(allNoticeUrl, {
        headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          checkNotice: res.data,
          loading: false,
          noticeCounter: this.state.checkNotice.length
        });


        const url = BASE_URL + "/api/student/student-notice";
        return axios
          .get(url, {
            headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              noticeAll: res.data,
              loading: false,
            });
            // alert(this.state.noticeCounter);
          });

      });

  }

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return (
        <>
          <Hero hero="NoticeHero d-flex align-items-center">
            <Banner
              title="Notices"
              bannerCSS="col-6 mx-auto text-center course-banner"
            >
            </Banner>
          </Hero>
          <div className="container mt-2">
            <div className="row">
              <div className="col-12">
                <Skeleton className="course-card" />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/* Error or Success Message */}
          {this.state.alert_danger ? (
            <div className="col-md-12 text-center bg-danger text-white p-3">
              {this.state.alert_danger}
            </div>
          ) : (
            ""
          )}
          {/* Error or Success Message */}
          <Hero hero="noticeHero  d-flex align-items-center">
            <Banner
              title="Notices"
              bannerCSS="col-6 mx-auto text-center course-banner"
            >
            </Banner>
          </Hero>
          <div className="container mt-2">
            <div className="row my-5 justify-content-center">

              {
                //check if notice exiest or not 
                this.state.checkNotice.length > 0 ?
                  this.state.noticeAll.map((item) => {
                    return item.map((subitem) => {
                      // check if post is enable or not
                      if (subitem.isEnable == 1) {
                        return <div className="col-lg-8 mt-5 single-notice-box2 card">
                          <img className="posImg" src={posImg} alt="" />
                          <div className="row align-items-center">
                            <div className="col-lg-8">
                              <h5 className="mb-3 notice-title text-dark"><i class="fa fa-bell mr-3 text-warning" aria-hidden="true"></i>{subitem.course_title}</h5>
                              <p className="mb-3 text-capitalize">{subitem.notice}</p>
                              <span className="badge badge-light">
                                {new Date(subitem.created_at).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                              </span>
                            </div>
                          </div>
                        </div>
                      } else {
                        return <>
                          <div className="col-lg-8 mt-5 single-notice-box2 card">
                            <img className="posImg" src={posImg} alt="" />
                            <div className="row align-items-center">
                              <div className="col-lg-8 text-center">
                                <h2>No Notice found</h2>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    })
                  })
                  : <div className="col-lg-8 mt-5 single-notice-box2 card">
                    <img className="posImg" src={posImg} alt="" />
                    <div className="row align-items-center">
                      <div className="col-lg-8 text-center">
                        <h2>No Notice found</h2>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </>
      );
    }
  }

}
export default (withRouter(StudentNotice));
