import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
import Cookies from "universal-cookie";
// Components
import Sidebar from "../../Sidebar";
const cookies = new Cookies();
class Promote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_title: "",
      image1: '',
      image2: '',
      image3: '',
      promo_des: '',
      promo_title1: '',
      promo_title2: '',
      promo_title3: '',
      loading: false,
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
      // imageTest:''
    };
  }
  componentDidMount() {

    document.title = "Promote";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: false,
      path: this.props.history.location.pathname,
    });

    const PromoteUrl = BASE_URL + `/api/get_promote_data`;
    return axios
      .get(PromoteUrl, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      }).then((res) => {
        this.setState({
          main_title: res.data[0].main_title,
          image1: null,
          image2: null,
          image3: null,
          // image1: res.data[0].image1,
          // image2: res.data[0].image2,
          // image3: res.data[0].image3,
          promo_des: res.data[0].promo_des,
          promo_title1: res.data[0].promo_title1,
          promo_title2: res.data[0].promo_title2,
          promo_title3: res.data[0].promo_title3,
          error: [],
          loading: false,
        });

        console.log(res.data);

      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });

  };


  // image 1
  handleChangeFile1 = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage1(files[0]);
  };

  createImage1 = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image1: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  // image 2
  handleChangeFile2 = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage2(files[0]);
  };

  createImage2 = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image2: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  // image 3
  handleChangeFile3 = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage3(files[0]);
  };

  createImage3 = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image3: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  };


  handleChangeImage1 = (e) => {
    this.setState({
      image1: e.target.files[0],
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/admin/add_promote`;

    const formData = {
      main_title: this.state.main_title,
      promo_title1: this.state.promo_title1,
      promo_title2: this.state.promo_title2,
      promo_title3: this.state.promo_title3,
      promo_des: this.state.promo_des,
      image1: this.state.image1,
      image2: this.state.image2,
      image3: this.state.image3,
      // imageTest : this.state.imageTest
    };


    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          error: [],
          loading: false,
        });

        console.log(res);

      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update Promote
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Promote Description
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <form
                          className="form-group"
                          onSubmit={this.handleSubmit}
                        >

                          <div className="col-md-12 input-group mb-2">
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4">Promo Title : </label>
                              <textarea
                                id="main_title"
                                name="main_title"
                                placeholder="Promote title"
                                className="form-control w-75 ml-auto"
                                value={this.state.main_title}
                                onChange={this.handleChange}
                                required
                              ></textarea>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4"> Title 1 : </label>
                              <input
                                id="promo_title1"
                                name="promo_title1"
                                placeholder="Title 1"
                                className="form-control w-75 ml-auto"
                                value={this.state.promo_title1}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4"> Title 2 : </label>
                              <input
                                id="promo_title2"
                                name="promo_title2"
                                placeholder="Title 2"
                                className="form-control w-75 ml-auto"
                                value={this.state.promo_title2}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4"> Description : </label>
                              <textarea
                                id="promo_des"
                                name="promo_des"
                                placeholder="Description"
                                className="form-control w-75 ml-auto"
                                value={this.state.promo_des}
                                onChange={this.handleChange}
                                required
                              ></textarea>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4"> Title 3 : </label>
                              <input
                                id="promo_title3"
                                name="promo_title3"
                                placeholder="Title 3"
                                className="form-control w-75 ml-auto"
                                value={this.state.promo_title3}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            {/* <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex align-items-center">
                              <label className="mr-4"> Test img : </label>
                              <input
                                  type="file"
                                  id="image"
                                  className="form-control-file pl-0 col-md-9"
                                  name="imageTest"
                                  onChange={this.handleChangeFile}
                                  required
                                />
                            </div> */}

                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex flex-wrap align-items-center">
                              <div className="w-100 d-flex justify-content-center mb-4">
                                <img
                                  src={`${BASE_URL}${`/images/promotion/${this.state.image1}`}`}
                                  className="img-fluid"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              </div>
                              <label className="mr-4"> Image 1 : </label>
                              <input
                                type="file"
                                className="form-control-file form-control w-75 ml-auto"
                                name="image1"
                                onChange={this.handleChangeFile1}

                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex flex-wrap align-items-center">
                              <div className="w-100 d-flex justify-content-center mb-4">
                                <img
                                  src={`${BASE_URL}${`/images/promotion/${this.state.image2}`}`}
                                  className="img-fluid"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              </div>
                              <label className="mr-4"> Image 2 : </label>
                              {/* <input type="text" value={this.state.image2} id="oldImg2" /> */}
                              <input
                                type="file"
                                id="image2"
                                className="form-control-file form-control w-75 ml-auto"
                                name="image2"
                                onChange={this.handleChangeFile2}

                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3 d-flex flex-wrap align-items-center">
                              <div className="w-100 d-flex justify-content-center mb-4">
                                <img
                                  src={`${BASE_URL}${`/images/promotion/${this.state.image3}`}`}
                                  className="img-fluid"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              </div>
                              <label className="mr-4"> Image 3 : </label>
                              <input
                                type="file"
                                id="image3"
                                className="form-control-file form-control w-75 ml-auto"
                                name="image3"
                                onChange={this.handleChangeFile3}

                              />
                            </div>


                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.error.desc ? (
                                  <ul className="list-group list-group-horizontal">
                                    {this.state.error.desc.map((item) => {
                                      return (
                                        <li className="list-group-item border-0 bg-light py-0 pl-0">
                                          {"*" + item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-2 px-4"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Promote);
