import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";

import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import axios from "axios";

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";

import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";

const cookies = new Cookies();

class AssignmentClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_title: "",
      class: [],
      feedback: [],
      assignment: [],
      students: [],
      admins: [],
      instructors: [],
      moderators: [],
      comment: "",
      user: [],
      submitAssignment: null,
      alert_success: null,
      loadAssignment: false,
      loading: false,
      commentLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Assignment";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/student/get_feedback";
    let formData = {
      class_id: this.props.match.params.classId,
      course_id: this.props.match.params.id,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          feedback: res.data[0],
          admins: res.data[1],
          instructors: res.data[2],
          moderators: res.data[3],
          class: res.data[4],
          user: res.data[5],
          enrollment_id: res.data[6].id,
          assignment: res.data[7],
          loading: false,
        });
        if (res.data[6].is_active == 0) {
          this.props.history.replace({
            pathname: `/assignment`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        } else {
          if (res.data[7] == null) {
            this.props.history.replace({
              pathname: `/assignment`,
              state: {
                alert_danger: "Can not perform the action. Contact Admin!",
              },
            });
          }
        }
        const seen_url =
          BASE_URL +
          "/api/student/seen_feedback/" +
          this.props.match.params.classId +
          "/" +
          res.data[7].id; //res.data[7].id = assignment_id
        return axios.get(seen_url, {
          headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        } else {
          this.props.history.replace({
            pathname: `/assignment`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      commentLoading: true,
    });
    let formData = {
      assignment_id: this.state.assignment.id,
      add_class_id: this.props.match.params.classId,
      enrollment_id: this.state.assignment.enrollment_id,
      comment: this.state.comment,
    };
    const url = BASE_URL + "/api/student/feedback";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          feedback: res.data,
          comment: "",
          commentLoading: false,
        });
      });
  };

  commentDelete = (id) => {
    const delete_url = BASE_URL + "/api/student/delete_feedback/" + id;
    const formData = {
      assignment_id: this.state.assignment.id,
    };
    return axios
      .post(delete_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          feedback: res.data,
        });
      });
  };

  handleAssignment = (e) => {
    this.setState({
      submitAssignment: e.target.files[0],
    });
  };

  handleAssignmentSubmit = (e, id) => {
    e.preventDefault();
    this.setState({
      loadAssignment: true,
    });
    const assignment_url = BASE_URL + `/api/student/assignment`;
    let formData = new FormData();
    formData.append("submitAssignment", this.state.submitAssignment);
    formData.append("enrollment_id", this.state.enrollment_id);
    formData.append("id", id);
    return axios
      .post(assignment_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then(() => {
        this.setState({
          alert_success: "Assignment submitted successfully",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
        // get assignments here
        const student_assignment_url =
          BASE_URL +
          `/api/student/assignment/` +
          this.props.match.params.classId;
        return axios
          .get(student_assignment_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              assignment: res.data,
              loadAssignment: false,
            });
          });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.errors,
          loadAssignment: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          5000
        );
        this.props.history.replace();
      });
  };

  // Jodit Editor plugin
  updateContent = (value) => {
    if (value !== null && value !== "") {
      this.setState({
        commentBtn: true,
        comment: value,
      });
    } else {
      this.setState({
        commentBtn: false,
      });
    }
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };
  // Jodit Editor plugin

  render() {
    const { t } = this.props;
    return (
      <>
        <Hero hero="courseAssignmentHero  d-flex align-items-center">
          <Banner
            title={
              <div
                title={
                  "Assignment of" +
                  (this.state.class ? this.state.class.title : "")
                }
              >
                <h5 className="text-truncate">Assignment of</h5>
                {this.state.loading ? (
                  <Skeleton className="bg-primary" width="50%" />
                ) : (
                  <h4 className="text-truncate">
                    {this.state.class ? this.state.class.title : ""}
                  </h4>
                )}
              </div>
            }
            bannerCSS="col-6 mx-auto text-center assignmentCourse-banner"
          >
            <Link to={`/assignment/${this.props.match.params.id}`}>
              <button type="button" className="btn btn-sm btn-primary px-5">
                {_t(t("Go Back"))}
              </button>
            </Link>
          </Banner>
        </Hero>
        {this.state.loading ? (
          <div className="mx-5">
            <div>
              <h1>
                <Skeleton />
              </h1>
              <h3>
                <Skeleton count={5} />
              </h3>
              <Skeleton count={5} />
            </div>
          </div>
        ) : (
          <div className="container mt-3 mb-2">
            <div className="row mb-2">
              <h2 className="course-heading text-uppercase mb-2">
                {_t(t("Class Title"))}:{" "}
                {this.state.class ? this.state.class.title : ""}
              </h2>
            </div>
            {this.state.loadAssignment ? (
              <div className="row">
                <div className="col-md-6 offset-md-3 d-flex justify-content-around">
                  <div className="card card-body text-center alert alert-primary">
                    {_t(t("Updating.."))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6 offset-md-3 d-flex justify-content-around">
                    {this.state.assignment
                      ? [
                          this.state.assignment.score ? (
                            <h3 className="bg-warning col-md-12 rounded-lg text-center py-2">
                              {_t(t("Score"))}: {this.state.assignment.score} /
                              10
                            </h3>
                          ) : (
                            <h3 className="bg-danger col-md-12 rounded-lg text-center text-white py-2">
                              {_t(t("Score is not given yet!"))}
                            </h3>
                          ),
                        ]
                      : ""}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 offset-md-3 d-flex justify-content-around  mb-2">
                    {this.state.assignment ? (
                      <a
                        tearget="_blank"
                        href={
                          BASE_URL +
                          "/assignments/" +
                          this.state.assignment.assignment
                        }
                        className="btn btn-sm col-md-12  btn-success p-3"
                      >
                        {_t(t("Download Submitted Assignment"))}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* Error or Success Message */}
                  {this.state.alert_success ? (
                    <div className="text-center col-md-6 offset-md-3 alert alert-success py-2 mb-2">
                      {this.state.alert_success}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div className="text-center col-md-6 offset-md-3 alert alert-danger py-2 mb-2">
                      {this.state.error.submitAssignment ? (
                        <div>{this.state.error.submitAssignment}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Error or Success Message */}
                </div>

                {/* <div className="row mb-2">
                  <div className="col-md-6 offset-md-3 d-flex justify-content-around">
                    <div className="row">
                      <span className="row col-md-12 mx-auto mb-2">
                        <form
                          className="mx-5 py-3 border px-2 bg-light"
                          onSubmit={(e) => {
                            this.handleAssignmentSubmit(
                              e,
                              this.props.match.params.classId
                            );
                          }}
                        >
                          <div className="input-group">
                            <label>
                              Re-submit Assignment
                              <span className="fs-13 bg-warning rounded-sm px-1 ml-1">
                                {" (Upload Zip / Image File)"}
                              </span>
                            </label>
                            <input
                              type="file"
                              id="submitAssignment"
                              className="form-control-file mt-2"
                              onChange={this.handleAssignment}
                              required
                            />
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-2 px-4"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </span>
                    </div>
                  </div>
                </div> */}
              </>
            )}

            <div className="row mb-2">
              <div className="col-md-12">
                <h3 className="course-heading text-uppercase">
                  {_t(t("Suggestions"))}
                </h3>
                <>
                  <div className="container mt-3 mb-2 text-justify col-md-12">
                    <div className="row mb-2">
                      {this.state.feedback.length > 0 ? (
                        <div className="col-md-12">
                          {this.state.feedback.map((item) => {
                            return (
                              <div className="d-flex align-items-center mb-2">
                                <div className="avatar">
                                  {item.student_id ? (
                                    <img
                                      src={`${BASE_URL}${`/images/student/${this.state.user.image}`}`}
                                      alt="dp"
                                      className="rounded-circle"
                                      height="80"
                                      width="80"
                                    />
                                  ) : (
                                    [
                                      item.admin_id
                                        ? [
                                            this.state.admins.map((admin) => {
                                              if (admin.id == item.admin_id) {
                                                return (
                                                  <img
                                                    src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                    alt="dp"
                                                    className="rounded-circle"
                                                    height="80"
                                                    width="80"
                                                  />
                                                );
                                              }
                                            }),
                                          ]
                                        : [
                                            item.instructor_id
                                              ? [
                                                  this.state.instructors.map(
                                                    (instructor) => {
                                                      if (
                                                        instructor.id ==
                                                        item.instructor_id
                                                      ) {
                                                        return (
                                                          <img
                                                            src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                            alt="dp"
                                                            className="rounded-circle"
                                                            height="80"
                                                            width="80"
                                                          />
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ]
                                              : [
                                                  this.state.moderators.map(
                                                    (moderator) => {
                                                      if (
                                                        moderator.id ==
                                                        item.moderator_id
                                                      ) {
                                                        return (
                                                          <img
                                                            src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                            alt="dp"
                                                            className="rounded-circle"
                                                            height="80"
                                                            width="80"
                                                          />
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ],
                                          ],
                                    ]
                                  )}
                                </div>

                                <div className="flex-grow-1 pl-4">
                                  {item.student_id ? (
                                    <>
                                      <b>
                                        {this.state.user.fname +
                                          " " +
                                          this.state.user.lname}
                                      </b>{" "}
                                      <span>
                                        (
                                        <Moment format="LLL">
                                          {item.created_at}
                                        </Moment>
                                        )
                                      </span>
                                      <i
                                        className="fa fa-times ml-2 delete-comment"
                                        onClick={() =>
                                          this.commentDelete(item.id)
                                        }
                                        title="Delete Comment"
                                      ></i>
                                    </>
                                  ) : (
                                    [
                                      item.admin_id
                                        ? [
                                            this.state.admins.map((admin) => {
                                              if (admin.id == item.admin_id) {
                                                return (
                                                  <>
                                                    <b>{admin.name}</b> (
                                                    <span className="text-primary">
                                                      {_t(t("Admin"))}
                                                    </span>
                                                    ){" "}
                                                    <span>
                                                      (
                                                      <Moment format="LLL">
                                                        {item.created_at}
                                                      </Moment>
                                                      )
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }),
                                          ]
                                        : [
                                            item.instructor_id
                                              ? [
                                                  this.state.instructors.map(
                                                    (instructor) => {
                                                      if (
                                                        instructor.id ==
                                                        item.instructor_id
                                                      ) {
                                                        return (
                                                          <>
                                                            <b>
                                                              {instructor.fname +
                                                                " " +
                                                                instructor.lname}
                                                            </b>{" "}
                                                            (
                                                            <span className="text-primary">
                                                              {_t(
                                                                t("Instructor")
                                                              )}
                                                            </span>
                                                            ){" "}
                                                            <span>
                                                              (
                                                              <Moment format="LLL">
                                                                {
                                                                  item.created_at
                                                                }
                                                              </Moment>
                                                              )
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ]
                                              : [
                                                  this.state.moderators.map(
                                                    (moderator) => {
                                                      if (
                                                        moderator.id ==
                                                        item.moderator_id
                                                      ) {
                                                        return (
                                                          <>
                                                            <b>
                                                              {moderator.fname +
                                                                " " +
                                                                moderator.lname}
                                                            </b>{" "}
                                                            (
                                                            <span className="text-primary">
                                                              {_t(
                                                                t("Moderator")
                                                              )}
                                                            </span>
                                                            ){" "}
                                                            <span>
                                                              (
                                                              <Moment format="LLL">
                                                                {
                                                                  item.created_at
                                                                }
                                                              </Moment>
                                                              )
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  ),
                                                ],
                                          ],
                                    ]
                                  )}
                                  <br />
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.remark,
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div
                          className="col-md-6 offset-3 card card-body bg-light"
                          style={{ minHeight: "120px" }}
                        >
                          <div className="text-center my-auto">
                            <div className="text-center">
                              <h3 className="bg-light text-danger px-5 mt-2">
                                {_t(t("No comments yet!"))}
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row mb-2">
                      <h3 className="col-md-10 offset-md-1 course-heading text-uppercase mb-2">
                        {_t(t("Add your comment"))}
                      </h3>
                    </div>
                    <form className="row" onSubmit={this.handleSubmit}>
                      <div className="col-md-10 offset-md-1">
                        <JoditEditor
                          editorRef={this.setRef}
                          value={this.state.comment}
                          config={this.config}
                          onChange={this.updateContent}
                        />
                      </div>
                      {this.state.commentLoading ? (
                        <button
                          className="btn btn-success btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                          disabled
                        >
                          {_t(t("Please wait"))}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                          disabled={!this.state.commentBtn}
                        >
                          {_t(t("Add comment"))}
                        </button>
                      )}
                    </form>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(AssignmentClass));
