import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import Sidebar from "../ModeratorSidebar";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";

import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const $ = require("jquery");
$.DataTable = require("datatables.net");

class ModeratorAllAssignment extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      assignments: [],
      students: [],
      courses: [],
      classes: [],
      alert_success: null,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Manage Assignments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const assignments_url = BASE_URL + "/api/moderator/checked_assignment";
    return axios
      .get(assignments_url, {
        headers: { Authorization: `Bearer ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        res.data.forEach((element) => {
          element.moderator_enrollment_checked_assignment.forEach(
            (enrollment) => {
              enrollment.checked_assignment.forEach((assignment) => {
                this.setState({
                  assignments: [...this.state.assignments, assignment],
                });
              });
            }
          );
        });
        let tempAssignments = [];
        const myData = []
          .concat(this.state.assignments)
          .sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1))
          .map((item, i) => {
            tempAssignments = [...tempAssignments, item];
          });
        this.setState({
          assignments: tempAssignments,
        });

        const students_url =
          BASE_URL + "/api/moderator/student/student_p170815s";
        return axios
          .get(students_url, {
            headers: {
              Authorization: `Bearer ${cookies.get("moderatorToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              students: res.data,
            });
            const classes_url = BASE_URL + "/api/moderator/class/p20081994s";
            return axios
              .get(classes_url, {
                headers: {
                  Authorization: `Bearer ${cookies.get("moderatorToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  classes: res.data,
                });
                const url = BASE_URL + "/api/moderator/course/p170815s";
                return axios
                  .get(url, {
                    headers: {
                      Authorization: `Bearer ${cookies.get("moderatorToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      courses: res.data,
                      loading: false,
                    });
                    $(document).ready(function () {
                      $("#myTableAll").DataTable({
                        ordering: false,
                      });
                    });
                  });
              });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // handleView = (e) => {
  //   this.props.history.push(`/moderator/view-assignments/${e}`);
  // };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h4 className="mt-2 mb-4">
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Manage Assignments
                </h4>
                <div className="container">
                  <div className="row">
                    {/* Error or Success Message */}
                    {this.state.alert_success ? (
                      <div className="text-center col-md-10 offset-md-1 bg-success text-white p-3 mb-2">
                        {this.state.alert_success}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Error or Success Message */}
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-success text-center text-white text-uppercase mb-3">
                        Checked Assignments
                      </div>
                      {this.state.loading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      ) : (
                        <div className="table-responsive">
                          <table
                            class="table table-striped text-dark table-hover"
                            id="myTableAll"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Avatar</th>
                                <th scope="col">Name</th>
                                <th scope="col">Contact</th>
                                <th scope="col">Course</th>
                                <th scope="col">Class</th>
                                <th scope="col">Submit</th>
                                <th scope="col">Checked</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.assignments.map(
                                (assignment, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        {this.state.students.map((student) => {
                                          if (
                                            assignment.student_id == student.id
                                          ) {
                                            return (
                                              <img
                                                src={`${BASE_URL}${`/images/student/${student.image}`}`}
                                                className="avatar-sm"
                                                alt="avatar"
                                              />
                                            );
                                          }
                                        })}
                                      </td>
                                      <td>
                                        {this.state.students.map((student) => {
                                          if (
                                            assignment.student_id == student.id
                                          ) {
                                            return (
                                              <span>
                                                {student.fname} {student.lname}
                                              </span>
                                            );
                                          }
                                        })}
                                      </td>

                                      <td>
                                        {this.state.students.map((student) => {
                                          if (
                                            assignment.student_id == student.id
                                          ) {
                                            return (
                                              <span>{student.phn_no}</span>
                                            );
                                          }
                                        })}
                                      </td>
                                      <td>
                                        {this.state.classes.map((tempClass) => {
                                          if (
                                            assignment.add_class_id ==
                                            tempClass.id
                                          ) {
                                            let course_title = "";
                                            {
                                              this.state.courses.map(
                                                (course) => {
                                                  if (
                                                    course.id ==
                                                    tempClass.course_id
                                                  ) {
                                                    course_title = course.title;
                                                  }
                                                }
                                              );
                                            }
                                            return <span>{course_title}</span>;
                                          }
                                        })}
                                      </td>

                                      <td>
                                        <div className="row mx-2">
                                          {this.state.classes.map(
                                            (tempClass) => {
                                              if (
                                                assignment.add_class_id ==
                                                tempClass.id
                                              ) {
                                                return (
                                                  <span>{tempClass.title}</span>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </td>
                                      <td scope="row">
                                        <Moment format="LLL">
                                          {assignment.created_at}
                                        </Moment>
                                      </td>
                                      <td scope="row">
                                        <Moment format="LLL">
                                          {assignment.updated_at}
                                        </Moment>
                                      </td>
                                      <td>
                                        <>
                                          <div className="row mx-2 justify-content-center">
                                            <NavLink
                                              to={`/moderator/view-assignments/${assignment.id}`}
                                              className="btn btn-sm btn-primary col"
                                              style={{ borderRadius: "0" }}
                                            >
                                              <i className="fa fa-eye"></i>
                                            </NavLink>
                                          </div>
                                        </>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ModeratorAllAssignment);
