import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ViewEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollment: [],
      course: [],
      student: [],
      notes: '',

      allow_class_from: null,
      allow_class_to: null,

      paid: null,
      due: null,
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
      alert_success: "",
    };
  }
  componentDidMount() {
    document.title = "Enrollment's Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        5000
      );
      this.props.history.replace();
    }
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const url = BASE_URL + `/api/admin/enrollment/enrollment_p170815s/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        let n2 = 0;
        let n3 = 0;
        let n4 = 0;
        let n5 = 0;
        if (res.data.second_payment !== null) {
          n2 = res.data.second_payment;
        }
        if (res.data.third_payment !== null) {
          n3 = res.data.third_payment;
        }
        if (res.data.fourth_payment !== null) {
          n4 = res.data.fourth_payment;
        }
        if (res.data.fifth_payment !== null) {
          n5 = res.data.fifth_payment;
        }
        this.setState({
          enrollment: res.data,
          notes: res.data.note,

          allow_class_from: res.data.class_access
            ? JSON.parse(res.data.class_access)[0][0]
            : "",
          allow_class_to: res.data.class_access
            ? JSON.parse(res.data.class_access)[0][1]
            : "",

          paid:
            parseInt(res.data.first_payment) +
            parseInt(n2) +
            parseInt(n3) +
            parseInt(n4) +
            parseInt(n5),
          due:
            parseInt(res.data.payable) -
            (parseInt(res.data.first_payment) +
              parseInt(n2) +
              parseInt(n3) +
              parseInt(n4) +
              parseInt(n5)),
        });
        const course_url =
          BASE_URL + `/api/admin/course/p170815s/${res.data.course_id}`;
        return axios
          .get(course_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              course: res.data,
            });
            const student_url =
              BASE_URL +
              `/api/admin/student/student_p170815s/${this.state.enrollment.student_id}`;
            return axios
              .get(student_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  student: res.data,
                  loading: false,
                });
              });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleActiveOrBlock = () => {
    const edit_url =
      BASE_URL +
      `/api/admin/enrollment/active_enrollment_p170815s/${this.props.match.params.id}`;
    return axios
      .patch(edit_url, null, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          enrollment: res.data,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Enrollment's Information
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-md-12">
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.alert_success ? (
                        <div className="text-center bg-success text-white p-3 mb-2">
                          {this.state.alert_success}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="row pl-1 mr-1 ml-3">
                              <Link
                                to={`/admin/manage-enrollment`}
                                className="nav-link text-light bg-secondary mb-3 rounded-sm py-2"
                              >
                                <i className="fa fa-reply-all"></i> Manage
                                Enrollment
                              </Link>
                            </div>
                          </div>
                          <div className="row col-md-8">
                            {this.state.enrollment.is_active === 1 ? (
                              <button
                                onClick={this.handleActiveOrBlock}
                                className="nav-link btn btn-danger mb-3 rounded-sm ml-auto"
                              >
                                Block Access
                              </button>
                            ) : (
                              <button
                                onClick={this.handleActiveOrBlock}
                                className="nav-link btn btn-success mb-3 rounded-sm ml-auto"
                              >
                                Give Access
                              </button>
                            )}
                            <a
                              href={this.state.student.fb}
                              className="nav-link text-white bg-primary mb-3 rounded-sm ml-1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-facebook"></i> Facebook
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="container">
                              <div className="bg-light">
                                <div className="card-header bg-primary text-white text-center text-uppercase mb-3">
                                  Enrollment Details
                                </div>
                                <table className="table table-striped table-hover table-bordered text-center table-sm">
                                  <thead></thead>
                                  <tbody>
                                    <tr className="bg-dark text-white">
                                      <td>Enrolled On</td>
                                      <td>
                                        {this.state.enrollment.first_paid_date}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Course Title</td>
                                      <td>{this.state.course.title}</td>
                                    </tr>

                                    <tr>
                                      <td>Enrolled By</td>
                                      <td>
                                        {this.state.student.fname +
                                          " " +
                                          this.state.student.lname}
                                      </td>
                                    </tr>

                                    {this.state.enrollment.batch ? (
                                      <tr>
                                        <td>Batch</td>
                                        <td>{this.state.enrollment.batch}</td>
                                      </tr>
                                    ) : null}

                                    {this.state.enrollment.roll ? (
                                      <tr>
                                        <td>Roll No</td>
                                        <td>{this.state.enrollment.roll}</td>
                                      </tr>
                                    ) : null}

                                    <tr>
                                      <td>Student Type</td>
                                      <td>{this.state.student.student_type}</td>
                                    </tr>

                                    <tr className="bg-info text-white">
                                      <td>Permitted Classes</td>
                                      <td>
                                        {this.state.allow_class_from +
                                          " - " +
                                          this.state.allow_class_to}
                                      </td>
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Course Fee</td>
                                      <td>{this.state.course.fee} BDT</td>
                                    </tr>
                                    <tr className="bg-success text-white">
                                      <td>Discount</td>
                                      <td>
                                        {this.state.enrollment.discount
                                          ? this.state.enrollment.discount
                                          : "0"}{" "}
                                        BDT
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Total Payable</td>
                                      <td>
                                        {this.state.enrollment.payable} BDT
                                      </td>
                                    </tr>
                                    <tr className="bg-success text-white">
                                      <td>Total Paid</td>
                                      <td>{this.state.paid} BDT</td>
                                    </tr>
                                    <tr className="bg-danger text-white">
                                      <td>Total Due</td>
                                      <td>{this.state.due} BDT</td>
                                    </tr>
                                    <tr>
                                      <td>First Payment</td>
                                      <td>
                                        {this.state.enrollment.first_payment}{" "}
                                        BDT
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>First Paid Date</td>
                                      <td>
                                        {this.state.enrollment.first_paid_date}
                                      </td>
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>2nd Payable</td>
                                      {this.state.enrollment.second_payable ? (
                                        <td>
                                          {this.state.enrollment.second_payable}{" "}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Second Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .second_payment_date
                                          ? [
                                            this.state.enrollment
                                              .second_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Second Payment</td>
                                      <td>
                                        {this.state.enrollment.second_payment
                                          ? this.state.enrollment
                                            .second_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Second Paid Date</td>
                                      <td>
                                        {this.state.enrollment.second_paid_date
                                          ? [
                                            this.state.enrollment
                                              .second_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>3rd Payable</td>
                                      {this.state.enrollment.third_payable ? (
                                        <td>
                                          {this.state.enrollment.third_payable}{" "}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Third Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .third_payment_date
                                          ? [
                                            this.state.enrollment
                                              .third_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Third Payment</td>
                                      <td>
                                        {this.state.enrollment.third_payment
                                          ? this.state.enrollment
                                            .third_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Third Paid Date</td>
                                      <td>
                                        {this.state.enrollment.third_paid_date
                                          ? [
                                            this.state.enrollment
                                              .third_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>4th Payable</td>
                                      {this.state.enrollment.fourth_payable ? (
                                        <td>
                                          {this.state.enrollment.fourth_payable}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Fourth Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .fourth_payment_date
                                          ? [
                                            this.state.enrollment
                                              .fourth_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fourth Payment</td>
                                      <td>
                                        {this.state.enrollment.fourth_payment
                                          ? this.state.enrollment
                                            .fourth_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fourth Paid Date</td>
                                      <td>
                                        {this.state.enrollment.fourth_paid_date
                                          ? [
                                            this.state.enrollment
                                              .fourth_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>5th Payable</td>
                                      {this.state.enrollment.fifth_payable ? (
                                        <td>
                                          {this.state.enrollment.fifth_payable}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Fifth Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .fifth_payment_date
                                          ? [
                                            this.state.enrollment
                                              .fifth_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fifth Payment</td>
                                      <td>
                                        {this.state.enrollment.fifth_payment
                                          ? this.state.enrollment
                                            .fifth_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fifth Paid Date</td>
                                      <td>
                                        {this.state.enrollment.fifth_paid_date
                                          ? [
                                            this.state.enrollment
                                              .fifth_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-info text-white mt-4">
                                      <td className="text-white">Notes</td>
                                      <td className="text-white text-capitalize">
                                        {this.state.notes}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-primary ml-1 mb-2"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/admin/edit-enrollment/${this.props.match.params.id}`
                                    );
                                  }}
                                >
                                  Update Payment Info
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ViewEnrollment);
