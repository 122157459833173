import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "../../Sidebar";
import axios from "axios";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { BASE_URL } from "../../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ManageAdmin extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Manage Admin";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
    });
    const admin_url = BASE_URL + "/api/admin/p20081994s";
    return axios
      .get(admin_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          admins: res.data,
        });
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = (e) => {
    this.props.history.push(`/admin/settings/edit-admin/${e}`);
  };

  handleDelete = (e) => {
    const admin_url = BASE_URL + `/api/admin/p20081994s/${e}`;
    return axios
      .delete(admin_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        const admin_url = BASE_URL + "/api/admin/p20081994s";
        return axios
          .get(admin_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              admins: res.data,
            });
          });
      });
  };

  handleView = (e) => {
    this.props.history.push(`/admin/settings/admin-info/${e}`);
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    const col = [
      {
        Header: "Avatar",
        id: "avatar",
        accessor: (props) => (
          <div className="text-center">
            <img
              src={`${BASE_URL}${`/images/admin/${props.image}`}`}
              className="avatar-sm"
              alt="avatar"
            />
          </div>
        ),
        maxWidth: 100,
      },
      {
        Header: "Name",
        id: "fullName",
        accessor: (d) => `${d.name}`,
        minWidth: 100,
        style: { paddingLeft: "10px", margin: "auto 0px" },
      },
      {
        Header: "Contact No.",
        accessor: "phn_no",
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <input
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder="search.."
          />
        ),
        style: { textAlign: "center", margin: "auto 0px" },
      },
      {
        Header: "Actions",
        Cell: (props) => {
          return (
            <>
              <div className="row mx-2">
                <button
                  className="btn btn-sm btn-primary col-md-6"
                  style={{ borderRadius: "0" }}
                  onClick={() => {
                    this.handleView(props.original.id);
                  }}
                >
                  <i className="fa fa-eye"></i>
                </button>

                <button
                  className="btn btn-sm btn-secondary col-md-6"
                  style={{ borderRadius: "0" }}
                  onClick={() => {
                    this.handleEdit(props.original.id);
                  }}
                >
                  <i className="fa fa-pencil"></i>
                </button>

                {/* MODAL TRIGGER */}
                {/* <button
                  className="btn btn-sm btn-danger col-md-4"
                  style={{ borderRadius: "0" }}
                  data-toggle="modal"
                  data-target={`#myModal${props.original.id}`}
                >
                  <i className="fa fa-times"></i>
                </button> */}
                {/* MODAL */}
                <div className="modal" id={`myModal${props.original.id}`}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Are you sure want to delete this admin?
                        </h5>
                        <button className="close" data-dismiss="modal">
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-8">
                            <h6>
                              <b>Admin's Name: </b> {props.original.name}
                            </h6>
                            <h6>
                              <b>Email: </b> {props.original.email}
                            </h6>
                            <h6>
                              <b>Contact Number: </b> {props.original.phn_no}
                            </h6>
                          </div>
                          <div className="col-md-4">
                            <img
                              src={`${BASE_URL}${`/images/admin/${props.original.image}`}`}
                              alt="avatar"
                              className="avatar-sm"
                            />
                          </div>
                        </div>
                        <div className="modal-footer justify-content-around">
                          <div className="row ">
                            <button
                              className="btn btn-danger mr-3"
                              onClick={() => {
                                this.handleDelete(props.original.id);
                              }}
                              data-dismiss="modal"
                            >
                              Yes
                            </button>
                            <button
                              className="btn btn-success"
                              data-dismiss="modal"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        },
        style: { margin: "auto 0px" },
      },
    ];

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h4 className="mt-2 mb-4">
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Manage Admins
                </h4>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        All Admins
                      </div>
                      <ReactTable
                        data={this.state.admins}
                        columns={col}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 25, 50]}
                        sortable={false}
                        defaultFilterMethod={filterCaseInsensitive}
                        className="-striped"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageAdmin);
