import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
import Cookies from "universal-cookie";
// Components
import Sidebar from "../../Sidebar";
const cookies = new Cookies();

class NewHero extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subtitle: "",
      social_icon1: "",
      social_icon2: "",
      social_icon3: "",
      social_icon4: "",
      social_link1: "",
      social_link2: "",
      social_link3: "",
      social_link4: "",
      loading: false,
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Hero";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );

    const url = BASE_URL + "/api/all-hero-des";
    return axios.get(url).then((res) => {
      this.setState({
        herodes: res.data[0],
        title: res.data[0].title,
        subtitle: res.data[0].subtitle,
        social_icon1: res.data[0].social_icon1,
        social_icon2: res.data[0].social_icon2,
        social_icon3: res.data[0].social_icon3,
        social_icon4: res.data[0].social_icon4,
        social_link1: res.data[0].social_link1,
        social_link2: res.data[0].social_link2,
        social_link3: res.data[0].social_link3,
        social_link4: res.data[0].social_link4,
        loading: false,
      });
    });

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/admin/add-hero-des`;
    const formData = {
      title: this.state.title,
      subtitle: this.state.subtitle,
      social_icon1: this.state.social_icon1,
      social_icon2: this.state.social_icon2,
      social_icon3: this.state.social_icon3,
      social_icon4: this.state.social_icon4,
      social_link1: this.state.social_link1,
      social_link2: this.state.social_link2,
      social_link3: this.state.social_link3,
      social_link4: this.state.social_link4,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          title: "",
          subtitle: "",
          social_icon1: "",
          social_icon2: "",
          social_icon3: "",
          social_icon4: "",
          social_link1: "",
          social_link2: "",
          social_link3: "",
          social_link4: "",
          error: [],
          loading: false,
        });
        console.log(res);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >

              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update Footer
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Hero Description
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <form
                          className="form-group"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="col-md-12 input-group mb-2">
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Title:</label>
                              <textarea
                                id="title"
                                name="title"
                                placeholder="Title"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.handleChange}
                                required
                              >
                              </textarea>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Subtitle:</label>
                              <textarea
                                id="subtitle"
                                name="subtitle"
                                placeholder="Subtitle"
                                className="form-control"
                                value={this.state.subtitle}
                                onChange={this.handleChange}
                                required
                              ></textarea>
                            </div>

                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social icon 1:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_icon1"
                                placeholder="ex: fa fa-grav"
                                className="form-control"
                                value={this.state.social_icon1}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <small children="py-3">only fontawseome 4.7 is supported</small>
                            </div>

                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social link 1:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_link1"
                                placeholder="ex: https://www.facebook.com/"
                                className="form-control"
                                value={this.state.social_link1}
                                onChange={this.handleChange}
                                required
                              />
                            </div>

                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social icon 2:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_icon2"
                                placeholder="ex: fa fa-grav"
                                className="form-control"
                                value={this.state.social_icon2}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <small children="py-3">only fontawseome 4.7 is supported</small>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social link 2:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_link2"
                                placeholder="ex: https://www.facebook.com/"
                                className="form-control"
                                value={this.state.social_link2}
                                onChange={this.handleChange}
                                required
                              />
                            </div>

                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social icon 3:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_icon3"
                                placeholder="ex: fa fa-grav"
                                className="form-control"
                                value={this.state.social_icon3}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <small children="py-3">only fontawseome 4.7 is supported</small>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social link 3:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_link3"
                                placeholder="ex: https://www.facebook.com/"
                                className="form-control"
                                value={this.state.social_link3}
                                onChange={this.handleChange}
                                required
                              />
                            </div>

                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social icon 4:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_icon4"
                                placeholder="ex: fa fa-grav"
                                className="form-control"
                                value={this.state.social_icon4}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <small children="py-3">only fontawseome 4.7 is supported</small>
                            </div>
                            <div className="col-md-8 offset-md-2 pl-0 mt-3">
                              <label>Social link 4:</label>
                              <input
                                type="text"
                                id="fb"
                                name="social_link4"
                                placeholder="ex: https://www.facebook.com/"
                                className="form-control"
                                value={this.state.social_link4}
                                onChange={this.handleChange}
                                required
                              />
                            </div>


                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.error.desc ? (
                                  <ul className="list-group list-group-horizontal">
                                    {this.state.error.desc.map((item) => {
                                      return (
                                        <li className="list-group-item border-0 bg-light py-0 pl-0">
                                          {"*" + item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-2 px-4"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </>
    );
  }
}
export default withRouter(NewHero);
