import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Smtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MAIL_DRIVER: "",
      MAIL_HOST: "",
      MAIL_PORT: "",
      MAIL_USERNAME: "",
      MAIL_PASSWORD: "",
      MAIL_ENCRYPTION: "",
      MAIL_FROM_ADDRESS: "",
      MAIL_FROM_NAME: "",
      loading: false,
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "SMS Settings";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });
    const url = BASE_URL + "/api/admin/smtp";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          MAIL_DRIVER: res.data[0].MAIL_DRIVER,
          MAIL_HOST: res.data[0].MAIL_HOST,
          MAIL_PORT: res.data[0].MAIL_PORT,
          MAIL_USERNAME: res.data[0].MAIL_USERNAME,
          MAIL_PASSWORD: res.data[0].MAIL_PASSWORD,
          MAIL_ENCRYPTION: res.data[0].MAIL_ENCRYPTION,
          MAIL_FROM_ADDRESS: res.data[0].MAIL_FROM_ADDRESS,
          MAIL_FROM_NAME: res.data[0].MAIL_FROM_NAME,
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/admin/smtp/store`;
    const formData = {
      MAIL_DRIVER: this.state.MAIL_DRIVER,
      MAIL_HOST: this.state.MAIL_HOST,
      MAIL_PORT: this.state.MAIL_PORT,
      MAIL_USERNAME: this.state.MAIL_USERNAME,
      MAIL_PASSWORD: this.state.MAIL_PASSWORD,
      MAIL_ENCRYPTION: this.state.MAIL_ENCRYPTION,
      MAIL_FROM_ADDRESS: this.state.MAIL_FROM_ADDRESS,
      MAIL_FROM_NAME: this.state.MAIL_FROM_NAME,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update SMTP Settings
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  {this.state.loading ? (
                    <Skeleton className="login-card" />
                  ) : (
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="card card-primary card-outline mb-2">
                          <div className="card-header">
                            <h3 className="card-title">SMTP Settings</h3>
                          </div>
                          <div className="card-body">
                            <form
                              className="form-horizontal"
                              onSubmit={this.handleSubmit}
                            >
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL DRIVER
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <select
                                    name="MAIL_DRIVER"
                                    className="form-control rounded-sm pl-2"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_DRIVER}
                                    required
                                  >
                                    <option value="">
                                      Please select MAIL_DRIVER
                                    </option>{" "}
                                    <option value="sendmail">Sendmail</option>
                                    <option value="smtp">SMTP</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL HOST
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_HOST"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_HOST}
                                    placeholder="localhost"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL PORT
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_PORT"
                                    onChange={this.handleChange}
                                    placeholder="e.g. 587"
                                    value={this.state.MAIL_PORT}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL USERNAME
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_USERNAME"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_USERNAME}
                                    placeholder="Enter your email username"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL PASSWORD
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="password"
                                    className="form-control"
                                    name="MAIL_PASSWORD"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_PASSWORD}
                                    placeholder="Email password"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL ENCRYPTION
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_ENCRYPTION"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_ENCRYPTION}
                                    placeholder="ssl / tls"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL FROM ADDRESS
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_FROM_ADDRESS"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_FROM_ADDRESS}
                                    placeholder="Mail from address"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="">
                                  <label className="control-label">
                                    MAIL FROM NAME
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="MAIL_FROM_NAME"
                                    onChange={this.handleChange}
                                    value={this.state.MAIL_FROM_NAME}
                                    placeholder="mail from name"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-lg-12 text-right">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="panel bg-gray-light">
                          <div className="panel-body">
                            <h4>Instruction</h4>
                            <p className="text-danger">
                              Please be carefull when you are configuring SMTP.
                              For incorrect configuration you may get error in
                              few features.
                            </p>

                            <p>For Non-SSL</p>
                            <ul className="list-group">
                              <li className="list-group-item text-dark">
                                Select 'sendmail' for Mail Driver if you face
                                any issue after configuring 'smtp' as Mail
                                Driver
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail Host according to your server Mail
                                Client Manual Settings
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail port as '587'
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail Encryption as 'ssl' if you face issue
                                with 'tls'
                              </li>
                            </ul>

                            <p>For SSL</p>
                            <ul className="list-group mar-no">
                              <li className="list-group-item text-dark">
                                Select 'sendmail' for Mail Driver if you face
                                any issue after configuring 'smtp' as Mail
                                Driver
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail Host according to your server Mail
                                Client Manual Settings
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail port as '465'
                              </li>
                              <li className="list-group-item text-dark">
                                Set Mail Encryption as 'ssl'
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Smtp);
