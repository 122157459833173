import React, { Component } from "react";
export default class Hero extends Component {
  render() {
    const { children, hero } = this.props;
    return (
      <>
        <div className={hero}>{children}</div>
      </>
    );
  }
}
