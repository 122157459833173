import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import ReactTable from "react-table";
import "react-table/react-table.css";

import Sidebar from "../Sidebar";

import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ManageCourse extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      path: "",
      alert_success: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Manage Courses";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    this.setState({
      path: this.props.history.location.pathname,
    });
    const url = BASE_URL + "/api/admin/course/p170815s";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          courses: res.data,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleView = (e) => {
    this.props.history.push(`/admin/course-info/${e}`);
  };

  render() {
    const col = [
      {
        Header: "Thumbnail",
        id: "thumbnail",
        accessor: (props) => (
          <div className="text-center">
            <img
              src={`${BASE_URL}${`/images/course/${props.thumbnail}`}`}
              className="avatar-sm"
              alt="thumbnail"
            />
          </div>
        ),
      },
      {
        Header: "Title",
        id: "title",
        accessor: "title",
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <input
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder="search.."
          />
        ),
        minWidth: 100,
        style: { paddingLeft: "10px", margin: "auto 0px" },
      },
      {
        Header: "Fee (BDT)",
        accessor: "fee",
        style: { textAlign: "center", margin: "auto 0px" },
        maxWidth: 100,
      },
      {
        Header: "Actions",
        Cell: (props) => {
          return (
            <>
              <div className="row mx-2">
                <button
                  className="btn btn-sm btn-primary offset-md-3 col-md-6"
                  style={{ borderRadius: "0" }}
                  onClick={() => {
                    this.handleView(props.original.id);
                  }}
                  title="View Details"
                >
                  {/* <i className="fa fa-eye"></i> */}
                  View
                </button>
              </div>
            </>
          );
        },
        style: { margin: "auto 0px" },
      },
    ];

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h4 className="mt-2 mb-4">
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Manage Courses
                </h4>
                <div className="container">
                  {/* Error or Success Message */}
                  {this.state.alert_success ? (
                    <div className="text-center bg-success text-white p-3 mb-2">
                      {this.state.alert_success}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Error or Success Message */}
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        All Courses
                      </div>
                      <ReactTable
                        data={this.state.courses}
                        columns={col}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 25, 50]}
                        sortable={false}
                        defaultFilterMethod={filterCaseInsensitive}
                        className="-striped"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageCourse);
