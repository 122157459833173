import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

const MAX_LENGTH = 18;

class MyCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      searchedCourses: [],
      enrollments: [],
      loading: false,
      alert_danger: "",
      search: false,
      search_query: "",
      timeout: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_danger;
      this.setState({
        alert_danger: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_danger: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    document.title = "My Courses";
    const url = BASE_URL + "/api/student/myCourses";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          courses: res.data[0],
          enrollments: res.data[1],
          loading: false,
        });
      });
  }

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/student/myCourses/findCourse/" + searchText;
      if (searchText !== null && searchText !== "") {
        return axios
          .get(url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedCourses: res.data,
            });
          });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return (
        <>
          {" "}
          <Hero hero="myCourseHero d-flex align-items-center">
            <Banner title="My Courses" bannerCSS="col-6 mx-auto course-banner ">
              <form
                className="col-md-8 offset-md-2"
                onSubmit={this.handleSubmit}
              >
                <div className="input-group">
                  <input
                    type="text"
                    name="search_query"
                    className="form-control text-center"
                    placeholder="Search.."
                    onChange={this.handleSearch}
                  />
                  <div className="input-group-append d-none">
                    <button
                      type="submit"
                      className="input-group-text bg-primary text-white"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </Banner>
          </Hero>
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/* Error or Success Message */}
          {this.state.alert_danger ? (
            <div className="col-md-12 text-center bg-danger text-white p-3">
              {this.state.alert_danger}
            </div>
          ) : (
            ""
          )}
          {/* Error or Success Message */}
          <Hero hero="myCourseHero d-flex align-items-center">
            <Banner
              title="My Courses"
              bannerCSS="col-6 mx-auto text-center course-banner"
            >
              <form
                className="col-md-8 offset-md-2"
                onSubmit={this.handleSubmit}
              >
                <div className="input-group">
                  <input
                    type="text"
                    name="search_query"
                    className="form-control text-center"
                    placeholder="Search.."
                    onChange={this.handleSearch}
                  />
                  <div className="input-group-append d-none">
                    <button
                      type="submit"
                      className="input-group-text bg-primary text-white"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </Banner>
          </Hero>
          <div className="padding-top-90 padding-bottom-80">
            <div className="container py-4">
              <div className="row">
                {!this.state.search
                  ? [
                      this.state.courses.map((course) => {
                        let enrolled_course = this.state.enrollments.find(
                          (content) => content.course_id == course.id
                        );
                        if (enrolled_course.is_active == 1) {
                          return (
                            <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                              <div class="course_card h-100">
                                <div class="course_card_img">
                                  <img
                                    src={`${BASE_URL}${`/images/course/${course.thumbnail}`}`}
                                    alt="course"
                                  />
                                </div>
                                <div class="course_card_content">
                                  <span class="title title-gray d-inline-block mt-4">
                                    <i
                                      class="fa fa-book"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {course.total_class} lessons
                                  </span>
                                  <div className="mt-3 position-relative">
                                    <span className="badge custom-percentage title-gray">
                                      {" "}
                                      {course.complete_percentage}
                                    </span>
                                    <div class="progress all-progress">
                                      <div
                                        className={`progress-bar`}
                                        style={{
                                          width: `${course.complete_percentage}`,
                                        }}
                                        role="progressbar"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>

                                  <Link to={`/my-courses/${course.id}`}>
                                    <h5 class="title mt-3">{course.title}</h5>
                                  </Link>
                                </div>
                                <div class="course_card_footer">
                                  <Link
                                    to={`/my-courses/${course.id}`}
                                    className="nav-item btn  button--ujarak position-relative"
                                  >
                                    start
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                              <div class="course_card h-100">
                                <div class="course_card_img">
                                  <img
                                    src={`${BASE_URL}${`/images/course/${course.thumbnail}`}`}
                                    alt="course"
                                  />
                                </div>
                                <div class="course_card_content">
                                  <span class="title title-gray d-inline-block mt-4">
                                    <i
                                      class="fa fa-book"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {course.total_class} lessons
                                  </span>
                                  <div className="mt-3 position-relative">
                                    <span className="badge custom-percentage title-gray">
                                      {" "}
                                      {course.complete_percentage}
                                    </span>
                                    <div class="progress all-progress">
                                      <div
                                        className={`progress-bar`}
                                        style={{
                                          width: `${course.complete_percentage}`,
                                        }}
                                        role="progressbar"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>

                                  <Link to="">
                                    <h5 class="title mt-3">{course.title}</h5>
                                  </Link>
                                </div>
                                <div class="course_card_footer">
                                  <Link
                                    to=""
                                    className="nav-item btn  button--ujarak position-relative"
                                  >
                                    {_t(t("Course disabled"))}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }),
                    ]
                  : [
                      this.state.searchedCourses.length > 0 ? (
                        this.state.searchedCourses.map((searchCourse) => {
                          let enrolled_course = this.state.enrollments.find(
                            (content) => content.course_id === searchCourse.id
                          );

                          if (enrolled_course.is_active === 1) {
                            return (
                              <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div class="course_card h-100">
                                  <div class="course_card_img">
                                    <img
                                      src={`${BASE_URL}${`/images/course/${searchCourse.thumbnail}`}`}
                                      alt="course"
                                    />
                                  </div>
                                  <div class="course_card_content">
                                    <span class="title title-gray d-inline-block mt-4">
                                      <i
                                        class="fa fa-book"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {searchCourse.total_class} lessons
                                    </span>
                                    <div className="mt-3 position-relative">
                                      <span className="badge custom-percentage title-gray">
                                        {" "}
                                        {searchCourse.complete_percentage}
                                      </span>
                                      <div class="progress all-progress">
                                        <div
                                          className={`progress-bar`}
                                          style={{
                                            width: `${searchCourse.complete_percentage}`,
                                          }}
                                          role="progressbar"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>

                                    <Link to={`/my-courses/${searchCourse.id}`}>
                                      <h5 class="title mt-3">
                                        {searchCourse.title}
                                      </h5>
                                    </Link>
                                  </div>
                                  <div class="course_card_footer">
                                    <Link
                                      to={`/my-courses/${searchCourse.id}`}
                                      className="nav-item btn  button--ujarak position-relative"
                                    >
                                      start
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div class="course_card h-100">
                                  <div class="course_card_img">
                                    <img
                                      src={`${BASE_URL}${`/images/course/${searchCourse.thumbnail}`}`}
                                      alt="course"
                                    />
                                  </div>
                                  <div class="course_card_content">
                                    <span class="title title-gray d-inline-block mt-4">
                                      <i
                                        class="fa fa-book"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {searchCourse.total_class} lessons
                                    </span>
                                    <div className="mt-3 position-relative">
                                      <span className="badge custom-percentage title-gray">
                                        {" "}
                                        {searchCourse.complete_percentage}
                                      </span>
                                      <div class="progress all-progress">
                                        <div
                                          className={`progress-bar`}
                                          style={{
                                            width: `${searchCourse.complete_percentage}`,
                                          }}
                                          role="progressbar"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>

                                    <Link to="">
                                      <h5 class="title mt-3">
                                        {searchCourse.title}
                                      </h5>
                                    </Link>
                                  </div>
                                  <div class="course_card_footer">
                                    <Link
                                      to=""
                                      className="nav-item btn  button--ujarak position-relative"
                                    >
                                      {_t(t("Course disabled"))}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="col-md-12 text-center  my-auto">
                          <div className="card card-body bg-light text-danger no-searched-course mb-2">
                            {_t(t("No course found by your query."))}
                          </div>
                        </div>
                      ),
                    ]}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default withTranslation()(withRouter(MyCourses));
