import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import makeAnimated from "react-select/animated";

import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ManageMods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course_id: "",
      title: "",
      instructor_name: "",
      moderators: [],
      assignedModerators: [],
      selectedModerator: [],
      loading: false,
      addModLoading: false,
      path: "",
      isMobile: false,
      sidebar: true,
      alert_success: "",
      alert_danger: "",
    };
  }
  componentDidMount() {
    document.title = "Course Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const course_url = BASE_URL + `/api/admin/course/p170815s/${id}`;
    return axios
      .get(course_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          thumbnail: res.data.thumbnail,
          course_id: res.data.id,
          title: res.data.title,
          fee: res.data.fee,
        });
        const instructor_url =
          BASE_URL +
          `/api/admin/instructor/instructor_p170815s/${res.data.instructor_id}`;
        return axios
          .get(instructor_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              instructor_name: res.data.fname + " " + res.data.lname,
            });
            //get moderator here
            const moderators_url =
              BASE_URL + "/api/admin/moderator/moderator_p170815s";
            return axios
              .get(moderators_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  moderators: res.data,
                });
                //get assigned moderator here
                const assigned_moderators_url =
                  BASE_URL +
                  `/api/admin/moderator/assign_moderator_p170815s/${this.props.match.params.id}`;
                return axios
                  .get(assigned_moderators_url, {
                    headers: {
                      Authorization: `Bearer  ${cookies.get("adminToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      assignedModerators: res.data,
                      loading: false,
                    });
                  });
              });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChangeAddModerator = (mod) => {
    this.setState({
      selectedModerator: mod,
    });
  };

  handleDeleteMods = (e) => {
    this.setState({
      loading: true,
    });
    const url =
      BASE_URL + `/api/admin/moderator/assign_moderator_p170815s/${e}`;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        //get assigned moderator here
        const assigned_moderators_url =
          BASE_URL +
          `/api/admin/moderator/assign_moderator_p170815s/${this.props.match.params.id}`;
        return axios
          .get(assigned_moderators_url, {
            headers: {
              Authorization: `Bearer  ${cookies.get("adminToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              assignedModerators: res.data,
              loading: false,
            });
          });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedModerator.length > 0) {
      this.setState({
        loading: true,
        addModLoading: true,
      });
      const url = BASE_URL + "/api/admin/moderator/assign_moderator_p170815s";
      const formData = {
        course_id: this.props.match.params.id,
        selectedModerator: this.state.selectedModerator,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
        })
        .then((res) => {
          this.setState({
            assignedModerators: res.data,
            error: [],
            addModLoading: false,
            alert_success: "Moderator assigned successfully.",
            selectedModerator: [],
            loading: false,
          });
          setTimeout(
            function () {
              this.setState({ alert_success: "" });
            }.bind(this),
            5000
          );
        })
        .catch((error) => {
          this.setState({
            error: error.response.data.errors,
            addModLoading: false,
            loading: false,
          });
        });
    } else {
      this.setState({
        alert_danger: "Select moderator(s)",
      });
      setTimeout(
        function () {
          this.setState({ alert_danger: "" });
        }.bind(this),
        3000
      );
    }
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.course_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          Course Moderators Information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="row mr-1 ml-1">
                          <Link
                            to={`/admin/course-info/${this.props.match.params.id}`}
                            className="nav-link text-light bg-secondary mb-3 rounded-sm"
                            style={{ width: "120px" }}
                          >
                            <i className="fa fa-reply-all"></i> Go Back
                          </Link>
                        </div>
                      </div>
                      <div className="row col-md-9">
                        <div className="row ml-auto">
                          {/* MODAL TRIGGER */}
                          <button
                            className="btn btn-primary mb-3 rounded-sm ml-3"
                            data-toggle="modal"
                            data-target={`#myModal`}
                            title="Assign Moderators"
                            onClick={this.handleMods}
                          >
                            <i className="fa fa-plus"></i> Moderators
                          </button>
                          {/* MODAL */}
                          <div className="modal" id={`myModal`}>
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Assign Moderators
                                  </h5>
                                  <button
                                    className="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body py-5">
                                  <div className="row">
                                    <div className="col-md-12">
                                      {this.state.addModLoading ? (
                                        <div className="row text-center">
                                          <div className="col-md-12 text-primary">
                                            <h3>
                                              <Skeleton />
                                            </h3>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {this.state.alert_success ? (
                                            <div className="text-center bg-success text-white p-2 mb-3">
                                              {this.state.alert_success}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.alert_danger ? (
                                            <div className="text-center bg-danger text-white p-2 mb-3">
                                              {this.state.alert_danger}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <form
                                            className="form-group"
                                            onSubmit={this.handleSubmit}
                                          >
                                            <div className="input-group col-md-12">
                                              <label className="my-auto  col-md-4">
                                                <b>Moderators</b>
                                              </label>
                                              <Select
                                                options={this.state.moderators}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) =>
                                                  option.fname +
                                                  " " +
                                                  option.lname
                                                }
                                                getOptionValue={(option) =>
                                                  option.id
                                                }
                                                className="basic-multi-select px-0  col-md-8"
                                                classNamePrefix="select"
                                                isMulti
                                                onChange={
                                                  this.handleChangeAddModerator
                                                }
                                                isRequired
                                                placeholder="Please assign moderators"
                                              />
                                            </div>
                                            <div className="text-right mr-3">
                                              <button
                                                type="submit"
                                                className="btn btn-success btn-sm mt-2 px-4"
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </form>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h3>
                          <Skeleton count={5} />
                        </h3>
                        <Skeleton count={5} />
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="container">
                              <div className="bg-light mt-2">
                                <h4 className="text-center pt-2">
                                  Course Info
                                </h4>
                                <table className="col-md-12 table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="text-center">Title</th>
                                      <th className="text-center">
                                        Instructor
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                    <tr>
                                      <td>{this.state.title}</td>
                                      <td>{this.state.instructor_name}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="bg-dark mt-2">
                                <h4 className="text-center text-white pt-2">
                                  Moderators
                                </h4>
                                <table className="col-md-12 table table-striped text-white ">
                                  <thead>
                                    <tr>
                                      <th className="text-center">#</th>
                                      <th className="text-center">Name</th>
                                      <th className="text-center">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                    {this.state.assignedModerators.map(
                                      (assignedMod) => {
                                        let mod = this.state.moderators.find(
                                          (tempMod) =>
                                            tempMod.id ==
                                            assignedMod.moderator_id
                                        );
                                        if (
                                          mod.id == assignedMod.moderator_id
                                        ) {
                                          return (
                                            <tr>
                                              <td>#</td>
                                              <td>
                                                {mod.fname + " " + mod.lname}
                                              </td>
                                              <td>
                                                {/* MODAL TRIGGER */}
                                                <button
                                                  className="btn btn-sm btn-danger col-md-4"
                                                  style={{ borderRadius: "0" }}
                                                  data-toggle="modal"
                                                  data-target={`#myModal${mod.id}`}
                                                >
                                                  <i className="fa fa-times"></i>
                                                </button>
                                                {/* MODAL */}
                                                <div
                                                  className="modal text-dark text-left"
                                                  id={`myModal${mod.id}`}
                                                >
                                                  <div className="modal-dialog">
                                                    <div className="modal-content">
                                                      <div className="modal-header">
                                                        <h5 className="modal-title">
                                                          Are you sure want
                                                          remove this moderator
                                                          from this course?
                                                        </h5>
                                                        <button
                                                          className="close"
                                                          data-dismiss="modal"
                                                        >
                                                          &times;
                                                        </button>
                                                      </div>
                                                      <div className="modal-body">
                                                        <div className="row">
                                                          <div className="col-md-8">
                                                            <h6>
                                                              <b>Course: </b>{" "}
                                                              {this.state.title}
                                                            </h6>
                                                            <h6>
                                                              <b>Name: </b>{" "}
                                                              {mod.fname +
                                                                " " +
                                                                mod.lname}
                                                            </h6>
                                                            <h6>
                                                              <b>Email: </b>{" "}
                                                              {mod.email}
                                                            </h6>
                                                            <h6>
                                                              <b>
                                                                Contact Number:{" "}
                                                              </b>{" "}
                                                              {mod.phn_no}
                                                            </h6>
                                                          </div>
                                                          <div className="col-md-4">
                                                            <img
                                                              src={`${BASE_URL}${`/images/moderator/${mod.image}`}`}
                                                              alt="avatar"
                                                              height="80"
                                                              width="100"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="modal-footer justify-content-around">
                                                          <div className="row ">
                                                            <button
                                                              className="btn btn-danger mr-3"
                                                              onClick={() => {
                                                                this.handleDeleteMods(
                                                                  assignedMod.id
                                                                );
                                                              }}
                                                              data-dismiss="modal"
                                                            >
                                                              Yes
                                                            </button>
                                                            <button
                                                              className="btn btn-success"
                                                              data-dismiss="modal"
                                                            >
                                                              No
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        } else {
                                          return false;
                                        }
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageMods);
