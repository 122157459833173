import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ViewCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnail: "",
      course_id: "",
      title: "",
      fee: "",
      instructor_name: "",
      total_enrollment: "",
      loading: false,
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Course Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const course_url = BASE_URL + `/api/admin/course/p170815s/${id}`;
    return axios
      .get(course_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          thumbnail: res.data.thumbnail,
          course_id: res.data.id,
          title: res.data.title,
          fee: res.data.fee,
        });
        const instructor_url =
          BASE_URL +
          `/api/admin/instructor/instructor_p170815s/${res.data.instructor_id}`;
        return axios
          .get(instructor_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              instructor_name: res.data.fname + " " + res.data.lname,
            });
            const url =
              BASE_URL +
              `/api/admin/course/enrollment_p170815s/${this.props.match.params.id}`;
            return axios
              .get(url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  total_enrollment: res.data,
                  loading: false,
                });
              });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleDelete = (e) => {
    const url = BASE_URL + `/api/admin/course/p170815s/${this.state.course_id}`;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.props.history.push({
          pathname: "/admin/manage-course",
          state: {
            alert_success: "Course deleted successfully",
          },
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.course_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          Course Information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row col-md-12 mr-1 ml-1">
                          <Link
                            to="/admin/manage-course"
                            className="nav-link text-light bg-secondary mb-3 rounded-sm"
                            style={{ width: "120px" }}
                          >
                            <i className="fa fa-reply-all"></i> Go Back
                          </Link>
                          <NavLink
                            to={"/admin/play-course/" + this.state.course_id}
                            className="nav-link text-light bg-success mb-3 ml-1 rounded-sm"
                            title="Play"
                          // target="_blank"
                          // rel="noopener noreferrer"
                          >
                            <i className="fa fa-play"></i>
                          </NavLink>
                        </div>
                      </div>
                      <div className="row col-md-8">
                        <div className="row ml-auto">
                          <Link
                            to={`/admin/course/manage-classes/${this.state.course_id}`}
                            className="nav-link text-dark bg-warning mb-3 rounded-sm ml-1"
                          >
                            <i className="fa fa-eye"></i> Classes
                          </Link>
                          <Link
                            to={`/admin/add-course/${this.state.course_id}/add-class`}
                            className="nav-link text-light bg-primary mb-3 rounded-sm ml-1"
                          >
                            <i className="fa fa-plus"></i> Class
                          </Link>
                          <Link
                            to={`/admin/course/manage-mods/${this.state.course_id}`}
                            className="nav-link text-light bg-info mb-3 rounded-sm ml-1"
                          >
                            <i className="fa fa-pencil"></i> Moderators
                          </Link>
                          <Link
                            to={`/admin/course/manage-instructor-course/${this.state.course_id}`}
                            className="nav-link text-light bg-info mb-3 rounded-sm ml-1"
                          >
                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>  Instructor
                          </Link>
                        </div>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <div className="container">
                        <div className="mt-3">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row mb-2">
                          <div className="col-md-12 text-center">
                            <img
                              src={`${BASE_URL}${`/images/course/${this.state.thumbnail}`}`}
                              alt="thumbnail"
                              className="img-fluid rounded-sm"
                              style={{ height: "150px", width: "300px" }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="container">
                              <div className="bg-light">
                                <table className="col-md-12 table table-striped table-bordered">
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-right">Title</td>
                                      <td>{this.state.title}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-right">Fee</td>
                                      <td>{this.state.fee} BDT</td>
                                    </tr>
                                    <tr>
                                      <td className="text-right">Instructor</td>
                                      <td>{this.state.instructor_name}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-right">
                                        Total Enrollments
                                      </td>
                                      <td>{this.state.total_enrollment}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary ml-1 mb-2"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/admin/edit-course/${this.state.course_id}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-pencil"></i> Update
                                  Information
                                </button>
                                {/* MODAL TRIGGER */}
                                <button
                                  className="btn btn-sm btn-danger ml-1 mb-2"
                                  data-toggle="modal"
                                  data-target={`#myModal`}
                                  title="Delete Course"
                                >
                                  <i className="fa fa-times"></i> Delete Course
                                </button>

                                {/* MODAL */}
                                <div className="modal" id={`myModal`}>
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title text-danger">
                                          Are you sure want to delete this
                                          course?
                                        </h5>
                                        <button
                                          className="close"
                                          data-dismiss="modal"
                                        >
                                          &times;
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="row">
                                          <div className="offset-md-3 col-md-6 justify-content-around">
                                            <button
                                              className="btn btn-danger mr-3"
                                              onClick={() => {
                                                this.handleDelete(
                                                  this.state.course_id
                                                );
                                              }}
                                              data-dismiss="modal"
                                            >
                                              Yes
                                            </button>
                                            <button
                                              className="btn btn-success"
                                              data-dismiss="modal"
                                            >
                                              No
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer text-danger fs-13">
                                        <b>
                                          N.B : All the classes, Videos &
                                          Enrollments of this course will be
                                          deleted.
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ViewCourse);
