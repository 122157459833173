import React, { Component } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../BaseUrl";

import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";

const MAX_LENGTH = 18;

class SingleAllCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      classes: [],
      contacts: [],
      // googleLink: null,
      loading: false,
    };
  }
  componentDidMount() {
    document.title = "Enrollment Details";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/all_courses/${this.props.match.params.id}`;
    axios.get(url).then((res) => {
      this.setState({
        course: res.data,
      });
      const class_url =
        BASE_URL + `/api/course_class_p20081994s/${this.props.match.params.id}`;
      return axios.get(class_url).then((res) => {
        this.setState({
          classes: res.data[0],
        });
        const contact_url = BASE_URL + "/api/contact_for_enrollment";
        return axios.get(contact_url).then((res) => {
          this.setState({
            contacts: res.data[0],
            // googleLink: res.data[1].google_link,
            loading: false,
          });
        });
      });
    });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card m-4 login-card">
                <div className="card-body bg-light">
                  {this.state.loading ? (
                    <>
                      <NavLink
                        to="/all-courses"
                        className="btn btn-sm btn-secondary mb-1"
                      >
                        <i className="fa fa-reply-all"></i> {_t(t("Go Back"))}
                      </NavLink>
                      <h3 className="text-center bg-success text-white rounded-sm py-2">
                        {_t(t("Enrollment Process"))}
                      </h3>
                      <h5 className="mt-3">{_t(t("Course Information"))}</h5>

                      <h3>
                        <Skeleton count={3} />
                      </h3>
                      <h5>{_t(t("Course Outline"))}</h5>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="class-card" />
                        </div>
                      </div>
                      <h5>{_t(t("Contact for enrollment"))}</h5>
                      <h6 className="container row mx-2">
                        {_t(t("To enroll, please fill this form"))}
                      </h6>
                      <h3>
                        <Skeleton count={3} />
                      </h3>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to="/all-courses"
                        className="btn btn-sm btn-secondary mb-1"
                      >
                        <i className="fa fa-reply-all"></i> {_t(t("Go Back"))}
                      </NavLink>
                      <h3 className="text-center bg-success text-white rounded-sm py-2">
                        {_t(t("Enrollment Process"))}
                      </h3>
                      <h5 className="mt-3">{_t(t("Course Information"))}</h5>
                      <div className="row mx-3">
                        <table className="table table-striped m-3">
                          <tbody>
                            <tr className="row">
                              <td className="col-md-4">{_t(t("Title"))}</td>
                              <td className="col-md-8">
                                {this.state.course.title}
                              </td>
                            </tr>
                            <tr className="row">
                              <td className="col-md-4">
                                {_t(t("Course Fee"))}
                              </td>
                              <td className="col-md-8">
                                {this.state.course.fee} {_t(t("BDT"))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h5>{_t(t("Course Outline"))}</h5>
                      <div className="row">
                        {this.state.classes.map((item, index) => {
                          return (
                            <div className="col-md-4">
                              <span className="card-link">
                                <div className="card class-card bg-light text-center">
                                  <div className="card-body">
                                    <h4></h4>
                                    <p className="text-primary">
                                      {item.title.length > MAX_LENGTH ? (
                                        <div>
                                          {`${item.title.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`}
                                        </div>
                                      ) : (
                                        <p>{item.title}</p>
                                      )}
                                    </p>
                                  </div>
                                  <div className="card-footer">
                                    <p>
                                      {_t(t("Main Concept"))}
                                      <br />
                                      <span>
                                        {item.content_about.length >
                                          MAX_LENGTH ? (
                                          <div>
                                            {`${item.content_about.substring(
                                              0,
                                              MAX_LENGTH
                                            )}...`}
                                          </div>
                                        ) : (
                                          <p>{item.content_about}</p>
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <h5>{_t(t("Contact for enrollment"))}</h5>
                      <h6 className="container row mx-2">
                        {_t(t("To enroll, please fill this form"))}
                        <Link
                          to="/student-registration"
                          target="_blank"
                          className="ml-1 mr-1"
                        // title={this.state.googleLink}
                        >
                          {_t(t("Click Here."))}
                        </Link>
                        {_t(
                          t("Then contact through any of these numbers below")
                        )}
                      </h6>
                      <div className="container row mx-2">
                        <ul>
                          {this.state.contacts.map((item) => {
                            return (
                              <b>
                                <li className="text-success">
                                  {" "}
                                  {item.phn_no} ({item.name})
                                </li>
                              </b>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(SingleAllCourse));
