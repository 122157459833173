import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Sidebar from "../Sidebar";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const $ = require("jquery");
$.DataTable = require("datatables.net");

class ManageEnrollment extends Component {
  state = {
    path: "",
    enrollments: [],
    students: [],
    courses: [],
    loading: false,
    isMobile: false,
    sidebar: true,
    alert_success: "",
    delete_enrollment: "",
  };

  componentDidMount() {
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    document.title = "Manage Enrollment";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        5000
      );
      this.props.history.replace();
    }
    const url = BASE_URL + "/api/admin/enrollment/enrollment_p170815s";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          enrollments: res.data,
        });
        const students_url = BASE_URL + "/api/admin/student/student_p170815s";
        return axios
          .get(students_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              students: res.data,
            });
            const url = BASE_URL + "/api/admin/course/p170815s";
            return axios
              .get(url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  courses: res.data,
                  loading: false,
                });
                $(document).ready(function () {
                  $("#myTable").DataTable({
                    ordering: true,
                  });
                });
              });
          });
      });
  }
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleDelete = (e) => {
    const enrollment_url =
      BASE_URL + `/api/admin/enrollment/enrollment_p170815s/${e}`;
    return axios
      .delete(enrollment_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        // reload added for deleting enrollment
        window.location.reload();
        // this.setState({
        //   delete_enrollment: "Enrollment Deleted Successfully.",
        //   enrollments: res.data,
        // });
        // setTimeout(
        //   function () {
        //     this.setState({ delete_enrollment: "" });
        //   }.bind(this),
        //   3000
        // );
        // const admin_url =
        //   BASE_URL + "/api/admin/enrollment/enrollment_p170815s";
        // return axios
        //   .get(admin_url, {
        //     headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` }
        //   })
        //   .then(res => {
        //     this.setState({
        //       enrollments: res.data
        //     });
        //   });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div>
                  <h4 className="mt-2 mb-4">
                    <i
                      className="fa fa-chevron-right"
                      style={{ fontSize: 20 }}
                    ></i>{" "}
                    Manage Enrollments
                  </h4>
                  <div className="container">
                    {this.state.delete_enrollment ? (
                      <div className="text-center bg-danger text-white p-3 mb-2">
                        {this.state.delete_enrollment}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.alert_success ? (
                      <div className="text-center bg-success text-white p-3 mb-2">
                        {this.state.alert_success}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-2">
                        <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                          All Enrollments
                        </div>

                        {this.state.loading ? (
                          <div className="col-md-12">
                            <>
                              <h1>
                                <Skeleton />
                              </h1>
                              <h3>
                                <Skeleton count={5} />
                              </h3>
                              <Skeleton count={5} />
                            </>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <table
                              class="table table-striped text-dark table-hover"
                              id="myTable"
                            >
                              <thead>
                                <tr>
                                  <th scope="col">S/L</th>
                                  <th scope="col">Avatar</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Course</th>
                                  <th scope="col">Roll</th>
                                  <th scope="col">Contact</th>
                                  <th scope="col">Next Payment</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.enrollments.map(
                                  (enrollment, index) => {
                                    return (
                                      <tr>
                                        <th scope="row" className="text-center">
                                          {index + 1}
                                        </th>
                                        <td>
                                          {this.state.students.map(
                                            (student) => {
                                              if (
                                                enrollment.student_id ==
                                                student.id
                                              ) {
                                                return (
                                                  <img
                                                    src={`${BASE_URL}${`/images/student/${student.image}`}`}
                                                    className="avatar-sm"
                                                    alt="avatar"
                                                  />
                                                );
                                              }
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {this.state.students.map(
                                            (student) => {
                                              if (
                                                enrollment.student_id ==
                                                student.id
                                              ) {
                                                return (
                                                  <span>
                                                    {student.fname}{" "}
                                                    {student.lname}
                                                  </span>
                                                );
                                              }
                                            }
                                          )}
                                        </td>

                                        <td className="text-center">
                                          {this.state.students.map(
                                            (student) => {
                                              if (
                                                enrollment.student_id ==
                                                student.id
                                              ) {
                                                return (
                                                  <span>
                                                    {student.student_type}
                                                  </span>
                                                );
                                              }
                                            }
                                          )}
                                        </td>

                                        <td>
                                          {this.state.courses.map((course) => {
                                            if (
                                              course.id == enrollment.course_id
                                            ) {
                                              return (
                                                <span>{course.title}</span>
                                              );
                                            }
                                          })}
                                        </td>

                                        <td>{enrollment.roll}</td>

                                        <td>
                                          {this.state.students.map(
                                            (student) => {
                                              if (
                                                enrollment.student_id ==
                                                student.id
                                              ) {
                                                return (
                                                  <span>{student.phn_no}</span>
                                                );
                                              }
                                            }
                                          )}
                                        </td>

                                        <td className="text-center">
                                          {!enrollment.second_paid_date
                                            ? enrollment.second_payment_date
                                              ? enrollment.second_payment_date
                                              : "-"
                                            : [
                                                !enrollment.third_paid_date
                                                  ? enrollment.third_payment_date
                                                    ? enrollment.third_payment_date
                                                    : "-"
                                                  : [
                                                      !enrollment.fourth_paid_date
                                                        ? enrollment.fourth_payment_date
                                                          ? enrollment.fourth_payment_date
                                                          : "-"
                                                        : [
                                                            !enrollment.fifth_paid_date
                                                              ? enrollment.fifth_payment_date
                                                                ? enrollment.fifth_payment_date
                                                                : "-"
                                                              : "-",
                                                          ],
                                                    ],
                                              ]}
                                        </td>
                                        <td>
                                          <>
                                            <div className="row  mx-auto">
                                              <NavLink
                                                to={`/admin/enrollment-info/${enrollment.id}`}
                                                className="btn btn-sm btn-primary col"
                                                style={{ borderRadius: "0" }}
                                              >
                                                <i className="fa fa-eye"></i>
                                              </NavLink>
                                              <NavLink
                                                to={`/admin/edit-enrollment/${enrollment.id}`}
                                                className="btn btn-sm btn-secondary col"
                                                style={{ borderRadius: "0" }}
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </NavLink>

                                              {/* MODAL TRIGGER */}
                                              <button
                                                className="btn btn-sm btn-danger col mt-2"
                                                style={{ borderRadius: "0" }}
                                                data-toggle="modal"
                                                data-target={`#myModal${enrollment.id}`}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                              {/* MODAL */}
                                              <div
                                                className="modal"
                                                id={`myModal${enrollment.id}`}
                                              >
                                                <div className="modal-dialog">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5 className="modal-title">
                                                        Are you sure want to
                                                        delete this enrollment?
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        data-dismiss="modal"
                                                      >
                                                        &times;
                                                      </button>
                                                    </div>
                                                    <div className="modal-body">
                                                      <div className="row">
                                                        <div className="col-md-12">
                                                          <h6>
                                                            <b>
                                                              Course Title:{" "}
                                                            </b>{" "}
                                                            {this.state.courses.map(
                                                              (course) => {
                                                                if (
                                                                  course.id ==
                                                                  enrollment.course_id
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {
                                                                        course.title
                                                                      }
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </h6>
                                                          <h6>
                                                            <b>Enrolled By: </b>{" "}
                                                            {this.state.students.map(
                                                              (student) => {
                                                                if (
                                                                  enrollment.student_id ==
                                                                  student.id
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {
                                                                        student.fname
                                                                      }{" "}
                                                                      {
                                                                        student.lname
                                                                      }
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </h6>
                                                          <h6>
                                                            <b>
                                                              Contact Number:{" "}
                                                            </b>{" "}
                                                            {this.state.students.map(
                                                              (student) => {
                                                                if (
                                                                  enrollment.student_id ==
                                                                  student.id
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {
                                                                        student.phn_no
                                                                      }
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </h6>
                                                        </div>
                                                      </div>
                                                      <div className="modal-footer justify-content-around">
                                                        <div className="row ">
                                                          <button
                                                            className="btn btn-danger mr-3"
                                                            onClick={() => {
                                                              this.handleDelete(
                                                                enrollment.id
                                                              );
                                                            }}
                                                            data-dismiss="modal"
                                                          >
                                                            Yes
                                                          </button>
                                                          <button
                                                            className="btn btn-success"
                                                            data-dismiss="modal"
                                                          >
                                                            No
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ManageEnrollment);
