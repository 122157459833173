import axios from "axios";
import { BASE_URL } from "../BaseUrl";

export const _t = (text) => {
  // const url = BASE_URL + `/api/languages`;
  // let formData = {
  //   key: text,
  // };
  // axios.post(url, formData);
  return text;
};
