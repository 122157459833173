import React, { Component } from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero.js";
import Banner from "../components/Banner";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";
import { BASE_URL } from "../BaseUrl";

class AllCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      searchedCourses: [],
      loading: false,
      search: false,
      propsV: null,
      search_query: "",
      timeout: 0,
    };
  }
  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/findCourse/" + searchText;
      if (searchText !== null && searchText !== "") {
        return axios.get(url).then((res) => {
          this.setState({
            search: true,
            searchedCourses: res.data,
          });
        });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    this.setState({
      propsV: this.props.timestamp
    })
    window.scrollTo(0, 0);
    document.title = "All Courses";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/all_courses";
    return axios.get(url).then((res) => {
      this.setState({
        courses: res.data,
        loading: false,
      });
    });
  }

  render() {
    const { t } = this.props;
    return [
      this.state.loading ? (
        <>
          <Hero hero="allCourseHero d-flex align-items-center">
            <Banner title="All Courses" bannerCSS="col-6 mx-auto course-banner">
              <form
                className="col-md-8 offset-md-2"
                onSubmit={this.handleSubmit}
              >
                <div className="input-group">
                  <input
                    type="text"
                    name="search_query"
                    className="form-control text-center"
                    placeholder="Search.."
                    onChange={this.handleSearch}
                  />
                  <div className="input-group-append d-none">
                    <button
                      type="submit"
                      className="input-group-text bg-primary text-white"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </Banner>
          </Hero>
          <div className="container mt-2">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Skeleton className="course-card" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Hero hero="allCourseHero d-flex align-items-center">
            <Banner title="All Courses" bannerCSS="col-6 mx-auto course-banner">
              <form
                className="col-md-8 offset-md-2"
                onSubmit={this.handleSubmit}
              >
                <div className="input-group">
                  <input
                    type="text"
                    name="search_query"
                    className="form-control text-center"
                    placeholder="Search.."
                    onChange={this.handleSearch}
                  />
                  <div className="input-group-append d-none">
                    <button
                      type="submit"
                      className="input-group-text bg-primary text-white"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </Banner>
          </Hero>
          <div className='v2_course-section'>
            <div className="container mt-2">
              <div className="row">
                {!this.state.search
                  ? [
                    this.state.courses.map((item) => {
                      return (
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <div class="course_card h-100">
                            <div class="course_card_img">

                              <img src={`${BASE_URL}${`/images/course/${item.thumbnail}`}`} alt="course" />
                            </div>
                            <div class="course_card_content">

                              <span class="title title-gray d-inline-block mt-4"><i class="fa fa-book" aria-hidden="true"></i> {item.lectures_count} lessons</span>
                              <Link to={`/all-courses/${item.id}`}>
                                <h5 class="title mt-3">{item.title}</h5>
                              </Link>
                              {item.title ? (
                                <p class="description mb-0">{item.description}</p>
                              ) : ''}

                            </div>
                            <div class="course_card_footer">
                              <Link to='/student-registration' className="nav-item btn  button--ujarak position-relative">enroll now</Link></div>
                          </div>
                        </div>

                      );
                    }),
                  ]
                  : [
                    this.state.searchedCourses.length > 0 ? (
                      this.state.searchedCourses.map((searchedItem) => {
                        return (
                          <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div class="course_card h-100">
                              <div class="course_card_img">

                                <img src={`${BASE_URL}${`/images/course/${searchedItem.thumbnail}`}`} alt="course" />
                              </div>
                              <div class="course_card_content">
                                <span class="title title-gray d-inline-block mt-4"><i class="fa fa-book" aria-hidden="true"></i> {searchedItem.lectures_count} lessons</span>
                                <Link to={`/all-courses/${searchedItem.id}`}>
                                  <h5 class="title mt-3">{searchedItem.title}</h5>
                                </Link>
                                {searchedItem.title ? (
                                  <p class="description mb-0">{searchedItem.description}</p>
                                ) : ''}

                              </div>
                              <div class="course_card_footer">
                                <Link to='/student-registration' className="nav-item btn  button--ujarak position-relative">enroll now</Link></div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12 text-center  my-auto">
                        <div className="card card-body bg-light text-danger no-searched-course mb-2">
                          {_t(t("No course found by your query"))}
                        </div>
                      </div>
                    ),
                  ]}
              </div>
            </div>
          </div>
        </>
      ),
    ];
  }
}
export default withTranslation()(AllCourses);
