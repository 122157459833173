import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Sms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      admin_phn_no: "",
      sms: "",
      day: "",
      details: [],
      loading: false,
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "SMS Settings";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });
    const url = BASE_URL + "/api/admin/get_sms_setting_details";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          details: res.data,
          loading: false,
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/admin/sms`;
    const formData = {
      name: this.state.name,
      admin_phn_no: this.state.admin_phn_no,
      sms: this.state.sms,
      day: this.state.day,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          details: res.data,
          name: "",
          admin_phn_no: "",
          sms: "",
          day: "",
          error: [],
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update SMS Settings
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        Sms Details
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-8 offset-2 mb-2">
                              <div className="container">
                                <div className="bg-light">
                                  <table className="table table-striped table-bordered">
                                    <thead></thead>
                                    <tbody>
                                      <tr>
                                        <td>Sender Name</td>
                                        <td>{this.state.details.name}</td>
                                      </tr>
                                      <tr>
                                        <td>Admin's Phone Number</td>
                                        <td>
                                          {this.state.details.admin_phn_no}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Day Before Due Date</td>
                                        <td>{this.state.details.day}</td>
                                      </tr>
                                      <tr>
                                        <td>Text</td>
                                        <td>{this.state.details.sms}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form
                            className="form-group"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="day"
                                  className="col-md-3 my-auto"
                                >
                                  Day before due
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="number"
                                    id="day"
                                    name="day"
                                    placeholder="Type how many days before the payment date to send sms"
                                    className="form-control"
                                    value={this.state.day}
                                    onChange={this.handleChange}
                                    required
                                    min="1"
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.day ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.day.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="name"
                                  className="col-md-3 my-auto"
                                >
                                  SMS Title
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="e.g. SoftTech-IT Instituition"
                                    className="form-control"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.name ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.name.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="admin_phn_no"
                                  className="col-md-3 my-auto"
                                >
                                  Admin Phone No.
                                </label>
                                <div className="col-md-9 pl-0">
                                  <input
                                    type="text"
                                    id="admin_phn_no"
                                    name="admin_phn_no"
                                    placeholder="019 xxxx (admin will get sms to this number)"
                                    className="form-control"
                                    value={this.state.admin_phn_no}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.admin_phn_no ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.admin_phn_no.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-12 input-group mb-2">
                                <label
                                  htmlFor="sms"
                                  className="col-md-3 my-auto"
                                >
                                  Message
                                </label>
                                <div className="col-md-9 pl-0">
                                  <textarea
                                    id="sms"
                                    name="sms"
                                    placeholder="Your message"
                                    className="form-control"
                                    value={this.state.sms}
                                    onChange={this.handleChange}
                                    rows="5"
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.sms ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.sms.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success btn-sm mt-2 px-4"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Sms);
