import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../BaseUrl";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import Cookies from "universal-cookie";
import posImg from "../../images/allCourses/traingle.jpg";
// import Marquee from "react-fast-marquee";

const cookies = new Cookies();

class Singlecoursefile extends Component {
  constructor({ props }) {
    super(props);
    this.state = {
      loading: false,
      files: [],
    };
  }

  componentDidMount() {
    document.title = "Single Course";
    this.setState({
      course_id: this.props.match.params.redirectParam,
      loading: true,
    });

    const url =
      BASE_URL +
      `/api/student/student-wise-files/${this.props.match.params.id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          files: res.data,
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="container padding-top-120 padding-bottom-120">
        <div className="row">
          {this.state.loading ? (
            <div className="container">
              <div className="mt-3">
                <h1>
                  <Skeleton />
                </h1>
                <h3>
                  <Skeleton count={5} />
                </h3>
                <Skeleton count={5} />
              </div>
            </div>
          ) : (
            <>
              {this.state.files.length > 0 ? (
                this.state.files.map((item, index) => {
                  return (
                    <>
                      <div className="col-12 mb-4 text-center">
                        {/* <Marquee> */}
                        <h6>
                          প্লাগিন/থিম ফাইল ডাউনলোড করে সরাসরি আপলোড করবেন না।
                          ডাউনলোড করা জিপ ফাইলটি আনজিপ করে সেটার ভিতরে মেইন
                          থিম/প্লাগিন ফোল্ডারটি জিপ করে আপলোড করবেন।
                        </h6>
                        {/* </Marquee> */}
                      </div>
                      <div className="col-12">
                        <div className="bg-dark text-white px-4 py-2 mb-4">
                          <div className="d-flex justify-content-between">
                            <h6>{item.name}</h6>
                          </div>
                        </div>
                        {item.file_uploads.length > 0
                          ? [
                              item.file_uploads.map((itemFile, subindex) => {
                                return (
                                  <>
                                    <div className="">
                                      <div className="d-flex justify-content-between align-items-center px-4 py-2">
                                        <h6 className="text-capitalize p-0 m-0">
                                          {" "}
                                          <span>{subindex + 1}. </span>
                                          {itemFile.name}
                                        </h6>
                                        <Link
                                          to={{
                                            pathname:
                                              BASE_URL +
                                              `/downloads/${itemFile.file}`,
                                          }}
                                          target="_blank"
                                          className="btn btn-primary"
                                        >
                                          Download
                                        </Link>
                                      </div>
                                      <hr />
                                    </div>
                                  </>
                                );
                              }),
                            ]
                          : ["no files"]}
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="col-lg-12 mt-5 single-notice-box2 card text-center">
                  <img className="posImg" src={posImg} alt="" />
                  <h4>No File found</h4>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Singlecoursefile);
