import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../InstructorSidebar";

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class InstructorViewDiscussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_title: "",
      class: [],
      course: [],
      comments: [],
      searchedComments: [],
      replies: [],
      students: [],
      admins: [],
      instructors: [],
      moderators: [],
      userID: null,
      comment: "",
      reply: "",
      userId: "",
      search: false,
      search_query: "",
      timeout: 0,
      commentBtn: false,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  // Jodit Editor plugin
  updateContent = (value) => {
    if (value !== null && value !== "") {
      this.setState({
        commentBtn: true,
        comment: value,
      });
    } else {
      this.setState({
        commentBtn: false,
      });
    }
  };

  updateReply = (e) => {
    this.setState({
      reply: e,
    });
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };
  // Jodit Editor plugin

  componentDidMount() {
    document.title = "Comments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const url = BASE_URL + "/api/instructor/get_comments/" + id;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data[0],
          students: res.data[1],
          admins: res.data[2],
          instructors: res.data[3],
          moderators: res.data[4],
          class: res.data[5],
          replies: res.data[6],
          course: res.data[7],
          userID: res.data[8],
        });
        if (res.data[5] == null || res.data[7] == null) {
          this.props.history.replace({
            pathname: `/instructor/check-comments`,
            state: {
              alert_danger: "Can not perform the action.",
            },
          });
        }
      })
      .then(() => {
        this.setState({
          loading: false,
        });
        // const seen_url = BASE_URL + "/api/instructor/make_seen/" + id;
        // return axios
        //   .get(seen_url, {
        //     headers: {
        //       Authorization: `Bearer ${cookies.get("instructorToken")}`,
        //     },
        //   })
        //   .then(() => {
        //     this.setState({
        //       loading: false,
        //     });
        //   });
      })
      .catch(() => {
        this.props.history.replace({
          pathname: `/instructor/check-comments`,
          state: {
            alert_danger: "Can not perform the action.",
          },
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/instructor/findComment/" + searchText;
      let formData = {
        add_class_id: this.props.match.params.id,
      };
      if (searchText !== null && searchText !== "") {
        return axios
          .post(url, formData, {
            headers: {
              Authorization: `Bearer ${cookies.get("instructorToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedComments: res.data,
            });
          });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const assignments_url = BASE_URL + `/api/instructor/add_comments`;
    let formData = {
      comment: this.state.comment,
      add_class_id: this.props.match.params.id,
    };
    return axios
      .post(assignments_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data,
          comment: "",
        });
      });
  };

  handleReplySubmit = (e, comment_id) => {
    e.preventDefault();
    let formData = {
      class_id: this.props.match.params.id,
      comment_id: comment_id,
      reply: this.state.reply,
    };
    const url = BASE_URL + "/api/instructor/add_replies";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          replies: res.data,
          reply: "",
        });
      });
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  commentDelete = (id) => {
    const delete_url = BASE_URL + "/api/instructor/delete_comments/" + id;
    let formData = {
      add_class_id: this.props.match.params.id,
    };
    return axios
      .post(delete_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data[0],
          replies: res.data[1],
          search: false,
        });
      });
  };

  replyDelete = (id) => {
    const delete_url = BASE_URL + "/api/instructor/delete_replies/" + id;
    let formData = {
      add_class_id: this.props.match.params.id,
    };
    return axios
      .post(delete_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
      })
      .then((res) => {
        this.setState({
          replies: res.data,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Check Comments
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <Link
                        to="/instructor/check-comments"
                        className="nav-link text-light bg-secondary rounded-sm ml-2"
                        style={{ width: "120px" }}
                      >
                        <i className="fa fa-reply-all"></i> Go Back
                      </Link>
                    </div>
                    {this.state.loading ? (
                      <div className="row text-center">
                        <div className="col-md-12 text-primary">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="mt-3 mb-2 text-justify">
                          <h3
                            className="text-truncate text-primary mb-2"
                            title={this.state.course.title}
                          >
                            Course Title - {this.state.course.title}
                          </h3>
                          <div className="row mb-2 justify-content-center">
                            <h3 className="course-heading text-uppercase border-bottom mb-2">
                              Class Title:{" "}
                              {this.state.class ? this.state.class.title : ""}
                            </h3>
                          </div>
                          <form
                            className="row col-md-6 offset-md-3 mb-4"
                            onSubmit={this.handleSearchSubmit}
                          >
                            <div className="input-group">
                              <input
                                type="text"
                                name="search_query"
                                className="form-control text-center"
                                placeholder="Search.."
                                onChange={this.handleSearch}
                              />
                              <div className="input-group-append d-none">
                                <button
                                  type="submit"
                                  className="input-group-text bg-primary text-white"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </form>
                          <div className="row mb-2">
                            {!this.state.search
                              ? [
                                  this.state.comments.length > 0 ? (
                                    <div className="col-md-12">
                                      {this.state.comments.map((item) => {
                                        return (
                                          <div className="card card-body mb-2">
                                            <div className="d-flex align-items-center">
                                              <div className="avatar">
                                                {item.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            item.student_id
                                                          ) {
                                                            return (
                                                              <img
                                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                alt="dp"
                                                                className="rounded-circle"
                                                                height="80"
                                                                width="80"
                                                              />
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      item.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  item.admin_id
                                                                ) {
                                                                  return (
                                                                    <img
                                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                      alt="dp"
                                                                      className="rounded-circle"
                                                                      height="80"
                                                                      width="80"
                                                                    />
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            item.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        item.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="80"
                                                                            width="80"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        item.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="80"
                                                                            width="80"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                              </div>

                                              <div className="flex-grow-1 pl-4">
                                                {item.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            item.student_id
                                                          ) {
                                                            return (
                                                              <>
                                                                <b>
                                                                  {stu.fname +
                                                                    " " +
                                                                    stu.lname}
                                                                </b>{" "}
                                                                <span>
                                                                  (
                                                                  <Moment format="LLL">
                                                                    {
                                                                      item.created_at
                                                                    }
                                                                  </Moment>
                                                                  )
                                                                </span>
                                                                <i
                                                                  className="fa fa-times ml-2 delete-comment"
                                                                  onClick={() =>
                                                                    this.commentDelete(
                                                                      item.id
                                                                    )
                                                                  }
                                                                  title="Delete Comment"
                                                                ></i>
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      item.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  item.admin_id
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <b>
                                                                        {
                                                                          admin.name
                                                                        }
                                                                      </b>{" "}
                                                                      (
                                                                      <span className="text-primary">
                                                                        Admin
                                                                      </span>
                                                                      ){" "}
                                                                      <span>
                                                                        (
                                                                        <Moment format="LLL">
                                                                          {
                                                                            item.created_at
                                                                          }
                                                                        </Moment>
                                                                        )
                                                                      </span>
                                                                    </>
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            item.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        item.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {instructor.fname +
                                                                                " " +
                                                                                instructor.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              Instructor
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  item.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                            {this
                                                                              .state
                                                                              .userID ==
                                                                            instructor.id ? (
                                                                              <i
                                                                                className="fa fa-times ml-2 delete-comment"
                                                                                onClick={() =>
                                                                                  this.commentDelete(
                                                                                    item.id
                                                                                  )
                                                                                }
                                                                                title="Delete Comment"
                                                                              ></i>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        item.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {moderator.fname +
                                                                                " " +
                                                                                moderator.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              Moderator
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  item.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                                <br />
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.comment,
                                                  }}
                                                />
                                                {/* Nested comments */}
                                              </div>
                                            </div>

                                            <>
                                              {this.state.replies ? (
                                                <div
                                                  className="mt-3"
                                                  style={{
                                                    marginLeft: "90px",
                                                  }}
                                                >
                                                  {this.state.replies.map(
                                                    (reply) => {
                                                      if (
                                                        reply.add_comment_id ==
                                                        item.id
                                                      ) {
                                                        return (
                                                          <>
                                                            <div className="d-flex mb-2 align-items-center card-body bg-light">
                                                              <div className="avatar-sm">
                                                                {reply.student_id
                                                                  ? [
                                                                      this.state.students.map(
                                                                        (
                                                                          stu
                                                                        ) => {
                                                                          if (
                                                                            stu.id ==
                                                                            reply.student_id
                                                                          ) {
                                                                            return (
                                                                              <img
                                                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                                alt="dp"
                                                                                className="rounded-circle"
                                                                                height="60"
                                                                                width="60"
                                                                              />
                                                                            );
                                                                          }
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      reply.admin_id
                                                                        ? [
                                                                            this.state.admins.map(
                                                                              (
                                                                                admin
                                                                              ) => {
                                                                                if (
                                                                                  admin.id ==
                                                                                  reply.admin_id
                                                                                ) {
                                                                                  return (
                                                                                    <img
                                                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                      alt="dp"
                                                                                      className="rounded-circle"
                                                                                      height="60"
                                                                                      width="60"
                                                                                    />
                                                                                  );
                                                                                }
                                                                              }
                                                                            ),
                                                                          ]
                                                                        : [
                                                                            reply.instructor_id
                                                                              ? [
                                                                                  this.state.instructors.map(
                                                                                    (
                                                                                      instructor
                                                                                    ) => {
                                                                                      if (
                                                                                        instructor.id ==
                                                                                        reply.instructor_id
                                                                                      ) {
                                                                                        return (
                                                                                          <img
                                                                                            src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                            alt="dp"
                                                                                            className="rounded-circle"
                                                                                            height="60"
                                                                                            width="60"
                                                                                          />
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              : [
                                                                                  this.state.moderators.map(
                                                                                    (
                                                                                      moderator
                                                                                    ) => {
                                                                                      if (
                                                                                        moderator.id ==
                                                                                        reply.moderator_id
                                                                                      ) {
                                                                                        return (
                                                                                          <img
                                                                                            src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                            alt="dp"
                                                                                            className="rounded-circle"
                                                                                            height="60"
                                                                                            width="60"
                                                                                          />
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                          ],
                                                                    ]}
                                                              </div>
                                                              <div className="flex-grow-1 pl-3">
                                                                {reply.student_id
                                                                  ? [
                                                                      this.state.students.map(
                                                                        (
                                                                          stu
                                                                        ) => {
                                                                          if (
                                                                            stu.id ==
                                                                            reply.student_id
                                                                          ) {
                                                                            return (
                                                                              <>
                                                                                <b>
                                                                                  {stu.fname +
                                                                                    " " +
                                                                                    stu.lname}
                                                                                </b>{" "}
                                                                                <span>
                                                                                  (
                                                                                  <Moment format="LLL">
                                                                                    {
                                                                                      reply.created_at
                                                                                    }
                                                                                  </Moment>

                                                                                  )
                                                                                </span>
                                                                                <i
                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                  onClick={() =>
                                                                                    this.replyDelete(
                                                                                      reply.id
                                                                                    )
                                                                                  }
                                                                                  title="Delete Reply"
                                                                                ></i>
                                                                              </>
                                                                            );
                                                                          }
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      reply.admin_id
                                                                        ? [
                                                                            this.state.admins.map(
                                                                              (
                                                                                admin
                                                                              ) => {
                                                                                if (
                                                                                  admin.id ==
                                                                                  reply.admin_id
                                                                                ) {
                                                                                  return (
                                                                                    <>
                                                                                      <b>
                                                                                        {
                                                                                          admin.name
                                                                                        }
                                                                                      </b>{" "}
                                                                                      (
                                                                                      <span className="text-primary">
                                                                                        Admin
                                                                                      </span>

                                                                                      ){" "}
                                                                                      <span>
                                                                                        (
                                                                                        <Moment format="LLL">
                                                                                          {
                                                                                            reply.created_at
                                                                                          }
                                                                                        </Moment>

                                                                                        )
                                                                                      </span>
                                                                                    </>
                                                                                  );
                                                                                }
                                                                              }
                                                                            ),
                                                                          ]
                                                                        : [
                                                                            reply.instructor_id
                                                                              ? [
                                                                                  this.state.instructors.map(
                                                                                    (
                                                                                      instructor
                                                                                    ) => {
                                                                                      if (
                                                                                        instructor.id ==
                                                                                        reply.instructor_id
                                                                                      ) {
                                                                                        return (
                                                                                          <>
                                                                                            <b>
                                                                                              {instructor.fname +
                                                                                                " " +
                                                                                                instructor.lname}
                                                                                            </b>{" "}
                                                                                            (
                                                                                            <span className="text-primary">
                                                                                              Instructor
                                                                                            </span>

                                                                                            ){" "}
                                                                                            <span>
                                                                                              (
                                                                                              <Moment format="LLL">
                                                                                                {
                                                                                                  reply.created_at
                                                                                                }
                                                                                              </Moment>

                                                                                              )
                                                                                            </span>
                                                                                            {this
                                                                                              .state
                                                                                              .userID ==
                                                                                            instructor.id ? (
                                                                                              <i
                                                                                                className="fa fa-times ml-2 delete-comment"
                                                                                                onClick={() =>
                                                                                                  this.replyDelete(
                                                                                                    reply.id
                                                                                                  )
                                                                                                }
                                                                                                title="Delete Reply"
                                                                                              ></i>
                                                                                            ) : (
                                                                                              ""
                                                                                            )}
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              : [
                                                                                  this.state.moderators.map(
                                                                                    (
                                                                                      moderator
                                                                                    ) => {
                                                                                      if (
                                                                                        moderator.id ==
                                                                                        reply.moderator_id
                                                                                      ) {
                                                                                        return (
                                                                                          <>
                                                                                            <b>
                                                                                              {moderator.fname +
                                                                                                " " +
                                                                                                moderator.lname}
                                                                                            </b>{" "}
                                                                                            (
                                                                                            <span className="text-primary">
                                                                                              Moderator
                                                                                            </span>

                                                                                            ){" "}
                                                                                            <span>
                                                                                              (
                                                                                              <Moment format="LLL">
                                                                                                {
                                                                                                  reply.created_at
                                                                                                }
                                                                                              </Moment>

                                                                                              )
                                                                                            </span>
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                          ],
                                                                    ]}
                                                                <br />
                                                                <div
                                                                  dangerouslySetInnerHTML={{
                                                                    __html:
                                                                      reply.reply,
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div>
                                                <p className="text-center">
                                                  <span
                                                    className="btn btn-warning btn-sm"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target={`#collapseExample${item.id}`}
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample"
                                                  >
                                                    <i className="fa fa-reply"></i>{" "}
                                                    Click here to reply
                                                  </span>
                                                </p>
                                                <div
                                                  className="collapse col-md-10 offset-md-1"
                                                  id={`collapseExample${item.id}`}
                                                >
                                                  <form
                                                    className="form-group"
                                                    onSubmit={(e) => {
                                                      this.handleReplySubmit(
                                                        e,
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    <h4 className="text-left">
                                                      Reply
                                                    </h4>
                                                    <JoditEditor
                                                      editorRef={this.setRef}
                                                      value={this.state.reply}
                                                      config={this.config}
                                                      onChange={
                                                        this.updateReply
                                                      }
                                                    />
                                                    <div className="text-left">
                                                      <button
                                                        type="submit"
                                                        className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                                      >
                                                        Add Reply
                                                      </button>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div
                                      className="col-md-6 offset-3 card card-body bg-light"
                                      style={{ minHeight: "120px" }}
                                    >
                                      <div className="text-center my-auto">
                                        <div className="text-center">
                                          <h3 className="bg-light text-danger px-5 mt-2">
                                            No comments yet!
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                ]
                              : [
                                  this.state.searchedComments.length > 0 ? (
                                    <div className="col-md-12">
                                      {this.state.searchedComments.map(
                                        (item) => {
                                          return (
                                            <div className="card card-body mb-2">
                                              <div className="d-flex align-items-center">
                                                <div className="avatar">
                                                  {item.student_id
                                                    ? [
                                                        this.state.students.map(
                                                          (stu) => {
                                                            if (
                                                              stu.id ==
                                                              item.student_id
                                                            ) {
                                                              return (
                                                                <img
                                                                  src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                  alt="dp"
                                                                  className="rounded-circle"
                                                                  height="80"
                                                                  width="80"
                                                                />
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        item.admin_id
                                                          ? [
                                                              this.state.admins.map(
                                                                (admin) => {
                                                                  if (
                                                                    admin.id ==
                                                                    item.admin_id
                                                                  ) {
                                                                    return (
                                                                      <img
                                                                        src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                        alt="dp"
                                                                        className="rounded-circle"
                                                                        height="80"
                                                                        width="80"
                                                                      />
                                                                    );
                                                                  }
                                                                }
                                                              ),
                                                            ]
                                                          : [
                                                              item.instructor_id
                                                                ? [
                                                                    this.state.instructors.map(
                                                                      (
                                                                        instructor
                                                                      ) => {
                                                                        if (
                                                                          instructor.id ==
                                                                          item.instructor_id
                                                                        ) {
                                                                          return (
                                                                            <img
                                                                              src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                              alt="dp"
                                                                              className="rounded-circle"
                                                                              height="80"
                                                                              width="80"
                                                                            />
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    this.state.moderators.map(
                                                                      (
                                                                        moderator
                                                                      ) => {
                                                                        if (
                                                                          moderator.id ==
                                                                          item.moderator_id
                                                                        ) {
                                                                          return (
                                                                            <img
                                                                              src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                              alt="dp"
                                                                              className="rounded-circle"
                                                                              height="80"
                                                                              width="80"
                                                                            />
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ],
                                                            ],
                                                      ]}
                                                </div>

                                                <div className="flex-grow-1 pl-4">
                                                  {item.student_id
                                                    ? [
                                                        this.state.students.map(
                                                          (stu) => {
                                                            if (
                                                              stu.id ==
                                                              item.student_id
                                                            ) {
                                                              return (
                                                                <>
                                                                  <b>
                                                                    {stu.fname +
                                                                      " " +
                                                                      stu.lname}
                                                                  </b>{" "}
                                                                  <span>
                                                                    (
                                                                    <Moment format="LLL">
                                                                      {
                                                                        item.created_at
                                                                      }
                                                                    </Moment>
                                                                    )
                                                                  </span>
                                                                  <i
                                                                    className="fa fa-times ml-2 delete-comment"
                                                                    onClick={() =>
                                                                      this.commentDelete(
                                                                        item.id
                                                                      )
                                                                    }
                                                                    title="Delete Comment"
                                                                  ></i>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        item.admin_id
                                                          ? [
                                                              this.state.admins.map(
                                                                (admin) => {
                                                                  if (
                                                                    admin.id ==
                                                                    item.admin_id
                                                                  ) {
                                                                    return (
                                                                      <>
                                                                        <b>
                                                                          {
                                                                            admin.name
                                                                          }
                                                                        </b>{" "}
                                                                        (
                                                                        <span className="text-primary">
                                                                          Admin
                                                                        </span>
                                                                        ){" "}
                                                                        <span>
                                                                          (
                                                                          <Moment format="LLL">
                                                                            {
                                                                              item.created_at
                                                                            }
                                                                          </Moment>
                                                                          )
                                                                        </span>
                                                                      </>
                                                                    );
                                                                  }
                                                                }
                                                              ),
                                                            ]
                                                          : [
                                                              item.instructor_id
                                                                ? [
                                                                    this.state.instructors.map(
                                                                      (
                                                                        instructor
                                                                      ) => {
                                                                        if (
                                                                          instructor.id ==
                                                                          item.instructor_id
                                                                        ) {
                                                                          return (
                                                                            <>
                                                                              <b>
                                                                                {instructor.fname +
                                                                                  " " +
                                                                                  instructor.lname}
                                                                              </b>{" "}
                                                                              (
                                                                              <span className="text-primary">
                                                                                Instructor
                                                                              </span>
                                                                              ){" "}
                                                                              <span>
                                                                                (
                                                                                <Moment format="LLL">
                                                                                  {
                                                                                    item.created_at
                                                                                  }
                                                                                </Moment>

                                                                                )
                                                                              </span>
                                                                              {this
                                                                                .state
                                                                                .userID ==
                                                                              instructor.id ? (
                                                                                <i
                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                  onClick={() =>
                                                                                    this.commentDelete(
                                                                                      item.id
                                                                                    )
                                                                                  }
                                                                                  title="Delete Comment"
                                                                                ></i>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </>
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    this.state.moderators.map(
                                                                      (
                                                                        moderator
                                                                      ) => {
                                                                        if (
                                                                          moderator.id ==
                                                                          item.moderator_id
                                                                        ) {
                                                                          return (
                                                                            <>
                                                                              <b>
                                                                                {moderator.fname +
                                                                                  " " +
                                                                                  moderator.lname}
                                                                              </b>{" "}
                                                                              (
                                                                              <span className="text-primary">
                                                                                Moderator
                                                                              </span>
                                                                              ){" "}
                                                                              <span>
                                                                                (
                                                                                <Moment format="LLL">
                                                                                  {
                                                                                    item.created_at
                                                                                  }
                                                                                </Moment>

                                                                                )
                                                                              </span>
                                                                            </>
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ],
                                                            ],
                                                      ]}
                                                  <br />
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.comment,
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* Nested comments */}
                                              <>
                                                {this.state.replies ? (
                                                  <div
                                                    className="mt-3"
                                                    style={{
                                                      marginLeft: "90px",
                                                    }}
                                                  >
                                                    {this.state.replies.map(
                                                      (reply) => {
                                                        if (
                                                          reply.add_comment_id ==
                                                          item.id
                                                        ) {
                                                          return (
                                                            <>
                                                              <div className="d-flex mb-2 align-items-center card-body bg-light">
                                                                <div className="avatar-sm">
                                                                  {reply.student_id
                                                                    ? [
                                                                        this.state.students.map(
                                                                          (
                                                                            stu
                                                                          ) => {
                                                                            if (
                                                                              stu.id ==
                                                                              reply.student_id
                                                                            ) {
                                                                              return (
                                                                                <img
                                                                                  src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                                  alt="dp"
                                                                                  className="rounded-circle"
                                                                                  height="60"
                                                                                  width="60"
                                                                                />
                                                                              );
                                                                            }
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        reply.admin_id
                                                                          ? [
                                                                              this.state.admins.map(
                                                                                (
                                                                                  admin
                                                                                ) => {
                                                                                  if (
                                                                                    admin.id ==
                                                                                    reply.admin_id
                                                                                  ) {
                                                                                    return (
                                                                                      <img
                                                                                        src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                        alt="dp"
                                                                                        className="rounded-circle"
                                                                                        height="60"
                                                                                        width="60"
                                                                                      />
                                                                                    );
                                                                                  }
                                                                                }
                                                                              ),
                                                                            ]
                                                                          : [
                                                                              reply.instructor_id
                                                                                ? [
                                                                                    this.state.instructors.map(
                                                                                      (
                                                                                        instructor
                                                                                      ) => {
                                                                                        if (
                                                                                          instructor.id ==
                                                                                          reply.instructor_id
                                                                                        ) {
                                                                                          return (
                                                                                            <img
                                                                                              src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                              alt="dp"
                                                                                              className="rounded-circle"
                                                                                              height="60"
                                                                                              width="60"
                                                                                            />
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    this.state.moderators.map(
                                                                                      (
                                                                                        moderator
                                                                                      ) => {
                                                                                        if (
                                                                                          moderator.id ==
                                                                                          reply.moderator_id
                                                                                        ) {
                                                                                          return (
                                                                                            <img
                                                                                              src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                              alt="dp"
                                                                                              className="rounded-circle"
                                                                                              height="60"
                                                                                              width="60"
                                                                                            />
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                            ],
                                                                      ]}
                                                                </div>
                                                                <div className="flex-grow-1 pl-3">
                                                                  {reply.student_id
                                                                    ? [
                                                                        this.state.students.map(
                                                                          (
                                                                            stu
                                                                          ) => {
                                                                            if (
                                                                              stu.id ==
                                                                              reply.student_id
                                                                            ) {
                                                                              return (
                                                                                <>
                                                                                  <b>
                                                                                    {stu.fname +
                                                                                      " " +
                                                                                      stu.lname}
                                                                                  </b>{" "}
                                                                                  <span>
                                                                                    (
                                                                                    <Moment format="LLL">
                                                                                      {
                                                                                        reply.created_at
                                                                                      }
                                                                                    </Moment>

                                                                                    )
                                                                                  </span>
                                                                                  <i
                                                                                    className="fa fa-times ml-2 delete-comment"
                                                                                    onClick={() =>
                                                                                      this.replyDelete(
                                                                                        reply.id
                                                                                      )
                                                                                    }
                                                                                    title="Delete Reply"
                                                                                  ></i>
                                                                                </>
                                                                              );
                                                                            }
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        reply.admin_id
                                                                          ? [
                                                                              this.state.admins.map(
                                                                                (
                                                                                  admin
                                                                                ) => {
                                                                                  if (
                                                                                    admin.id ==
                                                                                    reply.admin_id
                                                                                  ) {
                                                                                    return (
                                                                                      <>
                                                                                        <b>
                                                                                          {
                                                                                            admin.name
                                                                                          }
                                                                                        </b>{" "}
                                                                                        (
                                                                                        <span className="text-primary">
                                                                                          Admin
                                                                                        </span>

                                                                                        ){" "}
                                                                                        <span>
                                                                                          (
                                                                                          <Moment format="LLL">
                                                                                            {
                                                                                              reply.created_at
                                                                                            }
                                                                                          </Moment>

                                                                                          )
                                                                                        </span>
                                                                                      </>
                                                                                    );
                                                                                  }
                                                                                }
                                                                              ),
                                                                            ]
                                                                          : [
                                                                              reply.instructor_id
                                                                                ? [
                                                                                    this.state.instructors.map(
                                                                                      (
                                                                                        instructor
                                                                                      ) => {
                                                                                        if (
                                                                                          instructor.id ==
                                                                                          reply.instructor_id
                                                                                        ) {
                                                                                          return (
                                                                                            <>
                                                                                              <b>
                                                                                                {instructor.fname +
                                                                                                  " " +
                                                                                                  instructor.lname}
                                                                                              </b>{" "}
                                                                                              (
                                                                                              <span className="text-primary">
                                                                                                Instructor
                                                                                              </span>

                                                                                              ){" "}
                                                                                              <span>
                                                                                                (
                                                                                                <Moment format="LLL">
                                                                                                  {
                                                                                                    reply.created_at
                                                                                                  }
                                                                                                </Moment>

                                                                                                )
                                                                                              </span>
                                                                                              {this
                                                                                                .state
                                                                                                .userID ==
                                                                                              instructor.id ? (
                                                                                                <i
                                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                                  onClick={() =>
                                                                                                    this.replyDelete(
                                                                                                      reply.id
                                                                                                    )
                                                                                                  }
                                                                                                  title="Delete Reply"
                                                                                                ></i>
                                                                                              ) : (
                                                                                                ""
                                                                                              )}
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    this.state.moderators.map(
                                                                                      (
                                                                                        moderator
                                                                                      ) => {
                                                                                        if (
                                                                                          moderator.id ==
                                                                                          reply.moderator_id
                                                                                        ) {
                                                                                          return (
                                                                                            <>
                                                                                              <b>
                                                                                                {moderator.fname +
                                                                                                  " " +
                                                                                                  moderator.lname}
                                                                                              </b>{" "}
                                                                                              (
                                                                                              <span className="text-primary">
                                                                                                Moderator
                                                                                              </span>

                                                                                              ){" "}
                                                                                              <span>
                                                                                                (
                                                                                                <Moment format="LLL">
                                                                                                  {
                                                                                                    reply.created_at
                                                                                                  }
                                                                                                </Moment>

                                                                                                )
                                                                                              </span>
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                            ],
                                                                      ]}
                                                                  <br />
                                                                  <div
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        reply.reply,
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                <div>
                                                  <p className="text-center">
                                                    <span
                                                      className="btn btn-warning btn-sm"
                                                      type="button"
                                                      data-toggle="collapse"
                                                      data-target={`#collapseExample${item.id}`}
                                                      aria-expanded="false"
                                                      aria-controls="collapseExample"
                                                    >
                                                      <i className="fa fa-reply"></i>{" "}
                                                      Click here to reply
                                                    </span>
                                                  </p>
                                                  <div
                                                    className="collapse col-md-10 offset-md-1"
                                                    id={`collapseExample${item.id}`}
                                                  >
                                                    <form
                                                      className="form-group"
                                                      onSubmit={(e) => {
                                                        this.handleReplySubmit(
                                                          e,
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <h4 className="text-left">
                                                        Reply
                                                      </h4>
                                                      <JoditEditor
                                                        editorRef={this.setRef}
                                                        value={this.state.reply}
                                                        config={this.config}
                                                        onChange={
                                                          this.updateReply
                                                        }
                                                      />
                                                      <div className="text-left">
                                                        <button
                                                          type="submit"
                                                          className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                                        >
                                                          Add Reply
                                                        </button>
                                                      </div>
                                                    </form>
                                                  </div>
                                                </div>
                                              </>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="col-md-12 card card-body bg-light"
                                      style={{ minHeight: "120px" }}
                                    >
                                      <div className="text-center my-auto">
                                        <div className="text-center">
                                          <h3 className="bg-light text-danger mt-2">
                                            No comments yet!
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                ]}
                          </div>
                          <div className="row mb-2">
                            <h4 className="col-md-10 offset-md-1 course-heading text-uppercase text-center my-2 py-1 rounded-sm">
                              Add New Comment
                            </h4>
                          </div>
                          <form className="row" onSubmit={this.handleSubmit}>
                            <div className="col-md-10 offset-md-1">
                              <JoditEditor
                                editorRef={this.setRef}
                                value={this.state.comment}
                                config={this.config}
                                onChange={this.updateContent}
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-block mt-2 text-uppercase col-md-12 mb-2"
                              disabled={!this.state.commentBtn}
                            >
                              Add comment
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(InstructorViewDiscussion);
