import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
// components
import Sidebar from "../../../Sidebar";
// jodit 
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class EditContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      titles: [],
      addInputField: [[]],
      source: null,
      path: "",
      class: [],
      course: [],
      upload: false,
      error: [],
      isMobile: false,
      sidebar: true,
      loading: false,
      content_upload: false,
      addInstructions: ''
    };
  }
  componentDidMount() {
    document.title = `Update Class Contents`;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );

    const class_url =
      BASE_URL +
      `/api/admin/class/p20081994s/${this.props.match.params.classid}`;
    return axios
      .get(class_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          class: res.data,
        });
        const course_url =
          BASE_URL + `/api/admin/course/p170815s/${res.data.course_id}`;
        return axios
          .get(course_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              course: res.data,
            });
            const contents_url =
              BASE_URL +
              `/api/admin/content/course_content_p20081994s/${res.data.id}`;
            return axios
              .get(contents_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                let this_course_contents = res.data.filter((item) => {
                  return item.add_class_id == this.props.match.params.classid;
                });

                let tempInputField = [];
                this_course_contents.map((item) => {
                  return tempInputField.push(item);
                });

                let tempLinks = [];
                this_course_contents.map((item) => {
                  return tempLinks.push("https://vimeo.com/" + item.video_id);
                });

                let tempTitles = [];
                this_course_contents.map((item) => {
                  return tempTitles.push(item.title);
                });

                let tempInstructions = '';
                this_course_contents.map((item) => {
                  return tempInstructions = item.instructions !== null ? item.instructions : ' ';

                });

                this.setState({
                  addInputField: tempInputField,
                  links: tempLinks,
                  titles: tempTitles,
                  loading: false,
                  addInstructions: tempInstructions
                });
              });
          });
      })
      .catch(() => {
        console.clear();
      });
  }

  // Jodit Editor plugin
  updateContent = (value) => {
    this.setState({
      addInstructions: value,
    });
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };
  // Jodit Editor plugin
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  addField = (e) => {
    this.setState({
      addInputField: [...this.state.addInputField, []],
      links: [...this.state.links, []],
      titles: [...this.state.titles, []],
    });
  };

  handleLinks = (e, index) => {
    this.state.links[index] = e.target.value;
    this.setState({
      links: this.state.links,
    });
  };

  handleTitles = (event, index) => {
    this.state.titles[index] = event.target.value;
    this.setState({
      titles: this.state.titles,
    });
  };

  removeField = (inputField) => {
    let index = this.state.addInputField.indexOf(inputField);
    if (index > -1 && this.state.addInputField.length > 1) {
      let inputsFilter = this.state.addInputField.filter((item, inputIndex) => {
        return inputIndex !== index;
      });

      let linksFilter = this.state.links.filter((item, linkIndex) => {
        return linkIndex !== index;
      });

      let titlesFilter = this.state.titles.filter((item, titleIndex) => {
        return titleIndex !== index;
      });

      this.setState({
        addInputField: inputsFilter,
        links: linksFilter,
        titles: titlesFilter,
      });
    }
  };

  handleSource = (e) => {
    this.setState({
      source: e.target.files[0],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      upload: true,
      uploadForm: true,
      content_upload: true,
    });
    const content_url =
      BASE_URL + `/api/admin/content/update_course_content_p20081994s`;
    let formData = new FormData();
    this.state.links.forEach((item) => {
      formData.append("links[]", item);
    });
    this.state.titles.forEach((item) => {
      formData.append("titles[]", item);
    });
    formData.append("source", this.state.source || "");
    formData.append("course_id", this.state.course.id);
    formData.append("add_class_id", this.state.class.id);
    formData.append("instructions", this.state.addInstructions);
    return axios
      .post(content_url, formData, {
        headers: {
          Authorization: `Bearer  ${cookies.get("adminToken")}`,
        },
      })
      .then((res) => {
        this.setState({
          upload: false,
          content_upload: false,
        });
        this.props.history.push({
          pathname: `/admin/course/manage-classes/${this.state.course.id}`,
          state: { alert_success: "Contents Updated Successfully" },
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          upload: false,
          uploadForm: false,
          content_upload: false,
        });
      });
  };
  handleProgressbar = () => {
    this.setState({
      uploadForm: false,
    });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                  classid={this.props.match.params.classid}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              {this.state.loading ? (
                <div className="container">
                  <div className="mt-3">
                    <>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h3>
                        <Skeleton count={5} />
                      </h3>
                      <Skeleton count={5} />
                    </>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row mt-1">
                    <span>
                      {this.state.course.title ? (
                        <>
                          <Link
                            to={`/admin/course-info/${this.state.course.id}`}
                            className="nav-link text-dark pr-0"
                          >
                            <h4>
                              <i
                                className="fa fa-chevron-right"
                                style={{ fontSize: 20 }}
                              ></i>{" "}
                              {this.state.course.title}
                            </h4>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="#" className="nav-link text-dark pr-0">
                            <h4 className="text-danger">
                              <i
                                className="fa fa-chevron-right"
                                style={{ fontSize: 20 }}
                              ></i>{" "}
                              {"Course not found to assign class"}
                            </h4>
                          </Link>
                        </>
                      )}
                    </span>
                    <span>
                      {this.state.class.title ? (
                        <>
                          <Link
                            to={`/admin/course/manage-classes/${this.state.course.id}`}
                            className="nav-link text-dark pr-0"
                          >
                            <h4>
                              <i
                                className="fa fa-chevron-right"
                                style={{ fontSize: 20 }}
                              ></i>{" "}
                              {this.state.class.title}
                            </h4>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="#" className="nav-link text-dark pr-0">
                            <h4 className="text-danger">
                              <i
                                className="fa fa-chevron-right"
                                style={{ fontSize: 20 }}
                              ></i>{" "}
                              {"Class not found to assign contents"}
                            </h4>
                          </Link>
                        </>
                      )}
                    </span>
                    <span>
                      <Link to="#" className="nav-link text-dark">
                        <h4>
                          {this.state.class.title ? (
                            <>
                              <i
                                className="fa fa-chevron-right"
                                style={{ fontSize: 20 }}
                              ></i>{" "}
                              Update Contents
                            </>
                          ) : (
                            ""
                          )}
                        </h4>
                      </Link>
                    </span>
                  </div>

                  <div className="row">
                    <div className="container">
                      <div className="row">
                        <div className="container">
                          <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-3">
                            <div className="card-header bg-primary text-center text-light text-uppercase mb-4">
                              Please fill the form accordingly
                            </div>
                            {this.state.content_upload ? (
                              <div className="col-md-8 offset-md-2 text-primary">
                                <h1>
                                  <Skeleton />
                                </h1>
                                <h3>
                                  <Skeleton count={5} />
                                </h3>
                                <Skeleton count={5} />
                              </div>
                            ) : (
                              <form
                                className="form-group"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="col-md-6 offset-md-3 py-1 text-center alert alert-info">
                                  Video's Link & Title
                                </div>
                                {this.state.addInputField.map(
                                  (inputField, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="input-group col-md-10 offset-md-1 my-2"
                                      >
                                        <div className="col-md-8 offset-md-2">
                                          <button
                                            type="button"
                                            className="bg-danger text-white border-0 rounded-sm py-2 px-3 ml-2"
                                            onClick={() => {
                                              this.removeField(inputField);
                                            }}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                        <div className="input-group col-md-12 mt-3">
                                          <label
                                            htmlFor="links"
                                            className="my-auto col-md-3 text-right"
                                          >
                                            <b>Link</b>
                                          </label>
                                          <input
                                            type="url"
                                            id="links"
                                            className="form-control col-md-6 border"
                                            name="links[]"
                                            multiple
                                            required
                                            value={this.state.links[index]}
                                            onChange={(e) => {
                                              this.handleLinks(e, index);
                                            }}
                                            placeholder="e.g. https://vimeo.com/395635579"
                                          />
                                        </div>
                                        <div className="input-group col-md-12 mt-2 mb-2">
                                          <label
                                            htmlFor="titles"
                                            className="my-auto col-md-3 text-right"
                                          >
                                            <b>Title</b>
                                          </label>
                                          <input
                                            type="text"
                                            id="titles"
                                            className="form-control col-md-6 border"
                                            name="titles[]"
                                            multiple
                                            required
                                            value={this.state.titles[index]}
                                            onChange={(event) => {
                                              this.handleTitles(event, index);
                                            }}
                                            placeholder="Please insert the video title"
                                          />
                                        </div>
                                        <div className="d-flex justify-content-center col-md-8 offset-md-2 mt-1 mb-2 "></div>
                                      </div>
                                    );
                                  }
                                )}

                                <div className="d-flex justify-content-center mb-3">
                                  <div className="mx-1">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success px-3"
                                      onClick={this.addField}
                                      title="Add new field"
                                    >
                                      {this.state.addInputField.length + 1} ?
                                    </button>
                                  </div>
                                </div>

                                <div className="col-md-6 offset-md-3 py-1 text-center alert alert-warning mt-2">
                                  <label htmlFor="source" className="my-auto">
                                    <b>Source Code</b>
                                  </label>
                                </div>
                                {/* Error or Success Message */}
                                {this.state.error.source ? (
                                  <div className="text-center bg-danger text-white p-3 mb-2">
                                    {this.state.error.source}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* Error or Success Message */}
                                <div className="input-group col-md-6 offset-md-3">
                                  <input
                                    type="file"
                                    id="source"
                                    className="form-control-file pl-0 border"
                                    name="source"
                                    onChange={this.handleSource}
                                  />
                                </div>
                                <div className="col-md-8 offset-md-2 mt-4">
                                  <JoditEditor
                                    editorRef={this.setRef}
                                    value={this.state.addInstructions}
                                    config={this.config}
                                    onChange={this.updateContent}
                                  />
                                </div>
                                <div className="col-md-8 offset-md-2">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm btn-block mt-4 px-4"
                                    disabled={!this.state.class.id}
                                  >
                                    Update Class Contents
                                  </button>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(EditContents);
