import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";
import { BASE_URL } from "../BaseUrl";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caption: [],
      sliders: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/slider_image";
    return axios.get(url).then((res) => {
      this.setState({
        sliders: res.data[0] ? res.data[0] : [],
        caption: res.data[1] ? res.data[1] : [],
        loading: false,
      });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.loading ? (
          <div className="slider-height">
            <div className="col-md-8 offset-md-2 mt-4 p-4">
              <h1>
                <Skeleton count={3} />
              </h1>
              <h3>
                <Skeleton count={10} />
              </h3>
              <Skeleton count={10} />
            </div>
          </div>
        ) : (
          <div
            id="sliderImage"
            className="carousel slide"
            data-ride="carousel slider-height"
          >
            <ol className="carousel-indicators">
              {this.state.sliders.map((item, index) => {
                return (
                  <li
                    data-target="#sliderImage"
                    data-slide-to={index}
                    className={`${
                      this.state.sliders[0].id === item.id ? "active" : ""
                    }`}
                  ></li>
                );
              })}
            </ol>
            <div className="carousel-inner">
              {this.state.sliders.map((item) => {
                return (
                  <div
                    className={`carousel-item ${
                      this.state.sliders[0].id === item.id ? "active" : ""
                    }`}
                  >
                    <img
                      src={`${BASE_URL}${`/images/sliders/${item.image}`}`}
                      className="d-block w-100 slider-height"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block banner">
                      <h3 className="text-capitalize">
                        {this.state.caption.heading}
                      </h3>
                      <p>{this.state.caption.description}</p>
                      <Link
                        to="/all-courses"
                        className="btn btn-primary text-uppercase"
                      >
                        {_t(t("View All Courses"))}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <a
              className="carousel-control-prev"
              href="#sliderImage"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#sliderImage"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(Slider);
