import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class EditClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content_about: "",
      path: "",
      class: [],
      error: [],
      path_id: "",
      isMobile: false,
      sidebar: true,
      loading: false,
    };
  }
  componentDidMount() {
    document.title = `Edit Class`;
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      path_id: id,
      loading: true,
    });

    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }

    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );

    const class_url =
      BASE_URL +
      `/api/admin/class/p20081994s/${this.props.match.params.classid}`;
    return axios
      .get(class_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          class: res.data,
          title: res.data.title,
          content_about: res.data.content_about,
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url =
      BASE_URL +
      `/api/admin/class/p20081994s/${this.props.match.params.classid}`;
    const formData = {
      title: this.state.title,
      content_about: this.state.content_about,
    };
    return axios
      .patch(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.setState({
          title: "",
          content_about: "",
          error: [],
          loading: false,
        });
        this.props.history.push({
          pathname: `/admin/course/manage-classes/${this.props.match.params.id}`,
          state: {
            alert_success: "Class informations updated successfully",
          },
        });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.errors,
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.state.path_id}
                  classid={this.props.match.params.classid}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row mt-1">
                  {this.state.loading ? (
                    ""
                  ) : (
                    <>
                      <span>
                        {this.state.class.title ? (
                          <>
                            <div className="nav-link text-dark pr-0">
                              <h4>
                                <i
                                  className="fa fa-chevron-right"
                                  style={{ fontSize: 20 }}
                                ></i>{" "}
                                Update{" "}
                                <span className="text-primary">
                                  {this.state.class.title}
                                </span>
                                's Information
                              </h4>
                            </div>
                          </>
                        ) : (
                          <>
                            <Link to="#" className="nav-link text-dark pr-0">
                              <h4 className="text-danger">
                                <i
                                  className="fa fa-chevron-right"
                                  style={{ fontSize: 20 }}
                                ></i>{" "}
                                {"Class not found"}
                              </h4>
                            </Link>
                          </>
                        )}
                      </span>
                    </>
                  )}
                </div>

                {this.state.loading ? (
                  <>
                    <h1>
                      <Skeleton />
                    </h1>
                    <h3>
                      <Skeleton count={5} />
                    </h3>
                    <Skeleton count={5} />
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="container">
                        {/* Error or Success Message */}
                        {this.state.alert_success ? (
                          <div className="text-center bg-success text-white p-3 mb-2">
                            {this.state.alert_success}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* Error or Success Message */}
                        <div className="row">
                          <div className="container">
                            <div className="card card-body bg-light col-md-10 offset-md-1 pt-3">
                              <div className="row pl-3">
                                <Link
                                  to={`/admin/course/manage-classes/${this.props.match.params.id}`}
                                  className="nav-link text-light bg-secondary mb-2 rounded-sm"
                                  style={{ width: "120px" }}
                                >
                                  <i className="fa fa-reply-all"></i> Go Back
                                </Link>
                              </div>
                              <div className="card-header bg-primary text-center text-light text-uppercase mb-4">
                                Please fill the form accordingly
                              </div>
                              <form
                                className="form-group"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="input-group col-md-12">
                                  <label
                                    htmlFor="title"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Class Name</b>
                                  </label>
                                  <input
                                    type="text"
                                    id="title"
                                    className="form-control col-md-9 rounded-sm"
                                    name="title"
                                    placeholder="Enter the class name"
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.title}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="content_about"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Content About</b>
                                  </label>
                                  <input
                                    type="text"
                                    id="content_about"
                                    className="form-control col-md-9  rounded-sm"
                                    name="content_about"
                                    placeholder="Enter the main concept about this class"
                                    value={this.state.content_about}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-3">
                                    {this.state.error.content_about}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="text-center col">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm mt-3 px-4"
                                  >
                                    Update
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(EditClass);
