import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";

const cookies = new Cookies();

const MAX_LENGTH = 18;

class CourseForum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      course_title: null,
      classes: [],
      searchedClasses: [],
      loading: false,
      search: false,
      search_query: "",
      timeout: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Problems";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/student/forum/" + this.props.match.params.id;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          course: res.data[0],
          classes: res.data[1],
          course_title: res.data[0] ? res.data[0][0].title : null,
          loading: false,
        });
        if (res.data[2].is_active === 0) {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload(this.props.history.replace("/"));
        } else {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/student/findClasses/" + searchText;
      let formData = {
        course_id: this.props.match.params.id,
      };
      if (searchText !== null && searchText !== "") {
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedClasses: res.data,
            });
          });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Hero hero="courseForumHero  d-flex align-items-center">
          <Banner
            title={
              <div title={"Discussion of " + this.state.course_title}>
                {this.state.loading ? (
                  <Skeleton className="bg-primary mb-2" width="50%" />
                ) : (
                  <h4 className="text-truncate">{this.state.course_title}</h4>
                )}

                <h5 className="text-truncate">
                  {_t(t("Click on class below"))}
                </h5>
              </div>
            }
            bannerCSS="col-6 mx-auto text-center assignmentCourse-banner"
          >
            <form className="col-md-6 offset-md-3" onSubmit={this.handleSubmit}>
              <div className="input-group">
                <input
                  type="text"
                  name="search_query"
                  className="form-control text-center"
                  placeholder="Search.."
                  onChange={this.handleSearch}
                />
                <div className="input-group-append d-none">
                  <button
                    type="submit"
                    className="input-group-text bg-primary text-white"
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </form>
            <Link to="/forum">
              <button type="button" className="btn btn-sm btn-primary px-5">
                {_t(t("Go Back"))}
              </button>
            </Link>
          </Banner>
        </Hero>
        <div className="container mt-3">
          {this.state.loading ? (
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <Skeleton className="class-card" />
              </div>
            </div>
          ) : (
            <div className="row text-center">
              {this.state.classes == null
                ? this.props.history.replace({
                  pathname: `/forum`,
                })
                : [
                  !this.state.search
                    ? [
                      this.state.classes.map((item, index) => {
                        return (
                          <div className="col-md-4">
                            <Link
                              to={{
                                pathname: `/forum/${this.props.match.params.id}/${item.id}`,
                              }}
                              className="card-link"
                            >
                              <div className="card class-card bg-light">
                                <div className="card-body">
                                  <h5>{_t(t("Class Name"))}</h5>
                                  <p className="text-primary">
                                    {item.title.length > MAX_LENGTH ? (
                                      <div>
                                        {`${item.title.substring(
                                          0,
                                          MAX_LENGTH
                                        )}...`}
                                      </div>
                                    ) : (
                                      <p>{item.title}</p>
                                    )}
                                  </p>
                                </div>
                                <div className="card-footer">
                                  <p>
                                    {_t(t("Main Concept"))}
                                    <br />
                                    {item.content_about.length >
                                      MAX_LENGTH ? (
                                      <div>
                                        {`${item.content_about.substring(
                                          0,
                                          MAX_LENGTH
                                        )}...`}
                                      </div>
                                    ) : (
                                      <p>{item.content_about}</p>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      }),
                    ]
                    : [
                      this.state.searchedClasses.length > 0 ? (
                        this.state.searchedClasses.map((item, index) => {
                          return (
                            <div className="col-md-4">
                              <Link
                                to={{
                                  pathname: `/forum/${this.props.match.params.id}/${item.id}`,
                                  state: { class_title: item.title },
                                }}
                                className="card-link"
                              >
                                <div className="card class-card bg-light">
                                  <div className="card-body">
                                    <h5>{_t(t("Class Name"))}</h5>
                                    <h3 className="text-primary">
                                      {item.title.length > MAX_LENGTH ? (
                                        <div>
                                          {`${item.title.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`}
                                        </div>
                                      ) : (
                                        <p>{item.title}</p>
                                      )}
                                    </h3>
                                  </div>
                                  <div className="card-footer">
                                    <p>
                                      {_t(t("Main Concept"))}
                                      <br />
                                      {item.content_about.length >
                                        MAX_LENGTH ? (
                                        <div>
                                          {`${item.content_about.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`}
                                        </div>
                                      ) : (
                                        <p>{item.content_about}</p>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-12 text-center  my-auto">
                          <div className="card card-body bg-light text-danger no-searched-course mb-2">
                            {_t(t("No class found by your query."))}
                          </div>
                        </div>
                      ),
                    ],
                ]}
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(CourseForum));
