import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class CheckComment extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      students: [],
      courses: [],
      classes: [],
      alert_success: null,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Check Comments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    if (this.props.location.state) {
      let alert = this.props.location.state.alert_success;
      this.setState({
        alert_success: alert,
      });
      setTimeout(
        function () {
          this.setState({ alert_success: "" });
        }.bind(this),
        3000
      );
      this.props.history.replace();
    }
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const comments_url = BASE_URL + "/api/admin/get_unseen_comments";
    return axios
      .get(comments_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data,
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleView = (e) => {
    this.props.history.push(`/admin/check-comments/${e}`);
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h4 className="mt-2 mb-4">
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Unchecked Comments
                </h4>
                <div className="container">
                  <div className="row">
                    {/* Error or Success Message */}
                    {this.state.alert_success ? (
                      <div className="text-center col-md-10 offset-md-1 bg-success text-white p-3 mb-2">
                        {this.state.alert_success}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Error or Success Message */}
                    <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                      <div className="card-header bg-danger text-center text-white text-uppercase mb-3">
                        Unchecked Comments
                      </div>
                      {this.state.loading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </>
                      ) : (
                        [
                          this.state.comments.length > 0 ? (
                            [
                              this.state.comments.map((item) => {
                                // return (
                                //   <Link
                                //     to={`/admin/check-comments/${item.add_class_id}`}
                                //     className="alert alert-success nav-link"
                                //   >
                                //     <span className="row">
                                //       <span className="col-md-8 text-truncate">
                                //         <span className="text-dark">
                                //           {item.comment
                                //             ? "New Comment"
                                //             : [item.reply ? "New Reply" : ""]}
                                //         </span>{" "}
                                //         :{" "}
                                //         <b>
                                //           <span
                                //             dangerouslySetInnerHTML={{
                                //               __html:
                                //                 item.comment || item.reply,
                                //             }}
                                //           />
                                //         </b>
                                //       </span>
                                //       <span className="col-md-4 text-right">
                                //         <span className=" btn btn-sm btn-secondary">
                                //           View
                                //         </span>
                                //       </span>
                                //     </span>
                                //   </Link>
                                // );
                              }),
                            ]
                          ) : (
                            <div className="alert alert-danger py-5 text-center">
                              No comment's notification!
                            </div>
                          ),
                        ]
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(CheckComment);
