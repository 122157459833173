import React, { Component } from "react";

import Slider from "../components/Slider";
import NewHero from "../components/NewHero";
import Promote from "../components/Promote";
import NewAllCourse from "../components/NewAllCourse";
// import AllCourse from "../components/AllCourse";
import NewCourses from "../components/NewCourses";




export default class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "SoftTech-IT Online Courses";
  }
  render() {
    return (
      <>
        <NewHero />
        <Promote />
        <NewAllCourse />
        {/* <AllCourse/> */}
      </>
    );
  }
}

