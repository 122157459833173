import React, { Component } from "react";
import ProfileSidebar from "../../components/ProfileSidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      enrollments: [],
      total_payable: 0,
      total_paid: 0,
      total_due: 0,
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    document.title = "Payment Info";
    const url = BASE_URL + "/api/student/myCourses";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        res.data[0].map((course) => {
          let enrolled_course = res.data[1].find(
            (content) => content.course_id == course.id
          );
          let n2 = 0;
          let n3 = 0;
          let n4 = 0;
          let n5 = 0;
          if (enrolled_course.second_payment !== null) {
            n2 = enrolled_course.second_payment;
          }
          if (enrolled_course.third_payment !== null) {
            n3 = enrolled_course.third_payment;
          }
          if (enrolled_course.fourth_payment !== null) {
            n4 = enrolled_course.fourth_payment;
          }
          if (enrolled_course.fifth_payment !== null) {
            n5 = enrolled_course.fifth_payment;
          }
          let paid =
            parseInt(enrolled_course.first_payment) +
            parseInt(n2) +
            parseInt(n3) +
            parseInt(n4) +
            parseInt(n5);
          let due =
            parseInt(enrolled_course.payable) -
            (parseInt(enrolled_course.first_payment) +
              parseInt(n2) +
              parseInt(n3) +
              parseInt(n4) +
              parseInt(n5));
          this.setState({
            total_payable:
              parseInt(this.state.total_payable) +
              parseInt(enrolled_course.payable),
            total_paid: parseInt(this.state.total_paid) + parseInt(paid),
            total_due: parseInt(this.state.total_due) + parseInt(due),
          });
        });
        this.setState({
          courses: res.data[0],
          enrollments: res.data[1],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        }
      });
  }
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div
              className={`${
                this.state.isMobile ? "col-md-12  bg-primary" : "leftbar"
              }`}
            >
              {this.state.sidebar ? <ProfileSidebar /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <h3 className="mt-3 mb-2">Payment Status</h3>
                <div className="row">
                  <div className="container mb-3">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="col-md-12">
                          <h1>
                            <Skeleton count={3} />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={8} />
                        </div>
                      ) : (
                        <div className="container">
                          <div className="card-body bg-light">
                            <div className="row bg-primary text-white text-center text-capitalize p-3 mb-3">
                              <div className="col bg-warning text-dark rounded-sm mx-2 py-1">
                                {_t(t("Payable"))} : {this.state.total_payable}{" "}
                                BDT
                              </div>
                              <div className="col bg-success rounded-sm mx-2 py-1">
                                {_t(t("Paid"))} : {this.state.total_paid} BDT
                              </div>
                              <div className="col bg-danger rounded-sm mx-2 py-1">
                                {_t(t("Due"))} : {this.state.total_due} BDT
                              </div>
                            </div>
                            <div className="row">
                              {this.state.courses.map((course) => {
                                let enrolled_course =
                                  this.state.enrollments.find(
                                    (content) => content.course_id == course.id
                                  );
                                let n2 = 0;
                                let n3 = 0;
                                let n4 = 0;
                                let n5 = 0;
                                if (enrolled_course.second_payment !== null) {
                                  n2 = enrolled_course.second_payment;
                                }
                                if (enrolled_course.third_payment !== null) {
                                  n3 = enrolled_course.third_payment;
                                }
                                if (enrolled_course.fourth_payment !== null) {
                                  n4 = enrolled_course.fourth_payment;
                                }
                                if (enrolled_course.fifth_payment !== null) {
                                  n5 = enrolled_course.fifth_payment;
                                }
                                let paid =
                                  parseInt(enrolled_course.first_payment) +
                                  parseInt(n2) +
                                  parseInt(n3) +
                                  parseInt(n4) +
                                  parseInt(n5);
                                let due =
                                  parseInt(enrolled_course.payable) -
                                  (parseInt(enrolled_course.first_payment) +
                                    parseInt(n2) +
                                    parseInt(n3) +
                                    parseInt(n4) +
                                    parseInt(n5));
                                return (
                                  <div
                                    className="col-md-4"
                                    title={course.title}
                                  >
                                    <Link
                                      to={`/profile/payment-status/${course.id}`}
                                      className="card-link"
                                    >
                                      <div className="card course-card">
                                        <img
                                          className="card-img-top"
                                          src={`${BASE_URL}${`/images/course/${course.thumbnail}`}`}
                                          height="150px"
                                          width="100%"
                                          alt=""
                                        />
                                        <div className="card-body bg-light  border-top">
                                          <h6 className="card-title mb-0 text-truncate">
                                            {course.title}
                                          </h6>
                                        </div>
                                        <div className="card-footer text-center">
                                          <span>
                                            {enrolled_course.payable > paid ? (
                                              <span className="btn btn-sm btn-danger">
                                                {_t(t("Due"))} : {due} BDT
                                              </span>
                                            ) : (
                                              <span className="btn btn-sm btn-success">
                                                {_t(t("Paid"))}
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PaymentStatus);
