import React, { Component } from "react";
import { withRouter, NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
import Moment from "react-moment";

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
const $ = require("jquery");
const cookies = new Cookies();

class SingleCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollments: [],
      course: [],
      classes: [],
      contents: [],
      sourceCodes: [],
      last_seen: null,
      videoUrl: "",
      videoID: "",
      submitAssignment: null,
      alert_success: null,
      loadAssignment: false,
      uploadprogress: 0,
      error: null,
      loading: false,
      isMobile: false,
      sidebar: true,
      assignments: null,
      assignment: false,
      assignment_class_id: [],
      contentIdForCourse: null,
      // instructor
      instructor_fname: "",
      instructor_lname: "",
      instructor_phn_no: "",
      instructor_image: "",
      instructor_email: "",
      instructor_designation: "",
      instructor_intro: "",
      instructor_facebook: "",
      instructor_id: null,
      // forum
      class_title: "",
      class: [],
      comments: [],
      searchedComments: [],
      replies: [],
      students: [],
      admins: [],
      instructors: [],
      moderators: [],
      comment: "",
      reply: "",
      userId: "",
      search: false,
      search_query: "",
      timeout: 0,
      commentBtn: false,
      commentLoading: false,
      replyLoading: false,
      cls_id: null,
      // single instructor
      idOfContent: null,
      idOfCourse: null,
    };
  }

  componentDidMount() {
    const { t } = this.props;
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );

    // get assignments here
    const student_assignment_url = BASE_URL + `/api/student/assignment`;
    return axios
      .get(student_assignment_url, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          assignments: res.data,
        });
        const course_url =
          BASE_URL + `/api/student/myCourses/${this.props.match.params.id}`;
        return axios
          .get(course_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              course: res.data[0],
              classes: res.data[1],
              contents: res.data[2],
              enrollment: res.data[3],
              sourceCodes: res.data[4],
              last_seen: res.data[5] ? res.data[5].class_content_id : null,
              instructor_id: res.data[0].instructor_id,
            });

            // check if student has access to this course
            if (res.data[3].is_active == 1) {
              document.title = this.state.course.title;

              // Set first class active
              this.setState({
                assignment_class_id: [this.state.classes[0].id],
              });
              // Set first class active

              //check which content is available
              let available_content_id = null;
              this.state.contents.map((item) => {
                if (item.add_class_id == this.state.assignment_class_id[0]) {
                  available_content_id = item.id;
                }
              });

              // check for last time seen video
              let last_seen_videoId = this.state.last_seen
                ? this.state.last_seen
                : available_content_id;
              // check for last time seen video

              // make last seen video current video
              let videoID = this.props.match.params.activeVideo
                ? this.props.match.params.activeVideo
                : last_seen_videoId;
              // make last seen video current video

              this.state.contents.map((item) => {
                if (item.id == parseInt(videoID)) {
                  this.setState({
                    videoUrl: item.video_id,
                  });
                }
              });

              // Add permitted classes active
              if (this.state.enrollment.class_access) {
                JSON.parse(this.state.enrollment.class_access)[1].map(
                  (permittedClassId) => {
                    if (
                      !this.state.assignment_class_id.includes(permittedClassId)
                    ) {
                      this.setState({
                        assignment_class_id: [
                          ...this.state.assignment_class_id,
                          permittedClassId,
                        ],
                      });
                    }
                  }
                );
              }
              // Add permitted classes active

              // Active class depending on assignment & assignment's score
              res.data[1].map((check_class, check_class_index) => {
                if (check_class_index > 0) {
                  {
                    this.state.assignments.map(
                      (assignment_class, assignment_class_index) => {
                        if (
                          this.state.classes[check_class_index - 1].id ==
                          parseInt(assignment_class.add_class_id)
                        ) {
                          // if score of first class is given active 3rd class
                          if (assignment_class.score !== null) {
                            if (this.state.classes[check_class_index + 1]) {
                              if (
                                !this.state.assignment_class_id.includes(
                                  this.state.classes[check_class_index + 1].id
                                )
                              ) {
                                this.setState({
                                  assignment_class_id: [
                                    ...this.state.assignment_class_id,
                                    this.state.classes[check_class_index + 1]
                                      .id,
                                  ],
                                });
                              }
                            }
                          }
                          // if score of first class is given active 3rd class
                        }
                      }
                    );
                  }
                } else {
                  {
                    this.state.assignments.map(
                      (assignment_class, assignment_class_index) => {
                        if (
                          this.state.classes[check_class_index].id ==
                          parseInt(assignment_class.add_class_id)
                        ) {
                          if (
                            !this.state.assignment_class_id.includes(
                              this.state.classes[check_class_index + 1].id
                            )
                          ) {
                            this.setState({
                              assignment_class_id: [
                                ...this.state.assignment_class_id,
                                this.state.classes[check_class_index + 1].id,
                                // if first class's assignment is submitted active second class
                              ],
                            });
                          }
                        }
                      }
                    );
                  }
                }
              });
              // Active class depending on assignment & assignment's score

              // block url hit by student if no assignment
              let tempContentClass = this.state.contents.find(
                (content) => content.id == parseInt(videoID)
              );

              if (
                this.state.assignment_class_id.includes(
                  parseInt(tempContentClass.add_class_id)
                )
              ) {
                this.props.history.replace({
                  pathname: `/my-courses/${this.props.match.params.id}/${videoID}`,
                });
              } else {
                this.props.history.replace({
                  pathname: `/my-courses`,
                  state: {
                    alert_danger: _t(
                      t("Please contact SoftTech-IT to access this course!")
                    ),
                    // "দুঃখিত, এই কোর্স টি দেখতে SoftTech-IT তে যোগাযোগ করুন!",
                  },
                });
              }
              // block url hit by student if no assignment
            } else {
              this.props.history.replace({
                pathname: `/my-courses`,
                state: {
                  alert_danger: _t(
                    t("Please contact SoftTech-IT to access this course!!")
                  ),
                  // "দুঃখিত, এই কোর্স টি দেখতে SoftTech-IT তে যোগাযোগ করুন!",
                },
              });
            }
            this.setState({
              loading: false,
            });
          })
          .catch(() => {
            this.props.history.replace({
              pathname: `/my-courses`,
              state: {
                alert_danger: _t(
                  t("Please contact SoftTech-IT to access this course!!!")
                ),
                // "দুঃখিত, এই কোর্স টি দেখতে SoftTech-IT তে যোগাযোগ করুন!",
              },
            });
          });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        }
      })
      .then(() => {
        // single instructor course
        const Instructor_url =
          BASE_URL +
          `/api/student/single-instructor/${this.state.instructor_id}`;

        return axios
          .get(Instructor_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              instructor_fname: res.data[0].fname,
              instructor_lname: res.data[0].lname,
              instructor_phn_no: res.data[0].phn_no,
              instructor_image: res.data[0].image,
              instructor_email: res.data[0].email,
              instructor_designation: res.data[0].designation,
              instructor_intro: res.data[0].intro,
              instructor_facebook: res.data[0].facebook,
            });
          });
      });
  }

  // handle video
  handleVideo = (idOfContent, idOfCourse) => {
    this.setState({
      idOfContent: idOfContent,
      idOfCourse: idOfCourse,
    });

    this.state.contents.map((content) => {
      if (content.id == parseInt(idOfContent)) {
        this.setState({
          videoUrl: content.video_id,
          contentIdForCourse: idOfContent,
        });
      }
    });

    // getting forum data
    const url = BASE_URL + "/api/student/forum/" + this.props.match.params.id;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cookies.get("studentToken")}`,
        },
      })
      .then((res) => {
        if (res.data[2].is_active == 0) {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        } else {
          const url = BASE_URL + "/api/student/get_comments";
          let class_id = {
            class_id: idOfCourse,
          };
          return axios
            .post(url, class_id, {
              headers: {
                Authorization: `Bearer ${cookies.get("studentToken")}`,
              },
            })
            .then((res) => {
              this.setState({
                // comments: res.data[0],
                students: res.data[1],
                admins: res.data[2],
                instructors: res.data[3],
                moderators: res.data[4],
                class: res.data[5],
                userId: res.data[6],
              });
              if (res.data[5] !== null) {
                const get_replies_url = BASE_URL + "/api/student/get_replies";
                let class_id = {
                  class_id: idOfCourse,
                };
                return axios
                  .post(get_replies_url, class_id, {
                    headers: {
                      Authorization: `Bearer ${cookies.get("studentToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      // replies: res.data,
                      loading: false,
                    });
                  });
              } else {
                this.props.history.replace({
                  pathname: `/forum`,
                  state: {
                    alert_danger: "Can not perform the action. Contact Admin!",
                  },
                });
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        } else {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      });

    function last_seen() {
      const url = BASE_URL + "/api/student/last_seen";
      let formData = {
        course_id: this.state.course.id,
        class_content_id: idOfContent,
      };
      return axios.post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      });
    }

    last_seen();
  };

  handleAssignment = (e) => {
    this.setState({
      submitAssignment: e.target.files[0],
    });
  };

  // handle assignment instructions
  assignmentInstructions = (e) => {
    e.preventDefault();
  };
  // handle course instructor
  handleCourseInstructor = (e) => {
    e.preventDefault();
  };

  // handle course discussion
  handleCourseDiscussion = (e) => {
    e.preventDefault();
    const url = BASE_URL + "/api/student/forum/" + this.props.match.params.id;

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cookies.get("studentToken")}`,
        },
      })
      .then((res) => {
        if (res.data[2].is_active == 0) {
          this.props.history.replace({
            pathname: `/my-courses`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        } else {
          const url = BASE_URL + "/api/student/get_comments";

          let class_id = {
            class_id: this.state.idOfCourse,
          };
          return axios
            .post(url, class_id, {
              headers: {
                Authorization: `Bearer ${cookies.get("studentToken")}`,
              },
            })
            .then((res) => {
              this.setState({
                comments: res.data[0],
                students: res.data[1],
                admins: res.data[2],
                instructors: res.data[3],
                moderators: res.data[4],
                class: res.data[5],
                userId: res.data[6],
              });
              if (res.data[5] !== null) {
                const get_replies_url = BASE_URL + "/api/student/get_replies";
                let class_id = {
                  class_id: this.state.idOfCourse,
                };
                return axios
                  .post(get_replies_url, class_id, {
                    headers: {
                      Authorization: `Bearer ${cookies.get("studentToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      replies: res.data,
                      loading: false,
                    });
                  });
              } else {
                this.props.history.replace({
                  pathname: `/my-courses`,
                  state: {
                    alert_danger: "Can not perform the action. Contact Admin!",
                  },
                });
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        } else {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      });
  };

  handleAssignmentSubmit = (e, id) => {
    const { t } = this.props;
    e.preventDefault();
    this.setState({
      loadAssignment: true,
    });
    const assignment_url = BASE_URL + `/api/student/assignment`;
    let formData = new FormData();
    formData.append("submitAssignment", this.state.submitAssignment);
    formData.append("enrollment_id", this.state.enrollment.id);
    formData.append("id", id);
    return axios
      .post(assignment_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
        onUploadProgress: (ProgressEvent) => {
          let complete = Math.round(
            (ProgressEvent.loaded * 100) / ProgressEvent.total
          );
          this.setState({
            uploadprogress: complete,
          });
        },
      })
      .then(() => {
        this.setState({
          alert_success: _t(t("Assignment submitted successfully")),
          loadAssignment: false,
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
        // get assignments here
        const student_assignment_url = BASE_URL + `/api/student/assignment`;
        return axios
          .get(student_assignment_url, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              assignments: res.data,
            });
          });
      })
      .catch((error) => {
        if (error.response.status !== 413) {
          this.setState({
            error: error.response.data.errors,
            loadAssignment: false,
          });
          setTimeout(
            function () {
              this.setState({ error: null });
            }.bind(this),
            5000
          );
          this.props.history.replace();
        } else {
          this.setState({
            error: {
              submitAssignment:
                "Your file is too large, please upload low sized file.",
            },
            loadAssignment: false,
          });
          setTimeout(
            function () {
              this.setState({ error: null });
            }.bind(this),
            10000
          );
          this.props.history.replace();
        }
      });
  };

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  // from forum
  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/student/findComment/" + searchText;
      let formData = {
        add_class_id: this.state.idOfCourse,
      };
      if (searchText == null && searchText == "") {
        this.setState({
          search: false,
        });
      } else {
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedComments: res.data,
            });
          });
      }
    }, 1000);
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  // Jodit Editor plugin
  updateContent = (value) => {
    if (value !== null && value !== "") {
      this.setState({
        commentBtn: true,
        comment: value,
      });
    } else {
      this.setState({
        commentBtn: false,
      });
    }
  };

  updateReply = (e) => {
    this.setState({
      reply: e,
    });
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };

  // Jodit Editor plugin
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      commentLoading: true,
    });
    let formData = {
      class_id: this.state.idOfCourse,
      comment: this.state.comment,
    };
    const url = BASE_URL + "/api/student/comments";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data,
          comment: "",
          commentLoading: false,
        });
      });
  };

  handleReplySubmit = (e, comment_id) => {
    e.preventDefault();
    this.setState({
      replyLoading: true,
    });
    let formData = {
      class_id: this.state.idOfCourse,
      comment_id: comment_id,
      reply: this.state.reply,
    };
    const url = BASE_URL + "/api/student/replies";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          replies: res.data,
          reply: "",
          replyLoading: false,
        });
      });
  };

  commentDelete = (id) => {
    const delete_url = BASE_URL + "/api/student/delete_comments/" + id;
    return axios
      .post(delete_url, null, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then(() => {
        const url = BASE_URL + "/api/student/get_comments";
        let class_id = {
          class_id: this.state.idOfCourse,
        };
        return axios
          .post(url, class_id, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              comments: res.data[0],
              students: res.data[1],
              admins: res.data[2],
              instructors: res.data[3],
              moderators: res.data[4],
              class: res.data[5],
              userId: res.data[6],
            });
            const get_replies_url = BASE_URL + "/api/student/get_replies";
            let class_id = {
              class_id: this.state.idOfCourse,
            };
            return axios
              .post(get_replies_url, class_id, {
                headers: {
                  Authorization: `Bearer ${cookies.get("studentToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  replies: res.data,
                });
              });
          });
      });
  };

  replyDelete = (id) => {
    const delete_url = BASE_URL + "/api/student/delete_replies/" + id;
    return axios
      .post(delete_url, null, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then(() => {
        const get_replies_url = BASE_URL + "/api/student/get_replies";
        let class_id = {
          class_id: this.state.idOfCourse,
        };
        return axios
          .post(get_replies_url, class_id, {
            headers: {
              Authorization: `Bearer ${cookies.get("studentToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              replies: res.data,
            });
          });
      });
  };

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return (
        <>
          <div className="col-md-8 offset-md-2  mt-2 pb-3">
            <h1>
              <Skeleton style={{ height: "calc(100vh - 182px)" }} />
            </h1>
          </div>
        </>
      );
    } else {
      if (this.state.course) {
        return (
          <>
            <div className="container-fluid">
              <div className="row course-title single-course-title">
                <span>
                  <NavLink to="/my-courses">
                    <i className="fa fa-arrow-circle-left text-white"></i>
                  </NavLink>
                </span>
                <span className="ml-2">
                  {_t(t("Course Title"))} : {this.state.course.title}
                </span>
              </div>
            </div>
            <div
              className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
              style={{
                position: "fixed",
                top: "30%",
                zIndex: "10",
              }}
            >
              <button
                className="btn btn-sm btn-warning rounded-circle"
                title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                onClick={this.handleSidebar}
              >
                <i className="fa fa-align-right"></i>
              </button>
            </div>
            <div className={`${this.state.isMobile ? "marginx " : "course"}`}>
              <div className="row">
                <div
                  className={`${
                    this.state.isMobile
                      ? "col-md-12 mobileCoursebar"
                      : "coursebar"
                  }`}
                >
                  {this.state.sidebar ? (
                    <div className="accordion pb-3" id="accordion">
                      {this.state.classes.map((courseClass, index) => {
                        let tempContentID = this.state.contents.find(
                          (content) =>
                            content.id ==
                            parseInt(this.props.match.params.activeVideo)
                        );
                        return (
                          <div key={index}>
                            <div
                              className="card-header video-card-header text-left p-4"
                              style={{ borderRadius: 0 }}
                              id={`heading-${index}`}
                            >
                              <h5 className="mb-0">
                                <a
                                  className="collapsed sidebar-active text-white"
                                  role="button"
                                  data-toggle="collapse"
                                  href={`#collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse-${index}`}
                                >
                                  {_t(t("Class"))} {index + 1} :{" "}
                                  {courseClass.title}
                                </a>
                              </h5>
                            </div>

                            <div
                              id={`collapse-${index}`}
                              className={`collapse ${[
                                tempContentID
                                  ? [
                                      courseClass.id ==
                                      tempContentID.add_class_id
                                        ? "show"
                                        : "",
                                    ]
                                  : [
                                      courseClass.id ==
                                      this.state.contents[0].id
                                        ? "show"
                                        : "",
                                    ],
                              ]}`}
                              data-parent="#accordion"
                              aria-labelledby={`heading-${index}`}
                            >
                              <div className="card-body bg-white pt-1">
                                <div className="card-header bg-white text-secondary text-center fs-13 rounded-0 border-0 ">
                                  {_t(t("Main Concept"))} :{" "}
                                  {courseClass.content_about}
                                </div>

                                {this.state.contents.map(
                                  (classContent, index) => {
                                    if (
                                      parseInt(classContent.add_class_id) ==
                                      courseClass.id
                                    ) {
                                      return (
                                        <div
                                          key={index}
                                          className={`px-3 ${
                                            this.state.assignment_class_id.includes(
                                              parseInt(
                                                classContent.add_class_id
                                              )
                                            )
                                              ? ""
                                              : "disable-cursor"
                                          }`}
                                        >
                                          <NavLink
                                            to={`/my-courses/${courseClass.course_id}/${classContent.id}`}
                                            activeClassName="sidebar-active"
                                            className={`nav-link py-0 border-bottom ${
                                              this.state.assignment_class_id.includes(
                                                parseInt(
                                                  classContent.add_class_id
                                                )
                                              )
                                                ? ""
                                                : "disable-link"
                                            }`}
                                            onClick={
                                              this.state.assignment_class_id.includes(
                                                parseInt(
                                                  classContent.add_class_id
                                                )
                                              )
                                                ? () => {
                                                    this.handleVideo(
                                                      classContent.id,
                                                      courseClass.id
                                                    );
                                                  }
                                                : (e) => e.preventDefault()
                                            }
                                          >
                                            <span className="text-capitalize">
                                              <i className="fa fa-play-circle"></i>{" "}
                                              {classContent.title}
                                            </span>
                                          </NavLink>
                                        </div>
                                      );
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                                <div
                                  className={`mt-2 ${
                                    this.state.assignment_class_id.includes(
                                      courseClass.id
                                    )
                                      ? ""
                                      : "disable-cursor"
                                  }`}
                                >
                                  <div className="text-center mb-2">
                                    {this.state.sourceCodes.map((srcCode) => {
                                      if (
                                        srcCode.add_class_id == courseClass.id
                                      ) {
                                        return (
                                          <a
                                            href={
                                              BASE_URL +
                                              "/tutorials/source/" +
                                              srcCode.source
                                            }
                                            className={`mx-1 text-secondary ${
                                              this.state.assignment_class_id.includes(
                                                courseClass.id
                                              )
                                                ? ""
                                                : "disable-link"
                                            }`}
                                          >
                                            <i className="fa fa-file-text"></i>{" "}
                                            {_t(t("Source Code"))}
                                          </a>
                                        );
                                      } else {
                                        return "";
                                      }
                                    })}
                                  </div>

                                  {/* MODAL TRIGGER */}
                                  <button
                                    className={`btn btn-sm  btn-block btn-outline-primary mx-1 ${
                                      this.state.assignment_class_id.includes(
                                        courseClass.id
                                      )
                                        ? ""
                                        : "disable-link"
                                    }`}
                                    data-toggle="modal"
                                    data-target={`#myModal${courseClass.id}`}
                                  >
                                    {_t(t("Assignment"))}
                                  </button>
                                  {/* MODAL */}
                                  <div
                                    className="modal"
                                    id={`myModal${courseClass.id}`}
                                  >
                                    <div className="modal-dialog">
                                      <div className="modal-content assignment-modal">
                                        <div className="modal-header bg-dark text-white rounded-0">
                                          <h5 className="modal-title">
                                            {_t(t("Submit Your Assignment"))}
                                          </h5>
                                          <button
                                            className="close text-white"
                                            data-dismiss="modal"
                                          >
                                            &times;
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          {this.state.loadAssignment ? (
                                            <div className="col-md-12 text-center">
                                              {/* <Skeleton className="bg-primary" /> */}
                                              <div
                                                className="progress"
                                                style={{ height: "30px" }}
                                              >
                                                <div
                                                  className="progress-bar bg-success"
                                                  role="progressbar"
                                                  style={{
                                                    width: `${this.state.uploadprogress}%`,
                                                  }}
                                                  aria-valuenow="25"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                >
                                                  {this.state.uploadprogress}%
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="row">
                                                {/* Error or Success Message */}
                                                {this.state.alert_success ? (
                                                  <div className="text-center col-md-12 text-white bg-info py-2 mb-2">
                                                    {this.state.alert_success}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state.error ? (
                                                  <div className="text-center text-white col-md-12 bg-danger py-2 mb-2">
                                                    {this.state.error
                                                      .submitAssignment ? (
                                                      <div>
                                                        {
                                                          this.state.error
                                                            .submitAssignment
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {/* Error or Success Message */}

                                                {this.state.assignments.map(
                                                  (item) => {
                                                    if (
                                                      item.add_class_id ==
                                                      courseClass.id
                                                    ) {
                                                      return (
                                                        <>
                                                          {item.score ? (
                                                            <a
                                                              href={
                                                                "/assignment/" +
                                                                this.state
                                                                  .course.id +
                                                                "/" +
                                                                item.add_class_id
                                                              }
                                                              className="btn btn-warning col-md-10 offset-md-1 rounded-lg text-center mb-2"
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                            >
                                                              {_t(t("Score"))} :{" "}
                                                              {item.score} / 10
                                                            </a>
                                                          ) : (
                                                            <h3 className="btn btn-secondary col-md-12 text-center mb-2">
                                                              {/* আপনার স্কোর এর
                                                              জন্য অপেক্ষা করুন,
                                                              এই ক্লাসের স্কোর
                                                              পেলে পরবর্তী
                                                              ক্লাসের পরের ক্লাস
                                                              দেখতে পারবেন । */}
                                                              {_t(
                                                                t(
                                                                  "Wait for your score. If you get score in this class, you will get access to the class after next class"
                                                                )
                                                              )}
                                                            </h3>
                                                          )}
                                                          <span className="row col-md-12 mx-auto mb-2">
                                                            <a
                                                              href={
                                                                BASE_URL +
                                                                "/assignments/" +
                                                                item.assignment
                                                              }
                                                              target="_blank"
                                                              className="btn btn-sm btn-block btn-success mx-5 py-3"
                                                            >
                                                              {_t(
                                                                t(
                                                                  "Download Submitted Assignment"
                                                                )
                                                              )}
                                                            </a>
                                                          </span>
                                                        </>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                              <div className="row">
                                                <span className="row col-md-12 mx-auto mb-2">
                                                  <form
                                                    className="mx-5 py-3 border px-2 bg-light"
                                                    onSubmit={(e) => {
                                                      this.handleAssignmentSubmit(
                                                        e,
                                                        courseClass.id
                                                      );
                                                    }}
                                                  >
                                                    <div className="input-group">
                                                      <label>
                                                        {_t(
                                                          t("Submit Assignment")
                                                        )}
                                                        <span className="fs-13 bg-warning rounded-sm px-1 ml-1">
                                                          {
                                                            " (Upload Zip / Image file)"
                                                          }
                                                        </span>
                                                      </label>
                                                      <input
                                                        type="file"
                                                        id="submitAssignment"
                                                        className="form-control-file mt-2"
                                                        onChange={
                                                          this.handleAssignment
                                                        }
                                                        required
                                                      />
                                                    </div>
                                                    <div>
                                                      <button
                                                        type="submit"
                                                        className="btn btn-success btn-sm mt-2 px-4"
                                                      >
                                                        {_t(t("Submit"))}
                                                      </button>
                                                    </div>
                                                  </form>
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div className="modal-footer bg-light">
                                          <div className="row text-right mr-2">
                                            <button
                                              className="btn btn-danger btn-sm fs-13"
                                              data-dismiss="modal"
                                            >
                                              {_t(t("Exit"))}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`${
                    this.state.isMobile ? "col-md-12 mt-0 pt-0" : "videobar"
                  }`}
                >
                  <div className="container">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          {this.state.videoUrl ? (
                            <>
                              <iframe
                                src={`https://player.vimeo.com/video/${this.state.videoUrl}`}
                                style={{
                                  height: `calc(100vh - 176px)`,
                                  width: `100%`,
                                }}
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                              ></iframe>

                              {/* course tab info */}
                              <ul
                                className="nav mt-4 single-course-tab"
                                id="myTab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <button
                                    onClick={this.handleCourseInstructor}
                                    className="nav-link active btn"
                                    id="ins-tab"
                                    data-toggle="tab"
                                    href="#ins"
                                    role="tab"
                                    aria-controls="ins"
                                    aria-selected="true"
                                  >
                                    Course Instructor
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    onClick={this.assignmentInstructions}
                                    className="nav-link"
                                    id="assign-tab"
                                    data-toggle="tab"
                                    href="#assign-inst"
                                    role="tab"
                                    aria-controls="assign-inst"
                                    aria-selected="false"
                                  >
                                    Assignment Instructions
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    onClick={this.handleCourseDiscussion}
                                    className="nav-link"
                                    id="discussion-tab"
                                    data-toggle="tab"
                                    href="#discussion"
                                    role="tab"
                                    aria-controls="discussion"
                                    aria-selected="false"
                                  >
                                    Course Discussion
                                  </button>
                                </li>
                              </ul>
                              <div
                                className="tab-content w-100"
                                id="myTabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="ins"
                                  role="tabpanel"
                                  aria-labelledby="ins-tab"
                                >
                                  {/* instructor info */}
                                  <div className="container mt-5 d-flex justify-content-center">
                                    <div className="card single_course_instructor col-12 col-md-10">
                                      <div className="card-header d-flex justify-content-between">
                                        <div className="title d-flex align-items-center">
                                          <h6 className="text-gray mr-4">{`${this.state.instructor_fname} ${this.state.instructor_lname}`}</h6>
                                          <h6 className="text-gray">
                                            {this.state.instructor_designation}
                                          </h6>
                                        </div>
                                        <div className="link_info d-flex">
                                          <a
                                            href={
                                              this.state.instructor_facebook
                                            }
                                            className="link_item"
                                          >
                                            <i
                                              class="fa fa-facebook"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </div>
                                      </div>
                                      <div className="card-body d-flex align-items-center">
                                        <div className="row align-items-center">
                                          <div className="col-12 col-lg-2 mb-4 mb-lg-0">
                                            <img
                                              className="instructor_img"
                                              // src="https://unavatar.now.sh/github/drehimself"
                                              src={
                                                BASE_URL +
                                                `/images/instructor/${this.state.instructor_image}`
                                              }
                                              alt="instructor_img"
                                            />
                                          </div>
                                          <div className="col-12 col-lg-10">
                                            <p>{this.state.instructor_intro}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="assign-inst"
                                  role="tabpanel"
                                  aria-labelledby="assign-tab"
                                >
                                  {/* Assignment instructions */}
                                  <div className="container mt-5 mb-5 d-flex justify-content-center">
                                    <div className="card single_course_instructor col-12 col-md-8">
                                      <div className="card-body d-flex align-items-center">
                                        <div className="row align-items-center">
                                          <div className="col-12 ">
                                            {this.state.contents.map((ins) => {
                                              if (
                                                ins.id ==
                                                this.state.contentIdForCourse
                                              ) {
                                                if (ins.instructions !== null) {
                                                  return (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          ins.instructions,
                                                      }}
                                                    ></div>
                                                  );
                                                } else {
                                                  return "The instructor hasn’t added any instructions to this course yet. Instructions are used to inform you of updates or additions to the course.";
                                                }
                                              }
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="p-4 text-center">
                                    {this.state.contents.map((ins) => {
                                      if (ins.id == this.state.contentIdForCourse) {
                                        if (ins.instructions !== null) {
                                          return <div dangerouslySetInnerHTML={{ __html: ins.instructions }}></div>
                                        } else {
                                          return 'The instructor hasn’t added any instructions to this course yet. Instructions are used to inform you of updates or additions to the course.'
                                        }
                                      }
                                    })}
                                  </div> */}
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="discussion"
                                  role="tabpanel"
                                  aria-labelledby="discussion-tab"
                                >
                                  .{/* course discussion */}
                                  {/* <div className="container mb-5">
                                    <div className="row justify-content-center"> */}
                                  {/* comment */}
                                  {/* <div className="single_course_instructor col-12 col-md-10 mb-4">
                                        <div className="row align-items-center">
                                          <div className="col-lg-2 col-md-4 col-12 mb-4 mb-md-0">
                                            <img
                                              className="img-w-130"
                                              src="https://www.gravatar.com/avatar/9e7a3b2d24c2b15c53209ba8e7b4e724?s=100&d=https%3A%2F%2Fs3.amazonaws.com%2Flaracasts%2Fimages%2Fforum%2Favatars%2Fdefault-avatar-25.png" alt="" />
                                          </div>
                                          <div className="col-lg-10 col-md-8 col-12">
                                            <h6 className="text-uppercase">jeanluc</h6>
                                            <span className="text-capitalize"><b>posted at</b>: 4:00pm</span>
                                            <p>This is super exciting! Great course idead!</p>
                                            <button className="btn btn-primary text-capitalize">reply</button>
                                          </div>
                                        </div>
                                      </div> */}
                                  {/* reply */}
                                  {/* 
                                      <div className="single_course_instructor col-12 col-md-10 my-4 ml-5">
                                        <div className="row align-items-center">
                                          <div className="col-lg-2 col-md-4 col-12 mb-4 mb-md-0">
                                            <img
                                              className="img-w-130"
                                              src="https://www.gravatar.com/avatar/9e7a3b2d24c2b15c53209ba8e7b4e724?s=100&d=https%3A%2F%2Fs3.amazonaws.com%2Flaracasts%2Fimages%2Fforum%2Favatars%2Fdefault-avatar-25.png" alt="" />
                                          </div>
                                          <div className="col-lg-10 col-md-8 col-12 p-3">
                                            <h6 className="text-uppercase">jeanluc</h6>
                                            <span className="text-capitalize"><b>posted at</b>: 4:00pm</span>
                                            <p>This is super exciting! Great course idead!</p>
                                            <button className="btn btn-primary text-capitalize">reply</button>
                                          </div>
                                        </div>
                                      </div> */}
                                  {/* load button */}
                                  {/* 
                                      <div className="col-12 col-md-10 mb-4 d-flex justify-content-center">
                                        <button className="btn btn-primary">load more</button>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* ager code gula upore */}
                                  {this.state.loading ? (
                                    <div className="mx-5">
                                      <div>
                                        <h1>
                                          <Skeleton />
                                        </h1>
                                        <h3>
                                          <Skeleton count={5} />
                                        </h3>
                                        <Skeleton count={5} />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="container mt-3 mb-2 text-justify col-md-8 offset-md-2">
                                      <div className="row mb-2 justify-content-center">
                                        <h3 className="course-heading text-uppercase border-bottom mb-2">
                                          {_t(t("Class Title"))}:{" "}
                                          {this.state.class
                                            ? this.state.class.title
                                            : ""}
                                        </h3>
                                      </div>
                                      <div className="row mb-2">
                                        {!this.state.search
                                          ? [
                                              this.state.comments.length > 0 ? (
                                                <div className="col-md-12">
                                                  {this.state.comments.map(
                                                    (item) => {
                                                      return (
                                                        <div className="card card-body mb-2">
                                                          <div className="d-flex align-items-center">
                                                            <div className="avatar">
                                                              {item.student_id
                                                                ? [
                                                                    this.state.students.map(
                                                                      (stu) => {
                                                                        if (
                                                                          stu.id ==
                                                                          item.student_id
                                                                        ) {
                                                                          return (
                                                                            <img
                                                                              src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                              alt="dp"
                                                                              className="rounded-circle"
                                                                              height="80"
                                                                              width="80"
                                                                            />
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    item.admin_id
                                                                      ? [
                                                                          this.state.admins.map(
                                                                            (
                                                                              admin
                                                                            ) => {
                                                                              if (
                                                                                admin.id ==
                                                                                item.admin_id
                                                                              ) {
                                                                                return (
                                                                                  <img
                                                                                    src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                    alt="dp"
                                                                                    className="rounded-circle"
                                                                                    height="80"
                                                                                    width="80"
                                                                                  />
                                                                                );
                                                                              }
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          item.instructor_id
                                                                            ? [
                                                                                this.state.instructors.map(
                                                                                  (
                                                                                    instructor
                                                                                  ) => {
                                                                                    if (
                                                                                      instructor.id ==
                                                                                      item.instructor_id
                                                                                    ) {
                                                                                      return (
                                                                                        <img
                                                                                          src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                          alt="dp"
                                                                                          className="rounded-circle"
                                                                                          height="80"
                                                                                          width="80"
                                                                                        />
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                this.state.moderators.map(
                                                                                  (
                                                                                    moderator
                                                                                  ) => {
                                                                                    if (
                                                                                      moderator.id ==
                                                                                      item.moderator_id
                                                                                    ) {
                                                                                      return (
                                                                                        <img
                                                                                          src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                          alt="dp"
                                                                                          className="rounded-circle"
                                                                                          height="80"
                                                                                          width="80"
                                                                                        />
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ],
                                                                        ],
                                                                  ]}
                                                            </div>

                                                            <div className="flex-grow-1 pl-4">
                                                              {item.student_id
                                                                ? [
                                                                    this.state.students.map(
                                                                      (stu) => {
                                                                        if (
                                                                          stu.id ==
                                                                          item.student_id
                                                                        ) {
                                                                          return (
                                                                            <>
                                                                              <b>
                                                                                {stu.fname +
                                                                                  " " +
                                                                                  stu.lname}
                                                                              </b>{" "}
                                                                              <span>
                                                                                (
                                                                                <Moment format="LLL">
                                                                                  {
                                                                                    item.created_at
                                                                                  }
                                                                                </Moment>

                                                                                )
                                                                              </span>
                                                                              {this
                                                                                .state
                                                                                .userId ==
                                                                              item.student_id ? (
                                                                                <i
                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                  onClick={() =>
                                                                                    this.commentDelete(
                                                                                      item.id
                                                                                    )
                                                                                  }
                                                                                  title="Delete Comment"
                                                                                ></i>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </>
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    item.admin_id
                                                                      ? [
                                                                          this.state.admins.map(
                                                                            (
                                                                              admin
                                                                            ) => {
                                                                              if (
                                                                                admin.id ==
                                                                                item.admin_id
                                                                              ) {
                                                                                return (
                                                                                  <>
                                                                                    <b>
                                                                                      {
                                                                                        admin.name
                                                                                      }
                                                                                    </b>{" "}
                                                                                    (
                                                                                    <span className="text-primary">
                                                                                      {_t(
                                                                                        t(
                                                                                          "Admin"
                                                                                        )
                                                                                      )}
                                                                                    </span>

                                                                                    ){" "}
                                                                                    <span>
                                                                                      (
                                                                                      <Moment format="LLL">
                                                                                        {
                                                                                          item.created_at
                                                                                        }
                                                                                      </Moment>

                                                                                      )
                                                                                    </span>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          item.instructor_id
                                                                            ? [
                                                                                this.state.instructors.map(
                                                                                  (
                                                                                    instructor
                                                                                  ) => {
                                                                                    if (
                                                                                      instructor.id ==
                                                                                      item.instructor_id
                                                                                    ) {
                                                                                      return (
                                                                                        <>
                                                                                          <b>
                                                                                            {instructor.fname +
                                                                                              " " +
                                                                                              instructor.lname}
                                                                                          </b>{" "}
                                                                                          (
                                                                                          <span className="text-primary">
                                                                                            {_t(
                                                                                              t(
                                                                                                "Instructor"
                                                                                              )
                                                                                            )}
                                                                                          </span>

                                                                                          ){" "}
                                                                                          <span>
                                                                                            (
                                                                                            <Moment format="LLL">
                                                                                              {
                                                                                                item.created_at
                                                                                              }
                                                                                            </Moment>

                                                                                            )
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                this.state.moderators.map(
                                                                                  (
                                                                                    moderator
                                                                                  ) => {
                                                                                    if (
                                                                                      moderator.id ==
                                                                                      item.moderator_id
                                                                                    ) {
                                                                                      return (
                                                                                        <>
                                                                                          <b>
                                                                                            {moderator.fname +
                                                                                              " " +
                                                                                              moderator.lname}
                                                                                          </b>{" "}
                                                                                          (
                                                                                          <span className="text-primary">
                                                                                            {_t(
                                                                                              t(
                                                                                                "Moderator"
                                                                                              )
                                                                                            )}
                                                                                          </span>

                                                                                          ){" "}
                                                                                          <span>
                                                                                            (
                                                                                            <Moment format="LLL">
                                                                                              {
                                                                                                item.created_at
                                                                                              }
                                                                                            </Moment>

                                                                                            )
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ],
                                                                        ],
                                                                  ]}
                                                              <br />
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    item.comment,
                                                                }}
                                                              />
                                                              {/* Nested comments */}
                                                            </div>
                                                          </div>

                                                          <>
                                                            {this.state
                                                              .replies ? (
                                                              <div
                                                                className="mt-3"
                                                                style={{
                                                                  marginLeft:
                                                                    "90px",
                                                                }}
                                                              >
                                                                {this.state.replies.map(
                                                                  (reply) => {
                                                                    if (
                                                                      reply.add_comment_id ==
                                                                      item.id
                                                                    ) {
                                                                      return (
                                                                        <>
                                                                          <div className="d-flex mb-2 align-items-center card-body bg-light">
                                                                            <div className="avatar-sm">
                                                                              {reply.student_id
                                                                                ? [
                                                                                    this.state.students.map(
                                                                                      (
                                                                                        stu
                                                                                      ) => {
                                                                                        if (
                                                                                          stu.id ==
                                                                                          reply.student_id
                                                                                        ) {
                                                                                          return (
                                                                                            <img
                                                                                              src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                                              alt="dp"
                                                                                              className="rounded-circle"
                                                                                              height="60"
                                                                                              width="60"
                                                                                            />
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    reply.admin_id
                                                                                      ? [
                                                                                          this.state.admins.map(
                                                                                            (
                                                                                              admin
                                                                                            ) => {
                                                                                              if (
                                                                                                admin.id ==
                                                                                                reply.admin_id
                                                                                              ) {
                                                                                                return (
                                                                                                  <img
                                                                                                    src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                                    alt="dp"
                                                                                                    className="rounded-circle"
                                                                                                    height="60"
                                                                                                    width="60"
                                                                                                  />
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      : [
                                                                                          reply.instructor_id
                                                                                            ? [
                                                                                                this.state.instructors.map(
                                                                                                  (
                                                                                                    instructor
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      instructor.id ==
                                                                                                      reply.instructor_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <img
                                                                                                          src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                                          alt="dp"
                                                                                                          className="rounded-circle"
                                                                                                          height="60"
                                                                                                          width="60"
                                                                                                        />
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                this.state.moderators.map(
                                                                                                  (
                                                                                                    moderator
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      moderator.id ==
                                                                                                      reply.moderator_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <img
                                                                                                          src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                                          alt="dp"
                                                                                                          className="rounded-circle"
                                                                                                          height="60"
                                                                                                          width="60"
                                                                                                        />
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                        ],
                                                                                  ]}
                                                                            </div>
                                                                            <div className="flex-grow-1 pl-3">
                                                                              {reply.student_id
                                                                                ? [
                                                                                    this.state.students.map(
                                                                                      (
                                                                                        stu
                                                                                      ) => {
                                                                                        if (
                                                                                          stu.id ==
                                                                                          reply.student_id
                                                                                        ) {
                                                                                          return (
                                                                                            <>
                                                                                              <b>
                                                                                                {stu.fname +
                                                                                                  " " +
                                                                                                  stu.lname}
                                                                                              </b>{" "}
                                                                                              <span>
                                                                                                (
                                                                                                <Moment format="LLL">
                                                                                                  {
                                                                                                    reply.created_at
                                                                                                  }
                                                                                                </Moment>

                                                                                                )
                                                                                              </span>
                                                                                              {this
                                                                                                .state
                                                                                                .userId ==
                                                                                              reply.student_id ? (
                                                                                                <i
                                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                                  onClick={() =>
                                                                                                    this.replyDelete(
                                                                                                      reply.id
                                                                                                    )
                                                                                                  }
                                                                                                  title="Delete Comment"
                                                                                                ></i>
                                                                                              ) : (
                                                                                                ""
                                                                                              )}
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    reply.admin_id
                                                                                      ? [
                                                                                          this.state.admins.map(
                                                                                            (
                                                                                              admin
                                                                                            ) => {
                                                                                              if (
                                                                                                admin.id ==
                                                                                                reply.admin_id
                                                                                              ) {
                                                                                                return (
                                                                                                  <>
                                                                                                    <b>
                                                                                                      {
                                                                                                        admin.name
                                                                                                      }
                                                                                                    </b>{" "}
                                                                                                    (
                                                                                                    <span className="text-primary">
                                                                                                      {_t(
                                                                                                        t(
                                                                                                          "Admin"
                                                                                                        )
                                                                                                      )}
                                                                                                    </span>

                                                                                                    ){" "}
                                                                                                    <span>
                                                                                                      (
                                                                                                      <Moment format="LLL">
                                                                                                        {
                                                                                                          reply.created_at
                                                                                                        }
                                                                                                      </Moment>

                                                                                                      )
                                                                                                    </span>
                                                                                                  </>
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      : [
                                                                                          reply.instructor_id
                                                                                            ? [
                                                                                                this.state.instructors.map(
                                                                                                  (
                                                                                                    instructor
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      instructor.id ==
                                                                                                      reply.instructor_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <>
                                                                                                          <b>
                                                                                                            {instructor.fname +
                                                                                                              " " +
                                                                                                              instructor.lname}
                                                                                                          </b>{" "}
                                                                                                          (
                                                                                                          <span className="text-primary">
                                                                                                            {_t(
                                                                                                              t(
                                                                                                                "Instructor"
                                                                                                              )
                                                                                                            )}
                                                                                                          </span>

                                                                                                          ){" "}
                                                                                                          <span>
                                                                                                            (
                                                                                                            <Moment format="LLL">
                                                                                                              {
                                                                                                                reply.created_at
                                                                                                              }
                                                                                                            </Moment>

                                                                                                            )
                                                                                                          </span>
                                                                                                        </>
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                this.state.moderators.map(
                                                                                                  (
                                                                                                    moderator
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      moderator.id ==
                                                                                                      reply.moderator_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <>
                                                                                                          <b>
                                                                                                            {moderator.fname +
                                                                                                              " " +
                                                                                                              moderator.lname}
                                                                                                          </b>{" "}
                                                                                                          (
                                                                                                          <span className="text-primary">
                                                                                                            {_t(
                                                                                                              t(
                                                                                                                "Moderator"
                                                                                                              )
                                                                                                            )}
                                                                                                          </span>

                                                                                                          ){" "}
                                                                                                          <span>
                                                                                                            (
                                                                                                            <Moment format="LLL">
                                                                                                              {
                                                                                                                reply.created_at
                                                                                                              }
                                                                                                            </Moment>

                                                                                                            )
                                                                                                          </span>
                                                                                                        </>
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                        ],
                                                                                  ]}
                                                                              <br />
                                                                              <div
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    reply.reply,
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  }
                                                                )}
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            <div>
                                                              <p className="text-center">
                                                                <span
                                                                  className="btn btn-warning btn-sm"
                                                                  type="button"
                                                                  data-toggle="collapse"
                                                                  data-target={`#collapseExample${item.id}`}
                                                                  aria-expanded="false"
                                                                  aria-controls="collapseExample"
                                                                >
                                                                  <i className="fa fa-reply"></i>{" "}
                                                                  {_t(
                                                                    t(
                                                                      "Click here to reply"
                                                                    )
                                                                  )}
                                                                </span>
                                                              </p>
                                                              <div
                                                                className="collapse col-md-10 offset-md-1"
                                                                id={`collapseExample${item.id}`}
                                                              >
                                                                <form
                                                                  className="form-group"
                                                                  onSubmit={(
                                                                    e
                                                                  ) => {
                                                                    this.handleReplySubmit(
                                                                      e,
                                                                      item.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <h4 className="text-left">
                                                                    {_t(
                                                                      t("Reply")
                                                                    )}
                                                                  </h4>
                                                                  <JoditEditor
                                                                    editorRef={
                                                                      this
                                                                        .setRef
                                                                    }
                                                                    value={
                                                                      this.state
                                                                        .reply
                                                                    }
                                                                    config={
                                                                      this
                                                                        .config
                                                                    }
                                                                    onChange={
                                                                      this
                                                                        .updateReply
                                                                    }
                                                                  />
                                                                  <div className="text-left">
                                                                    {this.state
                                                                      .replyLoading ? (
                                                                      <button
                                                                        type="submit"
                                                                        className="btn btn-sm px-5 btn-success mt-2 text-uppercase"
                                                                        disabled
                                                                      >
                                                                        {_t(
                                                                          t(
                                                                            "Please wait"
                                                                          )
                                                                        )}
                                                                      </button>
                                                                    ) : (
                                                                      <button
                                                                        type="submit"
                                                                        className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                                                      >
                                                                        {_t(
                                                                          t(
                                                                            "Add Reply"
                                                                          )
                                                                        )}
                                                                      </button>
                                                                    )}
                                                                  </div>
                                                                </form>
                                                              </div>
                                                            </div>
                                                          </>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="col-md-6 offset-3 card card-body bg-light"
                                                  style={{ minHeight: "120px" }}
                                                >
                                                  <div className="text-center my-auto">
                                                    <div className="text-center">
                                                      <h3 className="bg-light text-danger px-5 mt-2">
                                                        {_t(
                                                          t("No comments yet!")
                                                        )}
                                                      </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                              ),
                                            ]
                                          : [
                                              this.state.searchedComments
                                                .length > 0 ? (
                                                <div className="col-md-12">
                                                  {this.state.searchedComments.map(
                                                    (item) => {
                                                      return (
                                                        <div className="card card-body mb-2">
                                                          <div className="d-flex align-items-center">
                                                            <div className="avatar">
                                                              {item.student_id
                                                                ? [
                                                                    this.state.students.map(
                                                                      (stu) => {
                                                                        if (
                                                                          stu.id ==
                                                                          item.student_id
                                                                        ) {
                                                                          return (
                                                                            <img
                                                                              src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                              alt="dp"
                                                                              className="rounded-circle"
                                                                              height="80"
                                                                              width="80"
                                                                            />
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    item.admin_id
                                                                      ? [
                                                                          this.state.admins.map(
                                                                            (
                                                                              admin
                                                                            ) => {
                                                                              if (
                                                                                admin.id ==
                                                                                item.admin_id
                                                                              ) {
                                                                                return (
                                                                                  <img
                                                                                    src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                    alt="dp"
                                                                                    className="rounded-circle"
                                                                                    height="80"
                                                                                    width="80"
                                                                                  />
                                                                                );
                                                                              }
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          item.instructor_id
                                                                            ? [
                                                                                this.state.instructors.map(
                                                                                  (
                                                                                    instructor
                                                                                  ) => {
                                                                                    if (
                                                                                      instructor.id ==
                                                                                      item.instructor_id
                                                                                    ) {
                                                                                      return (
                                                                                        <img
                                                                                          src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                          alt="dp"
                                                                                          className="rounded-circle"
                                                                                          height="80"
                                                                                          width="80"
                                                                                        />
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                this.state.moderators.map(
                                                                                  (
                                                                                    moderator
                                                                                  ) => {
                                                                                    if (
                                                                                      moderator.id ==
                                                                                      item.moderator_id
                                                                                    ) {
                                                                                      return (
                                                                                        <img
                                                                                          src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                          alt="dp"
                                                                                          className="rounded-circle"
                                                                                          height="80"
                                                                                          width="80"
                                                                                        />
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ],
                                                                        ],
                                                                  ]}
                                                            </div>

                                                            <div className="flex-grow-1 pl-4">
                                                              {item.student_id
                                                                ? [
                                                                    this.state.students.map(
                                                                      (stu) => {
                                                                        if (
                                                                          stu.id ==
                                                                          item.student_id
                                                                        ) {
                                                                          return (
                                                                            <>
                                                                              <b>
                                                                                {stu.fname +
                                                                                  " " +
                                                                                  stu.lname}
                                                                              </b>{" "}
                                                                              <span>
                                                                                (
                                                                                <Moment format="LLL">
                                                                                  {
                                                                                    item.created_at
                                                                                  }
                                                                                </Moment>

                                                                                )
                                                                              </span>
                                                                              {this
                                                                                .state
                                                                                .userId ==
                                                                              item.student_id ? (
                                                                                <i
                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                  onClick={() =>
                                                                                    this.commentDelete(
                                                                                      item.id
                                                                                    )
                                                                                  }
                                                                                  title="Delete Comment"
                                                                                ></i>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </>
                                                                          );
                                                                        }
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    item.admin_id
                                                                      ? [
                                                                          this.state.admins.map(
                                                                            (
                                                                              admin
                                                                            ) => {
                                                                              if (
                                                                                admin.id ==
                                                                                item.admin_id
                                                                              ) {
                                                                                return (
                                                                                  <>
                                                                                    <b>
                                                                                      {
                                                                                        admin.name
                                                                                      }
                                                                                    </b>{" "}
                                                                                    (
                                                                                    <span className="text-primary">
                                                                                      {_t(
                                                                                        t(
                                                                                          "Admin"
                                                                                        )
                                                                                      )}
                                                                                    </span>

                                                                                    ){" "}
                                                                                    <span>
                                                                                      (
                                                                                      <Moment format="LLL">
                                                                                        {
                                                                                          item.created_at
                                                                                        }
                                                                                      </Moment>

                                                                                      )
                                                                                    </span>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          item.instructor_id
                                                                            ? [
                                                                                this.state.instructors.map(
                                                                                  (
                                                                                    instructor
                                                                                  ) => {
                                                                                    if (
                                                                                      instructor.id ==
                                                                                      item.instructor_id
                                                                                    ) {
                                                                                      return (
                                                                                        <>
                                                                                          <b>
                                                                                            {instructor.fname +
                                                                                              " " +
                                                                                              instructor.lname}
                                                                                          </b>{" "}
                                                                                          (
                                                                                          <span className="text-primary">
                                                                                            {_t(
                                                                                              t(
                                                                                                "Instructor"
                                                                                              )
                                                                                            )}
                                                                                          </span>

                                                                                          ){" "}
                                                                                          <span>
                                                                                            (
                                                                                            <Moment format="LLL">
                                                                                              {
                                                                                                item.created_at
                                                                                              }
                                                                                            </Moment>

                                                                                            )
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                this.state.moderators.map(
                                                                                  (
                                                                                    moderator
                                                                                  ) => {
                                                                                    if (
                                                                                      moderator.id ==
                                                                                      item.moderator_id
                                                                                    ) {
                                                                                      return (
                                                                                        <>
                                                                                          <b>
                                                                                            {moderator.fname +
                                                                                              " " +
                                                                                              moderator.lname}
                                                                                          </b>{" "}
                                                                                          (
                                                                                          <span className="text-primary">
                                                                                            {_t(
                                                                                              t(
                                                                                                "Moderator"
                                                                                              )
                                                                                            )}
                                                                                          </span>

                                                                                          ){" "}
                                                                                          <span>
                                                                                            (
                                                                                            <Moment format="LLL">
                                                                                              {
                                                                                                item.created_at
                                                                                              }
                                                                                            </Moment>

                                                                                            )
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                ),
                                                                              ],
                                                                        ],
                                                                  ]}
                                                              <br />
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    item.comment,
                                                                }}
                                                              />
                                                              {/* Nested comments */}
                                                            </div>
                                                          </div>
                                                          <>
                                                            {this.state
                                                              .replies ? (
                                                              <div
                                                                className="mt-3"
                                                                style={{
                                                                  marginLeft:
                                                                    "90px",
                                                                }}
                                                              >
                                                                {this.state.replies.map(
                                                                  (reply) => {
                                                                    if (
                                                                      reply.add_comment_id ==
                                                                      item.id
                                                                    ) {
                                                                      return (
                                                                        <>
                                                                          <div className="d-flex mb-2 align-items-center card-body bg-light">
                                                                            <div className="avatar-sm">
                                                                              {reply.student_id
                                                                                ? [
                                                                                    this.state.students.map(
                                                                                      (
                                                                                        stu
                                                                                      ) => {
                                                                                        if (
                                                                                          stu.id ==
                                                                                          reply.student_id
                                                                                        ) {
                                                                                          return (
                                                                                            <img
                                                                                              src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                                              alt="dp"
                                                                                              className="rounded-circle"
                                                                                              height="60"
                                                                                              width="60"
                                                                                            />
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    reply.admin_id
                                                                                      ? [
                                                                                          this.state.admins.map(
                                                                                            (
                                                                                              admin
                                                                                            ) => {
                                                                                              if (
                                                                                                admin.id ==
                                                                                                reply.admin_id
                                                                                              ) {
                                                                                                return (
                                                                                                  <img
                                                                                                    src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                                                    alt="dp"
                                                                                                    className="rounded-circle"
                                                                                                    height="60"
                                                                                                    width="60"
                                                                                                  />
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      : [
                                                                                          reply.instructor_id
                                                                                            ? [
                                                                                                this.state.instructors.map(
                                                                                                  (
                                                                                                    instructor
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      instructor.id ==
                                                                                                      reply.instructor_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <img
                                                                                                          src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                                                          alt="dp"
                                                                                                          className="rounded-circle"
                                                                                                          height="60"
                                                                                                          width="60"
                                                                                                        />
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                this.state.moderators.map(
                                                                                                  (
                                                                                                    moderator
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      moderator.id ==
                                                                                                      reply.moderator_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <img
                                                                                                          src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                                                          alt="dp"
                                                                                                          className="rounded-circle"
                                                                                                          height="60"
                                                                                                          width="60"
                                                                                                        />
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                        ],
                                                                                  ]}
                                                                            </div>
                                                                            <div className="flex-grow-1 pl-3">
                                                                              {reply.student_id
                                                                                ? [
                                                                                    this.state.students.map(
                                                                                      (
                                                                                        stu
                                                                                      ) => {
                                                                                        if (
                                                                                          stu.id ==
                                                                                          reply.student_id
                                                                                        ) {
                                                                                          return (
                                                                                            <>
                                                                                              <b>
                                                                                                {stu.fname +
                                                                                                  " " +
                                                                                                  stu.lname}
                                                                                              </b>{" "}
                                                                                              <span>
                                                                                                (
                                                                                                <Moment format="LLL">
                                                                                                  {
                                                                                                    reply.created_at
                                                                                                  }
                                                                                                </Moment>

                                                                                                )
                                                                                              </span>
                                                                                              {this
                                                                                                .state
                                                                                                .userId ==
                                                                                              reply.student_id ? (
                                                                                                <i
                                                                                                  className="fa fa-times ml-2 delete-comment"
                                                                                                  onClick={() =>
                                                                                                    this.replyDelete(
                                                                                                      reply.id
                                                                                                    )
                                                                                                  }
                                                                                                  title="Delete Comment"
                                                                                                ></i>
                                                                                              ) : (
                                                                                                ""
                                                                                              )}
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    reply.admin_id
                                                                                      ? [
                                                                                          this.state.admins.map(
                                                                                            (
                                                                                              admin
                                                                                            ) => {
                                                                                              if (
                                                                                                admin.id ==
                                                                                                reply.admin_id
                                                                                              ) {
                                                                                                return (
                                                                                                  <>
                                                                                                    <b>
                                                                                                      {
                                                                                                        admin.name
                                                                                                      }
                                                                                                    </b>{" "}
                                                                                                    (
                                                                                                    <span className="text-primary">
                                                                                                      {_t(
                                                                                                        t(
                                                                                                          "Admin"
                                                                                                        )
                                                                                                      )}
                                                                                                    </span>

                                                                                                    ){" "}
                                                                                                    <span>
                                                                                                      (
                                                                                                      <Moment format="LLL">
                                                                                                        {
                                                                                                          reply.created_at
                                                                                                        }
                                                                                                      </Moment>

                                                                                                      )
                                                                                                    </span>
                                                                                                  </>
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      : [
                                                                                          reply.instructor_id
                                                                                            ? [
                                                                                                this.state.instructors.map(
                                                                                                  (
                                                                                                    instructor
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      instructor.id ==
                                                                                                      reply.instructor_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <>
                                                                                                          <b>
                                                                                                            {instructor.fname +
                                                                                                              " " +
                                                                                                              instructor.lname}
                                                                                                          </b>{" "}
                                                                                                          (
                                                                                                          <span className="text-primary">
                                                                                                            {_t(
                                                                                                              t(
                                                                                                                "Instructor"
                                                                                                              )
                                                                                                            )}
                                                                                                          </span>

                                                                                                          ){" "}
                                                                                                          <span>
                                                                                                            (
                                                                                                            <Moment format="LLL">
                                                                                                              {
                                                                                                                reply.created_at
                                                                                                              }
                                                                                                            </Moment>

                                                                                                            )
                                                                                                          </span>
                                                                                                        </>
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                this.state.moderators.map(
                                                                                                  (
                                                                                                    moderator
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      moderator.id ==
                                                                                                      reply.moderator_id
                                                                                                    ) {
                                                                                                      return (
                                                                                                        <>
                                                                                                          <b>
                                                                                                            {moderator.fname +
                                                                                                              " " +
                                                                                                              moderator.lname}
                                                                                                          </b>{" "}
                                                                                                          (
                                                                                                          <span className="text-primary">
                                                                                                            {_t(
                                                                                                              t(
                                                                                                                "Moderator"
                                                                                                              )
                                                                                                            )}
                                                                                                          </span>

                                                                                                          ){" "}
                                                                                                          <span>
                                                                                                            (
                                                                                                            <Moment format="LLL">
                                                                                                              {
                                                                                                                reply.created_at
                                                                                                              }
                                                                                                            </Moment>

                                                                                                            )
                                                                                                          </span>
                                                                                                        </>
                                                                                                      );
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                        ],
                                                                                  ]}
                                                                              <br />
                                                                              <div
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    reply.reply,
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  }
                                                                )}
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            <div>
                                                              <p className="text-center">
                                                                <span
                                                                  className="btn btn-warning btn-sm"
                                                                  type="button"
                                                                  data-toggle="collapse"
                                                                  data-target={`#collapseExample${item.id}`}
                                                                  aria-expanded="false"
                                                                  aria-controls="collapseExample"
                                                                >
                                                                  <i className="fa fa-reply"></i>
                                                                  {_t(
                                                                    t(
                                                                      "Click here to reply"
                                                                    )
                                                                  )}
                                                                </span>
                                                              </p>
                                                              <div
                                                                className="collapse col-md-10 offset-md-1"
                                                                id={`collapseExample${item.id}`}
                                                              >
                                                                <form
                                                                  className="form-group"
                                                                  onSubmit={(
                                                                    e
                                                                  ) => {
                                                                    this.handleReplySubmit(
                                                                      e,
                                                                      item.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <h4 className="text-left">
                                                                    {_t(
                                                                      t("Reply")
                                                                    )}
                                                                  </h4>
                                                                  <JoditEditor
                                                                    editorRef={
                                                                      this
                                                                        .setRef
                                                                    }
                                                                    value={
                                                                      this.state
                                                                        .reply
                                                                    }
                                                                    config={
                                                                      this
                                                                        .config
                                                                    }
                                                                    onChange={
                                                                      this
                                                                        .updateReply
                                                                    }
                                                                  />
                                                                  <div className="text-left">
                                                                    {this.state
                                                                      .replyLoading ? (
                                                                      <button
                                                                        type="submit"
                                                                        className="btn btn-sm px-5 btn-success mt-2 text-uppercase"
                                                                        disabled
                                                                      >
                                                                        {_t(
                                                                          t(
                                                                            "Please wait"
                                                                          )
                                                                        )}
                                                                      </button>
                                                                    ) : (
                                                                      <button
                                                                        type="submit"
                                                                        className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                                                      >
                                                                        {_t(
                                                                          t(
                                                                            "Add Reply"
                                                                          )
                                                                        )}
                                                                      </button>
                                                                    )}
                                                                  </div>
                                                                </form>
                                                              </div>
                                                            </div>
                                                          </>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="col-md-6 offset-3 card card-body bg-light"
                                                  style={{ minHeight: "120px" }}
                                                >
                                                  <div className="text-center my-auto">
                                                    <div className="text-center">
                                                      <h3 className="bg-light text-danger px-5 mt-2">
                                                        {_t(
                                                          t("No comments yet!")
                                                        )}
                                                      </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                              ),
                                            ]}
                                      </div>
                                      <div className="row mb-2">
                                        <h4 className="col-md-10 offset-md-1 course-heading text-uppercase text-center my-2 py-1 rounded-sm">
                                          {_t(t("Add New Comment"))}
                                        </h4>
                                      </div>
                                      <form
                                        className="row"
                                        onSubmit={this.handleSubmit}
                                      >
                                        <div className="col-md-10 offset-md-1">
                                          <JoditEditor
                                            editorRef={this.setRef}
                                            value={this.state.comment}
                                            config={this.config}
                                            onChange={this.updateContent}
                                          />
                                        </div>
                                        {this.state.commentLoading ? (
                                          <button
                                            className="btn btn-success btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                                            disabled
                                          >
                                            {_t(t("Please wait"))}
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                                            disabled={!this.state.commentBtn}
                                          >
                                            {_t(t("Add comment"))}
                                          </button>
                                        )}
                                      </form>
                                    </div>
                                  )}
                                  {/* eita notum code er shesh tori */}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return false;
      }
    }
  }
}
export default withTranslation()(withRouter(SingleCourse));
