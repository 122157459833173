import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
class Sidebar extends Component {
  render() {
    const { path, t } = this.props;
    return (
      <>
        <div id="accordion" className="pl-1">
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-1"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-1"
                  aria-expanded="false"
                  aria-controls="collapse-1"
                >
                  {_t(t("Dashboard"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-1"
              className={`collapse + ${
                path === "/admin" || path === "/admin/" ? "show" : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-1"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Dashboard"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-assignments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-assignments"
                  aria-expanded="false"
                  aria-controls="collapse-assignments"
                >
                  {_t(t("Assignments"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-assignments"
              className={`collapse + ${
                path === "/admin/check-assignments" ||
                path === "/admin/check-assignments/" ||
                path === `/admin/check-assignments/${this.props.id}` ||
                path === `/admin/check-assignments/${this.props.id}/` ||
                path === "/admin/manage-assignments" ||
                path === "/admin/manage-assignments/" ||
                path === `/admin/view-assignments/${this.props.id}` ||
                path === `/admin/view-assignments/${this.props.id}/` ||
                path === "/admin/manage-feedback" ||
                path === "/admin/manage-feedback/" ||
                path === `/admin/check-feedback/${this.props.id}` ||
                path === `/admin/check-feedback/${this.props.id}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-assignments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/manage-feedback"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-feedback" ||
                    path === "/admin/manage-feedback/" ||
                    path === `/admin/check-feedback/${this.props.id}` ||
                    path === `/admin/check-feedback/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Feedback"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/check-assignments"
                  className={`nav-link py-0 + ${
                    path === "/admin/check-assignments" ||
                    path === "/admin/check-assignments/" ||
                    path === `/admin/check-assignments/${this.props.id}` ||
                    path === `/admin/check-assignments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("New Assignments"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/manage-assignments"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-assignments" ||
                    path === "/admin/manage-assignments/" ||
                    path === `/admin/view-assignments/${this.props.id}` ||
                    path === `/admin/view-assignments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Checked Assignments"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-comments"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-comments"
                  aria-expanded="false"
                  aria-controls="collapse-comments"
                >
                  {_t(t("Discussion"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-comments"
              className={`collapse + ${
                path === "/admin/check-comments" ||
                path === "/admin/check-comments/" ||
                path === `/admin/check-comments/${this.props.id}` ||
                path === `/admin/check-comments/${this.props.id}/` ||
                path === "/admin/check-comments/courses" ||
                path === "/admin/check-comments/courses/" ||
                path === `/admin/check-comments/courses/${this.props.id}` ||
                path === `/admin/check-comments/courses/${this.props.id}/` ||
                path === `/admin/check-comments/class/${this.props.id}` ||
                path === `/admin/check-comments/class/${this.props.id}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-comments"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/check-comments"
                  className={`nav-link py-0 + ${
                    path === "/admin/check-comments" ||
                    path === "/admin/check-comments/" ||
                    path === `/admin/check-comments/${this.props.id}` ||
                    path === `/admin/check-comments/${this.props.id}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Check Comments"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/check-comments/courses"
                  className={`nav-link py-0
                    ${
                      path === "/admin/check-comments/courses" ||
                      path === "/admin/check-comments/courses/" ||
                      path ===
                        `/admin/check-comments/courses/${this.props.id}` ||
                      path ===
                        `/admin/check-comments/courses/${this.props.id}/` ||
                      path === `/admin/check-comments/class/${this.props.id}` ||
                      path === `/admin/check-comments/class/${this.props.id}/`
                        ? "sidebar-active"
                        : ""
                    }
                  `}
                >
                  {_t(t("All Discussion"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-4"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-5"
                  aria-expanded="false"
                  aria-controls="collapse-5"
                >
                  {_t(t("Enrollments"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-5"
              className={`collapse + ${
                path === "/admin/new-enrollment" ||
                path === "/admin/due-enrollment" ||
                path === "/admin/due-enrollment/" ||
                path === "/admin/blocked-enrollment" ||
                path === "/admin/blocked-enrollment/" ||
                path === "/admin/manage-enrollment" ||
                path === "/admin/new-enrollment/" ||
                path === "/admin/manage-enrollment/" ||
                path === `/admin/enrollment-info/${this.props.id}` ||
                path === `/admin/enrollment-info/${this.props.id}//` ||
                path === `/admin/edit-enrollment/${this.props.id}` ||
                path === `/admin/edit-enrollment/${this.props.id}//`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-5"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/new-enrollment"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("New Enrollment"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/due-enrollment"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Due Enrollments"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/blocked-enrollment"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Blocked Enrollments"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/manage-enrollment"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-enrollment" ||
                    path === "/admin/manage-enrollment/" ||
                    path === `/admin/enrollment-info/${this.props.id}` ||
                    path === `/admin/enrollment-info/${this.props.id}//` ||
                    path === `/admin/edit-enrollment/${this.props.id}` ||
                    path === `/admin/edit-enrollment/${this.props.id}//`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Enrollments"))}
                </NavLink>
              </div>
            </div>
          </div>

          {/* notice start */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-notices"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-notice"
                  aria-expanded="false"
                  aria-controls="collapse-notice"
                >
                  Notice
                </a>
              </h5>
            </div>
            <div
              id="collapse-notice"
              className={`nav-link py-0 collapse + ${
                path === "/admin/notice/all-notice" ||
                path === "/admin/notice/add-notice" ||
                path === `/admin/notice/edit-notice/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-notices"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/notice/all-notice"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  All Notice
                </NavLink>

                <NavLink
                  exact
                  to="/admin/notice/add-notice"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Add Notice
                </NavLink>
              </div>
            </div>
          </div>

          {/* notice end */}

          {/* upload files start */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-upload-files"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-files"
                  aria-expanded="false"
                  aria-controls="collapse-files"
                >
                  Upload
                </a>
              </h5>
            </div>
            <div
              id="collapse-files"
              className={`nav-link py-0 collapse + ${
                path === "/admin/files/categories" ||
                path === "/admin/files/all-upload-file" ||
                path === "/admin/files/add-upload-file" ||
                path === `/admin/files/edit-cate/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-upload-files"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/files/add-upload-file"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Add new file
                </NavLink>

                <NavLink
                  exact
                  to="/admin/files/all-upload-file"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  All Files
                </NavLink>

                <NavLink
                  exact
                  to="/admin/files/categories"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Categories
                </NavLink>
              </div>
            </div>
          </div>

          {/* uploads files end */}

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header  admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-3"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-2"
                  aria-expanded="false"
                  aria-controls="collapse-2"
                >
                  {_t(t("Courses"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-2"
              className={`collapse + ${
                path === "/admin/add-course" ||
                path === "/admin/add-course/" ||
                path === `/admin/add-course/${this.props.id}/add-class` ||
                path === `/admin/add-course/${this.props.id}/add-class/` ||
                path ===
                  `/admin/add-course/${this.props.id}/add-class/class-contents/${this.props.classid}` ||
                path ===
                  `/admin/add-course/${this.props.id}/add-class/class-contents/${this.props.classid}/` ||
                path === "/admin/manage-course" ||
                path === "/admin/manage-course/" ||
                path === `/admin/course-info/${this.props.id}` ||
                path === `/admin/course-info/${this.props.id}/` ||
                path === `/admin/edit-course/${this.props.id}` ||
                path === `/admin/edit-course/${this.props.id}/` ||
                path === `/admin/course/manage-mods/${this.props.id}` ||
                path === `/admin/course/manage-mods/${this.props.id}/` ||
                path === `/admin/course/manage-classes/${this.props.id}` ||
                path === `/admin/course/manage-classes/${this.props.id}/` ||
                path ===
                  `/admin/course/${this.props.id}/edit-class/${this.props.classid}` ||
                path ===
                  `/admin/course/${this.props.id}/edit-class/${this.props.classid}/` ||
                path ===
                  `/admin/edit-course/${this.props.id}/edit-class/class-contents/${this.props.classid}` ||
                path ===
                  `/admin/edit-course/${this.props.id}/edit-class/class-contents/${this.props.classid}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-2"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/add-course"
                  className={`nav-link py-0 + ${
                    path === "/admin/add-course" ||
                    path === "/admin/add-course/" ||
                    path === `/admin/add-course/${this.props.id}/add-class` ||
                    path ===
                      `/admin/add-course/${this.props.id}/add-class/class-contents/${this.props.classid}` ||
                    path ===
                      `/admin/add-course/${this.props.id}/add-class/class-contents/${this.props.classid}/` ||
                    path === `/admin/add-course/${this.props.id}/add-class/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Add New Course"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/manage-course"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-course" ||
                    path === "/admin/manage-course/" ||
                    path === `/admin/course-info/${this.props.id}` ||
                    path === `/admin/edit-course/${this.props.id}` ||
                    path === `/admin/edit-course/${this.props.id}/` ||
                    path === `/admin/course/manage-mods/${this.props.id}` ||
                    path === `/admin/course/manage-mods/${this.props.id}/` ||
                    path === `/admin/course/manage-classes/${this.props.id}` ||
                    path === `/admin/course/manage-classes/${this.props.id}/` ||
                    path ===
                      `/admin/course/${this.props.id}/edit-class/${this.props.classid}` ||
                    path ===
                      `/admin/course/${this.props.id}/edit-class/${this.props.classid}/` ||
                    path ===
                      `/admin/edit-course/${this.props.id}/edit-class/class-contents/${this.props.classid}` ||
                    path ===
                      `/admin/edit-course/${this.props.id}/edit-class/class-contents/${this.props.classid}/`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Courses"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header  admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-3"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-3"
                  aria-expanded="false"
                  aria-controls="collapse-3"
                >
                  {_t(t("Instructors"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-3"
              className={`collapse + ${
                path === "/admin/add-instructor" ||
                path === "/admin/manage-instructor" ||
                path === "/admin/add-instructor/" ||
                path === "/admin/manage-instructor/" ||
                path === `/admin/edit-instructor/${this.props.id}` ||
                path === `/admin/instructor-info/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-3"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/add-instructor"
                  activeClassName="sidebar-active"
                  className={`nav-link py-0`}
                >
                  {_t(t("Add Instructor"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/manage-instructor"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-instructor" ||
                    path === "/admin/manage-instructor/" ||
                    path === `/admin/edit-instructor/${this.props.id}` ||
                    path === `/admin/instructor-info/${this.props.id}`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Instructors"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header  admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-3"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-moderator"
                  aria-expanded="false"
                  aria-controls="collapse-moderator"
                >
                  {_t(t("Moderators"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-moderator"
              className={`collapse + ${
                path === "/admin/add-moderator" ||
                path === "/admin/manage-moderator" ||
                path === "/admin/add-moderator/" ||
                path === "/admin/manage-moderator/" ||
                path === `/admin/edit-moderator/${this.props.id}` ||
                path === `/admin/moderator-info/${this.props.id}`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-moderator"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/add-moderator"
                  activeClassName="sidebar-active"
                  className={`nav-link py-0`}
                >
                  {_t(t("Add Moderator"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/manage-moderator"
                  className={`nav-link py-0 + ${
                    path === "/admin/manage-moderator" ||
                    path === "/admin/manage-moderator/" ||
                    path === `/admin/edit-moderator/${this.props.id}` ||
                    path === `/admin/moderator-info/${this.props.id}`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Moderator"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-4"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-4"
                  aria-expanded="false"
                  aria-controls="collapse-4"
                >
                  {_t(t("Students"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-4"
              className={`collapse + ${
                path === "/admin/add-student" ||
                path === "/admin/manage-student" ||
                path === "/admin/student-requests" ||
                path === "/admin/student-requests/" ||
                path === "/admin/add-student/" ||
                path === "/admin/manage-student/" ||
                path === `/admin/edit-student/${this.props.id}` ||
                path === `/admin/student-info/${this.props.id}` ||
                path ===
                  `/admin/approve-registraton-request/${this.props.id}` ||
                path === `/admin/approve-registraton-request/${this.props.id}/`
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-4"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/student-requests"
                  className={`nav-link py-0 + ${
                    path === `/admin/student-requests` ||
                    path ===
                      `/admin/approve-registraton-request/${this.props.id}/` ||
                    path ===
                      `/admin/approve-registraton-request/${this.props.id}`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Requests"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/add-student"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Add New Student"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/manage-student"
                  className={`nav-link py-0 + ${
                    path === `/admin/manage-student` ||
                    path === `/admin/edit-student/${this.props.id}` ||
                    path === `/admin/student-info/${this.props.id}`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Students"))}
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-4"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed sidebar-active"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-6"
                  aria-expanded="false"
                  aria-controls="collapse-6"
                >
                  {_t(t("Settings"))}
                </a>
              </h5>
            </div>
            <div
              id="collapse-6"
              className={`collapse + ${
                path === "/admin/settings/add-admin" ||
                path === "/admin/settings/add-admin/" ||
                path === "/admin/settings/manage-admin" ||
                path === "/admin/settings/manage-admin/" ||
                path === `/admin/settings/edit-admin/${this.props.id}` ||
                path === `/admin/settings/admin-info/${this.props.id}` ||
                path === "/admin/settings/update-contacts" ||
                path === "/admin/settings/update-contacts/" ||
                path === "/admin/settings/enrollment-contacts" ||
                path === "/admin/settings/enrollment-contacts/" ||
                path === "/admin/settings/sliders/" ||
                path === "/admin/settings/sliders" ||
                path === "/admin/settings/update-links" ||
                path === "/admin/settings/update-links/" ||
                path === "/admin/settings/footer/" ||
                path === "/admin/settings/footer" ||
                path === "/admin/settings/logo/" ||
                path === "/admin/settings/logo" ||
                path === "/admin/settings/sms/" ||
                path === "/admin/settings/sms" ||
                path === "/admin/settings/smtp/" ||
                path === "/admin/settings/smtp" ||
                path === "/admin/settings/new-hero" ||
                path === "/admin/settings/new-hero" ||
                path === "/admin/settings/promote" ||
                path === "/admin/settings/promote" ||
                path === "/admin/settings/languages/" ||
                path === "/admin/settings/languages"
                  ? "show"
                  : ""
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-6"
            >
              <div className="card-body">
                <NavLink
                  exact
                  to="/admin/settings/add-admin"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Add Admin"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/settings/manage-admin"
                  className={`nav-link py-0 + ${
                    path === "/admin/settings/manage-admin" ||
                    path === "/admin/settings/manage-admin/" ||
                    path === `/admin/settings/edit-admin/${this.props.id}` ||
                    path === `/admin/settings/admin-info/${this.props.id}`
                      ? "sidebar-active"
                      : ""
                  }`}
                >
                  {_t(t("Manage Admins"))}
                </NavLink>
                <NavLink
                  exact
                  to="/admin/settings/update-contacts"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Contact Number"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/enrollment-contacts"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Enrollment Contacts"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/update-links"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Social Links"))}
                </NavLink>
                {/* <NavLink
                  exact
                  to=""
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Navbar Settings
                </NavLink> */}
                <NavLink
                  exact
                  to="/admin/settings/sliders"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Slider Settings"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/new-hero"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Hero section
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/promote"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Promotion section
                </NavLink>
                {/* <NavLink
                  exact
                  to=""
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  All Course Hero
                </NavLink>
                <NavLink
                  exact
                  to=""
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  My Course Hero
                </NavLink>
                <NavLink
                  exact
                  to=""
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Forum Page Hero
                </NavLink>
                <NavLink
                  exact
                  to=""
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  Assignment Hero
                </NavLink> */}

                <NavLink
                  exact
                  to="/admin/settings/footer"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Footer Text"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/languages"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Languages"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/logo"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Logo"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/smtp"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Smtp"))}
                </NavLink>

                <NavLink
                  exact
                  to="/admin/settings/sms"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >
                  {_t(t("Sms"))}
                </NavLink>
              </div>
            </div>
          </div>


          {/* support start */}
          <div className="card admin-sidebar border-right-0">
            <div
              className="card-header admin-card-header"
              style={{ borderRadius: 0 }}
              id="heading-notices"
            >
              <h5 className="mb-0">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-session"
                  aria-expanded="false"
                  aria-controls="collapse-session"
                >
                  Support
                </a>
              </h5>
            </div>
            <div
              id="collapse-session"
              className={`nav-link py-0 collapse + ${
                path === "/admin/notice/add-session"
              }`}
              data-parent="#accordion"
              aria-labelledby="heading-notices"
            >
              <div className="card-body">
                

                <NavLink
                  exact
                  to="/admin/support/add-session"
                  activeClassName="sidebar-active"
                  className="nav-link py-0"
                >Support
                </NavLink>
              </div>
            </div>
          </div>

          {/* support end */}
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(Sidebar));
