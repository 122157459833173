import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "../Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Skeleton from "react-loading-skeleton";
import axios from "axios";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class AddEnrollment extends Component {
  state = {
    path: "",
    courses: [],
    students: [],
    selectedStudents: [],
    batch: "",
    roll: "",
    course_id: "",
    course_fee: "",
    discount: "",
    payable: "",

    first_payment: "",
    first_payment_date: null,

    second_payable: "",
    second_payment_date: null,
    second_payment: "",
    second_paid_date: null,

    third_payable: "",
    third_payment_date: null,
    third_payment: "",
    third_paid_date: null,

    fourth_payable: "",
    fourth_payment_date: null,
    fourth_payment: "",
    fourth_paid_date: null,

    fifth_payable: "",
    fifth_payment_date: null,
    fifth_payment: "",
    fifth_paid_date: null,

    allow_class_from: "",
    allow_class_to: "",
    isMobile: false,
    sidebar: true,
    error: [],
    loading: false,
  };
  componentDidMount() {
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    document.title = "Add New Enrollment";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const url = BASE_URL + "/api/admin/course/p170815s";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          courses: res.data,
        });
        const students_url = BASE_URL + "/api/admin/student/student_p170815s";
        return axios
          .get(students_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              students: res.data,
              loading: false,
            });
          });
      });
  }

  handleSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChangeCourse = (e) => {
    this.setState({
      course_id: e.target.value,
    });
    const course_url =
      BASE_URL + `/api/admin/course/p170815s/${e.target.value}`;
    return axios
      .get(course_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          course_fee: res.data.fee,
          payable: res.data.fee - this.state.discount,
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDiscount = (e) => {
    let payableAmount = "";
    payableAmount = this.state.course_fee - e.target.value;
    this.setState({
      payable: payableAmount,
      discount: e.target.value,
    });
  };

  handleStudents = (student) => {
    this.setState({
      selectedStudents: student,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/enrollment/enrollment_p170815s";
    const formData = {
      course_id: this.state.course_id,
      selectedStudents: this.state.selectedStudents,
      batch: this.state.batch,
      roll: this.state.roll,
      course_fee: this.state.course_fee,
      discount: this.state.discount,
      payable: this.state.payable,

      first_payment: this.state.first_payment,
      first_payment_date: this.state.first_payment_date,

      second_payable: this.state.second_payable,
      second_payment_date: this.state.second_payment_date,
      second_payment: this.state.second_payment,
      second_paid_date: this.state.second_paid_date,

      third_payable: this.state.third_payable,
      third_payment_date: this.state.third_payment_date,
      third_payment: this.state.third_payment,
      third_paid_date: this.state.third_paid_date,

      fourth_payable: this.state.fourth_payable,
      fourth_payment_date: this.state.fourth_payment_date,
      fourth_payment: this.state.fourth_payment,
      fourth_paid_date: this.state.fourth_paid_date,

      fifth_payable: this.state.fifth_payable,
      fifth_payment_date: this.state.fifth_payment_date,
      fifth_payment: this.state.fifth_payment,
      fifth_paid_date: this.state.fifth_paid_date,

      allow_class_from: this.state.allow_class_from
        ? this.state.allow_class_from - 1
        : "",
      allow_class_to: this.state.allow_class_to
        ? this.state.allow_class_to - 1
        : "",
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.props.history.push({
          pathname: "/admin/manage-enrollment",
          state: { alert_success: res.data },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handleFirstPaymentDate = (date) => {
    this.setState({
      first_payment_date: date,
    });
  };

  handleSecondPaymentDate = (date) => {
    this.setState({
      second_payment_date: date,
    });
  };
  handleSecondPaidDate = (date) => {
    this.setState({
      second_paid_date: date,
    });
  };

  handleThirdPaymentDate = (date) => {
    this.setState({
      third_payment_date: date,
    });
  };
  handleThirdPaidDate = (date) => {
    this.setState({
      third_paid_date: date,
    });
  };

  handleFourthPaymentDate = (date) => {
    this.setState({
      fourth_payment_date: date,
    });
  };
  handleFourthPaidDate = (date) => {
    this.setState({
      fourth_paid_date: date,
    });
  };

  handleFifthPaymentDate = (date) => {
    this.setState({
      fifth_payment_date: date,
    });
  };
  handleFifthPaidDate = (date) => {
    this.setState({
      fifth_paid_date: date,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <div to="#" className="nav-link text-dark">
                    <h4>
                      <i
                        className="fa fa-chevron-right"
                        style={{ fontSize: 20 }}
                      ></i>{" "}
                      Add New Enrollment
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="container">
                        <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-3 pb-0">
                          <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                            Please fill the form accordingly
                          </div>
                          {this.state.loading ? (
                            <>
                              <h1>
                                <Skeleton />
                              </h1>
                              <h3>
                                <Skeleton count={5} />
                              </h3>
                              <Skeleton count={5} />
                            </>
                          ) : (
                            <form
                              className="form-group"
                              onSubmit={this.handleSubmit}
                            >
                              <div className="input-group col-md-12">
                                <label className="my-auto col-md-3">
                                  <b>Course</b>
                                </label>
                                <select
                                  name="course_id"
                                  className="form-control rounded-sm pl-2"
                                  onChange={this.handleChangeCourse}
                                  value={this.state.course_id}
                                  required
                                >
                                  <option value="">
                                    Please select a course
                                  </option>
                                  {/* Loop Here */}
                                  {this.state.courses.map((item) => {
                                    return (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                        className="text-capitalize"
                                      >{`${item.title}`}</option>
                                    );
                                  })}
                                  {/* Loop Here */}
                                </select>
                              </div>

                              <div className="input-group col-md-12 mt-2">
                                <label className="my-auto col-md-3">
                                  <b>Student</b>
                                </label>
                                <Select
                                  options={this.state.students}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) =>
                                    option.fname +
                                    " " +
                                    option.lname +
                                    " " +
                                    [`(${option.phn_no})`]
                                  }
                                  getOptionValue={(option) => option.id}
                                  className="basic-multi-select col-md-9 px-0"
                                  classNamePrefix="select"
                                  isMulti
                                  onChange={this.handleStudents}
                                  placeholder="Please select student(s)"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.selectedStudents}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="batch"
                                  className="my-auto col-md-3"
                                >
                                  <b>Batch</b>
                                </label>
                                <input
                                  type="text"
                                  id="batch"
                                  className="form-control col-md-9 rounded-sm"
                                  name="batch"
                                  value={this.state.batch}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.batch ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.batch.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="roll"
                                  className="my-auto col-md-3"
                                >
                                  <b>Roll No.</b>
                                </label>
                                <input
                                  type="text"
                                  id="roll"
                                  className="form-control col-md-9 rounded-sm"
                                  name="roll"
                                  value={this.state.roll}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.roll ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.roll.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label className="my-auto col-md-3">
                                  <b>Class Access</b>
                                </label>
                                <span className="col-md-3 form-control bg-warning mr-auto text-dark">
                                  Optional
                                </span>
                                <input
                                  type="number"
                                  id="allow_class_from"
                                  className="form-control col-md-3 rounded-sm mb-2 mr-auto"
                                  name="allow_class_from"
                                  placeholder="From.."
                                  value={this.state.allow_class_from}
                                  onChange={this.handleChange}
                                  min="1"
                                />
                                <input
                                  type="number"
                                  id="allow_class_to"
                                  className="form-control col-md-3 rounded-sm mb-2 ml-auto"
                                  name="allow_class_to"
                                  placeholder="To.."
                                  value={this.state.allow_class_to}
                                  onChange={this.handleChange}
                                  required={this.state.allow_class_from}
                                  min="1"
                                />
                              </div>

                              <div className="input-group col-md-12">
                                <label className="my-auto col-md-3">
                                  <b>Course Fee</b>
                                </label>
                                <span className="form-control col-md-9 rounded-sm border-right-0">
                                  <span className="text-left text-info">
                                    {this.state.course_fee ||
                                      "Select a course to see.."}
                                  </span>
                                </span>
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="discount"
                                  className="my-auto col-md-3"
                                >
                                  <b>Discount</b>
                                </label>
                                <input
                                  type="number"
                                  id="discount"
                                  className="form-control col-md-9 rounded-sm border-right-0"
                                  name="discount"
                                  placeholder="Amount of discount"
                                  value={this.state.discount}
                                  onChange={this.handleDiscount}
                                  disabled={!this.state.course_id}
                                  min="0"
                                  max={this.state.course_fee}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.discount}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label className="my-auto col-md-3">
                                  <b>Total Payable</b>
                                </label>
                                <span className="form-control col-md-9 rounded-sm border-right-0">
                                  <span className="text-left text-info">
                                    {this.state.payable || "0"}
                                  </span>
                                </span>
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="first_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>1st Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="first_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="first_payment"
                                  placeholder="First payment amount"
                                  value={this.state.first_payment}
                                  onChange={this.handleChange}
                                  required
                                  min="0"
                                  max={this.state.payable}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.first_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="first_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>1st Paid Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="first_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="first_payment_date"
                                  className="form-control"
                                  value={this.state.first_payment_date}
                                  onChange={this.handleChange}
                                  required
                                /> */}
                                <DatePicker
                                  selected={this.state.first_payment_date}
                                  onChange={this.handleFirstPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.first_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.first_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="second_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="second_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payable"
                                  placeholder="Second payable amount"
                                  value={this.state.second_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payable}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="second_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payment_date"
                                  className="form-control"
                                  value={this.state.second_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.second_payment_date}
                                  onChange={this.handleSecondPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.second_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="second_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="second_payment"
                                  placeholder="Second payment amount"
                                  value={this.state.second_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="second_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>2nd Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="second_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_paid_date"
                                    className="form-control"
                                    value={this.state.second_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.second_paid_date}
                                  onChange={this.handleSecondPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_second_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.second_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.second_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="third_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="third_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payable"
                                  placeholder="Third payable amount"
                                  value={this.state.third_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payable}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="third_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payment_date"
                                  className="form-control"
                                  value={this.state.third_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.third_payment_date}
                                  onChange={this.handleThirdPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.third_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="third_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="third_payment"
                                  placeholder="Third payment amount"
                                  value={this.state.third_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="third_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>3rd Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.third_paid_date}
                                  onChange={this.handleThirdPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_third_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.third_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.third_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="fourth_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="fourth_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payable"
                                  placeholder="Fourth payable amount"
                                  value={this.state.fourth_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payable}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="fourth_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payment_date"
                                  className="form-control"
                                  value={this.state.fourth_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.fourth_payment_date}
                                  onChange={this.handleFourthPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fourth_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="fourth_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fourth_payment"
                                  placeholder="Fourth payment amount"
                                  value={this.state.fourth_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fourth_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>4th Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.fourth_paid_date}
                                  onChange={this.handleFourthPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_fourth_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fourth_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fourth_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-5">
                                <label
                                  htmlFor="fifth_payable"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payable</b>
                                </label>
                                <input
                                  type="number"
                                  id="fifth_payable"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payable"
                                  placeholder="Fifth payable amount"
                                  value={this.state.fifth_payable}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payable}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_payment_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payment Date</b>
                                </label>
                                {/* <input
                                  type="date"
                                  id="fifth_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payment_date"
                                  className="form-control"
                                  value={this.state.fifth_payment_date}
                                  onChange={this.handleChange}
                                /> */}
                                <DatePicker
                                  selected={this.state.fifth_payment_date}
                                  onChange={this.handleFifthPaymentDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payment_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fifth_payment_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_payment"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Payment</b>
                                </label>
                                <input
                                  type="number"
                                  id="fifth_payment"
                                  className="form-control col-md-9 rounded-sm"
                                  name="fifth_payment"
                                  placeholder="Fifth payment amount"
                                  value={this.state.fifth_payment}
                                  onChange={this.handleChange}
                                />
                                <div class="input-group-append border-left-0">
                                  <div class="input-group-text">BDT</div>
                                </div>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_payment}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="fifth_paid_date"
                                  className="my-auto col-md-3"
                                >
                                  <b>5th Paid Date</b>
                                </label>
                                {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                <DatePicker
                                  selected={this.state.fifth_paid_date}
                                  onChange={this.handleFifthPaidDate}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control rounded-sm"
                                  placeholderText={
                                    this.state.given_fifth_paid_date
                                  }
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-2">
                                  {this.state.error.fifth_paid_date ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fifth_paid_date.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="text-center col">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-sm mt-4 px-4"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(AddEnrollment);
