import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";

import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Switch from "react-switch";

// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const $ = require("jquery");

class Languages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langs: [],
      code: "",
      name: "",
      defaultLanguage: null,
      toTranslate: null,
      tCode: null,
      translate: false,
      loading: false,
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "SMS Settings";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });
    const url = BASE_URL + `/api/languages`;
    axios.get(url).then((res) => {
      let df = res.data.find((item) => {
        return item.defaultLang === 1;
      });
      this.setState({
        langs: res.data,
        defaultLanguage: df ? df.id : null,
        loading: false,
      });
    });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + `/api/admin/languageStore`;
    const formData = {
      code: this.state.code,
      name: this.state.name,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          langs: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleTranslate = (code) => {
    this.setState({
      loading: true,
      tCode: code,
    });
    const t_url = BASE_URL + `/api/language/${code}`;
    axios.get(t_url).then((res) => {
      console.log(res.data);
      this.setState({
        toTranslate: Object.entries(res.data),
        translate: true,
        loading: false,
      });
    });
  };

  handleTranslationInput = (e, index, key) => {
    let temp = [];
    this.state.toTranslate.map((item, indexItem) => {
      if (index === indexItem) {
        temp.push([key, e.target.value]);
      } else {
        temp.push(item);
      }
    });

    this.setState({
      toTranslate: temp,
    });
  };

  handleSubmitTranslation = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    let data = Object.fromEntries(
      this.state.toTranslate.filter(([k, v]) => {
        return true; // some irrelevant conditions here
      })
    );

    let formData = {
      data: data,
      code: this.state.tCode,
    };

    const t_url = BASE_URL + `/api/admin/translationSave`;
    return axios
      .post(t_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          toTranslate: Object.entries(res.data),
          translate: true,
          loading: false,
        });

        this.notifyT();
      });
  };

  //translate in one click
  handleCopy = () => {
    let a = this.state.toTranslate;
    let temp = [];

    $("#tranlation-table > tbody  > tr").each(function (index, tr) {
      $(tr).find(".value").val($(tr).find(".key").text());
      let newTT = $(tr).find(".key").text();
      a.map((items, itemIndex) => {
        if (itemIndex === index) {
          temp.push([items[0], newTT]);
        }
      });
    });

    this.setState({
      toTranslate: temp,
    });
  };

  //delete lang
  handleDelete = (id) => {
    if (id !== 1) {
      const lang_url = BASE_URL + `/api/admin/delete_lang/${id}`;
      return axios
        .delete(lang_url, {
          headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
        })
        .then(() => {
          const url = BASE_URL + `/api/languages`;
          axios.get(url).then((res) => {
            let df = res.data.find((item) => {
              return item.defaultLang === 1;
            });
            this.setState({
              langs: res.data,
              defaultLanguage: df ? df.id : null,
            });
          });
          this.notify();
        });
    } else {
      const { t } = this.props;
      toast.error(`${t("English language can not be deleted!")}`, {
        position: "bottom-center",
        className: "text-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "toast-notification",
      });
    }
  };
  handleDefault = (code) => {
    const t_url = BASE_URL + `/api/admin/setDefault`;
    let formData = {
      code: code,
    };
    return axios
      .post(t_url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
      })
      .then(() => {
        const url = BASE_URL + `/api/languages`;
        axios.get(url).then((res) => {
          let df = res.data.find((item) => {
            return item.defaultLang === 1;
          });
          this.setState({
            langs: res.data,
            defaultLanguage: df ? df.id : null,
          });
        });
      });
  };

  notify = () => {
    const { t } = this.props;
    toast.error(`${t("Language has been deleted!")}`, {
      position: "bottom-center",
      className: "text-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "toast-notification",
    });
  };

  notifyT = () => {
    const { t } = this.props;
    toast.success(`${t("Translations added successfully")}`, {
      position: "bottom-center",
      className: "text-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "toast-notification",
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Language Settings
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  {this.state.loading ? (
                    <Skeleton className="login-card" />
                  ) : (
                    [
                      !this.state.translate ? (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card mb-3">
                              <div className="card-header">
                                <h2 className="card-title">Language List</h2>
                              </div>
                              <div className="card-body table-responsive">
                                <table className="table table-striped- table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>S/L</th>
                                      <th>Code</th>
                                      <th>Name</th>
                                      <th>Set as default</th>
                                      {/* <th>Logo</th> */}
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.langs.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{item.code}</td>
                                          <td>{item.name}</td>
                                          <td className="text-center">
                                            <Switch
                                              onChange={() =>
                                                this.handleDefault(item.code)
                                              }
                                              checked={
                                                item.defaultLang === 1
                                                  ? true
                                                  : false
                                              }
                                              height={22}
                                              width={44}
                                              offColor="#ee5253"
                                            />
                                          </td>
                                          <td>
                                            <div className="row mx-2">
                                              <button
                                                className="btn btn-sm btn-secondary col"
                                                style={{ borderRadius: "0" }}
                                                onClick={() => {
                                                  this.handleTranslate(
                                                    item.code
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </button>

                                              {/* MODAL TRIGGER */}
                                              <button
                                                className="btn btn-sm btn-danger col"
                                                style={{ borderRadius: "0" }}
                                                data-toggle="modal"
                                                data-target={`#myModalDelete${item.id}`}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                              {/* MODAL */}
                                              <div
                                                className="modal"
                                                id={`myModalDelete${item.id}`}
                                              >
                                                <div className="modal-dialog">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5 className="modal-title">
                                                        Are you sure want to
                                                        delete this admin?
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        data-dismiss="modal"
                                                      >
                                                        &times;
                                                      </button>
                                                    </div>
                                                    <div className="modal-body">
                                                      <div className="row">
                                                        <div className="col-md-8">
                                                          <h6>
                                                            <b>
                                                              Language:{" "}
                                                              {item.name}
                                                            </b>
                                                          </h6>
                                                          <h6>
                                                            <b>
                                                              Code: {item.code}{" "}
                                                            </b>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                      <div className="modal-footer justify-content-around">
                                                        <div className="row ">
                                                          <button
                                                            className="btn btn-danger mr-3"
                                                            onClick={() => {
                                                              this.handleDelete(
                                                                item.id
                                                              );
                                                            }}
                                                            data-dismiss="modal"
                                                          >
                                                            Yes
                                                          </button>
                                                          <button
                                                            className="btn btn-success"
                                                            data-dismiss="modal"
                                                          >
                                                            No
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card mb-3">
                              <div className="card-header">
                                <h3 className="card-title">Add Language</h3>
                              </div>
                              <div className="card-body">
                                <form
                                  className="form-horizontal"
                                  onSubmit={this.handleSubmit}
                                >
                                  <div className="form-group">
                                    <div className="">
                                      <label className="control-label">
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <div className="">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        required
                                        placeholder="Ex: English"
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="">
                                      <label className="control-label">
                                        Code{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <div className="">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="code"
                                        required
                                        placeholder="Ex: en for english"
                                        onChange={this.handleChange}
                                        value={this.state.code}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="col-lg-12 text-right p-0">
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div className="m-2">
                              <button
                                className="btn btn-secondary ml-2 py-1 px-3"
                                onClick={() => {
                                  this.setState({
                                    translate: !this.state.translate,
                                  });
                                }}
                              >
                                Go Back
                              </button>
                            </div>
                          </div>
                          <div className="card card-primary card-outline">
                            <div className="card-header">
                              <h3 className="panel-title text-center">
                                Translate website into your language
                              </h3>
                              <p className="text-muted">
                                You can translate to any language in a very easy
                                way. Install any google translation extention in
                                your browser, translate the whole page. Click
                                "Copy Translation" button. Click Save.
                              </p>
                            </div>
                            <div className="card-body">
                              <form
                                className="form-horizontal"
                                onSubmit={this.handleSubmitTranslation}
                              >
                                <div className="panel-body">
                                  <table
                                    className="table table-striped table-bordered demo-dt-basic"
                                    cellspacing="0"
                                    width="100%"
                                    id="tranlation-table"
                                  >
                                    <tbody className="">
                                      {this.state.toTranslate.map(
                                        (items, index) => {
                                          return (
                                            <tr className="text-center">
                                              <td>{index + 1}</td>
                                              <td className="key text-left">
                                                {items[0]}
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control value w-100"
                                                  name="translations[]"
                                                  onChange={(e) => {
                                                    this.handleTranslationInput(
                                                      e,
                                                      index,
                                                      items[0]
                                                    );
                                                  }}
                                                  value={items[1]}
                                                />
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>

                                <div className="form-group">
                                  <div className="col-lg-12 text-right">
                                    <button
                                      className="btn btn-warning"
                                      type="button"
                                      onClick={this.handleCopy}
                                    >
                                      Copy Translations
                                    </button>
                                    <button
                                      className="btn btn-success ml-2"
                                      type="submit"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </>
                      ),
                    ]
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(Languages));
