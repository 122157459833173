import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";
import axios from "axios";

import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";

import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";

const cookies = new Cookies();

class ClassForum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_title: "",
      class: [],
      comments: [],
      searchedComments: [],
      replies: [],
      students: [],
      admins: [],
      instructors: [],
      moderators: [],
      comment: "",
      reply: "",
      userId: "",
      search: false,
      search_query: "",
      timeout: 0,
      commentBtn: false,
      commentLoading: false,
      replyLoading: false,
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Problems";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/student/forum/" + this.props.match.params.id;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cookies.get("studentToken")}`,
        },
      })
      .then((res) => {
        if (res.data[2].is_active == 0) {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        } else {
          const url = BASE_URL + "/api/student/get_comments";
          let class_id = {
            class_id: this.props.match.params.classId,
          };
          return axios
            .post(url, class_id, {
              headers: {
                Authorization: `Bearer ${cookies.get("studentToken")}`,
              },
            })
            .then((res) => {
              this.setState({
                comments: res.data[0],
                students: res.data[1],
                admins: res.data[2],
                instructors: res.data[3],
                moderators: res.data[4],
                class: res.data[5],
                userId: res.data[6],
              });
              if (res.data[5] !== null) {
                const get_replies_url = BASE_URL + "/api/student/get_replies";
                let class_id = {
                  class_id: this.props.match.params.classId,
                };
                return axios
                  .post(get_replies_url, class_id, {
                    headers: {
                      Authorization: `Bearer ${cookies.get("studentToken")}`,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      replies: res.data,
                      loading: false,
                    });
                  });
              } else {
                this.props.history.replace({
                  pathname: `/forum`,
                  state: {
                    alert_danger: "Can not perform the action. Contact Admin!",
                  },
                });
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.reload(this.props.history.replace("/"));
        } else {
          this.props.history.replace({
            pathname: `/forum`,
            state: {
              alert_danger: "Can not perform the action. Contact Admin!",
            },
          });
        }
      });
  }

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/student/findComment/" + searchText;
      let formData = {
        add_class_id: this.props.match.params.classId,
      };
      if (searchText == null && searchText == "") {
        this.setState({
          search: false,
        });
      } else {
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedComments: res.data,
            });
          });
      }
    }, 1000);
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  // Jodit Editor plugin
  updateContent = (value) => {
    if (value !== null && value !== "") {
      this.setState({
        commentBtn: true,
        comment: value,
      });
    } else {
      this.setState({
        commentBtn: false,
      });
    }
  };

  updateReply = (e) => {
    this.setState({
      reply: e,
    });
  };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    // readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    spellcheck: false,
    defaultMode: "1",
    allowResizeY: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,image,undo,redo,fullsize",
  };
  // Jodit Editor plugin

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      commentLoading: true,
    });
    let formData = {
      class_id: this.props.match.params.classId,
      comment: this.state.comment,
    };
    const url = BASE_URL + "/api/student/comments";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          comments: res.data,
          comment: "",
          commentLoading: false,
        });
      });
  };

  handleReplySubmit = (e, comment_id) => {
    e.preventDefault();
    this.setState({
      replyLoading: true,
    });
    let formData = {
      class_id: this.props.match.params.classId,
      comment_id: comment_id,
      reply: this.state.reply,
    };
    const url = BASE_URL + "/api/student/replies";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then((res) => {
        this.setState({
          replies: res.data,
          reply: "",
          replyLoading: false,
        });
      });
  };

  commentDelete = (id) => {
    const delete_url = BASE_URL + "/api/student/delete_comments/" + id;
    return axios
      .post(delete_url, null, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then(() => {
        const url = BASE_URL + "/api/student/get_comments";
        let class_id = {
          class_id: this.props.match.params.classId,
        };
        return axios
          .post(url, class_id, {
            headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
          })
          .then((res) => {
            this.setState({
              comments: res.data[0],
              students: res.data[1],
              admins: res.data[2],
              instructors: res.data[3],
              moderators: res.data[4],
              class: res.data[5],
              userId: res.data[6],
            });
            const get_replies_url = BASE_URL + "/api/student/get_replies";
            let class_id = {
              class_id: this.props.match.params.classId,
            };
            return axios
              .post(get_replies_url, class_id, {
                headers: {
                  Authorization: `Bearer ${cookies.get("studentToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  replies: res.data,
                });
              });
          });
      });
  };

  replyDelete = (id) => {
    const delete_url = BASE_URL + "/api/student/delete_replies/" + id;
    return axios
      .post(delete_url, null, {
        headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
      })
      .then(() => {
        const get_replies_url = BASE_URL + "/api/student/get_replies";
        let class_id = {
          class_id: this.props.match.params.classId,
        };
        return axios
          .post(get_replies_url, class_id, {
            headers: {
              Authorization: `Bearer ${cookies.get("studentToken")}`,
            },
          })
          .then((res) => {
            this.setState({
              replies: res.data,
            });
          });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Hero hero="courseForumHero  d-flex align-items-center">
          <Banner
            title={
              <div
                title={
                  "Discussion of " +
                  (this.state.class ? this.state.class.title : "")
                }
              >
                {this.state.loading ? (
                  <Skeleton className="bg-primary mb-2" width="50%" />
                ) : (
                  <h4 className="text-truncate">
                    {this.state.class ? this.state.class.title : ""}
                  </h4>
                )}
                <h5 className="text-truncate">{_t(t("Discussion"))}</h5>
              </div>
            }
            bannerCSS="col-6 mx-auto text-center assignmentCourse-banner"
          >
            <form
              className="col-md-6 offset-md-3"
              onSubmit={this.handleSearchSubmit}
            >
              <div className="input-group">
                <input
                  type="text"
                  name="search_query"
                  className="form-control text-center"
                  placeholder="সমস্যা পোস্টের আগে সার্চ করুন"
                  onChange={this.handleSearch}
                />
                <div className="input-group-append d-none">
                  <button
                    type="submit"
                    className="input-group-text bg-primary text-white"
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </form>
            <Link to={`/forum/${this.props.match.params.id}`}>
              <button type="button" className="btn btn-sm btn-primary px-5">
                {_t(t("Go Back"))}
              </button>
            </Link>
          </Banner>
        </Hero>
        {this.state.loading ? (
          <div className="mx-5">
            <div>
              <h1>
                <Skeleton />
              </h1>
              <h3>
                <Skeleton count={5} />
              </h3>
              <Skeleton count={5} />
            </div>
          </div>
        ) : (
          <div className="container mt-3 mb-2 text-justify col-md-8 offset-md-2">
            <div className="row mb-2 justify-content-center">
              <h3 className="course-heading text-uppercase border-bottom mb-2">
                {_t(t("Class Title"))}:{" "}
                {this.state.class ? this.state.class.title : ""}
              </h3>
            </div>
            <div className="row mb-2">
              {!this.state.search
                ? [
                    this.state.comments.length > 0 ? (
                      <div className="col-md-12">
                        {this.state.comments.map((item) => {
                          return (
                            <div className="card card-body mb-2">
                              <div className="d-flex align-items-center">
                                <div className="avatar">
                                  {item.student_id
                                    ? [
                                        this.state.students.map((stu) => {
                                          if (stu.id == item.student_id) {
                                            return (
                                              <img
                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                alt="dp"
                                                className="rounded-circle"
                                                height="80"
                                                width="80"
                                              />
                                            );
                                          }
                                        }),
                                      ]
                                    : [
                                        item.admin_id
                                          ? [
                                              this.state.admins.map((admin) => {
                                                if (admin.id == item.admin_id) {
                                                  return (
                                                    <img
                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                      alt="dp"
                                                      className="rounded-circle"
                                                      height="80"
                                                      width="80"
                                                    />
                                                  );
                                                }
                                              }),
                                            ]
                                          : [
                                              item.instructor_id
                                                ? [
                                                    this.state.instructors.map(
                                                      (instructor) => {
                                                        if (
                                                          instructor.id ==
                                                          item.instructor_id
                                                        ) {
                                                          return (
                                                            <img
                                                              src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                              alt="dp"
                                                              className="rounded-circle"
                                                              height="80"
                                                              width="80"
                                                            />
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ]
                                                : [
                                                    this.state.moderators.map(
                                                      (moderator) => {
                                                        if (
                                                          moderator.id ==
                                                          item.moderator_id
                                                        ) {
                                                          return (
                                                            <img
                                                              src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                              alt="dp"
                                                              className="rounded-circle"
                                                              height="80"
                                                              width="80"
                                                            />
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ],
                                            ],
                                      ]}
                                </div>

                                <div className="flex-grow-1 pl-4">
                                  {item.student_id
                                    ? [
                                        this.state.students.map((stu) => {
                                          if (stu.id == item.student_id) {
                                            return (
                                              <>
                                                <b>
                                                  {stu.fname + " " + stu.lname}
                                                </b>{" "}
                                                <span>
                                                  (
                                                  <Moment format="LLL">
                                                    {item.created_at}
                                                  </Moment>
                                                  )
                                                </span>
                                                {this.state.userId ==
                                                item.student_id ? (
                                                  <i
                                                    className="fa fa-times ml-2 delete-comment"
                                                    onClick={() =>
                                                      this.commentDelete(
                                                        item.id
                                                      )
                                                    }
                                                    title="Delete Comment"
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            );
                                          }
                                        }),
                                      ]
                                    : [
                                        item.admin_id
                                          ? [
                                              this.state.admins.map((admin) => {
                                                if (admin.id == item.admin_id) {
                                                  return (
                                                    <>
                                                      <b>{admin.name}</b> (
                                                      <span className="text-primary">
                                                        {_t(t("Admin"))}
                                                      </span>
                                                      ){" "}
                                                      <span>
                                                        (
                                                        <Moment format="LLL">
                                                          {item.created_at}
                                                        </Moment>
                                                        )
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              }),
                                            ]
                                          : [
                                              item.instructor_id
                                                ? [
                                                    this.state.instructors.map(
                                                      (instructor) => {
                                                        if (
                                                          instructor.id ==
                                                          item.instructor_id
                                                        ) {
                                                          return (
                                                            <>
                                                              <b>
                                                                {instructor.fname +
                                                                  " " +
                                                                  instructor.lname}
                                                              </b>{" "}
                                                              (
                                                              <span className="text-primary">
                                                                {_t(
                                                                  t(
                                                                    "Instructor"
                                                                  )
                                                                )}
                                                              </span>
                                                              ){" "}
                                                              <span>
                                                                (
                                                                <Moment format="LLL">
                                                                  {
                                                                    item.created_at
                                                                  }
                                                                </Moment>
                                                                )
                                                              </span>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ]
                                                : [
                                                    this.state.moderators.map(
                                                      (moderator) => {
                                                        if (
                                                          moderator.id ==
                                                          item.moderator_id
                                                        ) {
                                                          return (
                                                            <>
                                                              <b>
                                                                {moderator.fname +
                                                                  " " +
                                                                  moderator.lname}
                                                              </b>{" "}
                                                              (
                                                              <span className="text-primary">
                                                                {_t(
                                                                  t("Moderator")
                                                                )}
                                                              </span>
                                                              ){" "}
                                                              <span>
                                                                (
                                                                <Moment format="LLL">
                                                                  {
                                                                    item.created_at
                                                                  }
                                                                </Moment>
                                                                )
                                                              </span>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ],
                                            ],
                                      ]}
                                  <br />
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.comment,
                                    }}
                                  />
                                  {/* Nested comments */}
                                </div>
                              </div>

                              <>
                                {this.state.replies ? (
                                  <div
                                    className="mt-3"
                                    style={{
                                      marginLeft: "90px",
                                    }}
                                  >
                                    {this.state.replies.map((reply) => {
                                      if (reply.add_comment_id == item.id) {
                                        return (
                                          <>
                                            <div className="d-flex mb-2 align-items-center card-body bg-light">
                                              <div className="avatar-sm">
                                                {reply.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            reply.student_id
                                                          ) {
                                                            return (
                                                              <img
                                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                alt="dp"
                                                                className="rounded-circle"
                                                                height="60"
                                                                width="60"
                                                              />
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      reply.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  reply.admin_id
                                                                ) {
                                                                  return (
                                                                    <img
                                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                      alt="dp"
                                                                      className="rounded-circle"
                                                                      height="60"
                                                                      width="60"
                                                                    />
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            reply.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        reply.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="60"
                                                                            width="60"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        reply.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="60"
                                                                            width="60"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                              </div>
                                              <div className="flex-grow-1 pl-3">
                                                {reply.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            reply.student_id
                                                          ) {
                                                            return (
                                                              <>
                                                                <b>
                                                                  {stu.fname +
                                                                    " " +
                                                                    stu.lname}
                                                                </b>{" "}
                                                                <span>
                                                                  (
                                                                  <Moment format="LLL">
                                                                    {
                                                                      reply.created_at
                                                                    }
                                                                  </Moment>
                                                                  )
                                                                </span>
                                                                {this.state
                                                                  .userId ==
                                                                reply.student_id ? (
                                                                  <i
                                                                    className="fa fa-times ml-2 delete-comment"
                                                                    onClick={() =>
                                                                      this.replyDelete(
                                                                        reply.id
                                                                      )
                                                                    }
                                                                    title="Delete Comment"
                                                                  ></i>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      reply.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  reply.admin_id
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <b>
                                                                        {
                                                                          admin.name
                                                                        }
                                                                      </b>{" "}
                                                                      (
                                                                      <span className="text-primary">
                                                                        {_t(
                                                                          t(
                                                                            "Admin"
                                                                          )
                                                                        )}
                                                                      </span>
                                                                      ){" "}
                                                                      <span>
                                                                        (
                                                                        <Moment format="LLL">
                                                                          {
                                                                            reply.created_at
                                                                          }
                                                                        </Moment>
                                                                        )
                                                                      </span>
                                                                    </>
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            reply.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        reply.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {instructor.fname +
                                                                                " " +
                                                                                instructor.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              {_t(
                                                                                t(
                                                                                  "Instructor"
                                                                                )
                                                                              )}
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  reply.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        reply.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {moderator.fname +
                                                                                " " +
                                                                                moderator.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              {_t(
                                                                                t(
                                                                                  "Moderator"
                                                                                )
                                                                              )}
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  reply.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                                <br />
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: reply.reply,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>
                                  <p className="text-center">
                                    <span
                                      className="btn btn-warning btn-sm"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#collapseExample${item.id}`}
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    >
                                      <i className="fa fa-reply"></i>{" "}
                                      {_t(t("Click here to reply"))}
                                    </span>
                                  </p>
                                  <div
                                    className="collapse col-md-10 offset-md-1"
                                    id={`collapseExample${item.id}`}
                                  >
                                    <form
                                      className="form-group"
                                      onSubmit={(e) => {
                                        this.handleReplySubmit(e, item.id);
                                      }}
                                    >
                                      <h4 className="text-left">
                                        {_t(t("Reply"))}
                                      </h4>
                                      <JoditEditor
                                        editorRef={this.setRef}
                                        value={this.state.reply}
                                        config={this.config}
                                        onChange={this.updateReply}
                                      />
                                      <div className="text-left">
                                        {this.state.replyLoading ? (
                                          <button
                                            type="submit"
                                            className="btn btn-sm px-5 btn-success mt-2 text-uppercase"
                                            disabled
                                          >
                                            {_t(t("Please wait"))}
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                          >
                                            {_t(t("Add Reply"))}
                                          </button>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        className="col-md-6 offset-3 card card-body bg-light"
                        style={{ minHeight: "120px" }}
                      >
                        <div className="text-center my-auto">
                          <div className="text-center">
                            <h3 className="bg-light text-danger px-5 mt-2">
                              {_t(t("No comments yet!"))}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ),
                  ]
                : [
                    this.state.searchedComments.length > 0 ? (
                      <div className="col-md-12">
                        {this.state.searchedComments.map((item) => {
                          return (
                            <div className="card card-body mb-2">
                              <div className="d-flex align-items-center">
                                <div className="avatar">
                                  {item.student_id
                                    ? [
                                        this.state.students.map((stu) => {
                                          if (stu.id == item.student_id) {
                                            return (
                                              <img
                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                alt="dp"
                                                className="rounded-circle"
                                                height="80"
                                                width="80"
                                              />
                                            );
                                          }
                                        }),
                                      ]
                                    : [
                                        item.admin_id
                                          ? [
                                              this.state.admins.map((admin) => {
                                                if (admin.id == item.admin_id) {
                                                  return (
                                                    <img
                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                      alt="dp"
                                                      className="rounded-circle"
                                                      height="80"
                                                      width="80"
                                                    />
                                                  );
                                                }
                                              }),
                                            ]
                                          : [
                                              item.instructor_id
                                                ? [
                                                    this.state.instructors.map(
                                                      (instructor) => {
                                                        if (
                                                          instructor.id ==
                                                          item.instructor_id
                                                        ) {
                                                          return (
                                                            <img
                                                              src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                              alt="dp"
                                                              className="rounded-circle"
                                                              height="80"
                                                              width="80"
                                                            />
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ]
                                                : [
                                                    this.state.moderators.map(
                                                      (moderator) => {
                                                        if (
                                                          moderator.id ==
                                                          item.moderator_id
                                                        ) {
                                                          return (
                                                            <img
                                                              src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                              alt="dp"
                                                              className="rounded-circle"
                                                              height="80"
                                                              width="80"
                                                            />
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ],
                                            ],
                                      ]}
                                </div>

                                <div className="flex-grow-1 pl-4">
                                  {item.student_id
                                    ? [
                                        this.state.students.map((stu) => {
                                          if (stu.id == item.student_id) {
                                            return (
                                              <>
                                                <b>
                                                  {stu.fname + " " + stu.lname}
                                                </b>{" "}
                                                <span>
                                                  (
                                                  <Moment format="LLL">
                                                    {item.created_at}
                                                  </Moment>
                                                  )
                                                </span>
                                                {this.state.userId ==
                                                item.student_id ? (
                                                  <i
                                                    className="fa fa-times ml-2 delete-comment"
                                                    onClick={() =>
                                                      this.commentDelete(
                                                        item.id
                                                      )
                                                    }
                                                    title="Delete Comment"
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            );
                                          }
                                        }),
                                      ]
                                    : [
                                        item.admin_id
                                          ? [
                                              this.state.admins.map((admin) => {
                                                if (admin.id == item.admin_id) {
                                                  return (
                                                    <>
                                                      <b>{admin.name}</b> (
                                                      <span className="text-primary">
                                                        {_t(t("Admin"))}
                                                      </span>
                                                      ){" "}
                                                      <span>
                                                        (
                                                        <Moment format="LLL">
                                                          {item.created_at}
                                                        </Moment>
                                                        )
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              }),
                                            ]
                                          : [
                                              item.instructor_id
                                                ? [
                                                    this.state.instructors.map(
                                                      (instructor) => {
                                                        if (
                                                          instructor.id ==
                                                          item.instructor_id
                                                        ) {
                                                          return (
                                                            <>
                                                              <b>
                                                                {instructor.fname +
                                                                  " " +
                                                                  instructor.lname}
                                                              </b>{" "}
                                                              (
                                                              <span className="text-primary">
                                                                {_t(
                                                                  t(
                                                                    "Instructor"
                                                                  )
                                                                )}
                                                              </span>
                                                              ){" "}
                                                              <span>
                                                                (
                                                                <Moment format="LLL">
                                                                  {
                                                                    item.created_at
                                                                  }
                                                                </Moment>
                                                                )
                                                              </span>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ]
                                                : [
                                                    this.state.moderators.map(
                                                      (moderator) => {
                                                        if (
                                                          moderator.id ==
                                                          item.moderator_id
                                                        ) {
                                                          return (
                                                            <>
                                                              <b>
                                                                {moderator.fname +
                                                                  " " +
                                                                  moderator.lname}
                                                              </b>{" "}
                                                              (
                                                              <span className="text-primary">
                                                                {_t(
                                                                  t("Moderator")
                                                                )}
                                                              </span>
                                                              ){" "}
                                                              <span>
                                                                (
                                                                <Moment format="LLL">
                                                                  {
                                                                    item.created_at
                                                                  }
                                                                </Moment>
                                                                )
                                                              </span>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    ),
                                                  ],
                                            ],
                                      ]}
                                  <br />
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.comment,
                                    }}
                                  />
                                  {/* Nested comments */}
                                </div>
                              </div>
                              <>
                                {this.state.replies ? (
                                  <div
                                    className="mt-3"
                                    style={{
                                      marginLeft: "90px",
                                    }}
                                  >
                                    {this.state.replies.map((reply) => {
                                      if (reply.add_comment_id == item.id) {
                                        return (
                                          <>
                                            <div className="d-flex mb-2 align-items-center card-body bg-light">
                                              <div className="avatar-sm">
                                                {reply.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            reply.student_id
                                                          ) {
                                                            return (
                                                              <img
                                                                src={`${BASE_URL}${`/images/student/${stu.image}`}`}
                                                                alt="dp"
                                                                className="rounded-circle"
                                                                height="60"
                                                                width="60"
                                                              />
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      reply.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  reply.admin_id
                                                                ) {
                                                                  return (
                                                                    <img
                                                                      src={`${BASE_URL}${`/images/admin/${admin.image}`}`}
                                                                      alt="dp"
                                                                      className="rounded-circle"
                                                                      height="60"
                                                                      width="60"
                                                                    />
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            reply.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        reply.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/instructor/${instructor.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="60"
                                                                            width="60"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        reply.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <img
                                                                            src={`${BASE_URL}${`/images/moderator/${moderator.image}`}`}
                                                                            alt="dp"
                                                                            className="rounded-circle"
                                                                            height="60"
                                                                            width="60"
                                                                          />
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                              </div>
                                              <div className="flex-grow-1 pl-3">
                                                {reply.student_id
                                                  ? [
                                                      this.state.students.map(
                                                        (stu) => {
                                                          if (
                                                            stu.id ==
                                                            reply.student_id
                                                          ) {
                                                            return (
                                                              <>
                                                                <b>
                                                                  {stu.fname +
                                                                    " " +
                                                                    stu.lname}
                                                                </b>{" "}
                                                                <span>
                                                                  (
                                                                  <Moment format="LLL">
                                                                    {
                                                                      reply.created_at
                                                                    }
                                                                  </Moment>
                                                                  )
                                                                </span>
                                                                {this.state
                                                                  .userId ==
                                                                reply.student_id ? (
                                                                  <i
                                                                    className="fa fa-times ml-2 delete-comment"
                                                                    onClick={() =>
                                                                      this.replyDelete(
                                                                        reply.id
                                                                      )
                                                                    }
                                                                    title="Delete Comment"
                                                                  ></i>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      reply.admin_id
                                                        ? [
                                                            this.state.admins.map(
                                                              (admin) => {
                                                                if (
                                                                  admin.id ==
                                                                  reply.admin_id
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <b>
                                                                        {
                                                                          admin.name
                                                                        }
                                                                      </b>{" "}
                                                                      (
                                                                      <span className="text-primary">
                                                                        {_t(
                                                                          t(
                                                                            "Admin"
                                                                          )
                                                                        )}
                                                                      </span>
                                                                      ){" "}
                                                                      <span>
                                                                        (
                                                                        <Moment format="LLL">
                                                                          {
                                                                            reply.created_at
                                                                          }
                                                                        </Moment>
                                                                        )
                                                                      </span>
                                                                    </>
                                                                  );
                                                                }
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            reply.instructor_id
                                                              ? [
                                                                  this.state.instructors.map(
                                                                    (
                                                                      instructor
                                                                    ) => {
                                                                      if (
                                                                        instructor.id ==
                                                                        reply.instructor_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {instructor.fname +
                                                                                " " +
                                                                                instructor.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              {_t(
                                                                                t(
                                                                                  "Instructor"
                                                                                )
                                                                              )}
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  reply.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  this.state.moderators.map(
                                                                    (
                                                                      moderator
                                                                    ) => {
                                                                      if (
                                                                        moderator.id ==
                                                                        reply.moderator_id
                                                                      ) {
                                                                        return (
                                                                          <>
                                                                            <b>
                                                                              {moderator.fname +
                                                                                " " +
                                                                                moderator.lname}
                                                                            </b>{" "}
                                                                            (
                                                                            <span className="text-primary">
                                                                              {_t(
                                                                                t(
                                                                                  "Moderator"
                                                                                )
                                                                              )}
                                                                            </span>
                                                                            ){" "}
                                                                            <span>
                                                                              (
                                                                              <Moment format="LLL">
                                                                                {
                                                                                  reply.created_at
                                                                                }
                                                                              </Moment>
                                                                              )
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      }
                                                                    }
                                                                  ),
                                                                ],
                                                          ],
                                                    ]}
                                                <br />
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: reply.reply,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>
                                  <p className="text-center">
                                    <span
                                      className="btn btn-warning btn-sm"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#collapseExample${item.id}`}
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    >
                                      <i className="fa fa-reply"></i>
                                      {_t(t("Click here to reply"))}
                                    </span>
                                  </p>
                                  <div
                                    className="collapse col-md-10 offset-md-1"
                                    id={`collapseExample${item.id}`}
                                  >
                                    <form
                                      className="form-group"
                                      onSubmit={(e) => {
                                        this.handleReplySubmit(e, item.id);
                                      }}
                                    >
                                      <h4 className="text-left">
                                        {_t(t("Reply"))}
                                      </h4>
                                      <JoditEditor
                                        editorRef={this.setRef}
                                        value={this.state.reply}
                                        config={this.config}
                                        onChange={this.updateReply}
                                      />
                                      <div className="text-left">
                                        {this.state.replyLoading ? (
                                          <button
                                            type="submit"
                                            className="btn btn-sm px-5 btn-success mt-2 text-uppercase"
                                            disabled
                                          >
                                            {_t(t("Please wait"))}
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn btn-sm px-5 btn-primary mt-2 text-uppercase"
                                          >
                                            {_t(t("Add Reply"))}
                                          </button>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        className="col-md-6 offset-3 card card-body bg-light"
                        style={{ minHeight: "120px" }}
                      >
                        <div className="text-center my-auto">
                          <div className="text-center">
                            <h3 className="bg-light text-danger px-5 mt-2">
                              {_t(t("No comments yet!"))}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            <div className="row mb-2">
              <h4 className="col-md-10 offset-md-1 course-heading text-uppercase text-center my-2 py-1 rounded-sm">
                {_t(t("Add New Comment"))}
              </h4>
            </div>
            <form className="row" onSubmit={this.handleSubmit}>
              <div className="col-md-10 offset-md-1">
                <JoditEditor
                  editorRef={this.setRef}
                  value={this.state.comment}
                  config={this.config}
                  onChange={this.updateContent}
                />
              </div>
              {this.state.commentLoading ? (
                <button
                  className="btn btn-success btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                  disabled
                >
                  {_t(t("Please wait"))}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-2 text-uppercase col-md-10 offset-md-1 mb-2"
                  disabled={!this.state.commentBtn}
                >
                  {_t(t("Add comment"))}
                </button>
              )}
            </form>
          </div>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(ClassForum));

// $(".sassico-service-content-with-fat-shadow").attr("href", "javascript:void(0)");
