import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";
import "react-toastify/dist/ReactToastify.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: "",
      langs: [],
    };
  }
  componentDidMount() {
    const url = BASE_URL + "/api/footer";
    return axios.get(url).then((res) => {
      this.setState({
        footer: res.data.footer,
      });
      const url = BASE_URL + `/api/languages`;
      axios.get(url).then((res) => {
        this.setState({
          langs: res.data,
        });
      });
    });
  }

  // language change function
  handleLanguageChange = (e) => {
    const { i18n } = this.props;
    i18n.changeLanguage(e.target.value);
    this.notify();
  };
  notify = () => {
    const { t } = this.props;
    toast.success(`${_t(t("Language has been switched!"))}`, {
      position: "bottom-center",
      className: "text-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "toast-notification",
    });
  };
  render() {
    const { t } = this.props;
    return (
      <section className="container-fluid">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
        <div className="row footer text-center fs-13 text-white">
          <div className="col my-auto">
            {this.state.footer !== "" ? _t(t(this.state.footer)) : ""}
          </div>
        </div>
        <select
          className="custom-lang"
          onChange={this.handleLanguageChange}
          value={localStorage.getItem("i18nextLng")}
        >
          {this.state.langs.map((item) => {
            return <option value={item.code}>{item.name}</option>;
          })}
        </select>
      </section>
    );
  }
}

export default withTranslation()(Footer);
