import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from "../BaseUrl";

import { withTranslation } from "react-i18next";
import { _t } from "../functions/Functions";

class StudentRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      course_id: "",
      fname: "",
      lname: "",
      image: "",
      gender: "",
      dateOfBirth: null,
      phn_no: "",
      occupation: "",
      address: "",
      email: "",
      fb: "",
      note: "",
      password: "",
      password_confirmation: "",
      first_payment: "",
      first_payment_date: null,
      paid_on: "",
      paid_number: "",
      transaction_id: "",
      admin_bank_account: "",
      error: [],
      fileError: "",
      bkash: false,
      nagad: false,
      rocket: false,
      bank: false,
      loading: false,
      submitted: false,
    };
  }
  componentDidMount() {
    document.title = "Student Registration";
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/registration_info";
    return axios.get(url).then((res) => {
      this.setState({
        courses: res.data,
        loading: false,
      });
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  handleChangeCourse = (e) => {
    this.setState({
      course_id: e.target.value,
    });
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  handleFirstPaymentDate = (date) => {
    this.setState({
      first_payment_date: date,
    });
  };

  handlePaymentMethod = (e) => {
    this.setState({
      paid_on: e.target.value,
    });
    if (e.target.value === "Bkash") {
      this.setState({
        bkash: true,
        nagad: false,
        rocket: false,
        bank: false,
      });
    }
    if (e.target.value === "Nagad") {
      this.setState({
        bkash: false,
        nagad: true,
        rocket: false,
        bank: false,
      });
    }
    if (e.target.value === "Rocket") {
      this.setState({
        bkash: false,
        nagad: false,
        rocket: true,
        bank: false,
      });
    }
    if (e.target.value === "Bank") {
      this.setState({
        bkash: false,
        nagad: false,
        rocket: false,
        bank: true,
      });
    }
    if (e.target.value === "Office") {
      this.setState({
        bkash: false,
        nagad: false,
        rocket: false,
        bank: false,
        paid_number: "",
        transaction_id: "",
        admin_bank_account: "",
      });
    }
    if (e.target.value === "") {
      this.setState({
        bkash: false,
        nagad: false,
        rocket: false,
        bank: false,
        paid_number: "",
        transaction_id: "",
        admin_bank_account: "",
      });
    }
  };

  handlePaymentMethodDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/registration_store";
    const bDay = moment(this.state.dateOfBirth).format("YYYY-MM-DD");
    const paymentDate = moment(this.state.first_payment_date).format(
      "YYYY-MM-DD"
    );
    let formData = new FormData();
    formData.append("course_id", this.state.course_id);
    formData.append("fname", this.state.fname);
    formData.append("lname", this.state.lname);
    formData.append("gender", this.state.gender);
    formData.append("dateOfBirth", bDay);
    formData.append("phn_no", this.state.phn_no);
    formData.append("occupation", this.state.occupation);
    formData.append("address", this.state.address);
    formData.append("email", this.state.email);
    formData.append("fb", this.state.fb);
    formData.append("note", this.state.note);
    formData.append("first_payment", this.state.first_payment);
    formData.append("first_payment_date", paymentDate);
    formData.append("password", this.state.password);
    formData.append("password_confirmation", this.state.password_confirmation);
    formData.append("file", this.state.image);
    formData.append("paid_number", this.state.paid_number);
    formData.append("transaction_id", this.state.transaction_id);
    formData.append("admin_bank_account", this.state.admin_bank_account);
    formData.append("paid_on", this.state.paid_on);
    return axios
      .post(url, formData)
      .then(() => {
        this.setState({
          loading: false,
          submitted: true,
        });
      })
      .catch((error) => {
        if (error.response.data.message === "Image source not readable") {
          this.setState({
            fileError:
              error.response.data.message +
              ". " +
              "Choose a valid image with jpeg, jpg, png extention.",
          });
        } else {
          this.setState({
            fileError: "",
          });
        }
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handle_submitted = () => {
    this.setState({
      course_id: "",
      fname: "",
      lname: "",
      image: "",
      gender: "",
      dateOfBirth: null,
      phn_no: "",
      occupation: "",
      address: "",
      email: "",
      fb: "",
      note: "",
      password: "",
      password_confirmation: "",
      first_payment: "",
      first_payment_date: null,
      paid_on: "",
      paid_number: "",
      transaction_id: "",
      admin_bank_account: "",
      error: [],
      fileError: "",
      bkash: false,
      nagad: false,
      rocket: false,
      bank: false,
      loading: false,
      submitted: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row col-md-10 offset-md-1 step1">
            <div className="col-md-8 offset-md-2 step2">
              <div className="card m-4 login-card step3">
                <div className="card-body bg-light">
                  {this.state.loading ? (
                    <>
                      <h3>
                        <Skeleton className="login-card" />
                      </h3>
                    </>
                  ) : (
                    <>
                      <h4 className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        {_t(t("Please fill the form accordingly"))}
                      </h4>
                      {this.state.submitted ? (
                        <div className="mx-5 mt-5">
                          <div className="text-center my-2">
                            {/* আপনাকে একটি ইমেইল করা হয়েছে, আপনার রেজিস্ট্রেশান এর
                            বিস্তারিত এডমিন এর কাছে জমা হয়েছে, এডমিন দেখা অব্দি
                            অপেক্ষা করুন। */}
                            {_t(
                              t(
                                "An email has been sent to you. Your registration form has been submitted to admin, please wait untill the confirmation."
                              )
                            )}
                          </div>
                          <button
                            className="btn btn-success btn-block d-flex justify-content-center"
                            onClick={this.handle_submitted}
                          >
                            {_t(t("Click Here."))}
                            <i className="fa fa-angle-right mx-2"></i>
                            {_t(t("I am waiting with patience"))}
                          </button>
                        </div>
                      ) : (
                        <form
                          className="form-group"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-12 input-group mb-2">
                              <label className="col-md-3 my-auto">
                                {_t(t("Course"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <select
                                  name="course_id"
                                  className="form-control rounded-sm pl-2"
                                  onChange={this.handleChangeCourse}
                                  value={this.state.course_id}
                                  required
                                >
                                  <option value="">
                                    {_t(t("Please select a course"))}
                                  </option>
                                  {/* Loop Here */}
                                  {this.state.courses.map((item) => {
                                    return (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                        className="text-capitalize"
                                      >{`${item.title}`}</option>
                                    );
                                  })}
                                  {/* Loop Here */}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="fname"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("First Name"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  placeholder="Type first name"
                                  className="form-control"
                                  value={this.state.fname}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.fname ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fname.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="lname"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Last Name"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="lname"
                                  name="lname"
                                  placeholder="Type last name"
                                  className="form-control"
                                  value={this.state.lname}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.lname ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.lname.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="image"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Image"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="file"
                                  id="image"
                                  name="image"
                                  className="form-control-file pl-0"
                                  onChange={this.handleChangeFile}
                                  required
                                />
                              </div>
                              <div className="offset-3 text-danger fs-13 pl-0">
                                {this.state.fileError
                                  ? "*" + this.state.fileError
                                  : ""}
                              </div>
                            </div>
                            <div className="col-md-7 input-group mb-2 mb-5 gender">
                              <label
                                htmlFor={"male" || "female"}
                                className="col-md-5 my-auto mr-2"
                              >
                                {_t(t("Gender"))}
                              </label>
                              <div className="col-md-7 pl-0 my-auto form-control pl-2 ml-1">
                                <input
                                  type="radio"
                                  value="MALE"
                                  id="male"
                                  name="gender"
                                  className="form-check-inline"
                                  onChange={this.handleChange}
                                  checked={this.state.gender === "MALE"}
                                  required
                                />
                                <label
                                  htmlFor="male"
                                  className="my-auto form-check-label mr-4"
                                >
                                  {" "}
                                  {_t(t("Male"))}
                                </label>
                                <input
                                  type="radio"
                                  value="FEMALE"
                                  id="female"
                                  name="gender"
                                  className="form-check-inline"
                                  onChange={this.handleChange}
                                  checked={this.state.gender === "FEMALE"}
                                  required
                                />
                                <label
                                  htmlFor="female"
                                  className="my-auto form-check-label"
                                >
                                  {" "}
                                  {_t(t("Female"))}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-5 input-group mb-5 dob">
                              <label
                                htmlFor="dateOfBirth"
                                className="col-md-5 my-auto"
                              >
                                {_t(t("Date of Birth"))}
                              </label>
                              <div className="col-md-7 pl-0">
                                <DatePicker
                                  selected={this.state.dateOfBirth}
                                  onChange={this.handleDateOfBirth}
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="phn_no"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Phone No."))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="phn_no"
                                  name="phn_no"
                                  placeholder="01xxxxx"
                                  className="form-control"
                                  value={this.state.phn_no}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.phn_no ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.phn_no.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="occupation"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Occupation"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <select
                                  name="occupation"
                                  className="form-control rounded-sm pl-2"
                                  onChange={this.handleChange}
                                  value={this.state.occupation}
                                  required
                                >
                                  <option value="">
                                    {_t(t("Please select your occupation"))}
                                  </option>{" "}
                                  <option
                                    value="Student"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Student"))}
                                  </option>{" "}
                                  <option
                                    value="Business"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Business"))}
                                  </option>
                                  <option
                                    value="Job"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Job"))}
                                  </option>{" "}
                                  <option
                                    value="Army"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Army"))}
                                  </option>{" "}
                                  <option
                                    value="Banker"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Banker"))}
                                  </option>
                                  <option
                                    value="Doctor"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Doctor"))}
                                  </option>
                                  <option
                                    value="Housewife"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Housewife"))}
                                  </option>
                                  <option
                                    value="Lawyer"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Lawyer"))}
                                  </option>
                                  <option
                                    value="Police"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Police"))}
                                  </option>
                                  <option
                                    value="Rab"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Rab"))}
                                  </option>{" "}
                                  <option
                                    value="Freelancer"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Freelancer"))}
                                  </option>{" "}
                                </select>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.occupation ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.occupation.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-5">
                              <label
                                htmlFor="address"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Address"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="text"
                                  id="address"
                                  name="address"
                                  placeholder="Type address"
                                  className="form-control"
                                  value={this.state.address}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.address ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.address.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="input-group col-md-12 mt-2">
                              <label
                                htmlFor="first_payment"
                                className="my-auto col-md-3"
                              >
                                {_t(t("First Payment"))}
                              </label>
                              <input
                                type="number"
                                id="first_payment"
                                className="form-control col-md-9 rounded-sm"
                                name="first_payment"
                                placeholder="Type payment amount"
                                value={this.state.first_payment}
                                onChange={this.handleChange}
                                required
                                min="0"
                                max={this.state.payable}
                              />
                              <div class="input-group-append border-left-0 mr-3">
                                <div class="input-group-text">BDT</div>
                              </div>
                            </div>
                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-2">
                                {this.state.error.first_payment}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="input-group col-md-12 my-2">
                              <label
                                htmlFor="first_payment_date"
                                className="my-auto col-md-3"
                              >
                                {_t(t("Date of payment"))}
                              </label>
                              <DatePicker
                                selected={this.state.first_payment_date}
                                onChange={this.handleFirstPaymentDate}
                                dateFormat="yyyy/MM/dd"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control col-md-4 rounded-sm"
                                required
                              />
                            </div>
                            {this.state.error ? (
                              <div className="offset-3 text-danger fs-13 pl-2">
                                {this.state.error.first_payment_date ? (
                                  <ul className="list-group list-group-horizontal">
                                    {this.state.error.first_payment_date.map(
                                      (item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="paid_on"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Paid To"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <select
                                  name="paid_on"
                                  className="form-control rounded-sm pl-2"
                                  onChange={this.handlePaymentMethod}
                                  value={this.state.paid_on}
                                  required
                                >
                                  <option value="">
                                    {_t(t("Please select your payment option"))}
                                  </option>{" "}
                                  <option
                                    value="Bkash"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Bkash"))}
                                  </option>{" "}
                                  <option
                                    value="Nagad"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Nagad"))}
                                  </option>{" "}
                                  <option
                                    value="Rocket"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Rocket"))}
                                  </option>
                                  <option
                                    value="Bank"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Bank"))}
                                  </option>
                                  <option
                                    value="Office"
                                    className="text-capitalize"
                                  >
                                    {_t(t("Office"))}
                                  </option>
                                </select>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.occupation ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.occupation.map(
                                        (item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {this.state.bkash === true ? (
                              <>
                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="paid_number"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Bkash Phone Number"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="paid_number"
                                      name="paid_number"
                                      placeholder="01xxxxx"
                                      className="form-control"
                                      value={this.state.paid_number}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.paid_number ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.paid_number.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="transaction_id"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Bkash Transaction ID"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="transaction_id"
                                      name="transaction_id"
                                      placeholder="Bkash transaction id"
                                      className="form-control"
                                      value={this.state.transaction_id}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.transaction_id ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.transaction_id.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.nagad === true ? (
                              <>
                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="paid_number"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Nagad Phone Number"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="paid_number"
                                      name="paid_number"
                                      placeholder="01xxxxx"
                                      className="form-control"
                                      value={this.state.paid_number}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.paid_number ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.paid_number.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="transaction_id"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Nagad Transaction ID"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="transaction_id"
                                      name="transaction_id"
                                      placeholder="Nagad transaction id"
                                      className="form-control"
                                      value={this.state.transaction_id}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.transaction_id ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.transaction_id.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.rocket === true ? (
                              <>
                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="paid_number"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Rocket Phone Number"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="paid_number"
                                      name="paid_number"
                                      placeholder="01xxxxx"
                                      className="form-control"
                                      value={this.state.paid_number}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.paid_number ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.paid_number.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="transaction_id"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Rocket Transaction ID"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="transaction_id"
                                      name="transaction_id"
                                      placeholder="Rocket transaction id"
                                      className="form-control"
                                      value={this.state.transaction_id}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.transaction_id ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.transaction_id.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.bank === true ? (
                              <>
                                <div className="col-md-12 input-group mb-2">
                                  <label
                                    htmlFor="admin_bank_account"
                                    className="col-md-3 my-auto"
                                  >
                                    {_t(t("Bank Account Number"))}
                                  </label>
                                  <div className="col-md-9 pl-0">
                                    <input
                                      type="text"
                                      id="admin_bank_account"
                                      name="admin_bank_account"
                                      placeholder="Type in which account number you have paid (Receiver's account number)"
                                      className="form-control"
                                      value={this.state.admin_bank_account}
                                      onChange={this.handlePaymentMethodDetails}
                                    />
                                  </div>
                                  {this.state.error ? (
                                    <div className="offset-3 text-danger fs-13 pl-0">
                                      {this.state.error.admin_bank_account ? (
                                        <ul className="list-group list-group-horizontal">
                                          {this.state.error.admin_bank_account.map(
                                            (item) => {
                                              return (
                                                <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                  {"*" + item}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="col-md-12 input-group mb-5">
                              <label
                                htmlFor="note"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Note"))} ({_t(t("optional"))})
                              </label>
                              <div className="col-md-9 pl-0">
                                <textarea
                                  id="note"
                                  name="note"
                                  placeholder="How many classes have been completed?&#10;Write if you have any other questions! &#10;Write if you need any help!"
                                  className="form-control"
                                  rows="8"
                                  onChange={this.handleChange}
                                >
                                  {this.state.note}
                                </textarea>
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.note ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.note.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="email"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Email"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="example@softechit.com"
                                  className="form-control"
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.email ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.email.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label htmlFor="fb" className="col-md-3 my-auto">
                                {_t(t("Facebook"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="url"
                                  id="fb"
                                  name="fb"
                                  placeholder="https://www.facebook.com/example"
                                  className="form-control"
                                  value={this.state.fb}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.fb ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.fb.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12 input-group mb-2">
                              <label
                                htmlFor="password"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Password"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  className="form-control"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12 input-group">
                              <label
                                htmlFor="password_confirmation"
                                className="col-md-3 my-auto"
                              >
                                {_t(t("Password Confirmation"))}
                              </label>
                              <div className="col-md-9 pl-0">
                                <input
                                  type="password"
                                  id="password_confirmation"
                                  name="password_confirmation"
                                  placeholder="Re-type password"
                                  className="form-control"
                                  value={this.state.password_confirmation}
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              {this.state.error ? (
                                <div className="offset-3 text-danger fs-13 pl-0">
                                  {this.state.error.password ? (
                                    <ul className="list-group list-group-horizontal">
                                      {this.state.error.password.map((item) => {
                                        return (
                                          <li className="list-group-item border-0 bg-light py-0 pl-0">
                                            {"*" + item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm mt-3 px-5"
                            >
                              {_t(t("Submit"))}
                            </button>
                          </div>
                        </form>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(withRouter(StudentRegistration));
