import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import emailVec from '../images/allCourses/email-vec.png';
import vecItems1 from '../images/allCourses/hero-vec2.png';
import vecItems2 from '../images/allCourses/hero-vec3.png';
import vecItems3 from '../images/allCourses/hero-vec4.png';
import vecItems4 from '../images/allCourses/hero-vec5.png';
import { BASE_URL } from '../BaseUrl';
import axios from 'axios';

class NewHero extends Component {
  constructor(props) {
    super(props);
    this.state = {

      herodes: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });

    const url = BASE_URL + "/api/all-hero-des";
    return axios.get(url).then((res) => {
      this.setState({
        herodes: res.data[0],
        loading: false,
      });
      // console.log(this.state.herodes);
    });

  }


  render() {
    return <>
      <div className="v2_hero-section hero-bg  position-relative">
        <div className="vec-items">
          <img src={vecItems1} className="vec-item1" alt="" />
          <img src={vecItems2} className="vec-item2" alt="" />
          <img src={vecItems3} className="vec-item3" alt="" />
          <img src={vecItems4} className="vec-item4 item-animateOne" alt="" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-10 col-12 text-center">
              <div className="top-social-icons d-flex flex-wrap align-items-center justify-content-center px-4">
                <div className="v2_icons-box d-flex mr-4">

                  <Link to={

                    { pathname: `${this.state.herodes.social_link1}` }} target="_blank" className="social-item"><i className={this.state.herodes.social_icon1} aria-hidden="true"></i></Link>
                  <Link to={

                    { pathname: `${this.state.herodes.social_link2}` }} target="_blank" className="social-item"><i className={this.state.herodes.social_icon2} aria-hidden="true"></i></Link>

                  <Link to={

                    { pathname: `${this.state.herodes.social_link3}` }} target="_blank" className="social-item"><i className={this.state.herodes.social_icon3} aria-hidden="true"></i></Link>

                  <Link to={

                    { pathname: `${this.state.herodes.social_link4}` }} target="_blank" className="social-item"><i className={this.state.herodes.social_icon4} aria-hidden="true"></i></Link>

                </div>
                <span className="icons-title mt-4 mt-sm-0">
                  {this.state.herodes.subtitle}
                </span>
              </div>
              <span className="v2_hero-title text-capitalize">
                {this.state.herodes.title}
                {/* <div dangerouslySetInnerHTML={ __html: "myHTML" } /> */}

              </span>
            </div>
          </div>
        </div>
      </div>

    </>
  }
}

export default NewHero;
