import React, { Component } from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { _t } from "../functions/Functions";

import Cookies from "universal-cookie";
const cookies = new Cookies();

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
      socialLinks: [],
      noticeAll: [],
      logo: [],
    };
  }

  componentDidMount() {
    const url = BASE_URL + `/api/contact_info`;
    return axios.get(url).then((res) => {
      this.setState({
        contact: res.data,
      });
      const url = BASE_URL + "/api/update_links";
      return axios.get(url).then((res) => {
        this.setState({
          socialLinks: res.data,
        });
        const url = BASE_URL + "/api/logo";
        return axios.get(url).then((res) => {
          this.setState({
            logo: res.data,
          });

          const url = BASE_URL + "/api/student/student-notice";
          return axios
            .get(url, {
              headers: {
                Authorization: `Bearer  ${cookies.get("studentToken")}`,
              },
            })
            .then((res) => {
              this.setState({
                noticeAll: res.data,
                loading: false,
              });
            });
        });
      });
    });
  }

  adminLogout = (e) => {
    e.preventDefault();
    cookies.remove("adminToken");
    this.props.history.push("/");
  };

  studentLogout = (e) => {
    e.preventDefault();
    // localStorage.removeItem("studentToken");
    cookies.remove("studentToken");
    this.props.history.push("/");
  };

  instructorLogout = (e) => {
    e.preventDefault();
    // localStorage.removeItem("instructorToken");
    cookies.remove("instructorToken");
    this.props.history.push("/");
  };

  moderatorLogout = (e) => {
    e.preventDefault();
    // localStorage.removeItem("moderatorToken");
    cookies.remove("moderatorToken");
    this.props.history.push("/");
  };

  render() {
    const items = this.state.noticeAll.filter((item) => item.length !== 0);
    let count = items.length;
    const { t } = this.props;
    let studentLoginLink = "";
    if (cookies.get("studentToken")) {
      studentLoginLink = "";
    } else {
      studentLoginLink = (
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/login" }}
            className="btn btn-primary btn-login text-capitalize px-5 my-auto"
          >
            {_t(t("Student Login"))}
          </NavLink>
        </li>
      );
    }
    let studentLink = (
      <ul className="navbar-nav ml-auto text-uppercase">
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/" }}
            exact
            className={`nav-link ${
              window.location.pathname === "/" ? "active" : ""
            }`}
          >
            {_t(t("Home"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/all-courses" }}
            className={`nav-link ${
              window.location.href.includes("/all-courses") ? "active" : ""
            }`}
          >
            {_t(t("All Courses"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/my-courses" }}
            className={`nav-link ${
              window.location.href.includes("/my-courses") ? "active" : ""
            }`}
          >
            {_t(t("My Courses"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/forum" }}
            className={`nav-link ${
              window.location.href.includes("/forum") ? "active" : ""
            }`}
          >
            {_t(t("Problems"))}
          </NavLink>
        </li>
        <li className="nav-item d-flex align-items-center">
          <NavLink
            to={{ pathname: "/refresh", state: "/support" }}
            className={`nav-link  ${
              window.location.href.includes("/support") ? "active" : ""
            }`}
          >
            {_t(t("Support"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/assignment" }}
            className={`nav-link ${
              window.location.href.includes("/assignment") ? "active" : ""
            }`}
          >
            {_t(t("Assignment"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/all-files" }}
            className={`nav-link ${
              window.location.href.includes("/all-files") ? "active" : ""
            }`}
          >
            {_t(t("ALL Files"))}
          </NavLink>
        </li>
        <li className="nav-item d-flex align-items-center">
          <NavLink
            to={{ pathname: "/refresh", state: "/student-notice" }}
            className={`nav-link  ${
              window.location.href.includes("/student-notice") ? "active" : ""
            }`}
          >
            {_t(t("Notice"))}
          </NavLink>
          <span className="badge bg-warning text-white notice-counter">
            {count}
          </span>
        </li>

        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/profile/personal-info" }}
            className={`nav-link ${
              window.location.href.includes("/profile/") ||
              window.location.pathname === "/feedback" ||
              window.location.pathname === "/notifications"
                ? "active"
                : ""
            }`}
          >
            {_t(t("Profile"))}
          </NavLink>
        </li>
        {studentLoginLink}
      </ul>
    );

    let adminLink = (
      <ul className="navbar-nav ml-auto text-uppercase">
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/" }}
            exact
            className={`nav-link ${
              window.location.pathname === "/" ? "active" : ""
            }`}
          >
            {_t(t("Home"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/all-courses" }}
            className={`nav-link ${
              window.location.href.includes("/all-courses") ? "active" : ""
            }`}
          >
            {_t(t("All Courses"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/admin" }}
            className={`nav-link ${
              window.location.href.includes("/admin") ? "active" : ""
            }`}
          >
            {_t(t("Dashboard"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/"
            onClick={this.adminLogout}
            className="btn btn-danger btn-login text-capitalize px-5 my-auto"
          >
            {_t(t("Logout"))}
          </NavLink>
        </li>
      </ul>
    );

    let InstructorLink = (
      <ul className="navbar-nav ml-auto text-uppercase">
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/" }}
            exact
            className={`nav-link ${
              window.location.pathname === "/" ? "active" : ""
            }`}
          >
            {_t(t("Home"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/all-courses" }}
            className={`nav-link ${
              window.location.href.includes("/all-courses") ? "active" : ""
            }`}
          >
            {_t(t("All Courses"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/instructor" }}
            className={`nav-link ${
              window.location.href.includes("/instructor") ? "active" : ""
            }`}
          >
            {_t(t("Dashboard"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/"
            onClick={this.instructorLogout}
            className="btn btn-danger btn-login text-capitalize px-5 my-auto"
          >
            {_t(t("Logout"))}
          </NavLink>
        </li>
      </ul>
    );

    let ModeratorLink = (
      <ul className="navbar-nav ml-auto text-uppercase">
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/" }}
            exact
            className={`nav-link ${
              window.location.pathname === "/" ? "active" : ""
            }`}
          >
            {_t(t("Home"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/all-courses" }}
            className={`nav-link ${
              window.location.href.includes("/all-courses") ? "active" : ""
            }`}
          >
            {_t(t("All Courses"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/refresh", state: "/moderator" }}
            className={`nav-link ${
              window.location.href.includes("/moderator") ? "active" : ""
            }`}
          >
            {_t(t("Dashboard"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/"
            onClick={this.moderatorLogout}
            className="btn btn-danger btn-login text-capitalize px-5 my-auto"
          >
            {_t(t("Logout"))}
          </NavLink>
        </li>
      </ul>
    );

    return (
      <>
        <section className="container-fluid">
          <div className="row top-height">
            <div className="col">
              <div className="fs-13 text-white">
                <i className="fs-13 fa fa-phone"></i>{" "}
                {this.state.contact.phn_no}
              </div>
            </div>

            {/* <div className="col">
              <div className="text-right fs-13">
                {cookies.get("adminToken") ||
                localStorage.instructorToken ||
                localStorage.moderatorToken ? (
                  ""
                ) : (
                  <>
                    <div className="btn-group">
                      <NavLink
                        to="/student-registration"
                        className="btn-admin-login"
                      >
                        Student Registration
                      </NavLink>
                    </div>
                  </>
                )}
              </div>
            </div> */}

            <div className="col">
              <div className="text-right fs-13">
                {cookies.get("adminToken") ||
                cookies.get("studentToken") ||
                cookies.get("instructorToken") ||
                cookies.get("moderatorToken") ? (
                  ""
                ) : (
                  <>
                    <div className="btn-group">
                      <NavLink
                        to="/student-registration"
                        className="bg-warning btn-admin-login mx-2 px-2 text-dark rounded-sm fixed-width-registration"
                      >
                        {_t(t("Registration"))}
                      </NavLink>
                      <button
                        className="btn btn-admin-login dropdown-toggle fs-13 mr-2"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {_t(t("Login"))}
                      </button>
                      <div className="dropdown-menu admin-login-links fs-13">
                        <NavLink to="/admin-login" className="dropdown-item">
                          {_t(t("Admin Login"))}
                        </NavLink>
                        <NavLink
                          to="/instructor-login"
                          className="dropdown-item"
                        >
                          {_t(t("Instructor Login"))}
                        </NavLink>
                        <NavLink
                          to="/moderator-login"
                          className="dropdown-item"
                        >
                          {_t(t("Moderator Login"))}
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}

                {this.state.socialLinks.length > 0
                  ? this.state.socialLinks.map((item, index) => {
                      return (
                        <a
                          key={index}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-icons  mx-1 px-1"
                        >
                          <span
                            title={item.link}
                            dangerouslySetInnerHTML={{
                              __html: item.icon_html,
                            }}
                          />
                        </a>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid sticky-top">
          <nav className="row navbar navbar-expand-lg text-center navbar-light bg-light">
            <div className="container-fluid" style={{ minHeight: "60px" }}>
              <NavLink to="/">
                <img
                  src={BASE_URL + "/images/logo/" + [this.state.logo.logo]}
                  alt="Online Courses"
                  height="50px"
                />
              </NavLink>
              <button
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                {cookies.get("adminToken")
                  ? adminLink
                  : [
                      cookies.get("instructorToken")
                        ? InstructorLink
                        : [
                            cookies.get("moderatorToken")
                              ? ModeratorLink
                              : studentLink,
                          ],
                    ]}
              </div>
            </div>
          </nav>
        </section>
      </>
    );
  }
}
export default withTranslation()(withRouter(Navbar));
