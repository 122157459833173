import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Components
import Sidebar from "../Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Addnewupload extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,//false
          error: [],
          batch_id: null,
          path: "",
          isMobile: false,
          sidebar: true,
          isChecked: true,
          courses: [],
          categories:[],
          name: '',
          cat_id: null,
          file: ''
        };
        this.addFiles = this.addFiles.bind(this);
        this.getCatFunc = this.getCatFunc.bind(this);
      }
      
      componentDidMount() {
        document.title = "Add File";
        const wid = window.innerWidth;
        if (wid < 768) {
          this.setState({
            isMobile: true,
            sidebar: false,
          });
        }
        window.addEventListener(
          "resize",
          () => {
            this.setState({
              isMobile: window.innerWidth < 768,
              sidebar: window.innerWidth > 768,
            });
          },
          false
        );
        this.setState({
          loading: false,//true
          path: this.props.history.location.pathname,
        });

        this.getCatFunc();

        const url = BASE_URL + "/api/admin/course/p170815s";
        return axios
          .get(url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res2) => {
            this.setState({
              courses: res2.data,
            });
            // console.log(this.state.batch_id);
            const students_url = BASE_URL + "/api/admin/student/student_p170815s";
            return axios
              .get(students_url, {
                headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
              })
              .then((res3) => {
                this.setState({
                  students: res3.data,
                  loading: false,
                });
              });
             
            
          });
      }
      

      handleCategory = (e) => {
        this.setState({
          cat_id: e.id,
        });
      }

      getCatFunc = () =>{
        // e.preventDefault();
        const url = BASE_URL + `/api/admin/all-cate`;
        return axios
          .get(url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
           console.log(res);
            this.setState({
              categories: res.data,
              loading :false,
            }); 
           
          });
      }

      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

      handleChangeFile = (e) => {
          let files =e.target.files || e.dataTransfer.files;
          if(!files.length) return;
          this.creatFiles(files[0]);
      }

      creatFiles = (file) => {
          let reader = new FileReader();
          reader.onload = (e) => {
              this.setState({
                  file : e.target.result,
              });
          };
          reader.readAsDataURL(file);
      }

      // addFile
      addFiles (e) {
        e.preventDefault();
       this.setState({ 
         loading: true,
         });
        const url = BASE_URL + `/api/admin/add-file`;
      
         const formData = {
          name : this.state.name,
          cat_id : this.state.cat_id,
          file : this.state.file,
        }
        return axios.post(url,formData,{
          headers: { Authorization: `Bearer ${cookies.get('adminToken')}` },
        }).then((res)=>{
          console.log(res);
          this.setState({
            name :'',
            file : '',
            cat_id : null,
            error :[],
            loading : false,
          })
          toast.success('File Added successfully')
          this.props.history.push('/admin/files/all-upload-file');
        }).catch((error,res) =>{
          this.setState({
            loading:false,
            error: error.response.data.errors
          })
        }); 
      }
    
      handleSidebar = (e) => {
        this.setState({
          sidebar: !this.state.sidebar,
        });
      };
      render() {
        return (
          <>
          {/* Notiification */}
              <ToastContainer 
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
          {/* sidebar navigatin for mobile */}
            <div
              className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
              style={{
                position: "fixed",
                top: "21%",
                zIndex: "10",
              }}
            >
              <button
                className="btn btn-sm btn-warning rounded-circle"
                title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                onClick={this.handleSidebar}
              >
                <i className="fa fa-align-right"></i>
              </button>
            </div>
            {/* sidebar navigatin for mobile */}
    
            <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
              <div className="row">
                <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
                  {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
                </div>
                <div
                  className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
                >
                  <div className="container">
                    <div className="row align-items-center justify-content-between mb-5">
                      <span className="mt-1">
                        <div className="nav-link text-dark">
                          <h4>
                            <i
                              className="fa fa-chevron-right"
                              style={{ fontSize: 20 }}
                            ></i>{" "}
                            Add New file
                          </h4>
                        </div>
                      </span>
                      <Link className="btn btn-primary" to='/admin/files/all-upload-file'>All Files</Link>
                    </div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="card card-body bg-light pt-3 mb-2 pb-0 col-lg-6">
                          <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                            Add New File
                          </div>
                          {this.state.loading ? (
                            <div className="container">
                              <div className="mt-3">
                                <h1>
                                  <Skeleton />
                                </h1>
                                <h3>
                                  <Skeleton count={5} />
                                </h3>
                                <Skeleton count={5} />
                              </div>
                            </div>
                          ) : (
                            <>
                              <form
                                className="form-group"
                                onSubmit={this.addFiles}
                              >
                              <div className="row justify-content-center ">
                                <div className="col-md-12 input-group mb-2">
                                 <input 
                                 type="text" 
                                 placeholder='Name'
                                 name='name'
                                 className="w-100"
                                 onChange={this.handleChange}
                                 />
                                  </div>
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                             
                                </div>
                                {/* <div className="col-lg-12 d-flex justify-content-between form-group">
                                <Select
                                      options={this.state.courses}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.id}
                                      className="basic-multi-select w-100"
                                      classNamePrefix="select"
                                      onChange={this.handleCourse}
                                      placeholder="Please a course"
                                    />
                                </div> */}
                    
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <Select
                                      options={this.state.categories}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      className="basic-multi-select w-100"
                                      classNamePrefix="select"
                                      onChange={this.handleCategory}
                                      placeholder="Please a Categories"
                                    />
                                </div>

                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <div class="form-group">
                                  <input 
                                  type="file" 
                                  className="w-100" 
                                  id="exampleCheck1"
                                  name='file'
                                  onChange={this.handleChangeFile}/>
                                  
                                </div>
                                </div>
                                  <div className="col-lg-12 d-flex justify-content-center">
                                  <button className="btn btn-success w-50 px-4" type="submit">Add file</button>
                                  </div>
                              </div>
                              </form>
                            </>
                          )}
                        </div>
                      </div>
                    </div>      
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
}

export default withRouter(Addnewupload);
