import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Logo extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      image: "",
      logo: [],
      alert_success: null,
      error: null,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Logo";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
      //   loading: true
    });
    const url = BASE_URL + "/api/admin/logo";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          logo: res.data,
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/add_logo";
    let formData = new FormData();
    formData.append("logo", this.state.image);
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          logo: res.data,
          loading: false,
          alert_success: "Successfull.",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          3000
        );
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Logo
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <div className="container">
                          <div
                            className="card card-body bg-light col-md-10 offset-md-1 pt-3"
                            style={{ minHeight: "250px" }}
                          >
                            {this.state.alert_success ? (
                              <div className="bg-success text-white text-center py-3 mb-2">
                                {this.state.alert_success}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Error Message */}
                            {this.state.error !== null ? (
                              <div className="bg-danger text-white text-center py-3 mb-2">
                                {/* Error Message */}
                                {this.state.error ? (
                                  <div className="fs-13 pl-0">
                                    {this.state.error.logo
                                      ? this.state.error.logo
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Error Message */}

                            <div className="card-header text-center text-uppercase mb-3">
                              Logo
                            </div>
                            <div className="row">
                              <div className="col-md-6 offset-md-3 mb-3">
                                <div
                                  className="card card-body bg-light p-0 text-center"
                                  style={{
                                    minHeight: "150px",
                                  }}
                                >
                                  <img
                                    src={`${BASE_URL}${`/images/logo/${this.state.logo.logo}`}`}
                                    className="img-fluid"
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mt-3 mb-3">
                            <div className="card-header text-center text-uppercase mb-3">
                              Add new Logo
                            </div>
                            <form
                              className="form-group"
                              onSubmit={this.handleSubmit}
                            >
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="image"
                                  className="my-auto col-md-3"
                                >
                                  <b>Add new Logo</b>
                                </label>
                                <input
                                  type="file"
                                  id="image"
                                  className="form-control-file pl-0 col-md-9"
                                  name="image"
                                  onChange={this.handleChangeFile}
                                  required
                                />
                              </div>
                              <div className="text-center col">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-sm mt-3 px-4"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Logo);
