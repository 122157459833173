import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cookies = new Cookies();


class EditNotice extends Component {


    constructor({props,match}) {
        super(props);
        this.state = {
          seletedCourse :'',
          course_id : null,
          loading: false,
          error: [],
          batch_id: null,
          path: "",
          isMobile: false,
          sidebar: true,
          batch: true,
          student: false,
          notice: '',
          isChecked: true,
          table_id: match.params.id
        };
        this.updateNotice = this.updateNotice.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
      }
      
      componentDidMount() {
        document.title = "Send Notice";
        const wid = window.innerWidth;
        if (wid < 768) {
          this.setState({
            isMobile: true,
            sidebar: false,
          });
        }
        window.addEventListener(
          "resize",
          () => {
            this.setState({
              isMobile: window.innerWidth < 768,
              sidebar: window.innerWidth > 768,
            });
          },
          false
        );
        this.setState({
          loading: true,
          path: this.props.history.location.pathname,
        });
        const single_url = BASE_URL + `/api/admin/view-single-notice/${this.state.table_id}`;
       
        return axios
          .get(single_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res2) => {
            // console.log(res2.data[0].course_title);
            // console.log(res2.data[0].notice);
            this.setState({
              seletedCourse : res2.data[0].course_title,
              course_id : res2.data[0].course_id,
              notice : res2.data[0].notice,
            });
            // console.log(this.state.batch_id);
            const students_url = BASE_URL + "/api/admin/student/student_p170815s";
            return axios
              .get(students_url, {
                headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
              })
              .then((res3) => {
                this.setState({
                  students: res3.data,
                  loading: false,
                });
              });
              toast.success('Notice updated successfully')
             
          });
      }
      handleBatch = (batch) => {
        this.setState({
          selectedBatch: batch.id,//batch
          seletedCourse : batch.title
        });
        // console.log(this.state.selectedBatch);
        // console.log(this.state.seletedCourse);
      };

      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

      updateNotice (e) {
        e.preventDefault();
        this.setState({ 
          loading: true,
          });
          const url = BASE_URL + `/api/admin/update-notice/${this.state.table_id}`;
       
         const formData = {
           course_id : this.state.course_id,
           course_title : this.state.seletedCourse,
           notice : this.state.notice,
           isEnable : this.state.isChecked
         }
         return axios.post(url,formData,{
          headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
         }).then((res)=>{
           console.log(res.data);
          //  this.setState({
          //    notice : '',
          //    selectedBatch: '',
          //    checked : true,
          //    error :[],
          //    loading : false,
          
          //  })
           this.props.history.push('/admin/notice/all-notice');
           toast.success('notice updated successfully')
         }).catch((error,res) =>{
           this.setState({
             loading:false,
             error: error.response.data.errors
           })
         }); 
      }

      toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }

      handleSidebar = (e) => {
        this.setState({
          sidebar: !this.state.sidebar,
        });
      };
      render() {
        return (
          <>
            {/* Notiification */}
              <ToastContainer 
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
          {/* sidebar navigatin for mobile */}
            <div
              className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
              style={{
                position: "fixed",
                top: "21%",
                zIndex: "10",
              }}
            >
              <button
                className="btn btn-sm btn-warning rounded-circle"
                title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                onClick={this.handleSidebar}
              >
                <i className="fa fa-align-right"></i>
              </button>
            </div>
            {/* sidebar navigatin for mobile */}
    
            <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
              <div className="row">
                <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
                  {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
                </div>
                <div
                  className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
                >
                  <div className="container">
                    <div className="row align-items-center justify-content-between mb-5">
                      <span className="mt-1">
                        <div className="nav-link text-dark">
                          <h4>
                            <i
                              className="fa fa-chevron-right"
                              style={{ fontSize: 20 }}
                            ></i>{" "}
                           Edit Notice
                          </h4>
                        </div>
                      </span>
                      <Link className="btn btn-primary" to='/admin/notice/all-notice'>Back to All Notice</Link>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="card card-body bg-light pt-3 mb-2 pb-0">
                          <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                          Edit Details
                          </div>
                          {this.state.loading ? (
                            <div className="container">
                              <div className="mt-3">
                                <h1>
                                  <Skeleton />
                                </h1>
                                <h3>
                                  <Skeleton count={5} />
                                </h3>
                                <Skeleton count={5} />
                              </div>
                            </div>
                          ) : (
                            <>
                              <form
                                className="form-group"
                                onSubmit={this.updateNotice}
                              >
                              <div className="row justify-content-center">
                                <div className="col-md-12 input-group mb-2">
                                    <input 
                                    type="text"
                                    disabled
                                    defaultValue={this.state.seletedCourse}
                                    className='form-control w-100'
                                    />
                                  </div>
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <textarea
                                    id="notice"
                                    name="notice"
                                    placeholder="Edit notice..."
                                    className="form-control"
                                    value={this.state.notice}
                                    onChange={this.handleChange}
                                    rows="3"
                                    required
                                  />
                                </div>
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <div class="form-group form-check">
                                  <input 
                                  type="checkbox" 
                                  class="form-check-input" 
                                  id="exampleCheck1"
                                  defaultChecked={this.state.isChecked}
                                  onChange={this.toggleChange}/>
                                  <label class="form-check-label" onChange={this.onCheck} for="exampleCheck1">Enable</label>
                                </div>
                                </div>
                                  <div className="col-lg-12 d-flex justify-content-center">
                                  <button className="btn btn-success w-50 px-4" type="submit">Update</button>
                                </div>
                              </div>
                              </form>
                            </>
                          )}
                        </div>
                      </div>
                    </div>      
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
}

export default withRouter(EditNotice);

