import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
      alert: "",
      loading: false,
    };
  }

  componentDidMount() {
    document.title = "Reset Password";
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/auth/student/resetPassword";
    const user = {
      email: this.state.email,
    };
    return axios
      .post(url, user)
      .then(() => {
        this.setState({
          alert:
            "A link has been sent to your email. Please check your email to reset your password. ",
          error: "",
          email: "",
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: "There is no student with this email.",
          alert: "",
          loading: false,
        });
      });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card m-4 login-card">
                <div className="card-body bg-info text-center text-white">
                  <h3 className="text-center">Reset Password</h3>
                  <img
                    src="https://image.freepik.com/free-vector/plumber-flat-color-faceless-character-specialist-fixing-toilet-handyman-with-wrench-house-improvement-handyperson-repairs-pipeline-home-repairs-isolated-cartoon-illustration_151150-1665.jpg"
                    alt="user"
                    height="100"
                    width="100"
                    className="rounded-circle"
                  />
                  {this.state.loading ? (
                    <div className="col-md-12 text-center bg-success text-white p-3 mt-2 rounded-sm">
                      {_t(t("Please wait!"))}
                    </div>
                  ) : (
                    <form className="col-md-12" onSubmit={this.handleSubmit}>
                      {this.state.error ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger mt-2">
                          {this.state.error}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.alert ? (
                        <div className="text-center text-white rounded-sm p-2 bg-success mt-2">
                          {this.state.alert}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group mt-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-light rounded-circle">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded ml-3"
                          placeholder="Enter your email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mt-5">
                        <div className="text-center">
                          <button type="submit" className="btn btn-light">
                            {_t(t("Reset Password"))}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(ResetPassword);
