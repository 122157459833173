import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cookies = new Cookies();
class AddCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
          selectedBatch: null,
          seletedCourse:'',
          loading: false,
          error: [],
          batch_id: null,
          path: "",
          isMobile: false,
          course_id: null,
          sidebar: true,
          courses: [],
          name: ''
        };
        this.onSubmit = this.onSubmit.bind(this);
      }
      
      componentDidMount() {
        document.title = "Add Category";
        const wid = window.innerWidth;
        if (wid < 768) {
          this.setState({
            isMobile: true,
            sidebar: false,
          });
        }
        window.addEventListener(
          "resize",
          () => {
            this.setState({
              isMobile: window.innerWidth < 768,
              sidebar: window.innerWidth > 768,
            });
          },
          false
        );
        this.setState({
          loading: false,
        });


        this.getCourseFunc();
      }

      // all couruse
      getCourseFunc = () =>{
        // e.preventDefault();
        const url = BASE_URL + "/api/admin/course/p170815s";
        return axios
          .get(url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
           console.log(res);
            this.setState({
              courses: res.data,
              loading :false,
            }); 
           
          });
      }

      // get course id
      handleCourse = (e) => {
        this.setState({
          course_id: e.id,
        });
      }

      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

      // onSubmit
      onSubmit (e) {
        e.preventDefault();
       this.setState({ 
         loading: true,
         });
         const url = BASE_URL + `/api/admin/add-categories`;
      
        const formData = {
          name : this.state.name,
          course_id : this.state.course_id,
        }
        return axios.post(url,formData,{
          headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
        }).then((res)=>{
          console.log(res);
          this.setState({
            name : '',
            error :[],
            loading : false,
          })
          toast.success('category added successfully')
        }).catch((error,res) =>{
          this.setState({
            loading:false,
            error: error.response.data.errors
          })
        }); 
      }
    
      handleSidebar = (e) => {
        this.setState({
          sidebar: !this.state.sidebar,
        });
      };
      render() {
        return (
          <>
          {/* Notiification */}
              <ToastContainer 
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
          {/* sidebar navigatin for mobile */}
            <div
              className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
              style={{
                position: "fixed",
                top: "21%",
                zIndex: "10",
              }}
            >
              <button
                className="btn btn-sm btn-warning rounded-circle"
                title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                onClick={this.handleSidebar}
              >
                <i className="fa fa-align-right"></i>
              </button>
            </div>
            {/* sidebar navigatin for mobile */}
    
            <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
              <div className="row">
                <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
                  {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
                </div>
                <div
                  className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
                >
                  <div className="container">
                    <div className="row align-items-center justify-content-between mb-5">
                      <span className="mt-1">
                        <div className="nav-link text-dark">
                          <h4>
                            <i
                              className="fa fa-chevron-right"
                              style={{ fontSize: 20 }}
                            ></i>{" "}
                            Add New Category
                          </h4>
                        </div>
                      </span>
                      <Link className="btn btn-primary" to='/admin/files/categories'>Back to All Categories</Link>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="card card-body bg-light pt-3 mb-2 pb-0">
                          <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                            Add Category
                          </div>
                          {this.state.loading ? (
                            <div className="container">
                              <div className="mt-3">
                                <h1>
                                  <Skeleton />
                                </h1>
                                <h3>
                                  <Skeleton count={5} />
                                </h3>
                                <Skeleton count={5} />
                              </div>
                            </div>
                          ) : (
                            <>
                              <form
                                className="form-group"
                                onSubmit={this.onSubmit}
                              >
                              <div className="row justify-content-center">
                
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <input
                                    name="name"
                                    placeholder="category"
                                    className="form-control"
                                    value={this.state.category}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="col-lg-12 d-flex justify-content-between form-group">
                                <Select
                                      options={this.state.courses}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.id}
                                      className="basic-multi-select w-100"
                                      classNamePrefix="select"
                                      onChange={this.handleCourse}
                                      placeholder="Please select a course"
                                    />
                                </div>
                                  <div className="col-lg-12 d-flex justify-content-center">
                                  <button className="btn btn-success w-50 px-4" type="submit">Add Category</button>
                                  </div>
                              </div>
                              </form>
                            </>
                          )}
                        </div>
                      </div>
                    </div>      
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
}

export default withRouter(AddCategories);

