import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class AllDiscussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      searchedCourses: [],
      search: false,
      search_query: "",
      timeout: 0,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }

  componentDidMount() {
    document.title = "Comments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const course_url = BASE_URL + "/api/admin/get_discussion_courses";
    return axios
      .get(course_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          courses: res.data,
          loading: false,
        });
      });
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  handleSearch = (e) => {
    var searchText = e.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      //search function
      const url = BASE_URL + "/api/admin/findCourse/" + searchText;
      if (searchText !== null && searchText !== "") {
        return axios
          .post(url, null, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              search: true,
              searchedCourses: res.data,
            });
          });
      } else {
        this.setState({
          search: false,
        });
      }
    }, 1000);
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        All Courses
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <form
                        className="ml-auto mr-3"
                        onSubmit={this.handleSearchSubmit}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            name="search_query"
                            className="form-control text-center"
                            placeholder="Search.."
                            onChange={this.handleSearch}
                          />
                          <div className="input-group-append d-none">
                            <button
                              type="submit"
                              className="input-group-text bg-primary text-white"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {this.state.loading ? (
                      <div className="row">
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                          <Skeleton className="course-card" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          {!this.state.search
                            ? [
                                this.state.courses.length > 0 ? (
                                  [
                                    this.state.courses.map((item) => {
                                      return (
                                        <div
                                          title={item.title}
                                          className="col-lg-4 col-md-6 mt-3"
                                        >
                                          <Link
                                            to={`/admin/check-comments/courses/${item.id}`}
                                            className="card-link"
                                          >
                                            <div className="card course-card">
                                              <img
                                                className="card-img-top"
                                                src={`${BASE_URL}${`/images/course/${item.thumbnail}`}`}
                                                height="150px"
                                                width="100%"
                                                alt=""
                                              />
                                              <div className="card-body bg-light  border-top">
                                                <h6 className="card-title mb-0 text-truncate">
                                                  {item.title}
                                                </h6>
                                              </div>

                                              <div className="card-footer text-center">
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-primary"
                                                >
                                                  Discussion
                                                </button>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      );
                                    }),
                                  ]
                                ) : (
                                  <div className="col text-center alert alert-danger mt-4 mb-3">
                                    No course has been found
                                  </div>
                                ),
                              ]
                            : [
                                this.state.searchedCourses.length > 0 ? (
                                  [
                                    this.state.searchedCourses.map(
                                      (searchedItem) => {
                                        return (
                                          <div
                                            title={searchedItem.title}
                                            className="col-lg-4 col-md-6 mt-3"
                                          >
                                            <Link
                                              to={`/admin/check-comments/courses/${searchedItem.id}`}
                                              className="card-link"
                                            >
                                              <div className="card course-card">
                                                <img
                                                  className="card-img-top"
                                                  src={`${BASE_URL}${`/images/course/${searchedItem.thumbnail}`}`}
                                                  height="150px"
                                                  width="100%"
                                                  alt=""
                                                />
                                                <div className="card-body bg-light  border-top">
                                                  <h6 className="card-title mb-0 text-truncate">
                                                    {searchedItem.title}
                                                  </h6>
                                                </div>

                                                <div className="card-footer text-center">
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                  >
                                                    Discussion
                                                  </button>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                        );
                                      }
                                    ),
                                  ]
                                ) : (
                                  <div className="col text-center alert alert-danger mt-4 mb-3">
                                    No course has been found by your query
                                  </div>
                                ),
                              ]}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(AllDiscussion);
