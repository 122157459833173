import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";

import { BASE_URL } from "./BaseUrl";
// Admin Components
import AdminRoute from "./routes/components/AdminRoute";
// Admin pages
import AdminLogin from "./pages/admin/AdminLogin";

import Dashboard from "./pages/admin/Dashboard";

import ManageAssignment from "./pages/admin/assignments/ManageAssignment";
import AllAssignment from "./pages/admin/assignments/AllAssignment";
import MarkAssignment from "./pages/admin/assignments/MarkAssignment";
import ViewAssignment from "./pages/admin/assignments/ViewAssignment";
import ViewFeedback from "./pages/admin/assignments/ViewFeedback";
import ManageFeedback from "./pages/admin/assignments/ManageFeedback";

import CheckComment from "./pages/admin/comments/CheckComment";
import ViewComment from "./pages/admin/comments/ViewComment";
import AllDiscussion from "./pages/admin/comments/AllDiscussion";
import DiscussionClass from "./pages/admin/comments/DiscussionClass";
import ViewDiscussion from "./pages/admin/comments/ViewDiscussion";

import AddCourse from "./pages/admin/course/AddCourse";
import ManageCourse from "./pages/admin/course/ManageCourse";
import ViewCourse from "./pages/admin/course/ViewCourse";
import PlayCourse from "./pages/admin/course/PlayCourse";
import EditCourse from "./pages/admin/course/EditCourse";
import ManageMods from "./pages/admin/course/ManageMods";
import ManageInstructorCourse from "./pages/admin/course/ManageInstructorCourse";
import ManageCourseClasses from "./pages/admin/course/ManageCourseClasses";

import AddClass from "./pages/admin/course/class/AddClass";
import EditClass from "./pages/admin/course/class/EditClass";

import AddContents from "./pages/admin/course/class/contents/AddContents";
import EditContents from "./pages/admin/course/class/contents/EditContents";

import AddInstructor from "./pages/admin/instructor/AddInstructor";
import ManageInstructor from "./pages/admin/instructor/ManageInstructor";
import EditInstructor from "./pages/admin/instructor/EditInstructor";
import ViewInstructor from "./pages/admin/instructor/ViewInstructor";
import Instructorprofile from "./pages/instructor/profile/InstructorProfile";

import AddModerator from "./pages/admin/moderator/AddModerator";
import ManageModerator from "./pages/admin/moderator/ManageModerator";
import EditModerator from "./pages/admin/moderator/EditModerator";
import ViewModerator from "./pages/admin/moderator/ViewModerator";

import AddStudent from "./pages/admin/student/AddStudent";
import ManageStudent from "./pages/admin/student/ManageStudent";
import ManageStudent4g from "./pages/admin/student/ManageStudent4g";
import EditStudent from "./pages/admin/student/EditStudent";
import ViewStudent from "./pages/admin/student/ViewStudent";
import StudentsRequest from "./pages/admin/student/StudentsRequest";
import ApproveRequest from "./pages/admin/student/ApproveRequest";

import AddEnrollment from "./pages/admin/enrollment/AddEnrollment";
import DueEnrollment from "./pages/admin/enrollment/DueEnrollMent";
import DueEnrollment4g from "./pages/admin/enrollment/DueEnrollMent4g";
import BlockedEnrollMent4g from "./pages/admin/enrollment/BlockedEnrollMent4g";
import BlockedEnrollMent from "./pages/admin/enrollment/BlockedEnrollMent";
import ManageEnrollment from "./pages/admin/enrollment/ManageEnrollment";
import ManageEnrollment4g from "./pages/admin/enrollment/ManageEnrollment4g";
import ViewEnrollment from "./pages/admin/enrollment/ViewEnrollment";
import EditEnrollment from "./pages/admin/enrollment/EditEnrollment";

import AddAdmin from "./pages/admin/settings/admin/AddAdmin";
import ManageAdmin from "./pages/admin/settings/admin/ManageAdmin";
import EditAdmin from "./pages/admin/settings/admin/EditAdmin";
import ViewAdmin from "./pages/admin/settings/admin/ViewAdmin";

import UpdateEnrollmentContact from "./pages/admin/settings/website/UpdateEnrollmentContact";
import UpdateContact from "./pages/admin/settings/website/UpdateContact";
import UpdateLink from "./pages/admin/settings/website/UpdateLink";
import Slider from "./pages/admin/settings/website/Slider";
import Logo from "./pages/admin/settings/website/Logo";
import Sms from "./pages/admin/settings/website/Sms";
import Smtp from "./pages/admin/settings/website/Smtp";
import Languages from "./pages/admin/settings/website/Languages";

// Instructor Route
import InstructorRoute from "./routes/components/InstructorRoute";
// Instructor Pages
import InstructorLogin from "./pages/instructor/InstructorLogin";
import InstructorDashboard from "./pages/instructor/InstructorDashboard";

import NewHero from "./pages/admin/settings/website/NewHero";
import Promote from "./pages/admin/settings/website/Promote";

import InstructorCheckComment from "./pages/instructor/comments/InstructorCheckComment";
import InstructorViewComment from "./pages/instructor/comments/InstructorViewComment";
import InstructorAllDiscussion from "./pages/instructor/comments/InstructorAllDiscussion";
import InstructorDiscussionClass from "./pages/instructor/comments/InstructorDiscussionClass";
import InstructorViewDiscussion from "./pages/instructor/comments/InstructorViewDiscussion";

import InstructorManageAssignment from "./pages/instructor/assignments/InstructorManageAssignment";
import InstructorAllAssignment from "./pages/instructor/assignments/InstructorAllAssignment";
import InstructorMarkAssignment from "./pages/instructor/assignments/InstructorMarkAssignment";
import InstructorViewAssignment from "./pages/instructor/assignments/InstructorViewAssignment";
import InstructorViewFeedback from "./pages/instructor/assignments/InstructorViewFeedback";
import InstructorManageFeedback from "./pages/instructor/assignments/InstructorManageFeedback";

// Moderator Pages
import ModeratorLogin from "./pages/moderator/ModeratorLogin";
import ModeratorDashboard from "./pages/moderator/ModeratorDashboard";

import ModeratorCheckComment from "./pages/moderator/comments/ModeratorCheckComment";
import ModeratorViewComment from "./pages/moderator/comments/ModeratorViewComment";
import ModeratorAllDiscussion from "./pages/moderator/comments/ModeratorAllDiscussion";
import ModeratorDiscussionClass from "./pages/moderator/comments/ModeratorDiscussionClass";
import ModeratorViewDiscussion from "./pages/moderator/comments/ModeratorViewDiscussion";

import ModeratorManageAssignment from "./pages/moderator/assignments/ModeratorManageAssignment";
import ModeratorAllAssignment from "./pages/moderator/assignments/ModeratorAllAssignment";
import ModeratorMarkAssignment from "./pages/moderator/assignments/ModeratorMarkAssignment";
import ModeratorViewAssignment from "./pages/moderator/assignments/ModeratorViewAssignment";
import ModeratorViewFeedback from "./pages/moderator/assignments/ModeratorViewFeedback";
import ModeratorManageFeedback from "./pages/moderator/assignments/ModeratorManageFeedback";

// student & user pages
import Login from "./pages/student/Login";
import ResetPassword from "./pages/student/ResetPassword";
import SetNewPassword from "./pages/student/SetNewPassword";

import Error from "./pages/Error";
import Home from "./pages/Home";
import AllCourses from "./pages/AllCourses";
import SingleAllCourse from "./pages/SingleAllCourse";
import StudentRegistration from "./pages/StudentRegistration";
import MyCourses from "./pages/student/MyCourses";

import Forum from "./pages/student/Forum";
import Assignment from "./pages/student/Assignment";
import AssignmentCourse from "./pages/student/AssignmentCourse";
import AssignmentClass from "./pages/student/AssignmentClass";
import CourseForum from "./pages/student/CourseForum";
import ClassForum from "./pages/student/ClassForum";
import SingleCourse from "./pages/student/SingleCourse";
import PersonalInfo from "./pages/student/PersonalInfo";
import PaymentStatus from "./pages/student/PaymentStatus";
import CoursePaymentStatus from "./pages/student/CoursePaymentStatus";
import Feedback from "./pages/student/Feedback";
import CommentNotifications from "./pages/student/CommentNotifications";

// Components
import StudentRoute from "./routes/components/StudentRoute";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FooterText from "./pages/admin/settings/website/FooterText";
import ModeratorRoute from "./routes/components/ModeratorRoute";

// all notice
import StudentNotice from "./pages/student/StudentNotice";
import AllNotice from "./pages/admin/notice/AllNotice";
import EditNotice from "./pages/admin/notice/EditNotice";
import AddNotice from "./pages/admin/notice/AddNotice";

// add support session 
import AddSession from "./pages/admin/support/AddSession";

import ModeratorAllNotice from "./pages/moderator/notice/AllNotice";
import ModeratorEditNotice from "./pages/moderator/notice/EditNotice";
import ModeratorAddNotice from "./pages/moderator/notice/AddNotice";

// all files
import Allfiles from "./pages/AllFiles";
import Addnewupload from "./pages/admin/files/AddNewUpload";
import Alluploads from "./pages/admin/files/AllUploads";
import EditUpload from "./pages/admin/files/EditUpload";

import ModeratorAllfiles from "./pages/AllFiles";
import ModeratorAddnewupload from "./pages/moderator/files/AddNewUpload";
import ModeratorAlluploads from "./pages/moderator/files/AllUploads";
import ModeratorEditUpload from "./pages/moderator/files/EditUpload";

// categories
import Categories from "./pages/admin/files/Categories";
import AddCategories from "./pages/admin/files/AddCategories";
import EditCategories from "./pages/admin/files/EditCategories";
import Singlecoursefile from "./pages/student/SingleCourseFile";

import ModeratorEditCategories from "./pages/moderator/files/EditCategories";
import ModeratorCategories from "./pages/moderator/files/Categories";
import ModeratorAddCategories from "./pages/moderator/files/AddCategories";
import Refresh from "./pages/Refresh";

import Cookies from "universal-cookie";
// import TawkToPage from "./pages/TawkToPage";
import StudentSupport from "./pages/student/StudentSupport";


const cookies = new Cookies();

class App extends Component {
  componentDidMount() {
    setTimeout(() => this.setState({ loading: true }), 1000);

    //coppying localstorage token to cookie
    if (localStorage.getItem("studentToken")) {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      cookies.set("studentToken", localStorage.studentToken, {
        path: "/",
        expires: date,
      });
      localStorage.removeItem("studentToken");
    }

    if (localStorage.getItem("adminToken")) {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      cookies.set("adminToken", localStorage.adminToken, {
        path: "/",
        expires: date,
      });
      localStorage.removeItem("adminToken");
    }

    if (localStorage.getItem("instructorToken")) {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      cookies.set("instructorToken", localStorage.instructorToken, {
        path: "/",
        expires: date,
      });
      localStorage.removeItem("instructorToken");
    }

    if (localStorage.getItem("moderatorToken")) {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      cookies.set("moderatorToken", localStorage.moderatorToken, {
        path: "/",
        expires: date,
      });
      localStorage.removeItem("moderatorToken");
    }
    //coppying localstorage token to cookie

    //handling unlimited loading on token mitchmatch

    //student
    if (cookies.get("studentToken")) {
      const user_url = BASE_URL + "/api/auth/student/me";
      return axios
        .get(user_url, {
          headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
        })
        .catch((error) => {
          if (error.response.status == 401) {
            cookies.remove("studentToken");
          }
        });
    }

    //admin
    if (cookies.get("adminToken")) {
      const user_url = BASE_URL + "/api/auth/admin/me";
      return axios
        .get(user_url, {
          headers: { Authorization: `Bearer ${cookies.get("adminToken")}` },
        })
        .catch((error) => {
          if (error.response.status == 401) {
            cookies.remove("adminToken");
          }
        });
    }

    //instructor
    if (cookies.get("instructorToken")) {
      const user_url = BASE_URL + "/api/auth/instructor/me";
      return axios
        .get(user_url, {
          headers: {
            Authorization: `Bearer ${cookies.get("instructorToken")}`,
          },
        })
        .catch((error) => {
          if (error.response.status == 401) {
            cookies.remove("instructorToken");
          }
        });
    }

    //moderator
    if (cookies.get("moderatorToken")) {
      const user_url = BASE_URL + "/api/auth/moderator/me";
      return axios
        .get(user_url, {
          headers: {
            Authorization: `Bearer ${cookies.get("moderatorToken")}`,
          },
        })
        .catch((error) => {
          if (error.response.status == 401) {
            cookies.remove("moderatorToken");
          }
        });
    }

    //handling unlimited loading on token mitchmatch

    //default lang
    const url = BASE_URL + `/api/languages`;
    axios.get(url).then((res) => {
      const { i18n } = this.props;
      let localLang = localStorage.i18nextLng;
      if (localLang === "undefined" || localLang.includes("en-")) {
        res.data.map((item) => {
          if (item.defaultLang === 1) {
            i18n.changeLanguage(item.code);
          }
        });
      }
    });
  }

  render() {
    return (
      <Router>
        <Navbar />
        <Switch>
          <Route path="/refresh" exact>
            <Refresh />
          </Route>

          {/* <Route path="/twak-to-page" exact>
            <TawkToPage />
          </Route> */}

          <Route path="/" exact component={Home} />

          <Route path="/all-courses" exact>
            <AllCourses />
          </Route>
          <Route path="/all-courses/:id(\d+)" exact>
            <SingleAllCourse />
          </Route>
          {/* <Route path="/all-files" exact>
            <Allfiles />
          </Route> */}
          <Route path="/student-registration" exact>
            <StudentRegistration />
          </Route>

          {/* Student */}
          <Route path="/login" exact component={Login} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route
            path="/set-new-password/:token"
            exact
            component={SetNewPassword}
          />

          <StudentRoute path="/support" exact>
            <StudentSupport />
          </StudentRoute>

          <StudentRoute path="/student-notice" exact>
            <StudentNotice />
          </StudentRoute>

          <StudentRoute path="/all-files" exact>
            <Allfiles />
          </StudentRoute>

          <StudentRoute path="/studnet-files/:id" exact>
            <Singlecoursefile />
          </StudentRoute>

          <StudentRoute path="/my-courses" exact>
            <MyCourses />
          </StudentRoute>
          {/* Course playing routes */}

          <StudentRoute path="/my-courses/:id(\d+)" exact>
            <SingleCourse />
          </StudentRoute>

          <StudentRoute path="/my-courses/:id(\d+)/:activeVideo(\d+)" exact>
            <SingleCourse />
          </StudentRoute>
          {/* Course playing routes */}

          {/* Assignment Routes */}
          <StudentRoute path="/assignment" exact>
            <Assignment />
          </StudentRoute>

          <StudentRoute path="/assignment/:id" exact>
            <AssignmentCourse />
          </StudentRoute>

          <StudentRoute path="/assignment/:id/:classId" exact>
            <AssignmentClass />
          </StudentRoute>

          {/* Assignment Routes */}

          {/* Forum Routes */}
          <StudentRoute path="/forum" exact>
            <Forum />
          </StudentRoute>

          <StudentRoute path="/forum/:id" exact>
            <CourseForum />
          </StudentRoute>
          <StudentRoute path="/forum/:id/:classId" exact>
            <ClassForum />
          </StudentRoute>
          {/* Forum Routes */}

          {/* Profile routes */}
          <StudentRoute path="/profile/personal-info" exact>
            <PersonalInfo />
          </StudentRoute>
          <StudentRoute path="/profile/payment-status" exact>
            <PaymentStatus />
          </StudentRoute>
          <StudentRoute path="/profile/payment-status/:id(\d+)" exact>
            <CoursePaymentStatus />
          </StudentRoute>

          <StudentRoute path="/feedback" exact>
            <Feedback />
          </StudentRoute>

          <StudentRoute path="/notifications" exact>
            <CommentNotifications />
          </StudentRoute>
          {/* Profile routes */}
          {/* Student Ends */}

          {/* Admin Routes */}
          <Route path="/admin-login" exact component={AdminLogin} />
          <AdminRoute path="/admin" exact>
            <Dashboard />
          </AdminRoute>

          {/* Adimn assignments routes */}
          <AdminRoute path="/admin/check-assignments" exact>
            <ManageAssignment />
          </AdminRoute>

          <AdminRoute path="/admin/manage-assignments" exact>
            <AllAssignment />
          </AdminRoute>

          <AdminRoute path="/admin/check-assignments/:id(\d+)" exact>
            <MarkAssignment />
          </AdminRoute>

          <AdminRoute path="/admin/view-assignments/:id(\d+)" exact>
            <ViewAssignment />
          </AdminRoute>

          <AdminRoute path="/admin/manage-feedback" exact>
            <ManageFeedback />
          </AdminRoute>

          <AdminRoute path="/admin/check-feedback/:id(\d+)" exact>
            <ViewFeedback />
          </AdminRoute>
          {/* Adimn assignments routes */}

          {/* Adimn Comments routes */}
          <AdminRoute path="/admin/check-comments" exact>
            <CheckComment />
          </AdminRoute>

          <AdminRoute path="/admin/check-comments/:id(\d+)" exact>
            <ViewComment />
          </AdminRoute>

          <AdminRoute path="/admin/check-comments/courses" exact>
            <AllDiscussion />
          </AdminRoute>

          <AdminRoute path="/admin/check-comments/courses/:id(\d+)" exact>
            <DiscussionClass />
          </AdminRoute>

          <AdminRoute path="/admin/check-comments/class/:id(\d+)" exact>
            <ViewDiscussion />
          </AdminRoute>
          {/* Adimn Comments routes */}

          {/* admin course routes */}
          <AdminRoute path="/admin/add-course" exact>
            <AddCourse />
          </AdminRoute>

          <AdminRoute path="/admin/manage-course" exact>
            <ManageCourse />
          </AdminRoute>

          <AdminRoute path="/admin/edit-course/:id(\d+)" exact>
            <EditCourse />
          </AdminRoute>

          <AdminRoute path="/admin/course-info/:id(\d+)" exact>
            <ViewCourse />
          </AdminRoute>

          <AdminRoute path="/admin/play-course/:id(\d+)" exact>
            <PlayCourse />
          </AdminRoute>

          <AdminRoute
            path="/admin/play-course/:id(\d+)/:activeVideo(\d+)"
            exact
          >
            <PlayCourse />
          </AdminRoute>

          <AdminRoute path="/admin/course/manage-mods/:id(\d+)" exact>
            <ManageMods />
          </AdminRoute>

          <AdminRoute
            path="/admin/course/manage-instructor-course/:id(\d+)"
            exact
          >
            <ManageInstructorCourse />
          </AdminRoute>

          <AdminRoute path="/admin/course/manage-classes/:id(\d+)" exact>
            <ManageCourseClasses />
          </AdminRoute>

          <AdminRoute path="/admin/add-course/:id(\d+)/add-class" exact>
            <AddClass />
          </AdminRoute>

          <AdminRoute
            path="/admin/course/:id(\d+)/edit-class/:classid(\d+)"
            exact
          >
            <EditClass />
          </AdminRoute>

          <AdminRoute
            path="/admin/add-course/:id(\d+)/add-class/class-contents/:classid(\d+)"
            exact
          >
            <AddContents />
          </AdminRoute>

          <AdminRoute
            path="/admin/edit-course/:id(\d+)/edit-class/class-contents/:classid(\d+)"
            exact
          >
            <EditContents />
          </AdminRoute>
          {/* admin course routes */}

          {/* Instructor */}
          <AdminRoute path="/admin/add-instructor" exact>
            <AddInstructor />
          </AdminRoute>

          <AdminRoute path="/admin/manage-instructor" exact>
            <ManageInstructor />
          </AdminRoute>

          <AdminRoute path="/admin/edit-instructor/:id(\d+)" exact>
            <EditInstructor />
          </AdminRoute>

          <AdminRoute path="/admin/instructor-info/:id(\d+)" exact>
            <ViewInstructor />
          </AdminRoute>
          {/* Instructor */}

          {/* Moderator */}
          <AdminRoute path="/admin/add-moderator" exact>
            <AddModerator />
          </AdminRoute>

          <AdminRoute path="/admin/manage-moderator" exact>
            <ManageModerator />
          </AdminRoute>

          <AdminRoute path="/admin/edit-moderator/:id(\d+)" exact>
            <EditModerator />
          </AdminRoute>

          <AdminRoute path="/admin/moderator-info/:id(\d+)" exact>
            <ViewModerator />
          </AdminRoute>
          {/* Moderator */}

          {/* Student */}

          <AdminRoute path="/admin/student-requests" exact>
            <StudentsRequest />
          </AdminRoute>

          <AdminRoute path="/admin/approve-registraton-request/:id(\d+)" exact>
            <ApproveRequest />
          </AdminRoute>

          <AdminRoute path="/admin/add-student" exact>
            <AddStudent />
          </AdminRoute>

          <AdminRoute path="/admin/manage-student" exact>
            <ManageStudent />
          </AdminRoute>

          <AdminRoute path="/admin/manage-student/sujan4g" exact>
            <ManageStudent4g />
          </AdminRoute>

          <AdminRoute path="/admin/edit-student/:id(\d+)" exact>
            <EditStudent />
          </AdminRoute>

          <AdminRoute path="/admin/student-info/:id(\d+)" exact>
            <ViewStudent />
          </AdminRoute>
          {/* Student */}

          <AdminRoute path="/admin/new-enrollment" exact>
            <AddEnrollment />
          </AdminRoute>

          <AdminRoute path="/admin/due-enrollment" exact>
            <DueEnrollment />
          </AdminRoute>

          <AdminRoute path="/admin/blocked-enrollment" exact>
            <BlockedEnrollMent />
          </AdminRoute>

          <AdminRoute path="/admin/due-enrollment/sujan4g" exact>
            <DueEnrollment4g />
          </AdminRoute>

          <AdminRoute path="/admin/blocked-enrollment/sujan4g" exact>
            <BlockedEnrollMent4g />
          </AdminRoute>

          <AdminRoute path="/admin/manage-enrollment" exact>
            <ManageEnrollment />
          </AdminRoute>
          <AdminRoute path="/admin/manage-enrollment/sujan4g" exact>
            <ManageEnrollment4g />
          </AdminRoute>

          <AdminRoute path="/admin/enrollment-info/:id(\d+)" exact>
            <ViewEnrollment />
          </AdminRoute>

          <AdminRoute path="/admin/edit-enrollment/:id(\d+)" exact>
            <EditEnrollment />
          </AdminRoute>
          {/* Admin Settings */}
          <AdminRoute path="/admin/settings/add-admin">
            <AddAdmin />
          </AdminRoute>

          <AdminRoute path="/admin/settings/manage-admin" exact>
            <ManageAdmin />
          </AdminRoute>

          <AdminRoute path="/admin/settings/edit-admin/:id(\d+)" exact>
            <EditAdmin />
          </AdminRoute>

          <AdminRoute path="/admin/settings/admin-info/:id(\d+)" exact>
            <ViewAdmin />
          </AdminRoute>

          <AdminRoute path="/admin/settings/update-contacts">
            <UpdateContact />
          </AdminRoute>

          <AdminRoute path="/admin/settings/new-hero">
            <NewHero />
          </AdminRoute>

          <AdminRoute path="/admin/settings/promote">
            <Promote />
          </AdminRoute>

          <AdminRoute path="/admin/settings/enrollment-contacts">
            <UpdateEnrollmentContact />
          </AdminRoute>

          <AdminRoute path="/admin/settings/update-links">
            <UpdateLink />
          </AdminRoute>

          <AdminRoute path="/admin/settings/sliders">
            <Slider />
          </AdminRoute>

          <AdminRoute path="/admin/settings/footer">
            <FooterText />
          </AdminRoute>

          <AdminRoute path="/admin/settings/languages">
            <Languages />
          </AdminRoute>

          <AdminRoute path="/admin/settings/logo">
            <Logo />
          </AdminRoute>

          <AdminRoute path="/admin/settings/smtp">
            <Smtp />
          </AdminRoute>

          <AdminRoute path="/admin/settings/sms">
            <Sms />
          </AdminRoute>
          {/* notice */}
          <AdminRoute path="/admin/notice/all-notice" exact>
            <AllNotice />
          </AdminRoute>

          <AdminRoute path="/admin/notice/edit-notice/:id" exact>
            <EditNotice />
          </AdminRoute>

          <AdminRoute path="/admin/notice/add-notice" exact>
            <AddNotice />
          </AdminRoute>

          {/* support session route */}

          <AdminRoute path="/admin/support/add-session" exact>
            <AddSession />
          </AdminRoute>

          {/* upload files */}
          <Route path="/admin/files/categories" exact>
            <Categories />
          </Route>

          <AdminRoute path="/admin/files/add-cate" exact>
            <AddCategories />
          </AdminRoute>

          <AdminRoute path="/admin/files/edit-cate/:id" exact>
            <EditCategories />
          </AdminRoute>

          <AdminRoute path="/admin/files/add-upload-file" exact>
            <Addnewupload />
          </AdminRoute>

          <AdminRoute path="/admin/files/all-upload-file" exact>
            <Alluploads />
          </AdminRoute>

          <AdminRoute path="/admin/files/edit-upload-file/:id" exact>
            <EditUpload />
          </AdminRoute>
          {/* Admin Routes */}

          {/* Instructor Routes */}

          <Route path="/instructor-login" exact component={InstructorLogin} />
          <InstructorRoute path="/instructor" exact>
            <InstructorDashboard />
          </InstructorRoute>

          {/* Instructor assignments routes */}
          <InstructorRoute path="/instructor/check-assignments" exact>
            <InstructorManageAssignment />
          </InstructorRoute>
          <InstructorRoute path="/instructor/manage-assignments" exact>
            <InstructorAllAssignment />
          </InstructorRoute>
          <InstructorRoute path="/instructor/check-assignments/:id(\d+)" exact>
            <InstructorMarkAssignment />
          </InstructorRoute>

          <InstructorRoute path="/instructor/view-assignments/:id(\d+)" exact>
            <InstructorViewAssignment />
          </InstructorRoute>

          <InstructorRoute path="/instructor/manage-feedback" exact>
            <InstructorManageFeedback />
          </InstructorRoute>

          <InstructorRoute path="/instructor/check-feedback/:id(\d+)" exact>
            <InstructorViewFeedback />
          </InstructorRoute>

          <InstructorRoute path="/instructor/instructor-profile">
            <Instructorprofile />
          </InstructorRoute>
          {/* Instructor assignments routes */}

          {/* Instructor Comments routes */}
          <InstructorRoute path="/instructor/check-comments" exact>
            <InstructorCheckComment />
          </InstructorRoute>

          <InstructorRoute path="/instructor/check-comments/:id(\d+)" exact>
            <InstructorViewComment />
          </InstructorRoute>

          <InstructorRoute path="/instructor/check-comments/courses" exact>
            <InstructorAllDiscussion />
          </InstructorRoute>

          <InstructorRoute
            path="/instructor/check-comments/courses/:id(\d+)"
            exact
          >
            <InstructorDiscussionClass />
          </InstructorRoute>

          <InstructorRoute
            path="/instructor/check-comments/class/:id(\d+)"
            exact
          >
            <InstructorViewDiscussion />
          </InstructorRoute>
          {/* Instructor Comments routes */}
          {/* Instructor Routes */}

          {/* Moderator Routes */}
          <Route path="/moderator-login" exact component={ModeratorLogin} />
          <ModeratorRoute path="/moderator" exact>
            <ModeratorDashboard />
          </ModeratorRoute>
          {/* Moderator assignments routes */}
          <ModeratorRoute path="/moderator/check-assignments" exact>
            <ModeratorManageAssignment />
          </ModeratorRoute>
          <ModeratorRoute path="/moderator/manage-assignments" exact>
            <ModeratorAllAssignment />
          </ModeratorRoute>
          <ModeratorRoute path="/moderator/check-assignments/:id(\d+)" exact>
            <ModeratorMarkAssignment />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/view-assignments/:id(\d+)" exact>
            <ModeratorViewAssignment />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/manage-feedback" exact>
            <ModeratorManageFeedback />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/check-feedback/:id(\d+)" exact>
            <ModeratorViewFeedback />
          </ModeratorRoute>

          {/* notice and files */}
          {/* notice */}
          <ModeratorRoute path="/moderator/notice/all-notice" exact>
            <ModeratorAllNotice />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/notice/edit-notice/:id" exact>
            <ModeratorEditNotice />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/notice/add-notice" exact>
            <ModeratorAddNotice />
          </ModeratorRoute>

          {/* upload files */}
          <ModeratorRoute path="/moderator/files/categories" exact>
            <ModeratorCategories />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/files/add-cate" exact>
            <ModeratorAddCategories />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/files/edit-cate/:id" exact>
            <ModeratorEditCategories />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/files/add-upload-file" exact>
            <ModeratorAddnewupload />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/files/all-upload-file" exact>
            <ModeratorAlluploads />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/files/edit-upload-file/:id" exact>
            <ModeratorEditUpload />
          </ModeratorRoute>
          {/* notice and files */}
          {/* Moderator assignments routes */}

          {/* Moderator Comments routes */}
          <ModeratorRoute path="/moderator/check-comments" exact>
            <ModeratorCheckComment />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/check-comments/:id(\d+)" exact>
            <ModeratorViewComment />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/check-comments/courses" exact>
            <ModeratorAllDiscussion />
          </ModeratorRoute>

          <ModeratorRoute
            path="/moderator/check-comments/courses/:id(\d+)"
            exact
          >
            <ModeratorDiscussionClass />
          </ModeratorRoute>

          <ModeratorRoute path="/moderator/check-comments/class/:id(\d+)" exact>
            <ModeratorViewDiscussion />
          </ModeratorRoute>
          {/* Moderator Comments routes */}
          {/* Moderator Routes */}

          {/* Error Routing */}
          <Route component={Error} />
          {/* Error Routing */}
        </Switch>
        <Footer />
      </Router>
    );
  }
}
export default withTranslation()(App);
