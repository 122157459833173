import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

class StudentRoute extends Component {
  render() {
    const { t } = this.props;
    // let token = localStorage.studentToken;
    let token = cookies.get("studentToken");
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => {
          return token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { alert: _t(t("You need to login first!")) },
              }}
            />
          );
        }}
      ></Route>
    );
  }
}

export default withTranslation()(StudentRoute);
