import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Sidebar from "../ModeratorSidebar";
import Cookies from "universal-cookie";
import "react-datepicker/dist/react-datepicker.css";
const cookies = new Cookies();

class Alluploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filesName: [],
      error: [],
      path: "",
      isMobile: false,
      sidebar: true,
      modalOpen: false,
      isChecked: false,
      files: []
    };
    // this.deleteNotice = this.deleteNotice.bind(this);
  }


  componentDidMount() {
    document.title = "All Files";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this.setState({
      loading: true,
      path: this.props.history.location.pathname,
    });

    const url = BASE_URL + `/api/moderator/all-files`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("moderatorToken")}` },
      })
      .then((res) => {
        //  console.log(res);
        this.setState({
          files: res.data,
          loading: false,
        });

      });
  }


  deleteFile(id, file) {
    const url = BASE_URL + `/api/moderator/delete-file/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookies.get('moderatorToken')}` },
      })
      .then(() => {
        toast.success('notice deleted successfully');
        window.location.reload(true);
      });
  }


  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };
  render() {
    return (
      <>
        {/* Notiification */}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* sidebar navigatin for mobile */}
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        {/* sidebar navigatin for mobile */}

        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row align-items-center justify-content-between mb-5">
                  <span className="mt-1">
                    <div className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        All files
                      </h4>
                    </div>
                  </span>
                  <Link className="btn btn-primary" to='/moderator/files/add-upload-file'>Add new file</Link>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="card card-body bg-light  pt-3 mb-2 pb-0">
                      <div className="card-header bg-primary text-center text-light text-uppercase mb-3">
                        All Files
                      </div>
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Sl</th>
                                <th scope="col">Name</th>
                                <th scope="col">Published</th>
                                <th scope="col">Downlaod</th>
                                <th scope="col">Edit</th>
                                <th scope="col">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.files && this.state.files.map((item, index) => {
                                return <>

                                  {/* <!-- Modal --> */}
                                  <div class="modal fade" id={`exampleModal${item.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <div className="text-center">
                                            <h5 class="modal-title text-center" id="exampleModalLabel">Do you want to delete this notice?</h5>
                                          </div>
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-footer d-flex justify-content-center">
                                          <button type="button" class="btn btn-danger" onClick={() => this.deleteFile(item.id, item.file)} data-dismiss="modal">Yes</button>
                                          <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">No</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    {/* <td><p className="px-2">{item.notice}</p></td> */}
                                    <td>{item.name}</td>
                                    <td>{item.created_at}</td>
                                    <td className="text-center">
                                      <Link to={{ pathname: BASE_URL + `/downloads/${item.file}` }} target="_blank">
                                        <i class="fa fa-arrow-circle-o-down" aria-hidden="true"></i>
                                      </Link>
                                    </td>
                                    <td ><Link to={`/moderator/files/edit-upload-file/${item.id}`} className="text-success"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></Link></td>

                                    <td ><Link data-toggle="modal" data-target={`#exampleModal${item.id}`} className="text-danger"> <i class="fa fa-trash" aria-hidden="true"></i></Link></td>

                                    {/* <td className="">
                          
                             <input 
                                  type="checkbox" 
                                  class="form-check-input" 
                                  id="exampleCheck1"
                                  defaultChecked={(item.isEnable)}
                                  disabled/>
                       
                                  </td> */}
                                  </tr>
                                </>
                              })}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Alluploads);
