import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UpdateLink extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      link: null,
      icon: null,
      socialLinks: [],
      alert_success: null,
      error: null,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
      submit: false,
    };
  }
  componentDidMount() {
    document.title = "UpdateLinks Images";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const url = BASE_URL + "/api/admin/update_links";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          socialLinks: res.data,
          loading: false,
        });
      });
  }

  handleSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitDetails = (e) => {
    e.preventDefault();
    this.setState({
      submit: true,
    });
    const url = BASE_URL + "/api/admin/update_links";
    let formData = {
      link: this.state.link,
      icon: this.state.icon,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          link: null,
          icon: null,
          socialLinks: res.data,
          submit: false,
          alert_success: "Successfull.",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          3000
        );
      });
  };
  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleDelete = (id) => {
    const url = BASE_URL + "/api/admin/update_links/" + id;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          socialLinks: res.data,
        });
      });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Update Links
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <div className="container">
                          <div className="card card-body bg-light pt-3 mt-3 mb-3">
                            <div className="card-header text-center text-uppercase mb-3">
                              Social Links
                            </div>
                            <div className="row table-responsive text-center">
                              {this.state.alert_success ? (
                                <div className="alert alert-success text-center">
                                  {this.state.alert_success}
                                </div>
                              ) : (
                                ""
                              )}
                              <table className="table col-md-6 offset-md-3 table-hover">
                                <thead>
                                  <tr>
                                    <th>Link</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.socialLinks.length > 0
                                    ? this.state.socialLinks.map((item) => {
                                        return (
                                          <tr>
                                            <td>
                                              <a
                                                href={item.link}
                                                target="_blank"
                                              >
                                                <div
                                                  style={{ fontSize: "24px" }}
                                                  title={item.link}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.icon_html,
                                                  }}
                                                />
                                              </a>
                                            </td>
                                            <td>
                                              <span
                                                onClick={() => {
                                                  this.handleDelete(item.id);
                                                }}
                                              >
                                                <i className="fa fa-times delete-comment"></i>
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    : ""}
                                </tbody>
                              </table>
                            </div>
                            {this.state.submit ? (
                              ""
                            ) : (
                              <form
                                className="form-group"
                                onSubmit={this.handleSubmitDetails}
                              >
                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="link"
                                    className="my-auto col-md-4"
                                  >
                                    <b>Link</b>
                                  </label>
                                  <input
                                    type="url"
                                    id="link"
                                    className="form-control col-md-8"
                                    name="link"
                                    onChange={this.handleChange}
                                    placeholder="Enter Link"
                                    required
                                  />
                                </div>
                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="icon"
                                    className="my-auto col-md-4"
                                  >
                                    <b>
                                      Font-awesome html (
                                      <a
                                        href="https://fontawesome.bootstrapcheatsheets.com/"
                                        target="_blank"
                                      >
                                        Choose
                                      </a>
                                      )
                                    </b>
                                  </label>
                                  <input
                                    type="text"
                                    id="icon"
                                    className="form-control col-md-8"
                                    name="icon"
                                    onChange={this.handleChange}
                                    placeholder="Please choose the html tag from the link"
                                    required
                                  />
                                </div>
                                <div className="text-center col">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm mt-3 px-4"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(UpdateLink);
