import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "../Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class EditEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollment: [],
      course: [],
      student: [],
      batch: "",
      roll: "",
      discount: "",
      payable: "",
      class_access: null,
      paid: null,
      due: null,
      notes: '',

      allow_class_from: "",
      allow_class_to: "",

      first_payment: "",
      first_payment_date: null,
      given_first_payment_date: null,

      second_payable: "",
      second_payment_date: null,
      given_second_payment_date: null,
      remove_second_payment_date: 0,
      second_payment: "",
      second_paid_date: null,
      given_second_paid_date: null,
      remove_second_paid_date: 0,

      third_payable: "",
      third_payment_date: null,
      given_third_payment_date: null,
      remove_third_payment_date: 0,
      third_payment: "",
      third_paid_date: null,
      given_third_paid_date: null,
      remove_third_paid_date: 0,

      fourth_payable: "",
      fourth_payment_date: null,
      given_fourth_payment_date: null,
      remove_fourth_payment_date: 0,
      fourth_payment: "",
      fourth_paid_date: null,
      given_fourth_paid_date: null,
      remove_fourth_paid_date: 0,

      fifth_payable: "",
      fifth_payment_date: null,
      given_fifth_payment_date: null,
      remove_fifth_payment_date: 0,
      fifth_payment: "",
      fifth_paid_date: null,
      given_fifth_paid_date: null,
      remove_fifth_paid_date: 0,

      path: "",
      isMobile: false,
      sidebar: true,
      error: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    document.title = "Update Payments";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    const url = BASE_URL + `/api/admin/enrollment/enrollment_p170815s/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        let n2 = 0;
        let n3 = 0;
        let n4 = 0;
        let n5 = 0;
        if (res.data.second_payment !== null) {
          n2 = res.data.second_payment;
        }
        if (res.data.third_payment !== null) {
          n3 = res.data.third_payment;
        }
        if (res.data.fourth_payment !== null) {
          n4 = res.data.fourth_payment;
        }
        if (res.data.fifth_payment !== null) {
          n5 = res.data.fifth_payment;
        }
        this.setState({
          notes: res.data.note,
          enrollment: res.data,

          allow_class_from: res.data.class_access
            ? JSON.parse(res.data.class_access)[0][0]
            : "",
          allow_class_to: res.data.class_access
            ? JSON.parse(res.data.class_access)[0][1]
            : "",

          batch: res.data.batch,
          roll: res.data.roll,

          discount: res.data.discount,
          payable: res.data.payable,

          first_payment: res.data.first_payment,
          given_first_payment_date: res.data.first_paid_date,

          second_payable: res.data.second_payable,
          given_second_payment_date: res.data.second_payment_date,
          remove_second_payment_date:
            res.data.second_payment_date === null ? 1 : 0,
          second_payment: res.data.second_payment,
          given_second_paid_date: res.data.second_paid_date,
          remove_second_paid_date: res.data.second_paid_date === null ? 1 : 0,

          third_payable: res.data.third_payable,
          given_third_payment_date: res.data.third_payment_date,
          remove_third_payment_date:
            res.data.third_payment_date === null ? 1 : 0,
          third_payment: res.data.third_payment,
          given_third_paid_date: res.data.third_paid_date,
          remove_third_paid_date: res.data.third_paid_date === null ? 1 : 0,

          fourth_payable: res.data.fourth_payable,
          given_fourth_payment_date: res.data.fourth_payment_date,
          remove_fourth_payment_date:
            res.data.fourth_payment_date === null ? 1 : 0,
          fourth_payment: res.data.fourth_payment,
          given_fourth_paid_date: res.data.fourth_paid_date,
          remove_fourth_paid_date: res.data.fourth_paid_date === null ? 1 : 0,

          fifth_payable: res.data.fifth_payable,
          given_fifth_payment_date: res.data.fifth_payment_date,
          remove_fifth_payment_date:
            res.data.fifth_payment_date === null ? 1 : 0,
          fifth_payment: res.data.fifth_payment,
          given_fifth_paid_date: res.data.fifth_paid_date,
          remove_fifth_paid_date: res.data.fifth_paid_date === null ? 1 : 0,

          paid:
            parseInt(res.data.first_payment) +
            parseInt(n2) +
            parseInt(n3) +
            parseInt(n4) +
            parseInt(n5),
          due:
            parseInt(res.data.payable) -
            (parseInt(res.data.first_payment) +
              parseInt(n2) +
              parseInt(n3) +
              parseInt(n4) +
              parseInt(n5)),
        });
        const course_url =
          BASE_URL + `/api/admin/course/p170815s/${res.data.course_id}`;
        return axios
          .get(course_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              course: res.data,
            });
            const student_url =
              BASE_URL +
              `/api/admin/student/student_p170815s/${this.state.enrollment.student_id}`;
            return axios
              .get(student_url, {
                headers: {
                  Authorization: `Bearer  ${cookies.get("adminToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  student: res.data,
                  loading: false,
                });
              });
          });
      });
  }

  handleSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDiscount = (e) => {
    let payableAmount = "";
    payableAmount = this.state.course.fee - e.target.value;
    this.setState({
      payable: payableAmount,
      discount: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url =
      BASE_URL +
      `/api/admin/enrollment/enrollment_p170815s/${this.props.match.params.id}`;
    const formData = {
      allow_class_from: this.state.allow_class_from
        ? this.state.allow_class_from - 1
        : "",
      allow_class_to: this.state.allow_class_to
        ? this.state.allow_class_to - 1
        : "",

      batch: this.state.batch,
      roll: this.state.roll,

      note: this.state.notes,

      discount: this.state.discount,
      payable: this.state.payable,

      first_payment: this.state.first_payment,
      first_payment_date: this.state.first_payment_date,

      second_payable: this.state.second_payable,
      second_payment_date: this.state.second_payment_date,
      remove_second_payment_date: this.state.remove_second_payment_date,
      second_payment: this.state.second_payment,
      second_paid_date: this.state.second_paid_date,
      remove_second_paid_date: this.state.remove_second_paid_date,

      third_payable: this.state.third_payable,
      third_payment_date: this.state.third_payment_date,
      remove_third_payment_date: this.state.remove_third_payment_date,
      third_payment: this.state.third_payment,
      third_paid_date: this.state.third_paid_date,
      remove_third_paid_date: this.state.remove_third_paid_date,

      fourth_payable: this.state.fourth_payable,
      fourth_payment_date: this.state.fourth_payment_date,
      remove_fourth_payment_date: this.state.remove_fourth_payment_date,
      fourth_payment: this.state.fourth_payment,
      fourth_paid_date: this.state.fourth_paid_date,
      remove_fourth_paid_date: this.state.remove_fourth_paid_date,

      fifth_payable: this.state.fifth_payable,
      fifth_payment_date: this.state.fifth_payment_date,
      remove_fifth_payment_date: this.state.remove_fifth_payment_date,
      fifth_payment: this.state.fifth_payment,
      fifth_paid_date: this.state.fifth_paid_date,
      remove_fifth_paid_date: this.state.remove_fifth_paid_date,
    };
    return axios
      .patch(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then(() => {
        this.props.history.push({
          pathname: `/admin/enrollment-info/${this.props.match.params.id}`,
          state: { alert_success: "Payment Status Updated Successfully." },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.errors,
        });
      });
  };

  handleFirstPaymentDate = (date) => {
    this.setState({
      first_payment_date: date,
    });
  };

  handleSecondPaymentDate = (date) => {
    this.setState({
      second_payment_date: date,
    });
  };
  handleSecondPaidDate = (date) => {
    this.setState({
      second_paid_date: date,
    });
  };

  handleThirdPaymentDate = (date) => {
    this.setState({
      third_payment_date: date,
    });
  };
  handleThirdPaidDate = (date) => {
    this.setState({
      third_paid_date: date,
    });
  };

  handleFourthPaymentDate = (date) => {
    this.setState({
      fourth_payment_date: date,
    });
  };
  handleFourthPaidDate = (date) => {
    this.setState({
      fourth_paid_date: date,
    });
  };

  handleFifthPaymentDate = (date) => {
    this.setState({
      fifth_payment_date: date,
    });
  };
  handleFifthPaidDate = (date) => {
    this.setState({
      fifth_paid_date: date,
    });
  };

  handleRemoveDate = (e, name) => {
    if (this.state[name] === 0) {
      this.setState({
        [name]: 1,
      });
    } else {
      this.setState({
        [name]: 0,
      });
    }
  };

  handleActiveOrBlock = () => {
    const edit_url =
      BASE_URL +
      `/api/admin/enrollment/active_enrollment_p170815s/${this.props.match.params.id}`;
    return axios
      .patch(edit_url, null, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          enrollment: res.data,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar
                  path={this.state.path}
                  id={this.props.match.params.id}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <div to="#" className="nav-link text-dark">
                    <h4>
                      <i
                        className="fa fa-chevron-right"
                        style={{ fontSize: 20 }}
                      ></i>{" "}
                      Update Payments
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="container">
                        <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-3 pb-0">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mr-1">
                                <Link
                                  to={`/admin/enrollment-info/${this.props.match.params.id}`}
                                  className="nav-link text-light bg-secondary mb-3 rounded-sm"
                                  style={{ width: "120px" }}
                                >
                                  <i className="fa fa-reply-all"></i> Go Back
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-8">
                              {this.state.loading
                                ? ""
                                : [
                                  this.state.enrollment.is_active === 1 ? (
                                    <button
                                      onClick={this.handleActiveOrBlock}
                                      className="nav-link btn btn-danger mb-3 rounded-sm ml-auto"
                                    >
                                      Block Access
                                    </button>
                                  ) : (
                                    <button
                                      onClick={this.handleActiveOrBlock}
                                      className="nav-link btn btn-success mb-3 rounded-sm ml-auto"
                                    >
                                      Give Access
                                    </button>
                                  ),
                                ]}
                            </div>
                          </div>

                          {this.state.loading ? (
                            <>
                              <h1>
                                <Skeleton />
                              </h1>
                              <h3>
                                <Skeleton count={5} />
                              </h3>
                              <Skeleton count={5} />
                            </>
                          ) : (
                            <>
                              <div className="card-header bg-primary text-center text-light text-uppercase mb-3 mt-1">
                                Please fill the form accordingly
                              </div>
                              <form
                                className="form-group"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="input-group col-md-12">
                                  <label className="my-auto col-md-3">
                                    <b>Student</b>
                                  </label>
                                  <span className="form-control col-md-9 rounded-sm">
                                    <span className="text-left text-info">
                                      {this.state.student.fname +
                                        " " +
                                        this.state.student.lname}
                                    </span>
                                  </span>
                                </div>

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="batch"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Batch</b>
                                  </label>
                                  <input
                                    type="text"
                                    id="batch"
                                    className="form-control col-md-9 rounded-sm"
                                    name="batch"
                                    value={this.state.batch}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.batch ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.batch.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="roll"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Roll No.</b>
                                  </label>
                                  <input
                                    type="text"
                                    id="roll"
                                    className="form-control col-md-9 rounded-sm"
                                    name="roll"
                                    value={this.state.roll}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-0">
                                    {this.state.error.roll ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.roll.map((item) => {
                                          return (
                                            <li className="list-group-item border-0 bg-light py-0 pl-0">
                                              {"*" + item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label className="my-auto col-md-3">
                                    <b>Class Access</b>
                                  </label>
                                  <span className="col-md-3 form-control bg-warning mr-auto text-dark">
                                    Optional
                                  </span>
                                  <input
                                    type="number"
                                    id="allow_class_from"
                                    className="form-control col-md-3 rounded-sm mb-2 mr-auto"
                                    name="allow_class_from"
                                    placeholder="From.."
                                    value={this.state.allow_class_from}
                                    onChange={this.handleChange}
                                    min="1"
                                    max={this.state.allow_class_to - 1}
                                  />
                                  <input
                                    type="number"
                                    id="allow_class_to"
                                    className="form-control col-md-3 rounded-sm mb-2 ml-auto"
                                    name="allow_class_to"
                                    placeholder="To.."
                                    value={this.state.allow_class_to}
                                    onChange={this.handleChange}
                                    required={this.state.allow_class_from}
                                    min="2"
                                  />
                                </div>

                                <div className="input-group col-md-12">
                                  <label className="my-auto col-md-3">
                                    <b>Course Fee</b>
                                  </label>
                                  <span className="form-control col-md-9 rounded-sm border-right-0">
                                    <span className="text-left text-info">
                                      {this.state.course.fee}
                                    </span>
                                  </span>
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="discount"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Discount</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="discount"
                                    className="form-control col-md-9 rounded-sm border-right-0"
                                    name="discount"
                                    placeholder="Amount of discount"
                                    value={this.state.discount}
                                    onChange={this.handleDiscount}
                                    min="0"
                                    max={this.state.course.fee}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.discount}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label className="my-auto col-md-3">
                                    <b>Total Payable</b>
                                  </label>
                                  <span className="form-control col-md-9 rounded-sm border-right-0">
                                    <span className="text-left text-info">
                                      {this.state.payable || "0"}
                                    </span>
                                  </span>
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>

                                <div className="input-group col-md-12 mt-5">
                                  <label
                                    htmlFor="first_payment"
                                    className="my-auto col-md-3"
                                  >
                                    <b>1st Payment</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="first_payment"
                                    className="form-control col-md-9 rounded-sm"
                                    name="first_payment"
                                    placeholder="First payment amount"
                                    value={this.state.first_payment}
                                    onChange={this.handleChange}
                                    required
                                    min="0"
                                    max={this.state.payable}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.first_payment}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="first_payment_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>1st Paid Date</b>
                                  </label>
                                  {/* <input
                                  type="date"
                                  id="first_payment_date"
                                  className="form-control col-md-9 rounded-sm"
                                  name="first_payment_date"
                                  className="form-control"
                                  value={this.state.first_payment_date}
                                  onChange={this.handleChange}
                                  required
                                /> */}
                                  <DatePicker
                                    selected={this.state.first_payment_date}
                                    onChange={this.handleFirstPaymentDate}
                                    dateFormat="yyyy/MM/dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control rounded-sm"
                                    placeholderText={
                                      this.state.given_first_payment_date
                                    }
                                  />
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.first_payment_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.first_payment_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-5">
                                  <label
                                    htmlFor="second_payable"
                                    className="my-auto col-md-3"
                                  >
                                    <b>2nd Payable</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="second_payable"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_payable"
                                    placeholder="Second payable amount"
                                    value={this.state.second_payable}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.second_payable}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="second_payment_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>2nd Payment Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state
                                        .remove_second_payment_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state
                                          .remove_second_payment_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_second_payment_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_second_payment_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="second_payment_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_payment_date"
                                    className="form-control"
                                    value={this.state.second_payment_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_second_payment_date ===
                                    0 ? (
                                    <DatePicker
                                      selected={this.state.second_payment_date}
                                      onChange={this.handleSecondPaymentDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_second_payment_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.second_payment_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.second_payment_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="second_payment"
                                    className="my-auto col-md-3"
                                  >
                                    <b>2nd Payment</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="second_payment"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_payment"
                                    placeholder="Second payment amount"
                                    value={this.state.second_payment}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.second_payment}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="second_paid_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>2nd Paid Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_second_paid_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_second_paid_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_second_paid_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_second_paid_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="second_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="second_paid_date"
                                    className="form-control"
                                    value={this.state.second_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_second_paid_date === 0 ? (
                                    <DatePicker
                                      selected={this.state.second_paid_date}
                                      onChange={this.handleSecondPaidDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_second_paid_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.second_paid_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.second_paid_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-5">
                                  <label
                                    htmlFor="third_payable"
                                    className="my-auto col-md-3"
                                  >
                                    <b>3rd Payable</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="third_payable"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_payable"
                                    placeholder="Third payable amount"
                                    value={this.state.third_payable}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.third_payable}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="third_payment_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>3rd Payment Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_third_payment_date ===
                                        0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_third_payment_date ===
                                          0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_third_payment_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_third_payment_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="third_payment_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_payment_date"
                                    className="form-control"
                                    value={this.state.third_payment_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_third_payment_date ===
                                    0 ? (
                                    <DatePicker
                                      selected={this.state.third_payment_date}
                                      onChange={this.handleThirdPaymentDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_third_payment_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.third_payment_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.third_payment_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="third_payment"
                                    className="my-auto col-md-3"
                                  >
                                    <b>3rd Payment</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="third_payment"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_payment"
                                    placeholder="Third payment amount"
                                    value={this.state.third_payment}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.third_payment}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="third_paid_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>3rd Paid Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_third_paid_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_third_paid_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_third_paid_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_third_paid_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}

                                  {this.state.remove_third_paid_date === 0 ? (
                                    <DatePicker
                                      selected={this.state.third_paid_date}
                                      onChange={this.handleThirdPaidDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_third_paid_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.third_paid_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.third_paid_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-5">
                                  <label
                                    htmlFor="fourth_payable"
                                    className="my-auto col-md-3"
                                  >
                                    <b>4th Payable</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="fourth_payable"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fourth_payable"
                                    placeholder="Fourth payable amount"
                                    value={this.state.fourth_payable}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fourth_payable}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fourth_payment_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>4th Payment Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state
                                        .remove_fourth_payment_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state
                                          .remove_fourth_payment_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_fourth_payment_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_fourth_payment_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="fourth_payment_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fourth_payment_date"
                                    className="form-control"
                                    value={this.state.fourth_payment_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_fourth_payment_date ===
                                    0 ? (
                                    <DatePicker
                                      selected={this.state.fourth_payment_date}
                                      onChange={this.handleFourthPaymentDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_fourth_payment_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fourth_payment_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fourth_payment_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fourth_payment"
                                    className="my-auto col-md-3"
                                  >
                                    <b>4th Payment</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="fourth_payment"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fourth_payment"
                                    placeholder="Fourth payment amount"
                                    value={this.state.fourth_payment}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fourth_payment}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fourth_paid_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>4th Paid Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_fourth_paid_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_fourth_paid_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_fourth_paid_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_fourth_paid_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_fourth_paid_date === 0 ? (
                                    <DatePicker
                                      selected={this.state.fourth_paid_date}
                                      onChange={this.handleFourthPaidDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_fourth_paid_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fourth_paid_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fourth_paid_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-5">
                                  <label
                                    htmlFor="fifth_payable"
                                    className="my-auto col-md-3"
                                  >
                                    <b>5th Payable</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="fifth_payable"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fifth_payable"
                                    placeholder="Fifth payable amount"
                                    value={this.state.fifth_payable}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fifth_payable}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fifth_payment_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>5th Payment Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_fifth_payment_date ===
                                        0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_fifth_payment_date ===
                                          0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_fifth_payment_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_fifth_payment_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="fifth_payment_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fifth_payment_date"
                                    className="form-control"
                                    value={this.state.fifth_payment_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_fifth_payment_date ===
                                    0 ? (
                                    <DatePicker
                                      selected={this.state.fifth_payment_date}
                                      onChange={this.handleFifthPaymentDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_fifth_payment_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fifth_payment_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fifth_payment_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fifth_payment"
                                    className="my-auto col-md-3"
                                  >
                                    <b>5th Payment</b>
                                  </label>
                                  <input
                                    type="number"
                                    id="fifth_payment"
                                    className="form-control col-md-9 rounded-sm"
                                    name="fifth_payment"
                                    placeholder="Fifth payment amount"
                                    value={this.state.fifth_payment}
                                    onChange={this.handleChange}
                                  />
                                  <div class="input-group-append border-left-0">
                                    <div class="input-group-text">BDT</div>
                                  </div>
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fifth_payment}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="fifth_paid_date"
                                    className="my-auto col-md-3"
                                  >
                                    <b>5th Paid Date</b>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ml-2 my-1 ${this.state.remove_fifth_paid_date === 0
                                        ? "btn-danger"
                                        : "btn-success"
                                        }`}
                                      title={
                                        this.state.remove_fifth_paid_date === 0
                                          ? "Remove date"
                                          : "Add date"
                                      }
                                      onClick={(e, name) => {
                                        this.handleRemoveDate(
                                          e,
                                          "remove_fifth_paid_date"
                                        );
                                      }}
                                    >
                                      {this.state.remove_fifth_paid_date ===
                                        0 ? (
                                        <i className="fa fa-times"></i>
                                      ) : (
                                        <i className="fa fa-pencil"></i>
                                      )}
                                    </button>
                                  </label>
                                  {/* <input
                                    type="date"
                                    id="third_paid_date"
                                    className="form-control col-md-9 rounded-sm"
                                    name="third_paid_date"
                                    className="form-control"
                                    value={this.state.third_paid_date}
                                    onChange={this.handleChange}
                                  /> */}
                                  {this.state.remove_fifth_paid_date === 0 ? (
                                    <DatePicker
                                      selected={this.state.fifth_paid_date}
                                      onChange={this.handleFifthPaidDate}
                                      dateFormat="yyyy/MM/dd"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control rounded-sm"
                                      placeholderText={
                                        this.state.given_fifth_paid_date
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      className="form-control rounded-sm"
                                      disabled
                                    />
                                  )}
                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.fifth_paid_date ? (
                                      <ul className="list-group list-group-horizontal">
                                        {this.state.error.fifth_paid_date.map(
                                          (item) => {
                                            return (
                                              <li className="list-group-item border-0 bg-light py-0 pl-0">
                                                {"*" + item}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}


                                <div className="input-group col-md-12 mt-2">
                                  <label
                                    htmlFor="notes"
                                    className="my-auto col-md-3"
                                  >
                                    <b>Notes</b>
                                  </label>

                                  <textarea
                                    id="notes"
                                    className="form-control col-md-9 rounded-sm"
                                    name="notes"
                                    placeholder="Notes"
                                    value={this.state.notes}
                                    onChange={this.handleChange}>
                                  </textarea>


                                </div>
                                {this.state.error ? (
                                  <div className="offset-3 text-danger fs-13 pl-2">
                                    {this.state.error.notes}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="text-center col">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm mt-4 px-4"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                              <div className="card-header bg-warning text-center text-uppercase mb-3">
                                Enrollment's Information
                              </div>
                              <div>
                                <table className="table table-striped table-hover table-bordered text-center table-sm">
                                  <thead></thead>
                                  <tbody>
                                    <tr className="bg-dark text-white">
                                      <td>Enrolled On</td>
                                      <td>
                                        {this.state.enrollment.first_paid_date}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Course Title</td>
                                      <td>{this.state.course.title}</td>
                                    </tr>
                                    <tr>
                                      <td>Enrolled By</td>
                                      <td>
                                        {this.state.student.fname +
                                          " " +
                                          this.state.student.lname}
                                      </td>
                                    </tr>

                                    {this.state.enrollment.batch ? (
                                      <tr>
                                        <td>Batch</td>
                                        <td>{this.state.enrollment.batch}</td>
                                      </tr>
                                    ) : null}

                                    {this.state.enrollment.roll ? (
                                      <tr>
                                        <td>Roll No</td>
                                        <td>{this.state.enrollment.roll}</td>
                                      </tr>
                                    ) : null}

                                    <tr>
                                      <td>Student Type</td>
                                      <td>{this.state.student.student_type}</td>
                                    </tr>

                                    <tr className="bg-info text-white">
                                      <td>Permitted Classes</td>
                                      <td>
                                        {this.state.allow_class_from +
                                          " - " +
                                          this.state.allow_class_to}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Course Fee</td>
                                      <td>{this.state.course.fee} BDT</td>
                                    </tr>
                                    <tr className="bg-success text-white">
                                      <td>Discount</td>
                                      <td>
                                        {this.state.enrollment.discount
                                          ? this.state.enrollment.discount
                                          : "0"}{" "}
                                        BDT
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Total Payable</td>
                                      <td>
                                        {this.state.enrollment.payable} BDT
                                      </td>
                                    </tr>
                                    <tr className="bg-success text-white">
                                      <td>Total Paid</td>
                                      <td>{this.state.paid} BDT</td>
                                    </tr>
                                    <tr className="bg-danger text-white">
                                      <td>Total Due</td>
                                      <td>{this.state.due} BDT</td>
                                    </tr>
                                    <tr>
                                      <td>First Payment</td>
                                      <td>
                                        {this.state.enrollment.first_payment}{" "}
                                        BDT
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>First Paid Date</td>
                                      <td>
                                        {this.state.enrollment.first_paid_date}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>2nd Payable</td>
                                      {this.state.enrollment.second_payable ? (
                                        <td>
                                          {this.state.enrollment.second_payable}{" "}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Second Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .second_payment_date
                                          ? [
                                            this.state.enrollment
                                              .second_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Second Payment</td>
                                      <td>
                                        {this.state.enrollment.second_payment
                                          ? this.state.enrollment
                                            .second_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Second Paid Date</td>
                                      <td>
                                        {this.state.enrollment.second_paid_date
                                          ? [
                                            this.state.enrollment
                                              .second_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>3rd Payable</td>
                                      {this.state.enrollment.third_payable ? (
                                        <td>
                                          {this.state.enrollment.third_payable}{" "}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Third Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .third_payment_date
                                          ? [
                                            this.state.enrollment
                                              .third_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Third Payment</td>
                                      <td>
                                        {this.state.enrollment.third_payment
                                          ? this.state.enrollment
                                            .third_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Third Paid Date</td>
                                      <td>
                                        {this.state.enrollment.third_paid_date
                                          ? [
                                            this.state.enrollment
                                              .third_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>4th Payable</td>
                                      {this.state.enrollment.fourth_payable ? (
                                        <td>
                                          {this.state.enrollment.fourth_payable}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Fourth Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .fourth_payment_date
                                          ? [
                                            this.state.enrollment
                                              .fourth_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fourth Payment</td>
                                      <td>
                                        {this.state.enrollment.fourth_payment
                                          ? this.state.enrollment
                                            .fourth_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fourth Paid Date</td>
                                      <td>
                                        {this.state.enrollment.fourth_paid_date
                                          ? [
                                            this.state.enrollment
                                              .fourth_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>5th Payable</td>
                                      {this.state.enrollment.fifth_payable ? (
                                        <td>
                                          {this.state.enrollment.fifth_payable}
                                          BDT
                                        </td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                    </tr>

                                    <tr className="bg-warning">
                                      <td>Fifth Payment Date</td>
                                      <td>
                                        {this.state.enrollment
                                          .fifth_payment_date
                                          ? [
                                            this.state.enrollment
                                              .fifth_payment_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fifth Payment</td>
                                      <td>
                                        {this.state.enrollment.fifth_payment
                                          ? this.state.enrollment
                                            .fifth_payment + " BDT"
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-warning">
                                      <td>Fifth Paid Date</td>
                                      <td>
                                        {this.state.enrollment.fifth_paid_date
                                          ? [
                                            this.state.enrollment
                                              .fifth_paid_date,
                                          ]
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr className="bg-info text-white mt-4">
                                      <td className="text-white">Notes</td>
                                      <td className="text-white text-capitalize">
                                        {this.state.notes}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(EditEnrollment);
