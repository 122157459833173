import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { withRouter, Link } from "react-router-dom";
import Hero from "../../components/Hero.js";
import Banner from "../../components/Banner";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import axios from "axios";
import posImg from "../../images/allCourses/traingle.jpg";
import Countdown from 'react-countdown';


const cookies = new Cookies();

class StudentSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alert_danger: "",
      session_running: 0,
      supportInfo: '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Student Support";




    // this.setState({
    //   loading: true,
    //   noticeTime: new Date("2021-09-30 12:23:58").toLocaleDateString("en-US", {
    //     weekday: "long",
    //     year: "numeric",
    //     month: "long",
    //     day: "numeric",
    //     hour: "numeric",
    //     minute: "numeric",
    //   }),
    // });

    // if (this.props.location.state) {
    //   let alert = this.props.location.state.alert_danger;
    //   this.setState({
    //     alert_danger: alert,
    //   });
    //   setTimeout(
    //     function () {
    //       this.setState({ alert_danger: "" });
    //     }.bind(this),
    //     3000
    //   );
    //   this.props.history.replace();
    // }

    const supportURL = BASE_URL + `/api/student/wordpress-session`;
    return axios
      .get(supportURL, {
        headers: { Authorization: `Bearer  ${cookies.get("studentToken")}` },
      })
      .then((res) => {

        console.log(res.data);
        this.setState({
          supportInfo: res.data,
          loading: false,
          session_running: (res.data).isEnable
        });

        
      });
  }

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return (
        <>
          <Hero hero="NoticeHero d-flex align-items-center">
            <Banner
              title="Student support panel"
              bannerCSS="col-6 mx-auto text-center course-banner"
            ></Banner>
          </Hero>
          <div className="container mt-2">
            <div className="row">
              <div className="col-12">
                <Skeleton className="course-card" />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/* Error or Success Message */}
          {this.state.alert_danger ? (
            <div className="col-md-12 text-center bg-danger text-white p-3">
              {this.state.alert_danger}
            </div>
          ) : (
            ""
          )}
          {/* Error or Success Message */}
          <Hero hero="noticeHero  d-flex align-items-center">
            <Banner
              title="Student support panel"
              bannerCSS="col-6 mx-auto text-center course-banner"
            ></Banner>
          </Hero>
          <div className="container mt-2">
            <div className="row my-5 justify-content-center">
              

              {/* if no schedule is found */}
              <div className="col-lg-8 mt-5 single-notice-box2 card">
                <img className="posImg" src={posImg} alt="" />
                <div className="row align-items-center">
                  <div className="col-lg-8 no-support-text">
                    {this.state.session_running != 1 ? (
                      <>
                        <h2 className="mb-4">এই মুহুর্তে কোন সাপোর্ট সেশন চলছেনা । পরবর্তি সাপোর্ট সেশন এর জন্য সময় বাকি আছেঃ </h2>
                        <Countdown date={'2022-09-20:16:00'} />
                      </>
                    ) : (
                      <>
                        <h2 className="mb-4">সাপোর্ট সেশন চলছে । নিচের লিংক এ ক্লিক করে জয়েন করুন ।</h2>
                        <a className="btn btn-info text-white" href={this.state.supportInfo.zoom_url} target="_blank">জয়েন করার জন্য ক্লিক করুন</a>
                      </>
                    )}
                    

                  </div>
                </div>
              </div>

              {/* Time schedule for support */}
              <div className="col-lg-8 mt-5 single-notice-box2 card">
              
                <div className="row align-items-center">
                  <div className="col-lg-12 text-center">
                    <h3 className="mb-3 notice-title text-dark text-center">
                      
                      সাপোর্ট এর সময়সূচীঃ
                    </h3>
                    <p className="mb-3 text-capitalize text-center text-bold">রবিবার - মঙ্গলবার - বৃহস্পতিবার</p>
                    <h5>যাদের ক্লাস ১ থেকে ৩০ এর মধ্যে চলছে তাদের সেশন</h5>
                    <p className="">সকাল ১১ টা থেকে দুপুর ১ টা</p>
                    <h5>যাদের ক্লাস ৩১ থেকে ৭১ এর মধ্যে চলছে তাদের সেশন</h5>
                    <p className="">বিকাল ৪ টা থেকে সন্ধ্যা ৬ টা</p>
                  </div>
                </div>
              </div>

              {/* {
                //check if notice exiest or not
                this.state.checkNotice.length > 0 ? (
                  this.state.noticeAll.map((item) => {
                    return item.map((subitem) => {
                      // check if post is enable or not
                      if (subitem.isEnable == 1) {
                        return (
                          <div className="col-lg-8 mt-5 single-notice-box2 card">
                            <img className="posImg" src={posImg} alt="" />
                            <div className="row align-items-center">
                              <div className="col-lg-8">
                                <h5 className="mb-3 notice-title text-dark">
                                  <i
                                    class="fa fa-bell mr-3 text-warning"
                                    aria-hidden="true"
                                  ></i>
                                  {subitem.course_title}
                                </h5>
                                <p className="mb-3 text-capitalize">
                                  {subitem.notice}
                                </p>
                                <span className="badge badge-light">
                                  {new Date(
                                    subitem.created_at
                                  ).toLocaleDateString("en-US", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <>
                            <div className="col-lg-8 mt-5 single-notice-box2 card">
                              <img className="posImg" src={posImg} alt="" />
                              <div className="row align-items-center">
                                <div className="col-lg-8 text-center">
                                  <h2>No schedule for today</h2>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    });
                  })
                ) : (
                  <div className="col-lg-8 mt-5 single-notice-box2 card">
                    <img className="posImg" src={posImg} alt="" />
                    <div className="row align-items-center">
                      <div className="col-lg-8 text-center">
                        <h2></h2>
                      </div>
                    </div>
                  </div>
                )
              } */}
            </div>
          </div>
        </>
      );
    }
  }
}
export default withRouter(StudentSupport);
