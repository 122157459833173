import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../BaseUrl";
// Components
import Sidebar from "../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ViewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_pic: "",
      student_id: "",
      fname: "",
      lname: "",
      gender: "",
      dateOfBirth: "",
      phn_no: "",
      occupation: "",
      address: "",
      email: "",
      fb: "",
      batch: "",
      roll: "",
      student_type: "",
      note: "",
      ip_limit: "",
      admissionDate: "",
      loading: false,
      path: "",
      isMobile: false,
      total_enrollment: "",
      enrollments: [],
      courses: [],
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Student's Information";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    const { id } = this.props.match.params;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const student_url = BASE_URL + `/api/admin/student/student_p170815s/${id}`;
    return axios
      .get(student_url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          student_pic: res.data.image,
          student_id: res.data.id,
          fname: res.data.fname,
          lname: res.data.lname,
          gender: res.data.gender,
          phn_no: res.data.phn_no,
          dateOfBirth: res.data.dateOfBirth,
          occupation: res.data.occupation,
          address: res.data.address,
          admissionDate: res.data.admissionDate,
          email: res.data.email,
          fb: res.data.fb,
          batch: res.data.batch,
          roll: res.data.roll,
          student_type: res.data.student_type,
          note: res.data.note,
          ip_limit: res.data.ip_limit,
        });
        const enrollment_url =
          BASE_URL +
          `/api/admin/student/student_total_enrollments/${this.props.match.params.id}`;
        axios
          .get(enrollment_url, {
            headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
          })
          .then((res) => {
            this.setState({
              courses: res.data[0],
              enrollments: res.data[1],
              total_enrollment: res.data[1].length,
              loading: false,
            });
          });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? (
                <Sidebar path={this.state.path} id={this.state.student_id} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      {this.state.loading ? (
                        <div className="text-center text-primary"> </div>
                      ) : (
                        <h4>
                          <i
                            className="fa fa-chevron-right"
                            style={{ fontSize: 20 }}
                          ></i>{" "}
                          <span className="text-primary">
                            {this.state.fname} {this.state.lname}
                          </span>
                          's information
                        </h4>
                      )}
                    </div>
                  </span>
                </div>
                <div className="container">
                  <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mb-2 pb-0">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row pl-1 mr-1 ml-3">
                          <Link
                            to={`/admin/manage-student`}
                            className="nav-link text-light bg-secondary mb-3 rounded-sm"
                            style={{ width: "120px" }}
                          >
                            <i className="fa fa-reply-all"></i> Go Back
                          </Link>
                        </div>
                      </div>
                      <div className="row col-md-8">
                        <div className="row ml-auto mr-1 pr-0">
                          <a
                            href={this.state.fb}
                            className="nav-link text-white bg-primary mb-3 rounded-sm ml-1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-facebook"></i> Facebook
                          </a>
                        </div>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <div className="row">
                        <div className="col-md-12 ">
                          <h1>
                            <Skeleton />
                          </h1>
                          <h3>
                            <Skeleton count={5} />
                          </h3>
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-3 text-center">
                          <img
                            src={`${BASE_URL}${`/images/student/${this.state.student_pic}`}`}
                            alt="avatar"
                            className="img-fluid mx-4 rounded-sm"
                            style={{ height: "200px" }}
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="container">
                            <div className="bg-light">
                              <table className="table table-striped table-bordered">
                                <thead></thead>
                                <tbody>
                                  {this.state.student_type ? (
                                    <tr>
                                      <td>Student Type</td>
                                      <td>{this.state.student_type}</td>
                                    </tr>
                                  ) : null}

                                  <tr>
                                    <td>Name</td>
                                    <td>
                                      {this.state.fname +
                                        " " +
                                        this.state.lname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>{this.state.email} </td>
                                  </tr>
                                  {this.state.batch ? (
                                    <tr>
                                      <td>Batch</td>
                                      <td>{this.state.batch}</td>
                                    </tr>
                                  ) : null}

                                  {this.state.roll ? (
                                    <tr>
                                      <td>Roll</td>
                                      <td>{this.state.roll}</td>
                                    </tr>
                                  ) : null}

                                  {this.state.enrollments.map(
                                    (myCourse, index) => {
                                      if (myCourse.batch !== "") {
                                        let tempCourse =
                                          this.state.courses.find(
                                            (content) =>
                                              content.id == myCourse.course_id
                                          );
                                        return (
                                          <tr>
                                            <td>Batch / Roll</td>
                                            <td>
                                              <span className="text-primary">
                                                {tempCourse.title}
                                              </span>
                                              - Batch: {myCourse.batch}, Roll
                                              No: {myCourse.roll}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    }
                                  )}
                                  <tr>
                                    <td>Gender</td>
                                    <td>{this.state.gender}</td>
                                  </tr>
                                  <tr>
                                    <td>Contact No.</td>
                                    <td>{this.state.phn_no}</td>
                                  </tr>
                                  <tr>
                                    <td>Date of Birth</td>
                                    <td>{this.state.dateOfBirth}</td>
                                  </tr>
                                  <tr>
                                    <td>Ocupation</td>
                                    <td>{this.state.occupation}</td>
                                  </tr>
                                  <tr>
                                    <td>Address</td>
                                    <td>{this.state.address}</td>
                                  </tr>
                                  <tr>
                                    <td>Addmission Date</td>
                                    <td>{this.state.admissionDate}</td>
                                  </tr>

                                  <tr>
                                    <td>Ip Limitation</td>
                                    <td>{this.state.ip_limit}</td>
                                  </tr>
                                  <tr>
                                    <td>Course Enrolled</td>
                                    <td>{this.state.total_enrollment}</td>
                                  </tr>

                                  {this.state.note ? (
                                    <tr>
                                      <td>Note</td>
                                      <td>{this.state.note}</td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary ml-1 mb-2"
                                onClick={() => {
                                  this.props.history.push(
                                    `/admin/edit-student/${this.state.student_id}`
                                  );
                                }}
                              >
                                Update Info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ViewStudent);
