import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { _t } from "../../functions/Functions";
const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      adminLogin: "",
      instructorLogin: "",
      moderatorLogin: "",
      student: "",
      ip: "",
      ip_error: "",
      alert: "",
      loading: false,
    };
  }

  componentDidMount() {
    //redirect if logged in
    cookies.get("studentToken") &&
      this.props.history.push("/profile/personal-info");

    document.title = "Student Login";
    if (this.props.location.state) {
      let alert = this.props.location.state.alert;
      this.setState({
        alert: alert,
      });
    }
    this.setState({
      adminLogin: cookies.get("adminToken"),
      instructorLogin: cookies.get("instructorToken"),
      moderatorLogin: cookies.get("moderatorToken"),
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    const { t } = this.props;
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/auth/student/login";
    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    return axios
      .post(url, user)
      .then((res) => {
        if (this.state.adminLogin) {
          cookies.remove("adminToken");
        }
        if (this.state.instructorLogin) {
          // localStorage.removeItem("instructorToken");
          cookies.remove("instructorToken");
        }
        if (this.state.moderatorLogin) {
          // localStorage.removeItem("moderatorToken");
          cookies.remove("moderatorToken");
        }
        // localStorage.setItem("studentToken", res.data.access_token);
        let date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        cookies.set("studentToken", res.data.access_token, {
          path: "/",
          expires: date,
        });

        this.props.history.push('/');

      })
      .then(() => {
        const user_url = BASE_URL + "/api/auth/student/me";
        return axios.get(user_url, {
          headers: { Authorization: `Bearer ${cookies.get("studentToken")}` },
        });
      })
      .then((res) => {
        this.setState({
          student: res.data,
        });
        if (!res.data.ip_address) {
          const ip_url = "http://ip-api.com/json";
          return axios.get(ip_url).then((res) => {
            let formData = {
              ip_address: res.data.query,
              location: res.data.city,
            };
            const url = BASE_URL + `/api/student/ip/${this.state.student.id}`;
            axios.post(url, formData, {
              headers: {
                Authorization: `Bearer ${cookies.get("studentToken")}`,
              },
            });
            this.props.history.push("/profile/personal-info");
          });
        } else {
          const ip_url = "http://ip-api.com/json";
          return axios.get(ip_url).then((res) => {
            this.setState({
              ip: res.data.query,
            });
            let formData = {
              ip_address: res.data.query,
              location: res.data.city,
            };
            const url = BASE_URL + `/api/student/ip/${this.state.student.id}`;
            axios
              .post(url, formData, {
                headers: {
                  Authorization: `Bearer ${cookies.get("studentToken")}`,
                },
              })
              .then((res) => {
                this.setState({
                  student: res.data,
                });

                let check_ip = JSON.parse(res.data.ip_address).find(
                  (item) => item.ip_address === this.state.ip
                );
                if (!check_ip) {
                  // localStorage.removeItem("studentToken");
                  cookies.remove("studentToken");
                  this.setState({
                    alert: "",
                    error: "",
                    ip_error: (
                      <span className="fs-13">
                        {/* আপনি আপনার বর্তমান জায়গা / ইন্টারনেট দিয়ে লগিন করতে
                        পারবেন না। */}
                        {_t(
                          t(
                            "You can not login from your current location or internet"
                          )
                        )}
                        <br />
                        <b>
                          {/* আগে যেখানে থেকে / যেই ইন্টারনেট দিয়ে লগিন করেছেন সেখান
                          থেকে করুন, */}
                          {_t(
                            t(
                              "Try from your previous loaction with previous internet connection"
                            )
                          )}
                          <br />
                          {/* এরপরেও না হলে SoftTech-IT কে কল করুন। */}
                          {_t(
                            t(
                              "If you still can not login then, contact SoftTech-IT"
                            )
                          )}
                        </b>
                      </span>
                    ),
                  });
                  this.setState({
                    loading: false,
                  });
                } else {
                  this.setState({
                    loading: false,
                  });
                  this.props.history.push("/profile/personal-info");
                }
              });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response.data.error : "",
          alert: "",
          loading: false,
        });
      });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid login-page">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card m-4 login-card">
                <div className="card-body bg-light text-center">
                  <h3 className="text-center ">Student Login</h3>
                  <img
                    src="https://image.freepik.com/free-vector/follow-me-social-business-theme-design_24877-50426.jpg"
                    alt="user"
                    height="100"
                    width="100"
                    className="rounded-circle"
                  />
                  {this.state.loading ? (
                    <div className="col-md-12 text-center bg-success text-white p-3 mt-2 rounded-sm">
                      {_t(t("Please wait!"))}
                    </div>
                  ) : (
                    <form className="col-md-12" onSubmit={this.handleSubmit}>
                      {this.state.error ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.error}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.alert ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.alert}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.ip_error ? (
                        <div className="text-center text-white rounded-sm p-2 bg-danger">
                          {this.state.ip_error}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group mt-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-primary text-white rounded-circle">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded ml-3"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-primary text-white rounded-circle">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          className="form-control rounded ml-3"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mt-5">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                          >
                            {_t(t("Login"))}
                          </button>
                        </div>
                        <div className="text-center mt-2">
                          {_t(t("Forgotten password?"))}
                          <Link
                            to="/reset-password"
                            className="nav-link text-uppercase"
                          >
                            {_t(t("Click Here To Reset Password"))}
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(Login);
