import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Sidebar from "../InstructorSidebar";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Instructorprofile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instructor: "",
            total_courses: 0,
            new_assignments: 0,
            new_feedback: 0,
            path: "",
            loading: false,
            isMobile: false,
            sidebar: true,
            fname: '',
            lname: '',
            phn_no: '',
            image: '',
            email: '',
            desc: '',
            link: [],
            designation: ''
        };
    }
    componentDidMount() {
        const wid = window.innerWidth;
        if (wid < 768) {
            this.setState({
                isMobile: true,
                sidebar: false,
            });
        }
        window.addEventListener(
            "resize",
            () => {
                this.setState({
                    isMobile: window.innerWidth < 768,
                    sidebar: window.innerWidth > 768,
                });
            },
            false
        );
        document.title = "Instructor Profile";
        this.setState({
            path: this.props.history.location.pathname,
            loading: true,
        });
        const user_url = BASE_URL + "/api/auth/instructor/me";
        return axios
            .get(user_url, {
                headers: { Authorization: `Bearer ${cookies.get("instructorToken")}` },
            })
            .then((res) => {
                this.setState({
                    instructor: res.data,

                });
                const user_url = BASE_URL + "/api/instructor/instructor_dashboard";
                return axios
                    .get(user_url, {
                        headers: {
                            Authorization: `Bearer ${cookies.get("instructorToken")}`,
                        },
                    })
                    .then((res) => {
                        this.setState({
                            total_courses: res.data[0],
                            new_assignments: res.data[1],
                            new_feedback: res.data[2],
                            loading: false,
                        });
                    });
            });
    }

    handleSidebar = (e) => {
        this.setState({
            sidebar: !this.state.sidebar,
        });
    };
    render() {
        return (
            <>
                <div
                    className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
                    style={{
                        position: "fixed",
                        top: "21%",
                        zIndex: "10",
                    }}
                >
                    <button
                        className="btn btn-sm btn-warning rounded-circle"
                        title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
                        onClick={this.handleSidebar}
                    >
                        <i className="fa fa-align-right"></i>
                    </button>
                </div>
                <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
                    <div className="row">
                        <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
                            {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
                        </div>
                        <div
                            className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
                        >
                            <div className="container">
                                {this.state.loading ? (
                                    <Skeleton height="40px" className="my-3" />
                                ) : (
                                    <h4 className="mt-2 mb-4">
                                        <i
                                            className="fa fa-chevron-right"
                                            style={{ fontSize: 20 }}
                                        ></i>{" "}
                                        Instructor Profile
                                        <br />
                                        <span style={{ fontSize: "14px" }}>
                                            Welcome instructor{" "}
                                            <b>
                                                {" " +
                                                    this.state.instructor.fname +
                                                    " " +
                                                    this.state.instructor.lname}
                                            </b>
                                        </span>{" "}
                                    </h4>
                                )}
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="container">
                                                {this.state.loading ? (
                                                    <div className="row col-md-12 justify-content-around text-white">
                                                        <div className="col-md-3 mb-3">
                                                            <Skeleton className="dashboard-card" />
                                                        </div>
                                                        <div className="col-md-3 mb-3">
                                                            <Skeleton className="dashboard-card" />
                                                        </div>
                                                        <div className="col-md-3 mb-3">
                                                            <Skeleton className="dashboard-card" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row  justify-content-around text-white">
                                                        <div className="col-md-12">
                                                            <form>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">First Name :</label>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" class="form-control" placeholder="first name" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">Last Name :</label>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" class="form-control" placeholder="first name" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">Phone Number :</label>
                                                                    <div class="col-sm-10">
                                                                        <input type="number" class="form-control" placeholder="phone" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">Email :</label>
                                                                    <div class="col-sm-10">
                                                                        <input type="email" class="form-control" placeholder="email" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">Introduction :</label>
                                                                    <div class="col-sm-10">
                                                                        <textarea row="3" class="form-control" placeholder="Introduction"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2 col-form-label text-dark">Profile Image :</label>
                                                                    <div class="col-sm-10">
                                                                        <input type="file" class="form-control" />
                                                                    </div>
                                                                </div>

                                                                <div class="form-group row justify-content-center">
                                                                    <div class="col-sm-4">
                                                                        <button type="submit" class="btn btn-primary w-100">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </div>
                                                )}

                                                <div className="col-md-12 mt-4 text-center">
                                                    <img
                                                        className="img-fluid"
                                                        src="https://image.freepik.com/free-vector/focused-tiny-people-reading-books_74855-5836.jpg"
                                                        alt="dashboard-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withRouter(Instructorprofile);

