import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../../BaseUrl";
// Components
import Sidebar from "../../Sidebar";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Slider extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      image: "",
      heading: "",
      description: "",
      caption: [],
      sliders: [],
      alert_success: null,
      error: null,
      path: "",
      loading: false,
      isMobile: false,
      sidebar: true,
    };
  }
  componentDidMount() {
    document.title = "Slider Images";
    const wid = window.innerWidth;
    if (wid < 768) {
      this.setState({
        isMobile: true,
        sidebar: false,
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
          sidebar: window.innerWidth > 768,
        });
      },
      false
    );
    this._isMounted = true;
    this.setState({
      path: this.props.history.location.pathname,
      loading: true,
    });
    const url = BASE_URL + "/api/admin/slider_image";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          sliders: res.data[0] ? res.data[0] : [],
          caption: res.data[1] ? res.data[1] : [],
          loading: false,
        });
      });
  }

  handleSidebar = (e) => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/slider_image";
    let formData = new FormData();
    formData.append("slider", this.state.image);
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          sliders: res.data,
          loading: false,
          alert_success: "Successfull.",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          3000
        );
      });
  };

  handleSliderDelete = (id) => {
    const url = BASE_URL + `/api/admin/slider_image/${id}`;
    return axios
      .delete(url, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          sliders: res.data,
          alert_success: "Successfull.",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      });
  };

  handleSubmitDetails = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const url = BASE_URL + "/api/admin/slider_description";
    let formData = {
      heading: this.state.heading,
      description: this.state.description,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer  ${cookies.get("adminToken")}` },
      })
      .then((res) => {
        this.setState({
          caption: res.data,
          loading: false,
          alert_success: "Successfull.",
        });
        setTimeout(
          function () {
            this.setState({ alert_success: null });
          }.bind(this),
          3000
        );
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.errors,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ error: null });
          }.bind(this),
          3000
        );
      });
  };
  render() {
    return (
      <>
        <div
          className={`${this.state.isMobile ? "" : "d-none"} pl-1`}
          style={{
            position: "fixed",
            top: "21%",
            zIndex: "10",
          }}
        >
          <button
            className="btn btn-sm btn-warning rounded-circle"
            title={this.state.sidebar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={this.handleSidebar}
          >
            <i className="fa fa-align-right"></i>
          </button>
        </div>
        <div className={`${this.state.isMobile ? "marginx" : "profile"}`}>
          <div className="row">
            <div className={`${this.state.isMobile ? "col-md-12" : "leftbar"}`}>
              {this.state.sidebar ? <Sidebar path={this.state.path} /> : ""}
            </div>
            <div
              className={`${this.state.isMobile ? "col-md-12" : "rightbar"}`}
            >
              <div className="container">
                <div className="row">
                  <span className="mt-1">
                    <div to="#" className="nav-link text-dark">
                      <h4>
                        <i
                          className="fa fa-chevron-right"
                          style={{ fontSize: 20 }}
                        ></i>{" "}
                        Sliders
                      </h4>
                    </div>
                  </span>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="container">
                          <div className="mt-3">
                            <h1>
                              <Skeleton />
                            </h1>
                            <h3>
                              <Skeleton count={5} />
                            </h3>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <div className="container">
                          <div
                            className="card card-body bg-light col-md-10 offset-md-1 pt-3"
                            style={{ minHeight: "350px" }}
                          >
                            {this.state.alert_success ? (
                              <div className="bg-success text-white text-center py-3 mb-2">
                                {this.state.alert_success}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Error Message */}
                            {this.state.error !== null ? (
                              <div className="bg-danger text-white text-center py-3 mb-2">
                                {/* Error Message */}
                                {this.state.error ? (
                                  <div className="fs-13 pl-0">
                                    {this.state.error.slider
                                      ? this.state.error.slider
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* Error Message */}
                                {/* Error Message */}
                                {this.state.error ? (
                                  <div className="fs-13 pl-0">
                                    {this.state.error.heading
                                      ? this.state.error.heading
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* Error Message */}
                                {/* Error Message */}
                                {this.state.error ? (
                                  <div className="fs-13 pl-0">
                                    {this.state.error.description
                                      ? this.state.error.description
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Error Message */}

                            <div className="card-header text-center text-uppercase mb-3">
                              Slider Images
                            </div>
                            <div className="row">
                              {this.state.sliders.map((item) => {
                                return (
                                  <div className="col-md-4 mb-3">
                                    <div
                                      className="card card-body bg-light p-0"
                                      style={{
                                        minHeight: "250px",
                                      }}
                                    >
                                      <img
                                        src={`${BASE_URL}${`/images/sliders/${item.image}`}`}
                                        className="img-fluid"
                                        style={{
                                          height: "250px",
                                          width: "100%",
                                          position: "absolute",
                                        }}
                                      />
                                      <span
                                        className="text-right "
                                        style={{
                                          position: "relative",
                                          top: 5,
                                          right: 10,
                                        }}
                                        onClick={() => {
                                          this.handleSliderDelete(item.id);
                                        }}
                                      >
                                        <i
                                          className="fa fa-times slider-delete"
                                          style={{ fontSize: "24px" }}
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mt-3 mb-3">
                            <div className="card-header text-center text-uppercase mb-3">
                              Add new slider images
                            </div>
                            <form
                              className="form-group"
                              onSubmit={this.handleSubmit}
                            >
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="image"
                                  className="my-auto col-md-3"
                                >
                                  <b>Add new slider</b>
                                </label>
                                <input
                                  type="file"
                                  id="image"
                                  className="form-control-file pl-0 col-md-9"
                                  name="image"
                                  onChange={this.handleChangeFile}
                                  required
                                />
                              </div>
                              <div className="text-center col">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-sm mt-3 px-4"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>

                          <div className="card card-body bg-light col-md-10 offset-md-1 pt-3 mt-3 mb-3">
                            <div className="card-header text-center text-uppercase mb-3">
                              Slider Description
                            </div>
                            <div className="table-responsive text-center">
                              <table className="table table-striped">
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <td>Heading</td>
                                    <td>{this.state.caption.heading}</td>
                                  </tr>
                                  <tr>
                                    <td>Description</td>
                                    <td>
                                      {this.state.caption
                                        ? this.state.caption.description
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <form
                              className="form-group"
                              onSubmit={this.handleSubmitDetails}
                            >
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="heading"
                                  className="my-auto col-md-3"
                                >
                                  <b>Heading</b>
                                </label>
                                <input
                                  type="text"
                                  id="heading"
                                  className="form-control pl-0 col-md-9"
                                  name="heading"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              <div className="input-group col-md-12 mt-2">
                                <label
                                  htmlFor="description"
                                  className="my-auto col-md-3"
                                >
                                  <b>Description</b>
                                </label>
                                <input
                                  type="text"
                                  id="description"
                                  className="form-control pl-0 col-md-9"
                                  name="description"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              <div className="text-center col">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-sm mt-3 px-4"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Slider);
